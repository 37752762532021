import React from "react";
import { useSelector } from "react-redux"; // Hook to access the Redux store
import { Skeleton } from "@mui/material"; // MUI Skeleton component for loading placeholders

import FulfillmentCard from "./FulfillmentCard";
import InstamartUpcomingPO from "./InstamartUpcomingPO";
import FillRateInstamart from "./FillRateInstamart";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Function to calculate the average time difference between last inward date and purchase order date
const groupedDataAverageTime = (data) => {
  // Helper function to calculate date difference in days
  function calculateDateDifference(lastInwardDateStr, poDateStr) {
    const lastInwardDate = new Date(lastInwardDateStr);
    const poDate = new Date(poDateStr);
    const differenceInMillis = lastInwardDate - poDate;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.round(differenceInMillis / millisecondsPerDay); // Return difference in days
  }

  const result = {}; // Object to store PO code and associated time difference

  // Loop through each entry in the data
  data?.forEach((entry) => {
    const lastInwardDate = entry["fulfillment_report_instamart.last_inward_date"]; // Extract last inward date
    const poDate = entry["fulfillment_report_instamart.podate"]; // Extract purchase order date
    const pocode = entry["fulfillment_report_instamart.pocode"]; // Extract purchase order code

    // If both dates exist, calculate the date difference
    if (lastInwardDate && poDate) {
      const difference = calculateDateDifference(lastInwardDate, poDate);
      if (!result[pocode]) {
        result[pocode] = {
          diff: 0,
          count: 0,
        };
      }
      // Add difference to result and increment count
      result[pocode].diff += difference;
      result[pocode].count++;
    }
  });

  return result; // Return the result containing PO codes and their associated differences
};

// Function to group data based on PO code and calculate quantities delivered in and after lead time
const getGroupedData = (data) => {
  return data?.reduce((acc, item) => {
    const sku = item["fulfillment_report_instamart.pocode"]; // Extract PO code
    const afterLeadTime = parseInt(item["fulfillment_report_instamart.qty_delivered_after_leadtime"], 10); // Extract quantity delivered after lead time
    const inLeadTime = parseInt(item["fulfillment_report_instamart.qty_delivered_in_leadtime"], 10); // Extract quantity delivered in lead time

    // Initialize object for the PO code if it doesn't exist
    if (!acc[sku]) {
      acc[sku] = {
        after_lead_time: 0,
        in_lead_time: 0,
      };
    }

    // Add quantities to the corresponding lead time fields
    acc[sku].after_lead_time += afterLeadTime;
    acc[sku].in_lead_time += inLeadTime;

    return acc; // Return the accumulated result
  }, {});
};

// Main container component for Instamart fulfillment sections
const FulfillmentInstamartSectionsContainer = () => {
  const { instamartFulfillment } = useSelector((state) => state.fulfillment); // Get Instamart fulfillment data from Redux store
  const groupedData = getGroupedData(instamartFulfillment.data); // Group the data based on PO code
  const averageTime = groupedDataAverageTime(instamartFulfillment.data); // Calculate the average turnaround time

  let totalAfter = 0;
  let totalBefore = 0;

  // Calculate total fulfilled POs for both after lead time and in lead time
  for (const key in groupedData) {
    if (groupedData[key].hasOwnProperty("after_lead_time") && groupedData[key].after_lead_time !== 0) {
      totalAfter++; // Increment total after lead time
    }
    if (groupedData[key].hasOwnProperty("in_lead_time") && groupedData[key].in_lead_time !== 0) {
      totalBefore++; // Increment total in lead time
    }
  }

  let count = 0;
  let total = 0;

  // Calculate total average turnaround time from the averageTime object
  for (const key in averageTime) {
    if (averageTime.hasOwnProperty(key)) {
      count++; // Count the number of PO codes
      total += averageTime[key].diff / averageTime[key].count; // Calculate average time for each PO code
    }
  }

  // Display loading skeletons when the data is loading
  if (instamartFulfillment.loading) {
    return (
      <div>
        {/* Skeleton placeholders for the cards */}
        <div className="mb-2 rounded p-2 flex gap-10">
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} />
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} />
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} />
        </div>

        {/* Skeleton placeholders for the table data */}
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }

  // If no data is found, display the "NoDataFound" component
  if (!instamartFulfillment.data || instamartFulfillment.data.length === 0) {
    return <NoDataFound />;
  }

  // If data is available, render the actual content
  return (
    <>
      <div className="w-full py-2 flex items-center gap-4 flex-wrap">
        {/* Card displaying the total number of POs fulfilled before lead time */}
        <FulfillmentCard total={totalBefore} text={"Total Number of PO's fulfilled before Lead Time"} card={"first"} />
        {/* Card displaying the total number of POs fulfilled after lead time */}
        <FulfillmentCard total={totalAfter} text={"Total Number of PO's fulfilled After Time"} card={"second"} />
        {/* Card displaying the average turnaround time */}
        <FulfillmentCard total={`${(total / count).toFixed(2)} Days`} text={"Average Turn Around Time"} card={"last"} />
      </div>

      <>
        {/* Component for displaying upcoming purchase orders */}
        <InstamartUpcomingPO />
        {/* Component for displaying fill rate data */}
        <FillRateInstamart />
      </>
    </>
  );
};

export default FulfillmentInstamartSectionsContainer;
