import React, { useEffect, useState } from "react";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import ShareOfVoiceSectionsContainer from "../../components/visibility/ShareOfVoiceComponents/ShareOfVoiceSectionsContainer";
import {
  fetchSoaThirdPartyVisibilityKeywordData,
  fetchSoaThirdPartyVisibilityAreaNameData,
  fetchSovThirdPartyVisibilityWeeklyKeywordData,
  fetchSoaThirdPartyVisibilityWeeklyAreaNameData,
} from "../../services/visibility/sovThirdPartyVisibilityServices";
import {
  getFilteredSovAreaName,
  getSovByAreaNameFailure,
  getSovByAreaNameSuccess,
  getSovByAreaNameRequest,
  getSovByKeywordFailure,
  getSovByKeywordRequest,
  getSovByKeywordSuccess,
  getSovByKeywordWeeklyRequest,
  getSovByKeywordWeeklySuccess,
  getSovByKeywordWeeklyFailure,
  getSovByAreaNameWeeklyRequest,
  getSovByAreaNameWeeklySuccess,
  getSovByAreaNameWeeklyFailure,
  getFilteredSovWeeklyAreaName,
} from "../../redux/actions/sovThirdPartyActions";
import { useDispatch, useSelector } from "react-redux";

const options = ["Share of Voice"];

const MAX_ATTEMPTS = 180;

const VisibilityScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);

  const { sovAreaNameData, selectedSovKeyword, sovAreaNameWeeklyData } = useSelector((state) => state.sovThirdParty);


  const { selectedDate } = useDate();
  const dispatch = useDispatch();

  const fetchWithRetryPlatformName = async (
    fetchFunction,
    successAction,
    failureAction,
    date,
    attempt = 1
  ) => {
    if (attempt > MAX_ATTEMPTS) {
      dispatch(failureAction(new Error("No data available for the past 15 days.")));
      return;
    }


    // to get initail areanames data

    try {
      console.log("Attempt:", attempt, "Date:", date.toISOString().split("T")[0]);
      const result = await fetchFunction(date.toISOString().split("T")[0], platformName);
      if (result && result.length > 0) {
        dispatch(successAction(result)); // Success case, stop retries
      } else {
        throw new Error("No data found");
      }
    } catch (error) {

      console.warn(`Retrying with previous date due to: ${error.message}`);

      // Clone the date object and decrement it by one day
      const previousDate = new Date(date);
      previousDate.setDate(previousDate.getDate() - 1);

      // Retry with decremented date
      await fetchWithRetryPlatformName(
        fetchFunction,
        successAction,
        failureAction,
        previousDate,
        attempt + 1
      );

    }
  };

  const handleQueries = async () => {
    const initialDate = new Date(selectedDate);

    await fetchWithRetryPlatformName(
      fetchSoaThirdPartyVisibilityKeywordData,
      getSovByKeywordSuccess,
      getSovByKeywordFailure,
      initialDate
    );

    await fetchWithRetryPlatformName(
      fetchSoaThirdPartyVisibilityAreaNameData,
      getSovByAreaNameSuccess,
      getSovByAreaNameFailure,
      initialDate
    );

    await fetchWithRetryPlatformName(
      fetchSovThirdPartyVisibilityWeeklyKeywordData,
      getSovByKeywordWeeklySuccess,
      getSovByKeywordWeeklyFailure,
      initialDate
    );

    await fetchWithRetryPlatformName(
      fetchSoaThirdPartyVisibilityWeeklyAreaNameData,
      getSovByAreaNameWeeklySuccess,
      getSovByAreaNameWeeklyFailure,
      initialDate
    );
  };

  const filterAreaNamesForSelectedKeyword = (selectedSovKeyword) => {
    try {
      dispatch(getSovByAreaNameRequest());

      const filteredAreaNameData = sovAreaNameData.data.filter(
        (item) => item["third_party_keyword.keyword"] === selectedSovKeyword
      );

      dispatch(getFilteredSovAreaName(filteredAreaNameData));
    } catch (error) {
      console.log("error", error);
      dispatch(getSovByAreaNameFailure(error));
    }
  };

  const filterWeeklyAreaNamesForSelectedKeyword = (selectedSovKeyword) => {
    try {
      dispatch(getSovByAreaNameWeeklyRequest());

      const filteredWeeklyAreaNameData = sovAreaNameWeeklyData.data.filter(
        (item) => item["third_party_keyword.keyword"] === selectedSovKeyword
      );

      dispatch(getFilteredSovWeeklyAreaName(filteredWeeklyAreaNameData));
    } catch (error) {
      dispatch(getSovByAreaNameWeeklyFailure(error));
    }
  };

  useEffect(() => {
    if (selectedSovKeyword) {
      filterAreaNamesForSelectedKeyword(selectedSovKeyword);
      filterWeeklyAreaNamesForSelectedKeyword(selectedSovKeyword);
    } else {
      dispatch(getFilteredSovAreaName(sovAreaNameData.data));
    }
  }, [selectedDate, selectedSovKeyword]);

  useEffect(() => {
    handleQueries();
  }, [selectedDate, platformName]);

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 && <ShareOfVoiceSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default VisibilityScreen;
