import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CategoryRcaOverviewChartCard = ({ info }) => {
  const data = {
    labels: ["Jun 24", "Jul 24", "Aug 24"],
    datasets: [
      {
        label: "Offtake at MRP",
        data: [1.4, 1.5, 1.6], // Example data for the graph
        fill: true,
        backgroundColor: "rgba(247,250,254,0.8)",
        borderColor: "#387FF5",
        tension: 0.3,
        borderColor: "#387FF5",
        borderWidth: 1,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#387FF5",
        pointBorderWidth: 2,
        pointRadius: 2,
        pointHoverRadius: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `₹${value} Cr`,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="max-w-sm w-96 p-4 bg-white border rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <div>
          <span className="text-sm text-gray-600">{info.title}</span>
          <span className="ml-2 bg-navBarColour text-white px-2 py-1 rounded-full text-xs font-semibold">MRP</span>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-gray-800 mb-1">{info.value}</span>
        <span className="ml-2 text-sm text-gray-500">for MTD</span>
      </div>
      <div className="text-sm text-green-600">
        <span className="font-semibold">11.9%</span> (<span>₹16.9 lac</span>) vs Previous Month
      </div>
      <div className="mt-4">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default CategoryRcaOverviewChartCard;