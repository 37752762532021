import { RESET_SIDEBAR, SIDEBAR_OPEN } from "../constants/SidebarConstants";

const initialState = {
  submenuOpen: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  },
};

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    case RESET_SIDEBAR:
      return {};
    default:
      return state;
  }
};
