// import React from "react";
// import { dummyPricingData } from "../../../utils/dummyPricingData";
// import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

// const groupedDataBySKU = (data) => {
//   return data.reduce((acc, item) => {
//     const sku = item.sku;
//     if (!acc[sku]) {
//       acc[sku] = {
//         sku: sku,
//         product: item.product,
//         brands: item.brand,
//         price: 0,
//         total_discounted_price: 0,
//         count: 0,
//       };
//     }

//     acc[sku].price += parseFloat(item.price);
//     acc[sku].total_discounted_price += parseFloat(item.discounted_price);
//     acc[sku].count += 1;
//     return acc;
//   }, {});
// };

// const CompetitorPricingDrainers = () => {
//   // Step 1: Sort the data by date in descending order
//   const sortedData = dummyPricingData.sort((a, b) => new Date(b.date) - new Date(a.date));

//   // Step 2: Get unique dates
//   const uniqueDates = [...new Set(sortedData.map((item) => item.date))];

//   // Step 3: Filter data for the last two dates
//   const lastDate = uniqueDates[0];
//   const firstDate = uniqueDates[uniqueDates.length - 1];

//   const last = sortedData.filter((item) => item.date === lastDate);
//   const first = sortedData.filter((item) => item.date === firstDate);
//   const firstData = groupedDataBySKU(first);
//   const lastData = groupedDataBySKU(last);
//   const lastDateData = Object.values(lastData);
//   const firstDateData = Object.values(firstData);

//   // Step 4: Create a lookup for first date data
//   const firstDateLookup = firstDateData.reduce((lookup, item) => {
//     const key = `${item.sku}_${item.product}`;
//     lookup[key] = item;
//     return lookup;
//   }, {});

//   // Step 5: Calculate price difference and create a result array
//   const priceDifferenceData = lastDateData
//     .map((item) => {
//       const key = `${item.sku}_${item.product}`;
//       const firstItem = firstDateLookup[key];

//       if (firstItem) {
//         const priceDifference = parseFloat(item.price) / item.count - parseFloat(firstItem.price) / firstItem.count;
//         return {
//           sku: item.sku,
//           product: item.product,
//           brand: item.brand,
//           city: item.city,
//           lastDatePrice: parseFloat(item.price) / item.count,
//           firstDatePrice: parseFloat(firstItem.price) / firstItem.count,
//           priceDifference: priceDifference,
//           diffPercentage: (priceDifference / (item.price / item.count)) * 100,
//         };
//       }
//       return null;
//     })
//     .filter((item) => item !== null);

//   // Step 6: Sort the result by price difference and get the top 5
//   const top5PriceDifferences = priceDifferenceData
//     .sort((a, b) => b.priceDifference - a.priceDifference)
//     .slice(0, 5)
//     .filter((item) => item.priceDifference > 0);

//   return (
//     <div className="flex-1 bg-white">
//       <div className="font-extrabold p-2">Top Drainers</div>
//       <div className="border rounded-md p-2">
//         <TableContainer sx={{ borderRadius: "0.25rem" }}>
//           <Table sx={{ position: "relative" }}>
//             <TableHead className="sticky top-0">
//               <TableRow className="border-t">
//                 <TableCell sx={{ fontWeight: "500", bgcolor: "#F9F9F9", fontSize: "0.875rem", textAlign: "left" }}>
//                   SKU
//                 </TableCell>
//                 <TableCell sx={{ fontWeight: "500", bgcolor: "#F9F9F9", fontSize: "0.875rem", textAlign: "center" }}>
//                   PRICE
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {top5PriceDifferences.length > 0 ? (
//                 <>
//                   {top5PriceDifferences.map((item, index) => (
//                     <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
//                       <TableCell sx={{ textAlign: "left", color: "#4d4d4d" }}>{item.sku}</TableCell>
//                       <TableCell sx={{ color: "green", fontWeight: "500", textAlign: "center" }}>
//                         ▲ {item.lastDatePrice} ({item.priceDifference.toFixed(2)}%)
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </>
//               ) : (
//                 <TableRow className="w-full flex justify-center items-center font-semibold">
//                   No Data in the Gainers
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </div>
//     </div>
//   );
// };

// export default CompetitorPricingDrainers;

import React, { useState } from "react";
import { dummyPricingData } from "../../../utils/dummyPricingData";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";

const groupedDataBySKU = (data) => {
  return data.reduce((acc, item) => {
    const sku = item.sku;
    if (!acc[sku]) {
      acc[sku] = {
        sku: sku,
        product: item.product,
        brands: item.brand,
        price: 0,
        total_discounted_price: 0,
        count: 0,
      };
    }

    acc[sku].price += parseFloat(item.price);
    acc[sku].total_discounted_price += parseFloat(item.discounted_price);
    acc[sku].count += 1;
    return acc;
  }, {});
};

const CompetitorPricingDrainers = () => {
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("sku");

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Step 1: Sort the data by date in descending order
  const sortedData = dummyPricingData.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Step 2: Get unique dates
  const uniqueDates = [...new Set(sortedData.map((item) => item.date))];

  // Step 3: Filter data for the last two dates
  const lastDate = uniqueDates[0];
  const firstDate = uniqueDates[uniqueDates.length - 1];

  const last = sortedData.filter((item) => item.date === lastDate);
  const first = sortedData.filter((item) => item.date === firstDate);
  const firstData = groupedDataBySKU(first);
  const lastData = groupedDataBySKU(last);
  const lastDateData = Object.values(lastData);
  const firstDateData = Object.values(firstData);

  // Step 4: Create a lookup for first date data
  const firstDateLookup = firstDateData.reduce((lookup, item) => {
    const key = `${item.sku}_${item.product}`;
    lookup[key] = item;
    return lookup;
  }, {});

  // Step 5: Calculate price difference and create a result array
  const priceDifferenceData = lastDateData
    .map((item) => {
      const key = `${item.sku}_${item.product}`;
      const firstItem = firstDateLookup[key];

      if (firstItem) {
        const priceDifference = parseFloat(item.price) / item.count - parseFloat(firstItem.price) / firstItem.count;
        return {
          sku: item.sku,
          product: item.product,
          brand: item.brand,
          city: item.city,
          lastDatePrice: parseFloat(item.price) / item.count,
          firstDatePrice: parseFloat(firstItem.price) / firstItem.count,
          priceDifference: priceDifference,
          diffPercentage: (priceDifference / (item.price / item.count)) * 100,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  // Step 6: Sort the result by price difference and get the top 5
  const top5PriceDifferences = priceDifferenceData
    .sort((a, b) => b.priceDifference - a.priceDifference)
    .slice(0, 5)
    .filter((item) => item.priceDifference > 0);

  const sortedTop5 = top5PriceDifferences.sort((a, b) => {
    if (orderBy === "sku") {
      return orderDirection === "asc" ? a.sku.localeCompare(b.sku) : b.sku.localeCompare(a.sku);
    } else if (orderBy === "lastDatePrice") {
      return orderDirection === "asc" ? a.lastDatePrice - b.lastDatePrice : b.lastDatePrice - a.lastDatePrice;
    }
    return 0;
  });

  return (
    <div className="flex-1 bg-white">
      <div className="font-extrabold p-2">Top Drainers</div>
      <div className="border rounded-md p-2">
        <TableContainer sx={{ borderRadius: "0.25rem" }}>
          <Table sx={{ position: "relative" }}>
            <TableHead className="sticky top-0">
              <TableRow className="border-t">
                <TableCell sx={{ fontWeight: "500", bgcolor: "#F9F9F9", fontSize: "0.875rem", textAlign: "left" }}>
                  <TableSortLabel
                    active={orderBy === "sku"}
                    direction={orderDirection}
                    onClick={() => handleSortRequest("sku")}
                  >
                    SKU
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: "500", bgcolor: "#F9F9F9", fontSize: "0.875rem", textAlign: "center" }}>
                  <TableSortLabel
                    active={orderBy === "lastDatePrice"}
                    direction={orderDirection}
                    onClick={() => handleSortRequest("lastDatePrice")}
                  >
                    PRICE
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTop5.length > 0 ? (
                <>
                  {sortedTop5.map((item, index) => (
                    <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                      <TableCell sx={{ textAlign: "left", color: "#4d4d4d" }}>{item.sku}</TableCell>
                      <TableCell sx={{ color: "green", fontWeight: "500", textAlign: "center" }}>
                        ▲ {item.lastDatePrice} ({item.priceDifference.toFixed(2)}%)
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow className="w-full flex justify-center items-center font-semibold">
                  No Data in the Drainers
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CompetitorPricingDrainers;
