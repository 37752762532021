import { MenuItem, Select } from "@mui/material";

const ShareOfVoiceSelectBox = ({ text, items, selectedItem, setSelectedItem }) => {
  return (
    <div className="gap-3 mt-2 w-full flex font-semibold justify-end items-center">
      <span>{text}</span>
      <Select
        className="bg-white"
        value={selectedItem}
        sx={{
          width: "20rem",
        }}
        onChange={(e) => setSelectedItem(e.target.value)}
        displayEmpty
      >
        {items.map((item) => (
          <MenuItem key={item} value={item} selected={selectedItem == item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ShareOfVoiceSelectBox;
