import React, { useCallback, useEffect, useState } from "react";
import { BsGraphUpArrow } from "react-icons/bs";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import "./css/adChart.css";

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const DisplayAdsChart = ({ groupedData }) => {
  const [hiddenDatasets, setHiddenDatasets] = useState([]);
  const [showLeftYAxis, setShowLeftYAxis] = useState(true);
  const [showRightYAxis, setShowRightYAxis] = useState(true);

  const data = groupedData;

  // Sort the dates in ascending order
  const sortedDates = Object.keys(data).sort((a, b) => {
    const [dayA, monthA, yearA] = a.split("-").map(Number);
    const [dayB, monthB, yearB] = b.split("-").map(Number);
    return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
  });

  // Format the dates to show only DD-MM
  const formattedDates = sortedDates.map((date) => {
    const [day, month] = date.split("-");
    return `${day}-${month}`;
  });

  const reachData = sortedDates.map((date) => data[date].reach);
  const impressionsData = sortedDates.map((date) => data[date].impressions);
  const estimateBudgetData = sortedDates.map((date) => data[date].estimateBudget);
  const cpmData = sortedDates.map((date) => data[date].cpm / data[date].count);

  const chartData = {
    labels: formattedDates,
    datasets: [
      {
        label: "Reach",
        data: reachData,
        borderColor: "#FF6384",
        pointBorderColor: "#FF6384",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("Add to Cart"),
      },
      {
        label: "Impressions",
        data: impressionsData,
        borderColor: "#22CFCF",
        pointBorderColor: "#22CFCF",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("Total Quantities Sold"),
      },
      {
        label: "Total Spent",
        data: estimateBudgetData,
        borderColor: "#FEBE40",
        pointBorderColor: "#FEBE40",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "left-y-axis",
        hidden: hiddenDatasets.includes("Total Spent"),
      },
      {
        label: "CPM",
        data: cpmData,
        borderColor: "#8142FF",
        pointBackgroundColor: "white",
        pointBorderColor: "#8142FF",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("CPM"),
      },
    ],
  };

  const getAxisTitles = useCallback(() => {
    const leftAxisLabels = chartData.datasets
      .filter((dataset) => dataset.yAxisID === "left-y-axis" && !dataset.hidden)
      .map((dataset) => dataset.label)
      .join(" & ");

    const rightAxisLabels = chartData.datasets
      .filter((dataset) => dataset.yAxisID === "right-y-axis" && !dataset.hidden)
      .map((dataset) => dataset.label)
      .join(" & ");

    return {
      left: leftAxisLabels || "Total Quantities Spent",
      right: rightAxisLabels || "Reach, CPM & Impressions",
    };
  }, [hiddenDatasets, chartData.datasets]);

  const updateAxisVisibility = useCallback(() => {
    const leftYAxisHidden = chartData.datasets.every(
      (dataset) => dataset.yAxisID === "left-y-axis" && hiddenDatasets.includes(dataset.label)
    );

    const rightYAxisHidden = chartData.datasets.every(
      (dataset) => dataset.yAxisID === "right-y-axis" && hiddenDatasets.includes(dataset.label)
    );

    setShowLeftYAxis(!leftYAxisHidden);
    setShowRightYAxis(!rightYAxisHidden);
  }, [hiddenDatasets, chartData.datasets]);

  useEffect(() => {
    updateAxisVisibility();
  }, [hiddenDatasets, updateAxisVisibility]);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to be more responsive
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true, // Makes legend items more modern with point styles
          padding: 20,
          font: {
            size: 12,
            weight: 400,
          },
        },
        onClick: (event, legendItem) => {
          const datasetLabel = legendItem.text;
          setHiddenDatasets((prev) =>
            prev.includes(datasetLabel) ? prev.filter((label) => label !== datasetLabel) : [...prev, datasetLabel]
          );
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0,0,0,0.7)",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },
        cornerRadius: 4,
        padding: 10,
      },
    },
    scales: {
      "left-y-axis": {
        display: showLeftYAxis,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: getAxisTitles().left,
          font: {
            size: 12,
          },
          color: "#333",
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
          font: {
            family: "Nunito", // Use the Nunito font family here
            size: 12, // Optional: set the font size
            weight: 400,
            style: "normal", // Optional: set the font style
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      "right-y-axis": {
        display: showRightYAxis,
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: getAxisTitles().right,
          font: {
            size: 12,
          },
          color: "#333",
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
          font: {
            family: "Nunito", // Use the Nunito font family here
            size: 12, // Optional: set the font size
            weight: 400,
            style: "normal", // Optional: set the font style
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Dates",
          font: {
            size: 12,
          },
          color: "#333",
        },
        ticks: {
          color: "#333",
          font: {
            family: "Nunito", // Use the Nunito font family here
            size: 12.5, // Optional: set the font size
            weight: 400,
            style: "normal", // Optional: set the font style
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
      line: {
        borderWidth: 3,
      },
    },
  };

  return (
    <div className="shadow-md rounded-xl mb-8 mt-4 bg-white">
      <div className="flex p-4 border-b">
        <h1 className="flex  gap-4 items-center text-lg font-medium">
          {/* Orders Per Day section with icon */}
          <div>
            <BsGraphUpArrow />
          </div>
          Ads Metrics
          <CustomTooltip
            title={
              "Analyze campaign performance over time with a detailed breakdown of key metrics including Impressions, Reach, Total Spend, and Cost Per Mille (CPM). Gain insights into trends and make informed decisions."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
      </div>

      <div className="chart-container-ads">
        <div className="w-full h-full p-6 font-nunito ">
          <Line className="border p-6 font-nunito  rounded-lg" data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default DisplayAdsChart;
