import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import {
  cityProfitabilityGroupingBlinkit,
  cityProfitabilityGroupingInstamart,
  cityProfitabilityGroupingZepto,
} from "../../../utils/dataGrouping/OverallProfitability/CityProfitabilityGrouping";
import TopPerformingCitiesCard from "./TopPerformingCitiesCard";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

function mergeArraysByProductName(arr1, arr2) {
  const mergedArray = [];

  // Create a map for faster lookup
  const arr2Map = new Map(arr2.map((item) => [item.category, item]));

  // Iterate through the first array
  arr1.forEach((item1) => {
    const item2 = arr2Map.get(item1.category);

    if (item2) {
      // If the product name is the same, merge the objects
      let diff;
      let diffPercentage;
      if (item2.mrp > item1.mrp) {
        diff = item2.mrp - item1.mrp;
        diffPercentage = (diff / item1.mrp) * 100;
      } else {
        diff = item1.mrp - item2.mrp;
        diffPercentage = (diff / item2.mrp) * 100;
      }

      mergedArray.push({
        city: item1.category,
        previous: item1.mrp,
        current: item2.mrp,
        diff: diff,
        diffPercentage: diffPercentage,
      });
    }
  });

  return mergedArray;
}

const TopPerformingCities = ({ salesData, cityInsights, platformName }) => {
  let currentData;
  let previousData;
  if (platformName == "Blinkit") {
    currentData = cityProfitabilityGroupingBlinkit(salesData);
    previousData = cityProfitabilityGroupingBlinkit(cityInsights);
  } else if (platformName == "Instamart") {
    currentData = cityProfitabilityGroupingInstamart(salesData);
    previousData = cityProfitabilityGroupingInstamart(cityInsights);
  } else {
    currentData = cityProfitabilityGroupingZepto(salesData);
    previousData = cityProfitabilityGroupingZepto(cityInsights);
  }
  const mergedArray = mergeArraysByProductName(previousData, currentData);
  const top5Cities = mergedArray.sort((a, b) => b.current - a.current).slice(0, 5);

  return (
    <div>
      <div className="font-semibold">Top Performing Cities</div>
      <div className="border p-3 rounded-lg">
        {top5Cities.length > 0 ? (
          top5Cities.map((item, index) => (
            <TopPerformingCitiesCard
              key={index}
              title={item.city}
              mrp={item.current}
              previous={item.previous}
              percentageChange={item.diffPercentage.toFixed(2)}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-full">No Top Performing Cities</div>
        )}
      </div>
    </div>
  );
};

export default TopPerformingCities;
