import { Skeleton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import FulfillmentCard from "./FulfillmentCard";
import FillRateZepto from "./FillRateZepto";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Function to group and calculate the average time difference between two dates
const groupedDataAverageTime = (data) => {
  // Function to calculate the difference in days between two dates
  function calculateDateDifference(lastInwardDateStr, poDateStr) {
    const lastInwardDate = new Date(lastInwardDateStr); // Convert last inward date string to Date object
    const poDate = new Date(poDateStr); // Convert PO date string to Date object
    const differenceInMillis = lastInwardDate - poDate; // Calculate the difference in milliseconds
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    return Math.round(differenceInMillis / millisecondsPerDay); // Return the difference in days
  }

  // Object to store the results for each PO code
  const result = {};

  // Loop through each entry in the data to calculate the date differences
  data?.forEach((entry) => {
    // Reverse the date strings from "dd-mm-yyyy" to "yyyy-mm-dd" for Date parsing
    const lastInwardDate = entry["fulfillment_report_zepto.grn_date"].split("-").reverse().join("-");
    const poDate = entry["fulfillment_report_zepto.po_date"].split("-").reverse().join("-");
    const pocode = entry["fulfillment_report_zepto.po_code"].split("-").reverse().join("-");

    // If both dates are available, calculate the difference
    if (lastInwardDate && poDate) {
      const difference = calculateDateDifference(lastInwardDate, poDate);

      // If the PO code is not in the result object, initialize it
      if (!result[pocode]) {
        result[pocode] = {
          diff: 0, // Initialize the total difference
          count: 0, // Initialize the count for this PO code
        };
      }

      // Add the difference to the total and increment the count
      result[pocode].diff += difference;
      result[pocode].count++;
    }
  });

  return result; // Return the object containing total differences and counts
};

// Main component for rendering Zepto fulfillment data
const FulfillmentZeptoSectionsContainer = () => {
  const { zeptoFulfillment } = useSelector((state) => state.fulfillment); // Get Zepto fulfillment data from Redux store
  const averageTime = groupedDataAverageTime(zeptoFulfillment.data); // Get average time data
  let count = 0; // Initialize count of POs
  let total = 0; // Initialize total difference in days

  // Loop through the averageTime object to calculate the overall average
  for (const key in averageTime) {
    if (averageTime.hasOwnProperty(key)) {
      count++; // Increment the count for each PO code
      total += averageTime[key].diff / averageTime[key].count; // Add the average for each PO code to the total
    }
  }

  // Render loading state with skeletons while data is being fetched
  if (zeptoFulfillment.loading) {
    return (
      <div>
        <div className="mb-2 rounded p-2 flex gap-10">
          <Skeleton variant="rounded" sx={{ width: "30%", height: "150px" }} />
        </div>

        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }

  // Render a "No Data Found" component if there is no data
  if (!zeptoFulfillment.data || zeptoFulfillment.data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <>
      {/* Display the calculated average turnaround time in a card */}
      <div className="w-full py-2 flex items-center gap-4 flex-wrap">
        <FulfillmentCard
          total={`${(total / count).toFixed(2)} Days`} // Display the average turnaround time rounded to 2 decimal places
          text={"Average Turn Around Time"} // Label for the card
        />
      </div>

      {/* Additional section to show fulfillment-related data */}
      <>
        <FillRateZepto /> {/* Component to show fill rate details for Zepto */}
      </>
    </>
  );
};

export default FulfillmentZeptoSectionsContainer;
