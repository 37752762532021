import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider.js";

import {
  fetchPotentialSalesLossBlinkit,
  fetchPotentialSalesLossZepto,
  fetchPotentialSalesLossInstamart,
  fetchPslBlinkitFulfillment,
  fetchPslZeptoFulfillment,
  fetchPslInstamartFulfillment,
} from "../../services/fulfillmentServices/potentialSalesLossServices.js";

// Import Redux actions for fulfillment and potential sales loss
import {
  getPslBlinkitFulfillmentRequest,
  getPslBlinkitFulfillmentSuccess,
  getPslBlinkitFulfillmentFailure,
  getPslZeptoFulfillmentRequest,
  getPslZeptoFulfillmentSuccess,
  getPslZeptoFulfillmentFailure,
  getPslInstamartFulfillmentRequest,
  getPslInstamartFulfillmentSuccess,
  getPslInstamartFulfillmentFailure,
} from "../../redux/actions/pslFulfillmentActions.js";

import {
  getPotentialSalesLossBlinkitRequest,
  getPotentialSalesLossBlinkitSuccess,
  getPotentialSalesLossBlinkitFailure,
  getPotentialSalesLossZeptoRequest,
  getPotentialSalesLossZeptoSuccess,
  getPotentialSalesLossZeptoFailure,
  getPotentialSalesLossInstamartRequest,
  getPotentialSalesLossInstamartSuccess,
  getPotentialSalesLossInstamartFailure,
} from "../../redux/actions/potentialSalesLossActions.js";
import PotentialSalesLossBlinkitSectionsContainer from "../../components/Fulfillment/PotentialSalesLossComponents/PotentialSalesLossBlinkitSectionsContainer.js";
import PotentialSalesLossZeptoSectionsContainer from "../../components/Fulfillment/PotentialSalesLossComponents/PotentialSalesLossZeptoSectionsContainer.js";
import PotentialSalesLossInstamartSectionsContainer from "../../components/Fulfillment/PotentialSalesLossComponents/PotentialSalesLossInstamartSectionsContainer.js";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar.js";
import Toolbar from "../../components/Toolbar/Toolbar.js";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch.js";
import TableSkeleton from "../../components/common/Skeletons/TableSkeleton.js";
import NoDataFound from "../../components/common/NoDataFound/NoDataFound.js";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback.js";

const options = ["Potential Sales Loss"];

const PotentialSalesLossScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit"); // State to manage the selected platform
  const { potentialSalesLossBlinkit, potentialSalesLossZepto, potentialSalesLossInstamart } = useSelector(
    (state) => state.potentailSalesLoss
  ); // Retrieve potential sales loss data from Redux store

  const dispatch = useDispatch(); // Initialize Redux dispatch
  const [selectedOption, setSelectedOption] = useState(0); // State to manage the selected option in the ComponentSwitch
  const { selectedDate } = useDate(); // Retrieve selected date from the custom hook
  console.log("🚀 ~ PotentialSalesLossScreen ~ selectedDate:", selectedDate);

  useEffect(() => {
    setSelectedOption(0); // Reset selected option when platformName changes
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchLoss: fetchPotentialSalesLossBlinkit,
      fetchFulfillment: fetchPslBlinkitFulfillment,
      successAction: {
        request: getPotentialSalesLossBlinkitRequest,
        success: getPotentialSalesLossBlinkitSuccess,
        failure: getPotentialSalesLossBlinkitFailure,
      },
      fulfillmentSuccess: {
        request: getPslBlinkitFulfillmentRequest,
        success: getPslBlinkitFulfillmentSuccess,
        failure: getPslBlinkitFulfillmentFailure,
      },
    },
    Zepto: {
      fetchLoss: fetchPotentialSalesLossZepto,
      fetchFulfillment: fetchPslZeptoFulfillment,
      successAction: {
        request: getPotentialSalesLossZeptoRequest,
        success: getPotentialSalesLossZeptoSuccess,
        failure: getPotentialSalesLossZeptoFailure,
      },
      fulfillmentSuccess: {
        request: getPslZeptoFulfillmentRequest,
        success: getPslZeptoFulfillmentSuccess,
        failure: getPslZeptoFulfillmentFailure,
      },
    },
    Instamart: {
      fetchLoss: fetchPotentialSalesLossInstamart,
      fetchFulfillment: fetchPslInstamartFulfillment,
      successAction: {
        request: getPotentialSalesLossInstamartRequest,
        success: getPotentialSalesLossInstamartSuccess,
        failure: getPotentialSalesLossInstamartFailure,
      },
      fulfillmentSuccess: {
        request: getPslInstamartFulfillmentRequest,
        success: getPslInstamartFulfillmentSuccess,
        failure: getPslInstamartFulfillmentFailure,
      },
    },
    // Add configurations for other platforms/screens similarly
  };

  // Effect to trigger the appropriate query handling based on the selected platform and date
  // useEffect(() => {
  //   if (platformName === "Blinkit") {
  //     handleBlinkitQueries();
  //   } else if (platformName === "Instamart") {
  //     handleInstamartQueries();
  //   } else {
  //     handleZeptoQueries();
  //   }
  // }, [platformName, selectedDate]);

  useEffect(() => {
    const platform = platformDetails[platformName];
    if (platform) {
      // Fetch Loss Data
      dispatch(platform.successAction.request());
      fetchWithRetry(
        platform.fetchLoss,
        platform.successAction.success,
        platform.successAction.failure,
        selectedDate,
        dispatch,
        1
      );

      // Fetch Fulfillment Data
      dispatch(platform.fulfillmentSuccess.request());
      fetchWithRetry(
        platform.fetchFulfillment,
        platform.fulfillmentSuccess.success,
        platform.fulfillmentSuccess.failure,
        selectedDate,
        dispatch,
        1
      );
    }
  }, [platformName, selectedDate]);

  return (
    <>
      {/* Render the HeadingBar component with platformName and selectedDate */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />
      <Toolbar>
        {/* Component switch to toggle between different options */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 && (
          <div className="flex flex-col mt-[1rem] w-full overflow-hidden h-max pb-10">
            {/* Render different components based on the selected platform */}
            {platformName == "Blinkit" && (
              <>
                {potentialSalesLossBlinkit.loading ? (
                  <TableSkeleton /> // Show loading skeleton if data is still loading
                ) : !potentialSalesLossBlinkit.data || potentialSalesLossBlinkit.data.length === 0 ? (
                  <NoDataFound /> // Show no data found component if no data is available
                ) : (
                  <PotentialSalesLossBlinkitSectionsContainer platformName={platformName} /> // Render Blinkit data component
                )}
              </>
            )}
            {platformName == "Zepto" && (
              <>
                {potentialSalesLossZepto.loading ? (
                  <TableSkeleton /> // Show loading skeleton if data is still loading
                ) : !potentialSalesLossZepto.data || potentialSalesLossZepto.data.length === 0 ? (
                  <NoDataFound /> // Show no data found component if no data is available
                ) : (
                  <PotentialSalesLossZeptoSectionsContainer platformName={platformName} /> // Render Zepto data component
                )}
              </>
            )}
            {platformName == "Instamart" && (
              <>
                {potentialSalesLossInstamart.loading ? (
                  <TableSkeleton /> // Show loading skeleton if data is still loading
                ) : !potentialSalesLossInstamart.data || potentialSalesLossInstamart.data.length === 0 ? (
                  <NoDataFound /> // Show no data found component if no data is available
                ) : (
                  <PotentialSalesLossInstamartSectionsContainer platformName={platformName} /> // Render Instamart data component
                )}
              </>
            )}
          </div>
        )}
      </Toolbar>
    </>
  );
};

export default PotentialSalesLossScreen;
