import React from "react";
import { useSelector } from "react-redux";
import FunnelChart from "./FunnelChart";
import { AiFillFunnelPlot } from "react-icons/ai";
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";

const TopTenKeywordsByBudget = ({ groupedData }) => {
  // Extract the values from the object and convert them into an array
  const valuesArray = Object.values(groupedData);

  // Sort the array in descending order based on the budget
  const sortedArray = valuesArray?.sort((a, b) => b.estimateBudget - a.estimateBudget);

  // Get the top 10 objects with the highest budget
  const top10 = sortedArray.slice(0, 10);
  console.log("top10 keyword=====", top10);

  return (
    <div className="bg-white shadow-md rounded-xl ">
      <h1 className="flex gap-3 items-center text-lg font-medium p-4 border-b">
        <AiFillFunnelPlot />
        Top 10 Keywords By Budget
        <CustomTooltip
          title={
            "Explore the top 10 keywords ranked by budget allocation, allowing you to see which keywords are driving the most investment and potentially the best returns."
          }
        >
          <PiInfoLight />
        </CustomTooltip>
      </h1>
      <div className="flex p-4 flex-wrap justify-between gap-4">
        {top10?.map((item, index) => (
          <FunnelChart obj={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default TopTenKeywordsByBudget;
