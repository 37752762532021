import {
  ADD_CATEGORIES,
  ADD_CHART_DATA,
  ADD_FINAL_CATEGORY,
  ADD_FINAL_CHART_DATA,
  ADD_FINAL_DATE,
  ADD_FINAL_MONTH,
  RESET_FILTER,
} from "../constants/chartConstants";

export const addCategories = (categories) => {
  return {
    type: ADD_CATEGORIES,
    payload: categories,
  };
};

export const addFinalDate = (date) => {
  return {
    type: ADD_FINAL_DATE,
    payload: date,
  };
};
export const addFinalMonth = (month) => {
  return {
    type: ADD_FINAL_MONTH,
    payload: month,
  };
};
export const addFinalCategory = (category) => {
  return {
    type: ADD_FINAL_CATEGORY,
    payload: category,
  };
};

export const addChartData = (chartData) => {
  return {
    type: ADD_CHART_DATA,
    payload: chartData,
  };
};
export const addFinalChartData = (finalChartData) => {
  return {
    type: ADD_FINAL_CHART_DATA,
    payload: finalChartData,
  };
};

export const resetFilters = () => ({
  type: RESET_FILTER,
});
