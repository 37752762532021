import dayjs from "dayjs";


// Format selectedDate without the timezone
const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  // Start of the day (00:00:00.000)
  const startOfDay = dayjs(date).startOf("day");
  // End of the day (23:59:59.999)
  const endOfDay = dayjs(date).endOf("day");

  // Format the start and end dates using the formatDateWithoutTimezone function
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);

  // Return the range as an object
  return {
    start: formattedStartOfDay,
    end: formattedEndOfDay,
  };
};

export const blinkitSearchSuggestionQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_blinkit_search_suggestion.campaign_name",
      "marketting_report_blinkit_search_suggestion.date",
      "marketting_report_blinkit_search_suggestion.ctr",
      "marketting_report_blinkit_search_suggestion.cpm",
      "marketting_report_blinkit_search_suggestion.reach",
      "marketting_report_blinkit_search_suggestion.match_type",
      "marketting_report_blinkit_search_suggestion.impressions",
      "marketting_report_blinkit_search_suggestion.unique_clicks",
      "marketting_report_blinkit_search_suggestion.targeting_type",
      "marketting_report_blinkit_search_suggestion.targeting_value",
      "marketting_report_blinkit_search_suggestion.estimated_budget",
    ],
    order: [["marketting_report_blinkit_search_suggestion.campaign_name", "asc"]],
    timeDimensions: [
      {
        dimension: "marketting_report_blinkit_search_suggestion.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const blinkitBannerListingQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_blinkit_banner_listing.campaign_name",
      "marketting_report_blinkit_banner_listing.date",
      "marketting_report_blinkit_banner_listing.cpm",
      "marketting_report_blinkit_banner_listing.reach",
      "marketting_report_blinkit_banner_listing.match_type",
      "marketting_report_blinkit_banner_listing.impressions",
      "marketting_report_blinkit_banner_listing.unique_clicks",
      "marketting_report_blinkit_banner_listing.targeting_type",
      "marketting_report_blinkit_banner_listing.targeting_value",
      "marketting_report_blinkit_banner_listing.estimated_budget",
    ],
    order: [["marketting_report_blinkit_banner_listing.campaign_name", "asc"]],
    timeDimensions: [
      {
        dimension: "marketting_report_blinkit_banner_listing.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const zeptoCampaignPerformanceQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_zepto_campaign_performance.primary_key",
      "marketting_report_zepto_campaign_performance.campaign_name",
      "marketting_report_zepto_campaign_performance.tenant_id",
      "marketting_report_zepto_campaign_performance.status",
      "marketting_report_zepto_campaign_performance.revenue",
      "marketting_report_zepto_campaign_performance.date_of_report",
      "marketting_report_zepto_campaign_performance.cpc",
      "marketting_report_zepto_campaign_performance.clicks",
      "marketting_report_zepto_campaign_performance.curr_daily_budget",
      "marketting_report_zepto_campaign_performance.spend",
      "marketting_report_zepto_campaign_performance.cc_date",
      "marketting_report_zepto_campaign_performance.roi",
      "marketting_report_zepto_campaign_performance.impressions",
      "marketting_report_zepto_campaign_performance.current_date",
    ],
    order: [
      ["marketting_report_zepto_campaign_performance.campaign_name", "asc"],
    ],
    timeDimensions: [
      {
        dimension: "marketting_report_zepto_campaign_performance.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const zeptoAwarenessPerformanceQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "marketting_report_zepto_awareness_performance.campaign_name",
      "marketting_report_zepto_awareness_performance.ccd_date",
      "marketting_report_zepto_awareness_performance.revenue",
      "marketting_report_zepto_awareness_performance.tenant_id",
      "marketting_report_zepto_awareness_performance.page_type",
      "marketting_report_zepto_awareness_performance.date_of_report",
      "marketting_report_zepto_awareness_performance.camp_end_date",
      "marketting_report_zepto_awareness_performance.roi",
      "marketting_report_zepto_awareness_performance.status",
      "marketting_report_zepto_awareness_performance.spend",
      "marketting_report_zepto_awareness_performance.c_daily_budget",
      "marketting_report_zepto_awareness_performance.impressions",
      "marketting_report_zepto_awareness_performance.clicks",
      "marketting_report_zepto_awareness_performance.current_date",
    ],
    order: [
      ["marketting_report_zepto_awareness_performance.date_of_report", "asc"],
    ],
    timeDimensions: [
      {
        dimension: "marketting_report_zepto_awareness_performance.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};