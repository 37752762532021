import { blinkitQuery, instamartQuery, zeptoQuery } from "../../utils/queries/pricingDetailQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchPricingDetailDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDate));
  return result.tablePivot();
};

export const fetchPricingDetailDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDate));
  return result.tablePivot();
};

export const fetchPricingDetailDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDate));
  return result.tablePivot();
};
