import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2"; // Import the Line chart from react-chartjs-2
import {
  Chart as ChartJS, // Import necessary modules from Chart.js
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Import date-fns adapter for time formatting in Chart.js
import { MenuItem, Select } from "@mui/material"; // Import MUI components for dropdown selection
import Dropdown from "../../common/Dropdown/Dropdown";

// Register the necessary Chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

// Function to transform the API data into a format that the chart can use
const transformDataForChart = (data) => {
  const dateAvailabilityMap = {}; // Object to store stock availability data by date

  // Loop through the data to organize it by date
  data?.forEach((item) => {
    const {
      "third_party_availability.date": date, // Destructure the date field
      "third_party_availability.stock_binary": stockBinary, // Destructure the stock binary field
    } = item;

    const parsedDate = new Date(date); // Parse the date into a Date object

    // Initialize the array for the parsed date if it doesn't exist
    if (!dateAvailabilityMap[parsedDate]) {
      dateAvailabilityMap[parsedDate] = [];
    }

    // Add the stockBinary value to the corresponding date
    dateAvailabilityMap[parsedDate].push(stockBinary);
  });

  // Create an array of data points with the availability percentage per date
  const dataPoints = Object.keys(dateAvailabilityMap).map((date) => {
    const stockBinaries = dateAvailabilityMap[new Date(date)]; // Get the stock binaries for the date
    const totalPincodes = stockBinaries.length; // Calculate the total number of pincodes
    // Check for both '1' (string) and 1 (number) when calculating availability
    const availableStock = stockBinaries.filter(
      (binary) => binary === "1" || binary === 1 // Check for both string and number types
    ).length;

    const availabilityPercentage = (availableStock / totalPincodes) * 100; // Calculate the availability percentage

    return {
      date: new Date(date), // Store the date
      availability: availabilityPercentage, // Store the calculated availability percentage
    };
  });

  const chartDataPoints = dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Return the data formatted for the Line chart
  return {
    labels: chartDataPoints.map((item) => item.date), // X-axis labels (dates)
    datasets: [
      {
        label: "Average Availability", // Dataset label for the chart
        data: chartDataPoints.map((item) => item.availability), // Y-axis data (availability percentages)
        borderColor: "#387FF5",
        tension: 0.4,
        borderWidth: 2,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#387FF5",
        pointBorderWidth: 2,
        pointRadius: 2,
        pointHoverRadius: 3,
        backgroundColor: "rgba(247,250,254,0.2)",
        fill: true,
      },
    ],
  };
};

// Component for rendering the line chart
const WeeklyLevelOverviewLineChart = ({ apiData, brands }) => {
  const userDetail = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userDetail);
  // State for the chart data and the selected brand
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [selectedBrand, setSelectedBrand] = useState(parsedUserData?.organizationDetail?.name); // Default brand selected
  const prevDataRef = useRef(null);
  // Filter the API data based on the selected brand
  const data = apiData?.filter((item) => item["third_party_availability.brand"] == selectedBrand);

  // useEffect hook to update the chart data when the selected brand's data changes
  useEffect(() => {
    const dataString = JSON.stringify(data); // Stringify data for deep comparison

    if (prevDataRef.current !== dataString) {
      prevDataRef.current = dataString; // Update the reference
      setChartData(transformDataForChart(data)); // Update chart data when the actual content of `data` changes
    }
  }, [data]);

  // Chart configuration options
  const chartOptions = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: false, // Disable the aspect ratio maintenance
    scales: {
      x: {
        type: "time", // X-axis type is time
        time: {
          unit: "day", // Group the data by day
          tooltipFormat: "dd/MM/yyyy", // Format for tooltip display on hover
        },
        title: {
          display: true,
          text: "Date", // X-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: "Average Availability Percentage", // Y-axis label
        },
        min: 0, // Minimum Y value
        max: 100, // Maximum Y value (since it's percentage)
      },
    },
  };

  return (
    <div className="w-full">
      {/* Brand selection dropdown */}
      {/* Container for the line chart */}
      <div className="w-full h-96 p-4">
        <Line data={chartData} options={chartOptions} /> {/* Render the chart */}
      </div>
    </div>
  );
};

export default WeeklyLevelOverviewLineChart;
