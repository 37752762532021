// {
//     "marketting_report_instamart.campaign_name": "Anveshan-Keyword-Campaign-01-07-2024",
//     "marketting_report_instamart.start_date": "1/7/2024",
//     "marketting_report_instamart.end_date": "31/7/2024",
//     "marketting_report_instamart.campaign_status": "CAMPAIGN_STATUS_LIVE",
//     "marketting_report_instamart.keyword": "anveshan",
//     "marketting_report_instamart.total_a2c": "0",
//     "marketting_report_instamart.total_gmv": "1110",
//     "marketting_report_instamart.total_roi": "17.34",
//     "marketting_report_instamart.total_impressions": "16",
//     "marketting_report_instamart.total_budget_burnt": "64",
//     "marketting_report_instamart.total_budget": "50000",
//     "marketting_report_instamart.product_name": "Anveshan A2 Desi Cow Ghee Jar",
//     "marketting_report_instamart.current_date": "2024-07-12T00:00:00.000"
// }

export const groupedDataByCampaignNameAndKeyword = (array) => {
  return array.reduce((acc, item) => {
    const {
      "marketting_report_instamart.campaign_name": campaignName,
      "marketting_report_instamart.keyword": targetingValue,
      "marketting_report_instamart.total_gmv": revenue,
      "marketting_report_instamart.total_budget_burnt": spend,
    } = item;

    // Initialize campaign grouping if it does not exist
    if (!acc[campaignName]) {
      acc[campaignName] = {};
    }

    // Initialize targeting value grouping if it does not exist
    if (!acc[campaignName][targetingValue]) {
      acc[campaignName][targetingValue] = {
        totalSpend: 0,
        totalRevenue: 0,
        ROI: 0,
      };
    }

    // Directly assign totalSpend and totalRevenue
    acc[campaignName][targetingValue].totalSpend = parseFloat(spend) || 0;
    acc[campaignName][targetingValue].totalRevenue = parseFloat(revenue) || 0;

    // Update ROI
    const { totalSpend, totalRevenue } = acc[campaignName][targetingValue];
    acc[campaignName][targetingValue].ROI =
      totalSpend > 0 ? totalRevenue / totalSpend : 0;

    return acc;
  }, {});
};

export const groupedDataByDate = (array) => {
  // Step 1: First, calculate totalSpend, totalRevenue, and ROI for each item
  const calculatedArray = array.map((item) => {
    const {
      "marketting_report_instamart.total_gmv": revenue,
      "marketting_report_instamart.total_budget_burnt": spend,

      "marketting_report_instamart.total_impressions": impression,
    } = item;

    // Calculate totalSpend and totalRevenue
    const totalSpend = parseFloat(spend) || 0;
    const totalRevenue = parseFloat(revenue) || 0;

    // Calculate ROI
    const ROI = totalSpend > 0 ? totalRevenue / totalSpend : 0;

    // Return the item with the calculated values
    return {
      ...item,
      totalSpend,
      totalRevenue,
      ROI,
      impression,
    };
  });

  // Step 2: Group the data by date without recalculating
  return calculatedArray.reduce((acc, item) => {
    const { "marketting_report_instamart.start_date": date } = item;

    // Initialize date grouping if it does not exist
    if (!acc[date]) {
      acc[date] = {
        data: [],
      };
    }

    // Push the item into the respective date array
    acc[date].data.push(item);

    return acc;
  }, {});
};
