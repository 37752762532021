import React from "react";
import { Line } from "react-chartjs-2";
import { PiInfoLight } from "react-icons/pi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { BsGraphUpArrow } from "react-icons/bs";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const colors = [
  "#FF6384", // Deep Pink
  "#22CFCF",
  "#FEBE40",
  "#8142FF",
  "#00fa9a", // Medium Spring Green
  "#ffb6c1", // Light Pink
  "#000000", // Black
  "#696969", // Dim Gray
  "#006400", // Dark Green
  "#808000", // Olive
  "#483d8b", // Dark Slate Blue
  "#3cb371", // Medium Sea Green
  "#008b8b", // Dark Cyan
  "#32cd32", // Lime Green
  "#800080", // Purple
  "#b03060", // Maroon 3
  "#00ff00", // Lime
  "#8a2be2", // Blue Violet
  "#0000ff", // Blue
  "#adff2f", // Green Yellow
  "#ffff54", // Laser Lemon
  "#add8e6", // Light Blue
  "#8b4513", // Saddle Brown
  "#7b68ee", // Medium Slate Blue
  "#fafad2", // Light Goldenrod
];

const PricePerUnitOverWeek = ({ chartData }) => {
  // Get all unique dates from chartData
  const allDates = Array.from(new Set(chartData.map((item) => item.date))).sort((a, b) => new Date(a) - new Date(b));
  console.log("🚀 ~ PricePerUnitOverWeek ~ allDates:", allDates);

  // Format the dates to show only DD-MM
  const formattedDates = allDates.map((date) => {
    const [day, month] = date.split("-");
    return `${day}/${month}`;
  });

  // Group data by SKU and map to product name
  const groupedBySKU = chartData.reduce((acc, item) => {
    const pricePer1 = parseFloat(item.price) / parseFloat(item.unitWeight); // Calculate price for 1ml/1gm

    if (!acc[item.sku]) {
      acc[item.sku] = { product: item.product, prices: {} };
    }

    // Store price indexed by date for easier lookup later
    acc[item.sku].prices[item.date] = pricePer1;

    return acc;
  }, {});

  // Generate datasets for each SKU, using product names as labels
  const datasets = Object.keys(groupedBySKU).map((sku, index) => {
    const dataForSKU = allDates.map((date) => groupedBySKU[sku].prices[date] || 0); // Ensure each date has a value

    return {
      label: groupedBySKU[sku].product, // Use product name instead of SKU
      data: dataForSKU,
      borderWidth: 2,
      borderColor: colors[index % colors.length],
      pointBorderColor: colors[index % colors.length],
      pointBackgroundColor: colors[index % colors.length],
      pointBorderWidth: 2,
      pointRadius: 2,
      pointHoverRadius: 3,
      tension: 0.4,
    };
  });

  const data = {
    labels: formattedDates,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top", // Positions the legend at the top
        align: "center", // Aligns the legend to the right
        labels: {
          usePointStyle: true, // Makes legend items modern with point styles
          font: {
            size: 12,
            weight: 400,
          },
        },
      },
      // title: {
      //   display: true,
      //   text: "Price Per Unit Over the Week by Product",
      // },
    },
    
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 0.04,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
        title: {
          display: true,
          text: "Price Per 1ml/1gm",
          color: "#87878F",
          font: {
            size: 12,
            family: "DM Sans Variable",
            weight: 600,
          },
          padding: {
            // Adjust margin to the right
            bottom: 12, // Add some margin below the title
          },
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          beginAtZero: true,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },

        title: {
          display: true,
          text: "Date", // X-axis title
          color: "#87878F",
          font: {
            size: 12,
            family: "DM Sans Variable",
            weight: 600,
          },
          padding: {
            top: 10, // Add some margin at the top
          },
        },
      },
    },
  };

  return (
    <div className="bg-white shadow-md rounded-[0.625rem] h-[25.8rem] w-[49%]">
      <div className="flex py-5 border-b border-[#EAEDF3]">
        <span className="flex  gap-4 items-center leading-6 text-[#4F4E69] text-lg  font-body font-medium ml-5">
          <BsGraphUpArrow />
          Price Per Unit Over the Week
          <CustomTooltip title={"Analyze Self-Brand Price Fluctuations This Week"}>
            <PiInfoLight />
          </CustomTooltip>
        </span>
      </div>

      <div className="px-4 mt-[2rem] h-[18rem] w-full">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default PricePerUnitOverWeek;
