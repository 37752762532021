import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider.js";
import {
  getMonthyProfitabilityRequest,
  getMonthyProfitabilityError,
  getMonthyProfitabilitySuccess,
  getCityProfitabilityRequest,
  getCategoryProfitabilityRequest,
  getCityProfitabilityError,
  getCityProfitabilitySuccess,
  getCategoryProfitabilityError,
  getCategoryProfitabilitySuccess,
} from "../../redux/actions/profitabilityActions.js";
import CityProfitability from "../../components/profitability/OverallProfitabilityComponents/CityProfitability.js";
import CategoryProfitability from "../../components/profitability/OverallProfitabilityComponents/CategoryProfitability.js";
import {
  fetchCategoryProfitabilityDataBlinkit,
  fetchCategoryProfitabilityDataInstamart,
  fetchCategoryProfitabilityDataZepto,
  fetchCityProfitabilityDataBlinkit,
  fetchCityProfitabilityDataInstamart,
  fetchCityProfitabilityDataZepto,
  fetchMonthlyProfitabilityDataBlinkit,
  fetchMonthlyProfitabilityDataInstamart,
  fetchMonthlyProfitabilityDataZepto,
} from "../../services/profitability/overallProfitabilityServices.js";
import MonthlyProfitability from "../../components/profitability/OverallProfitabilityComponents/MonthlyProfitability.js";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar.js";
import Toolbar from "../../components/Toolbar/Toolbar.js";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch.js";
import TableSkeleton from "../../components/common/Skeletons/TableSkeleton.js";
import NoDataFound from "../../components/common/NoDataFound/NoDataFound.js";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback.js";

const options = ["Monthly Profitability", "City Profitablity", "Category Profitablity"];
const monthHeadings = ["MONTH NAME", "MRP", "GST", "NET SALES", "COGS", "CM1"];
const cityHeadings = ["CITY NAME", "MRP", "GST", "NET SALES", "COGS", "CM1"];
const categoryHeadings = ["CATEGORY NAME", "MRP", "GST", "NET SALES", "COGS", "CM1"];

const OverallProfitabilityScreen = () => {
  const { monthlyProfitability, cityProfitability, categoryProfitability } = useSelector(
    (state) => state.profitability
  );
  const [selectedOption, setSelectedOption] = useState(0);
  const [platformName, setPlatformName] = useState("Blinkit");
  const { selectedDate } = useDate();
  const dispatch = useDispatch();

  // Effect to reset selected option to 0 (Monthly Profitability) when platform changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchMonthly: fetchMonthlyProfitabilityDataBlinkit,
      fetchCity: fetchCityProfitabilityDataBlinkit,
      fetchCategory: fetchCategoryProfitabilityDataBlinkit,
      monthlyActions: {
        request: getMonthyProfitabilityRequest,
        success: getMonthyProfitabilitySuccess,
        failure: getMonthyProfitabilityError,
      },

      cityActions: {
        request: getCityProfitabilityRequest,
        success: getCityProfitabilitySuccess,
        failure: getCityProfitabilityError,
      },
      categoryActions: {
        request: getCategoryProfitabilityRequest,
        success: getCategoryProfitabilitySuccess,
        failure: getCategoryProfitabilityError,
      },
    },

    Zepto: {
      fetchMonthly: fetchMonthlyProfitabilityDataZepto,
      fetchCity: fetchCityProfitabilityDataZepto,
      fetchCategory: fetchCategoryProfitabilityDataZepto,
      monthlyActions: {
        request: getMonthyProfitabilityRequest,
        success: getMonthyProfitabilitySuccess,
        failure: getMonthyProfitabilityError,
      },

      cityActions: {
        request: getCityProfitabilityRequest,
        success: getCityProfitabilitySuccess,
        failure: getCityProfitabilityError,
      },
      categoryActions: {
        request: getCategoryProfitabilityRequest,
        success: getCategoryProfitabilitySuccess,
        failure: getCategoryProfitabilityError,
      },
    },

    Instamart: {
      fetchMonthly: fetchMonthlyProfitabilityDataInstamart,
      fetchCity: fetchCityProfitabilityDataInstamart,
      fetchCategory: fetchCategoryProfitabilityDataInstamart,
      monthlyActions: {
        request: getMonthyProfitabilityRequest,
        success: getMonthyProfitabilitySuccess,
        failure: getMonthyProfitabilityError,
      },

      cityActions: {
        request: getCityProfitabilityRequest,
        success: getCityProfitabilitySuccess,
        failure: getCityProfitabilityError,
      },
      categoryActions: {
        request: getCategoryProfitabilityRequest,
        success: getCategoryProfitabilitySuccess,
        failure: getCategoryProfitabilityError,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];
    if (platform) {
      dispatch(platform.monthlyActions.request());

      // Fetch data for monthly
      fetchWithRetry(
        platform.fetchMonthly,
        platform.monthlyActions.success,
        platform.monthlyActions.failure,
        selectedDate,
        dispatch,
        1
      );

      // Conditionally fetch data for option 2 only if it exists
      if (platform.fetchCity && platform.cityActions) {
        dispatch(platform.cityActions.request());
        // Fetch data for city
        fetchWithRetry(
          platform.fetchCity,
          platform.cityActions.success,
          platform.cityActions.failure,
          selectedDate,
          dispatch,
          1
        );
      }

      // Conditionally fetch data for option 3 only if it exists
      if (platform.fetchCategory && platform.categoryActions) {
        dispatch(platform.cityActions.request());
        // Fetch data for city
        fetchWithRetry(
          platform.fetchCategory,
          platform.categoryActions.success,
          platform.categoryActions.failure,
          selectedDate,
          dispatch,
          1
        );
      }
    }
  }, [platformName, selectedOption, selectedDate]);



  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 ? (
          <>
            {monthlyProfitability.loading ? (
              <TableSkeleton />
            ) : !monthlyProfitability.data || monthlyProfitability.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <MonthlyProfitability headings={monthHeadings} platformName={platformName} />
            )}
          </>
        ) : selectedOption === 1 ? (
          <>
            {cityProfitability.loading ? (
              <TableSkeleton />
            ) : !cityProfitability.data || cityProfitability.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <CityProfitability headings={cityHeadings} platformName={platformName} />
            )}
          </>
        ) : (
          <>
            {categoryProfitability.loading ? (
              <TableSkeleton />
            ) : !categoryProfitability.data || categoryProfitability.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <CategoryProfitability headings={categoryHeadings} platformName={platformName} />
            )}
          </>
        )}
      </Toolbar>
    </>
  );
};

export default OverallProfitabilityScreen;
