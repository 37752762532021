import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import CompetitorPricingContainer from "../../components/ads/CompetitorPricingComponents/CompetitorPricingContainer";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import {
  fetchCompetitorPricingDataBlinkit,
  fetchCompetitorPricingDataInstamart,
  fetchCompetitorPricingDataZepto,
} from "../../services/adsServices/competitorPricingServices";
import {
  fetchCompetitorPricingFailure,
  fetchCompetitorPricingRequest,
  fetchCompetitorPricingSuccess,
} from "../../redux/actions/competitorPricingActions";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

const options = ["Competitor Pricing"];

const CompetitorPricingScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { competitorPricing } = useSelector((state) => state.competitor);
  const dispatch = useDispatch();
  const { selectedDate } = useDate();
  const user = JSON.parse(localStorage.getItem("user"));

  const products = Array.from(
    new Set(competitorPricing.data.map((item) => item["third_party_availability.product_name"]))
  ).sort();

  const locations = Array.from(
    new Set(competitorPricing.data.map((item) => item["third_party_availability.area_name_lm"]))
  ).sort();

  const dataFetchFunctions = {
    Blinkit: fetchCompetitorPricingDataBlinkit,
    Zepto: fetchCompetitorPricingDataZepto,
    Instamart: fetchCompetitorPricingDataInstamart,
  };

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchCompetitorPricing: fetchCompetitorPricingDataBlinkit,
      competitorPricingActions: {
        request: fetchCompetitorPricingRequest,
        success: fetchCompetitorPricingSuccess,
        failure: fetchCompetitorPricingFailure,
      },
    },
    Zepto: {
      fetchCompetitorPricing: fetchCompetitorPricingDataZepto,
      competitorPricingActions: {
        request: fetchCompetitorPricingRequest,
        success: fetchCompetitorPricingSuccess,
        failure: fetchCompetitorPricingFailure,
      },
    },
    Instamart: {
      fetchCompetitorPricing: fetchCompetitorPricingDataInstamart,
      competitorPricingActions: {
        request: fetchCompetitorPricingRequest,
        success: fetchCompetitorPricingSuccess,
        failure: fetchCompetitorPricingFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];
    if (platform) {
      dispatch(platform.competitorPricingActions.request());
      fetchWithRetry(
        platform.fetchCompetitorPricing,
        platform.competitorPricingActions.success,
        platform.competitorPricingActions.failure,
        selectedDate,
        dispatch,
        1
      );
    }
  }, [platformName, selectedOption, selectedDate]);

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption == 0 && <CompetitorPricingContainer products={products} locations={locations} user={user} />}
      </Toolbar>
    </>
  );
};

export default CompetitorPricingScreen;
