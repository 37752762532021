import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2"; // Bar chart component from Chart.js
import { useSelector } from "react-redux"; // Hook for accessing Redux store
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"; // Chart.js components for creating bar charts
import { makeChartDataByCategories } from "../../../utils/makeChartDataByCategories"; // Utility function to process chart data
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton"; // Skeleton loader for chart

// Registering required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DailySalesAndOrdersPerDay = ({ selectedOption, platformName }) => {
  const [error, setError] = useState(false); // State to track if there is an error
  const [finalChartData, setFinalChartData] = useState([]); // State to store processed chart data

  // Accessing Redux state for final category and total sales data
  const { finalCategory } = useSelector((state) => state.chart);
  const { totalSales } = useSelector((state) => state.sales);

  // Effect to process chart data when totalSales.data or platformName changes
  useEffect(() => {
    if (totalSales.data.length > 0) {
      const processedChartData = makeChartDataByCategories(totalSales.data, platformName);
      setFinalChartData(processedChartData);
    }
  }, [totalSales.data, platformName]);

  // Effect to filter data based on selected category and update chart data
  useEffect(() => {
    if (finalCategory) {
      const data = totalSales.data.filter((item) => {
        const category =
          platformName === "Blinkit"
            ? item["combined_sales_report_blinkit.category"]
            : platformName === "Instamart"
            ? item["combined_sales_report_instamart.category"]
            : item["combined_sales_report_zepto.category"];

        return category === finalCategory;
      });
      setError(data.length === 0); // Set error if no data is found
      const processedChartData = makeChartDataByCategories(data, platformName);
      setFinalChartData(processedChartData);
    } else {
      const processedChartData = makeChartDataByCategories(totalSales.data, platformName);
      setFinalChartData(processedChartData);
    }
  }, [finalCategory, totalSales.data, platformName]);

  // Define border colors for chart bars
  const borderColor = [
    "#FF6384",
    "#22CFCF",
    "#4597DB",
    "#FEBE40",
    "#8142FF",
    "rgba(255, 159, 64, 1)", // Orange
    "rgba(255, 99, 132, 0.8)", // Light Red
    "rgba(54, 162, 235, 0.8)", // Light Blue
    "rgba(255, 206, 86, 0.8)", // Light Yellow
    "rgba(75, 192, 192, 0.8)", // Light Green
    "rgba(123, 239, 178, 1)", // Light Mint
    "rgba(255, 87, 34, 1)", // Deep Orange
    "rgba(0, 150, 136, 1)", // Teal
    "rgba(233, 30, 99, 1)", // Pink
    "rgba(63, 81, 181, 1)", // Indigo
  ];

  // Collect unique categories from finalChartData
  const categories = new Set();
  Object.values(finalChartData).forEach((dateData) => {
    Object.keys(dateData).forEach((category) => {
      categories.add(category);
    });
  });

  // Generate labels for the x-axis
  const labels = Object.keys(finalChartData).map(
    (date) => (platformName === "Zepto" ? date : date.split("-").reverse().join("-"))
    // new Date(date).getDate()
  );

  // Prepare datasets for the chart
  const datasets = Array.from(categories).map((category, index) => ({
    label: category,
    data:
      selectedOption === 2
        ? Object.keys(finalChartData).map((date) =>
            finalChartData[date][category] ? finalChartData[date][category].qtySold : 0
          )
        : Object.keys(finalChartData).map((date) =>
            finalChartData[date][category] ? finalChartData[date][category].sales : 0
          ),
    backgroundColor: borderColor[index % borderColor.length],
    borderRadius: 5,
    barThickness: 24,
  }));

  // Data object for the Bar chart
  const data = {
    labels,
    datasets,
  };

  // Options for the Bar chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 10, // Adjust the font size to make the circles smaller
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
        title: {
          display: true,
          text: "Date",
          font: {
            size: 14,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        title: {
          display: true,
          text: selectedOption === 2 ? "Orders Per Day" : "Sales Per Day",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  // Display chart skeleton if data is not available
  if (!totalSales.data.length) {
    return <ChartSkeleton />;
  }

  // Display error message if no data is found for the selected category
  if (error) {
    return (
      <div className="h-96 w-full flex justify-center items-center">
        <div>No Data Found</div>
      </div>
    );
  }

  // Render the Bar chart with the processed data and options
  return (
    <div className="p-6 pt-4">
      <Bar className="border p-4 rounded-lg" data={data} options={options} />
    </div>
  );
};

export default DailySalesAndOrdersPerDay;

// Use Case

// DailySalesAndOrdersPerDay: Renders a bar chart showing daily sales or orders, updating based on selected options and platform.
// ChartSkeleton: Displays a loading state while data is being fetched.
// NoDataFound: Shows an error message when no data is available.
