import React from "react";
import ReactFlow, { MiniMap, Controls, Background } from "react-flow-renderer";
import "react-flow-renderer/dist/style.css"; // Import the correct CSS

// Utility function to format numbers with K, L (lakh), and Cr (crore)
const formatPrice = (value) => {
  if (value >= 10000000) {
    return `₹${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    return `₹${(value / 100000).toFixed(2)} Lac`;
  } else if (value >= 1000) {
    return `₹${(value / 1000).toFixed(2)} K`;
  } else {
    return `₹${value}`;
  }
};

// A utility function to map filtered SKUs to nodes and edges
const createFlowElements = (filteredSkus) => {
  const nodes = [];
  const edges = [];

  // Add root node for total GMV
  const rootNode = {
    id: "root",
    data: { label: `Total GMV: ${formatPrice(filteredSkus?.totalGMV)}` },
    position: { x: 0, y: 50 },
    style: {
      background: "#000", // Black background
      color: "#fff", // White text color
      padding: 10,
      borderRadius: 10,
      border: "2px solid navy", // Navy blue border
    },
    sourcePosition: "right",
    title: `Overall GMV: ${formatPrice(filteredSkus?.totalGMV)} \nOverall Qty: ${filteredSkus?.totalQty}`, // Tooltip content
  };
  nodes.push(rootNode);

  // Loop through SKUs and add nodes for each SKU
  filteredSkus?.data?.forEach((sku, index) => {
    const nodeId = `sku-${index}`;
    const skuNode = {
      id: nodeId,
      data: {
        label: `${sku["category_rca_blinkit.brand_internal_sku_code"]} \nGMV: ${formatPrice(
          sku["category_rca_blinkit.total_gmv"]
        )} \nQty: ${sku["category_rca_blinkit.total_qty_sold"]}`,
      },
      position: { x: (index + 1) * 200, y: 150 }, // Spacing between nodes
      style: {
        background: "#fff", // White background
        color: "#000", // Black text color
        padding: 10,
        borderRadius: 10,
        border: "1px solid #d9d9d9", // Black border
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow effect
      },
      targetPosition: "left",
      sourcePosition: "right",
      title: `SKU Code: ${sku["category_rca_blinkit.brand_internal_sku_code"]} \nGMV: ${formatPrice(
        sku["category_rca_blinkit.total_gmv"]
      )} \nQty: ${sku["category_rca_blinkit.total_qty_sold"]}`, // Tooltip content
    };
    nodes.push(skuNode);

    // Add edge connecting the root node to the SKU node
    edges.push({
      id: `edge-root-${nodeId}`,
      source: "root",
      target: nodeId,
      type: "smoothstep",
    });
  });

  return { nodes, edges };
};

const CategoryRcaFlowChart = ({ filteredSkus }) => {
  const { nodes, edges } = createFlowElements(filteredSkus);
  console.log("nodes===edges===", nodes, edges);
  return (
    <div style={{ height: 500, width: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView // Ensures the graph fits the view
      >
        <MiniMap
          nodeStrokeColor={(n) => {
            if (n.style?.background === "#000") return "navy";
            return "#fff";
          }}
          nodeColor={(n) => {
            return n.style?.background || "#001f3f";
          }}
          nodeBorderRadius={10}
        />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default CategoryRcaFlowChart;
