import dayjs from "dayjs";

// Format selectedDate without the timezone
const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  // Start of the day (00:00:00.000)
  const startOfDay = dayjs(date).startOf("day");
  // End of the day (23:59:59.999)
  const endOfDay = dayjs(date).endOf("day");

  // Format the start and end dates using the formatDateWithoutTimezone function
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);

  // Return the range as an object
  return {
    start: formattedStartOfDay,
    end: formattedEndOfDay,
  };
};

// Calculate startDate as 30 days before selectedDate
const calculateDateRange = (selectedDate) => {
  const endDate = formatDateWithoutTimezone(selectedDate);
  const startDate = formatDateWithoutTimezone(
    dayjs(selectedDate).subtract(30, "day")
  );
  return [startDate, endDate];
};

// Blinkit Query
export const blinkitQuery = (selectedDate) => {
  const [startDate, endDate] = calculateDateRange(selectedDate, 30); // Assume this function can also take a range duration.
  const { start, end } = getSingleDayDateRange(selectedDate);

  return {
    dimensions: [
      "combined_inventory_data_blinkit.created_at",
      "combined_inventory_data_blinkit.primary_key",
      "combined_inventory_data_blinkit.item_id",
      "combined_inventory_data_blinkit.backend_facility_name",
      "combined_inventory_data_blinkit.backend_facility_id",
      "combined_inventory_data_blinkit.frontend_inv_qty",
      "combined_inventory_data_blinkit.backend_inv_qty",
      "combined_inventory_data_blinkit.item_name",
      "combined_inventory_data_blinkit.brands_choice",
      "combined_inventory_data_blinkit.category",
      "combined_inventory_data_blinkit.sub_category",
      "combined_inventory_data_blinkit.brand_internal_sku_code",
    ],
    measures: ["combined_sales_report_blinkit.daily_run_rate"],
    order: [["combined_sales_report_blinkit.daily_run_rate", "desc"]],

    timeDimensions: [
      {
        dimension: "combined_inventory_data_blinkit.normalized_date",
        dateRange: [start, end], // Inventory data for the current date.
      },
      {
        dimension: "combined_sales_report_blinkit.normalized_date",
        dateRange: [startDate, endDate], // Sales report for the last 30 days.
      },
    ],
  };
};

// Instamart Query
export const instamartQuery = (selectedDate) => {
  const [startDate, endDate] = calculateDateRange(selectedDate);
  const { start, end } = getSingleDayDateRange(selectedDate);

  return {
    dimensions: [
      "combined_inventory_data_instamart.brand_internal_sku_code",
      "combined_inventory_data_instamart.dt",
      "combined_inventory_data_instamart.brands_choice",
      "combined_inventory_data_instamart.product_name",
      "combined_inventory_data_instamart.sub_category",
      "combined_inventory_data_instamart.wh_name",
      "combined_inventory_data_instamart.item_code",
      "combined_inventory_data_instamart.wh_soh",
      "combined_inventory_data_instamart.category",
    ],
    measures: ["combined_sales_report_instamart.daily_run_rate"],
    order: {
      "combined_sales_report_instamart.daily_run_rate": "desc",
    },
    timeDimensions: [
      {
        dimension: "combined_inventory_data_instamart.normalized_date",
        dateRange: [start, end], // Inventory data for the current date.
      },
      {
        dimension: "combined_sales_report_instamart.normalized_date",
        dateRange: [startDate, endDate], // Sales report for the last 30 days.
      },
    ],
  };
};


// Zepto Query
export const zeptoQuery = (selectedDate) => {
  const [startDate, endDate] = calculateDateRange(selectedDate);
  const { start, end } = getSingleDayDateRange(selectedDate);

  return {
    dimensions: [
      "combined_inventory_data_zepto.city",
      "combined_inventory_data_zepto.tenant_id",
      "combined_inventory_data_zepto.store_name",
      "combined_inventory_data_zepto.sku_id",
      "combined_inventory_data_zepto.sku_name",
      "combined_inventory_data_zepto.total_quantity",
      "combined_inventory_data_zepto.brand_internal_sku_code",
      "combined_inventory_data_zepto.category",
      "combined_inventory_data_zepto.sub_category",
      "combined_inventory_data_zepto.brands_choice",
    ],
    measures: ["combined_sales_report_zepto.daily_run_rate"],
    order: [["combined_sales_report_zepto.daily_run_rate", "desc"]],

    timeDimensions: [
      {
        dimension: "combined_inventory_data_zepto.normalized_date",
        dateRange: [start, end], // Inventory data for the current date.
      },
      {
        dimension: "combined_sales_report_zepto.normalized_date",
        dateRange: [startDate, endDate], // Sales report for the last 30 days.
      },
    ],
  };
};

