export const groupedInsightsProductWiseData = (data) => {
  const groupedData = {};
  data?.forEach((element) => {
    const product = element["combined_inventory_data_blinkit.item_name"];
    const dailyRunRate = parseFloat(element["combined_sales_report_blinkit.daily_run_rate"]) || 0;
    if (!groupedData[product]) {
      groupedData[product] = {
        productName: product,
        dailyRunRate: 0,
        count: 0,
      };
    }
    groupedData[product].dailyRunRate += dailyRunRate;
    groupedData[product].count += 1;
  });
  return groupedData;
};

export const monthlyInsightsGroupingBlinkit = (tableData) => {
  if (!tableData || !tableData.data) {
    console.error("Table data is undefined or does not contain 'data'");
    return [];
  }
  console.log(tableData);
  const { data } = tableData;
  return data?.map((row) => ({
    // month: month,
    product_name: row["combined_sales_report_blinkit.item_name"],
    mrp: parseFloat(row["combined_sales_report_blinkit.total_gmv"]),
    gst: parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_blinkit.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_blinkit.total_cogs"]),
  }));
};

export const monthlyInsightsGroupingZepto = (tableData) => {
  if (!tableData || !tableData.data) {
    console.error("Table data is undefined or does not contain 'data'");
    return [];
  }
  const { data } = tableData;
  return data?.map((row) => ({
    // month: month,
    product_name: row["combined_sales_report_zepto.sku_name"],
    mrp: parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]),
    gst: parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_zepto.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]),
    cm1:
      parseFloat(["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_zepto.total_cogs"]),
  }));
};

export const monthlyInsightsGroupingInstamart = (tableData) => {
  if (!tableData || !tableData.data) {
    console.error("Table data is undefined or does not contain 'data'");
    return [];
  }
  const { data } = tableData;
  return data?.map((row) => ({
    // month: month,
    product_name: row["combined_sales_report_instamart.product_name"],
    mrp: parseFloat(row["combined_sales_report_instamart.total_gmv"]),
    gst: parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_instamart.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_instamart.total_cogs"]),
  }));
};

export const productInsightsGroupingBlinkit = (tableData) => {
  return tableData?.map((row) => ({
    product: row["combined_sales_report_blinkit.item_name"],
    mrp: parseFloat(row["combined_sales_report_blinkit.total_gmv"]),
    gst: parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_blinkit.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_blinkit.total_gmv"]) -
      parseFloat(row["combined_sales_report_blinkit.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_blinkit.total_cogs"]),
  }));
};

export const productInsightsGroupingZepto = (tableData) => {
  return tableData?.map((row) => ({
    product: row["combined_sales_report_zepto.sku_name"],
    mrp: parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]),
    gst: parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_zepto.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_zepto.total_gross_merchandise_value"]) -
      parseFloat(row["combined_sales_report_zepto.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_zepto.total_cogs"]),
  }));
};

export const productInsightsGroupingInstamart = (tableData) => {
  return tableData?.map((row) => ({
    product: row["combined_sales_report_instamart.product_name"],
    mrp: parseFloat(row["combined_sales_report_instamart.total_gmv"]),
    gst: parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]),
    cogs: parseFloat(row["combined_sales_report_instamart.total_cogs"]),
    netSales:
      parseFloat(row["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]),
    cm1:
      parseFloat(row["combined_sales_report_instamart.total_gmv"]) -
      parseFloat(row["combined_sales_report_instamart.total_taxes_paid"]) -
      parseFloat(row["combined_sales_report_instamart.total_cogs"]),
  }));
};
