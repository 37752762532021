import "@fontsource/poppins";
// Supports weights 100-900
import "@fontsource-variable/dm-sans";
import "@fontsource/ibm-plex-sans";
import "@fontsource-variable/inter";

const typography = {

  fontFamily: "DM Sans Variable, sans-serif",
  h1: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "2rem",
  },
  h2: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1.8rem",
  },
  h3: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1.6rem",
  },
  h4: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1.4rem",
  },
  h5: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1.2rem",
  },
  h6: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1rem",
  },
  subtitle1: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1rem",
  },
  subtitle2: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "0.8rem",
  },
  body1: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1rem",
  },
  body2: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "0.8rem",
  },
  button: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "1rem",
  },
  caption: {
    fontFamily: "DM Sans Variable, sans-serif",
    fontSize: "0.8rem",
  },
  overline: {
    fontFamily: "DM Sans Variable, sans-serif",

    fontSize: "0.8rem",
  },
};

export default typography;
