import React from "react";
import { BiSolidChart } from "react-icons/bi";
import CategoryRcaOverviewChartCard from "./CategoryRcaOverviewChartCard";
import { useSelector } from "react-redux";

// Utility function to format numbers with K, L (lakh), and Cr (crore)
const formatPrice = (value) => {
  if (value >= 10000000) {
    // If value is greater than or equal to 1 crore
    return `₹${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    // If value is greater than or equal to 1 lakh
    return `₹${(value / 100000).toFixed(2)} Lac`;
  } else if (value >= 1000) {
    // If value is greater than or equal to 1 thousand
    return `₹${(value / 1000).toFixed(2)} K`;
  } else {
    // For values below 1 thousand
    return `₹${value}`;
  }
};

const CategoryRcaOverview = () => {
  const { rcaSkusData } = useSelector((state) => state.rcaCategory);
  // Calculate overall GMV and Quantity
  const overallCategoryGMV = rcaSkusData.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_gmv"] || 0),
    0
  );

  // Use formatted GMV
  const offtakesAtMrp = {
    title: "Offtake at",
    value: formatPrice(overallCategoryGMV), // Format the GMV value here
  };

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2">
          <div className="bg-slate-200 p-1 rounded-full">
            <BiSolidChart className="text-xl" />
          </div>
          <span className="font-semibold text-lg">Overview</span> for All
        </div>

        <div className="flex items-center gap-1 border rounded-lg p-1">
          <span className="font-semibold">MTD</span>
          <span>vs</span>
          <span className="font-semibold">Previous Month</span>
        </div>
      </div>
      <hr />
      <div className="p-4">
        <div className="flex items-center gap-2 justify-between overflow-x-scroll">
          <CategoryRcaOverviewChartCard info={offtakesAtMrp} />
        </div>
      </div>
    </div>
  );
};

export default CategoryRcaOverview;
