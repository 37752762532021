import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross1 } from "react-icons/rx";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Popover, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
  overflow: "hidden", // Ensures the modal contents don't overflow
};

const SkuCityLevelAvailabilityModal = ({ open, handleClose, data, selectedSku }) => {
  const [anchorEl, setAnchorEl] = useState(null); // State for popover

  const navigate = useNavigate();

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the element that was clicked
  };

  const handlePopoverClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const isPopoverOpen = Boolean(anchorEl); // Check if popover is open
  console.log("city====", data);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjusted opacity here
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* Heading container */}
          <div className="flex justify-between items-center text-black p-4 rounded-t-2xl border-b">
            <h2 className="text-lg font-medium flex items-center gap-2">
              <img src="/logos/pincode-level.svg" alt="" />
              Localities with DS in{" "}
              <span className="bg-[#387FF51A] text-[#387FF5] py-1 px-2 rounded-md font-light">
                {data?.city}
              </span> for{" "}
              <span className="bg-[#387FF51A] text-[#387FF5] py-1 px-2 rounded-md font-light">{selectedSku}</span>
            </h2>
            <RxCross1 className="text-xl cursor-pointer" onClick={handleClose} />
          </div>

          {/* Content container */}
          <div
            style={{
              height: "100%",
              flexGrow: 1,
              overflowY: "auto",
              backgroundColor: "white",
              borderRadius: "0 0 16px 16px",
            }}
            className="bg-white rounded-b-2xl p-4"
          >
            <div className="border p-2 rounded-xl max-h-[450px] overflow-y-scroll">
              <TableContainer sx={{ borderRadius: "0.25rem" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ bgcolor: "#F9F9F9", fontWeight: "500", color: "#4D4D4D" }}>Pincode</TableCell>
                      <TableCell sx={{ bgcolor: "#F9F9F9", fontWeight: "500", color: "#4D4D4D" }}>Locality</TableCell>
                      <TableCell sx={{ bgcolor: "#F9F9F9", fontWeight: "500", color: "#4D4D4D", textAlign: "center" }}>
                        Status
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", bgcolor: "#F9F9F9" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.details?.map((detail, index) => (
                      <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                        <TableCell sx={{ fontWeight: "500" }}>{detail?.pincode}</TableCell>
                        <TableCell sx={{ fontWeight: "500", color: "#323C47" }}>{detail?.areaName}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <span
                            className={`w-40 py-[0.375rem] px-4 gap-2 rounded-full text-center ${
                              detail?.stockListing !== 1
                                ? "bg-[#FEBE401A] text-[#FEBE40]" // You can change this color to something that represents "Not Listed"
                                : detail?.stockBinary === "1"
                                ? "bg-[#22C55E1A] text-[#22C55E]"
                                : "bg-[#F53E3E1A] text-[#F53E3E]"
                            }`}
                          >
                            {detail?.stockListing !== 1
                              ? "Not Listed"
                              : detail?.stockBinary === "1"
                              ? "In Stock"
                              : "Out of Stock"}
                          </span>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>
                          <img src="/logos/dots.svg" alt="dots" onClick={handleDotsClick} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Popover for Potential Sales Loss */}
              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      navigate("/fulfillment/potential-sales-loss");
                      handlePopoverClose();
                    }}
                  >
                    Go to Potential Sales Loss Screen
                  </Button>
                </Box>
              </Popover>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SkuCityLevelAvailabilityModal;
