import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllMarketingReportBlinkitProductListingSuccess,
  getAllMarketingReportBlinkitProductListingRequest,
  getAllMarketingReportBlinkitProductListingFailure,
  getAllMarketingReportBlinkitProductRecommandationSuccess,
  getAllMarketingReportBlinkitProductRecommandationRequest,
  getAllMarketingReportBlinkitProductRecommandationFailure,
  getAllMarketingReportInstamartSuccess,
  getAllMarketingReportInstamartRequest,
  getAllMarketingReportInstamartFailure,
  getAllMarketingReportZeptoSkuPerformanceSuccess,
  getAllMarketingReportZeptoSkuPerformanceRequest,
  getAllMarketingReportZeptoSkuPerformanceFailure,
  getAllMarketingReportZeptoSkuAnalyticsSuccess,
  getAllMarketingReportZeptoSkuAnalyticsRequest,
  getAllMarketingReportZeptoSkuAnalyticsFailure,
} from "../../redux/actions/marketingActions";
import {
  fetchPerformanceMarketingAdsCampaignPerformanceDataZepto,
  fetchPerformanceMarketingAdsMarketingReportDataInstamart,
  fetchPerformanceMarketingAdsProductListingDataBlinkit,
  fetchPerformanceMarketingAdsProductRecommendationDataBlinkit,
  fetchPerformanceMarketingAdsSkuPerformanceDataZepto,
} from "../../services/adsServices/performanceMarketingAdsServices";
import ProductListingCampaignSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/ProductListingCampaignSectionsContainer";
import ProcuctRecommandationCampaignSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/ProcuctRecommandationCampaignSectionsContainer";
import MarketingReportSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/MarketingReportSectionsContainer";
import SkuPerformanceZeptoSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/SkuPerformanceZeptoSectionsContainer";
import CampaignPerformanceSectionsContainer from "../../components/ads/PerformanceMarketingAdsComponents/CampaignPerformanceSectionsContainer";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

const optionsBlinkit = ["Product Listing", "Product Recommendation"];
const optionsInstamart = ["Marketing Report"];
const optionsZepto = ["SKU Performance", "Campaign Performance"];

const PerformanceMarketingAdsScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDate } = useDate();

  const options =
    platformName === "Blinkit"
      ? optionsBlinkit
      : platformName === "Instamart"
      ? optionsInstamart
      : platformName === "Zepto"
      ? optionsZepto
      : [];

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchDataOption1: fetchPerformanceMarketingAdsProductListingDataBlinkit,
      fetchDataOption2: fetchPerformanceMarketingAdsProductRecommendationDataBlinkit,
      performanceActions1: {
        request: getAllMarketingReportBlinkitProductListingRequest,
        success: getAllMarketingReportBlinkitProductListingSuccess,
        failure: getAllMarketingReportBlinkitProductListingFailure,
      },
      performanceActions2: {
        request: getAllMarketingReportBlinkitProductRecommandationRequest,
        success: getAllMarketingReportBlinkitProductRecommandationSuccess,
        failure: getAllMarketingReportBlinkitProductRecommandationFailure,
      },
    },

    Zepto: {
      fetchDataOption1: fetchPerformanceMarketingAdsSkuPerformanceDataZepto,
      fetchDataOption2: fetchPerformanceMarketingAdsCampaignPerformanceDataZepto,
      performanceActions1: {
        request: getAllMarketingReportZeptoSkuPerformanceRequest,
        success: getAllMarketingReportZeptoSkuPerformanceSuccess,
        failure: getAllMarketingReportZeptoSkuPerformanceSuccess,
      },
      performanceActions2: {
        request: getAllMarketingReportZeptoSkuAnalyticsRequest,
        success: getAllMarketingReportZeptoSkuAnalyticsSuccess,
        failure: getAllMarketingReportZeptoSkuAnalyticsFailure,
      },
    },

    Instamart: {
      fetchDataOption1: fetchPerformanceMarketingAdsMarketingReportDataInstamart,
      performanceActions1: {
        request: getAllMarketingReportInstamartRequest,
        success: getAllMarketingReportInstamartSuccess,
        failure: getAllMarketingReportInstamartFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];
  
    if (platform) {
      // Dispatch the request action for performanceActions1
      dispatch(platform.performanceActions1.request());
  
      // Fetch data for option 1
      fetchWithRetry(
        platform.fetchDataOption1,
        platform.performanceActions1.success,
        platform.performanceActions1.failure,
        selectedDate,
        dispatch,
        1
      );
  
      // Conditionally fetch data for option 2 only if it exists
      if (platform.fetchDataOption2 && platform.performanceActions2) {
        dispatch(platform.performanceActions2.request());
  
        fetchWithRetry(
          platform.fetchDataOption2,
          platform.performanceActions2.success,
          platform.performanceActions2.failure,
          selectedDate,
          dispatch,
          1
        );
      }
    }
  }, [platformName, selectedDate, selectedOption]);
  

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate);
    const startDate = formatDateWithoutTimezone(dayjs(selectedDate).subtract(30, "day"));
    return [startDate, endDate];
  };

  return (
    <>
      <HeadingBar
        setPlatformName={setPlatformName}
        selectedDate={calculateDateRange(selectedDate)}
        platformName={platformName}
      />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 && (
          <div className="flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max pb-10">
            {platformName === "Blinkit" && <ProductListingCampaignSectionsContainer />}
            {platformName === "Instamart" && <MarketingReportSectionsContainer />}
            {platformName === "Zepto" && <SkuPerformanceZeptoSectionsContainer />}
          </div>
        )}
      {selectedOption === 1 && (
          <div className="rounded-lg w-full flex flex-col items-center mt-[1rem] pb-10">
            {platformName === "Blinkit" && <ProcuctRecommandationCampaignSectionsContainer />}
            {platformName === "Zepto" && <CampaignPerformanceSectionsContainer />}
          </div>
        )}
      </Toolbar>
    </>
  );
};

export default PerformanceMarketingAdsScreen;
