export const GET_ALL_SALES_REQUEST = "GET_ALL_SALES_REQUEST";
export const GET_ALL_SALES_SUCCESS = "GET_ALL_SALES_SUCCESS";
export const GET_ALL_SALES_FAILURE = "GET_ALL_SALES_FAILURE";

export const INVENTORY_REPORT_BLINKIT_REQUEST =
  "INVENTORY_REPORT_BLINKIT_REQUEST";
export const INVENTORY_REPORT_BLINKIT_SUCCESS =
  "INVENTORY_REPORT_BLINKIT_SUCCESS";
export const INVENTORY_REPORT_BLINKIT_FAILURE =
  "INVENTORY_REPORT_BLINKIT_FAILURE";

export const INVENTORY_REPORT_INSTAMART_REQUEST =
  "INVENTORY_REPORT_INSTAMART_REQUEST";
export const INVENTORY_REPORT_INSTAMART_SUCCESS =
  "INVENTORY_REPORT_INSTAMART_SUCCESS";
export const INVENTORY_REPORT_INSTAMART_FAILURE =
  "INVENTORY_REPORT_INSTAMART_FAILURE";

export const INVENTORY_REPORT_ZEPTO_REQUEST = "INVENTORY_REPORT_ZEPTO_REQUEST";
export const INVENTORY_REPORT_ZEPTO_FAILURE = "INVENTORY_REPORT_ZEPTO_FAILURE";
export const INVENTORY_REPORT_ZEPTO_SUCCESS = "INVENTORY_REPORT_ZEPTO_SUCCESS";
