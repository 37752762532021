import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import {
  productInsightsGroupingBlinkit,
  productInsightsGroupingInstamart,
  productInsightsGroupingZepto,
} from "../../../utils/dataGrouping/Insights/InsightsGrouping";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Register the required components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title);

const ProductInsightsPieChart = ({ salesData, platformName }) => {
  let groupedData;
  // Set grouped data according to platform name
  if (platformName === "Blinkit") {
    groupedData = productInsightsGroupingBlinkit(salesData);
  } else if (platformName === "Instamart") {
    groupedData = productInsightsGroupingInstamart(salesData);
  } else {
    groupedData = productInsightsGroupingZepto(salesData);
  }

  // Filter out the null category and calculate CM1
  const filteredData = groupedData.filter((item) => item.product);
  const uniqueProducts = Array.from(new Set(filteredData.map((item) => item.product))); // Get unique products
  const cm1 = uniqueProducts.map((product) => {
    const item = filteredData.find((item) => item.product === product);
    return item ? parseFloat(item.mrp) - parseFloat(item.gst) - parseFloat(item.cogs) : 0; // Ensure CM1 calculation matches unique products
  });

  // Chart.js data structure
  const data = {
    labels: uniqueProducts,
    datasets: [
      {
        label: "CM1 in different Products over 1 Month",
        data: cm1,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#0000F0",
          "#F08080",
          "#8A2BE2",
          "#FFA500",
          "#008000",
          "#FFD700",
          "#00FF00",
          "#00FFFF",
          "#DC143C",
          "#FF00FF",
          "#800080",
          "#4682B4",
          "#FF4500",
          "#2E8B57",
          "#B22222",
        ].slice(0, uniqueProducts.length), // Ensure colors match the number of unique products
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#0000F0",
          "#F08080",
          "#8A2BE2",
          "#FFA500",
          "#008000",
          "#FFD700",
          "#00FF00",
          "#00FFFF",
          "#DC143C",
          "#FF00FF",
          "#800080",
          "#4682B4",
          "#FF4500",
          "#2E8B57",
          "#B22222",
        ].slice(0, uniqueProducts.length),
        borderWidth: 3,
        hoverBorderColor: "#fff",
      },
    ],
  };

  // Chart.js options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: "#333",
          font: {
            size: 12,
            weight: "bold",
          },
          pointStyle: "circle",
          usePointStyle: true,
          padding: 20,
          boxWidth: 12,
        },
      },
      title: {
        display: true,
        text: "CM1 in different Products over 1 Month",
        font: {
          size: 18,
          weight: "bold",
        },
        color: "#333",
      },
      tooltip: {
        backgroundColor: "#333",
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}`;
          },
        },
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => value.toFixed(2),
        font: {
          weight: "bold",
          size: 10,
        },
        anchor: "bottom",
        align: "bottom",
      },
    },
    cutout: "40%",
    elements: {
      arc: {
        borderRadius: 8,
      },
    },
  };

  if (salesData.length == 0) {
    return (
      <div className="h-full">
        <div className="text-sm pl-12 text-[#4F4E69] font-medium font-body">CM1 in different Products over 1 Month</div>
        <div className="h-full flex justify-center items-center">No Data in Product Insights</div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ height: "300px", width: "100%" }}>
        <Doughnut data={data} options={options} />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "20px",
          maxHeight: "100%", // Increased max height
          overflow: "auto", // Changed to auto
        }}
      >
        {data.labels.map((label, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px",
              width: "calc(50% - 10px)", // Use calc for better spacing
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                backgroundColor: data.datasets[0].backgroundColor[index],
                borderRadius: "50%",
                height: "12px",
                width: "12px",
                marginRight: "8px",
              }}
            ></div>
            <span
              style={{
                fontSize: "12px",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              {label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductInsightsPieChart;
