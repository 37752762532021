import React from "react";
import { useSelector } from "react-redux";
import CityWiseSalesTable from "./CityWiseSalesTable"; // Component for displaying city-wise sales table
import CityWiseSkuSplitCharts from "./CityWiseSkuSplitCharts"; // Component for displaying SKU split charts
import { FaChartArea } from "react-icons/fa6"; // Importing area chart icon
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton"; // Loading skeleton for charts
import TableSkeleton from "../../common/Skeletons/TableSkeleton"; // Loading skeleton for tables
import NoDataFound from "../../common/NoDataFound/NoDataFound"; // Component to display when no data is available
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";

const CityWiseSkuSplitSectionsContainer = ({ platformName }) => {
  // Retrieve total sales data from Redux store
  const { totalSales } = useSelector((state) => state.sales);

  // Show loading skeletons if data is still being fetched
  if (totalSales.loading) {
    return (
      <>
        {/* Display loading placeholders for both table and chart */}
        <TableSkeleton />
        <ChartSkeleton />
      </>
    );
  }

  // Show 'No Data Found' component if no data is available
  if (!totalSales.data || totalSales.data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      {/* City Wise Sales Section */}

      <div className="mb-4">
        {/* City-wise sales table based on the selected platform */}
        <CityWiseSalesTable platformName={platformName} />
      </div>

      {/* City SKU Split Section */}
      <div className="bg-white shadow-md rounded-xl mb-4">
        <div className="flex p-4 border-b">
          <h1 className="flex gap-4 items-center text-lg font-medium">
            {/* Icon for City SKU Split */}
            <FaChartArea />
            City SKU Split
            <CustomTooltip
              title={
                "Displays a daily breakdown of SKU sales across cities, providing insights into sales trends for each city."
              }
            >
              <PiInfoLight />
            </CustomTooltip>
          </h1>
        </div>

        <div>
          {/* City-wise SKU split charts based on the selected platformName */}
          <CityWiseSkuSplitCharts platformName={platformName} />
        </div>
      </div>
    </div>
  );
};

export default CityWiseSkuSplitSectionsContainer;

// Indexing of the code:

// --City Wise Sales Section:
// Displays a table with city-wise sales using the CityWiseSalesTable component.
// Use case: Show detailed sales information filtered by city.

// --City SKU Split Section:
// Displays charts with city-wise SKU split using the CityWiseSkuSplitCharts component.
// Use case: Visualize the distribution of SKUs across different cities based on the selected platform and also filtred by category and city.
