import React from "react";

const TopPerformingCitiesCard = ({ title, mrp, previous, percentageChange }) => {
  return (
    <div className="bg-white border shadow-sm rounded-lg p-4 mb-2 text-[#727278] font-medium h-[6.25rem]">
      {/* upper */}
      <div className="flex items-end text-sm gap-4">
        <img src="/logos/sales-report.svg" alt="" className="w-[1.125rem] h-[1.125rem]" />
        <div className="font-medium">{title}</div>
      </div>
      {/* lower */}
      <div className="mt-2 text-xs flex justify-between">
        <div className="text-xs">
          Current MRP : <span className="text-[#387FF5]">{mrp}</span>
        </div>
        <div className="text-xs">
          Previous MRP : <span className="text-[#387FF5]">{previous}</span>
        </div>
      </div>
      <div className="text-[#22C55E] flex items-center gap-2 text-xs">▲{percentageChange}%</div>
    </div>
  );
};

export default TopPerformingCitiesCard;
