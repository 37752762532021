import {
  sovThirdPartyVisibilityKeywordQuery,
  sovThirdPartyVisibilityAreaNameQuery,
  sovThirdPartyVisibilityWeeklyKeywordQuery,
  sovThirdPartyVisibilityWeeklyAreaNameQuery,
} from "../../utils/queries/sovThirdPartyVisibilityQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchSoaThirdPartyVisibilityKeywordData = async (selectedDate, platformName) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(sovThirdPartyVisibilityKeywordQuery(selectedDate, platformName));
  return result.tablePivot();
};

export const fetchSoaThirdPartyVisibilityAreaNameData = async (selectedDate, platformName) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(
    sovThirdPartyVisibilityAreaNameQuery(selectedDate, platformName )
  );
  return result.tablePivot();
};

export const fetchSovThirdPartyVisibilityWeeklyKeywordData = async (selectedDate, platformName) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(sovThirdPartyVisibilityWeeklyKeywordQuery(selectedDate, platformName));
  return result.tablePivot();
};

export const fetchSoaThirdPartyVisibilityWeeklyAreaNameData = async (
  selectedDate,
  platformName,
 
) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(
    sovThirdPartyVisibilityWeeklyAreaNameQuery(selectedDate, platformName)
  );
  return result.tablePivot();
};
