import {
  blinkitQuery,
  instamartQuery,
  zeptoQuery,
  blinkitQueryforWeeklyChart,
  zeptoQueryforWeeklyChart,
  instamartQueryforWeeklyChart,
} from "../../utils/queries/localityAvailabilityQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

// Fetch Blinkit data for daily view
export const fetchBlinkitData = async (selectedDate) => {
  console.log("selectedDate  query=====", selectedDate)
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Instamart data for daily view
export const fetchInstamartData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Zepto data for daily view
export const fetchZeptoData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDate));
  return result.tablePivot();
};

// Fetch Blinkit data for weekly chart view
export const fetchBlinkitWeeklyData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQueryforWeeklyChart(selectedDate));
  return result.tablePivot();
};

// Fetch Instamart data for weekly chart view
export const fetchInstamartWeeklyData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQueryforWeeklyChart(selectedDate));
  return result.tablePivot();
};

// Fetch Zepto data for weekly chart view
export const fetchZeptoWeeklyData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQueryforWeeklyChart(selectedDate));
  return result.tablePivot();
};
