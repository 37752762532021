import dayjs from "dayjs";

// Format selectedDate without the timezone
const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  const startOfDay = dayjs(date).startOf("day");
  const endOfDay = dayjs(date).endOf("day");
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);
  return { start: formattedStartOfDay, end: formattedEndOfDay };
};

const getLast7DaysRange = (selectedDate) => {
  // Ensure selectedDate is valid
  if (!dayjs(selectedDate).isValid()) {
    console.error("Invalid selectedDate:", selectedDate);
    return ["", ""];
  }
  // Get endDate and startDate in ISO 8601 format
  const endDate = dayjs(selectedDate).toISOString();
  const startDate = dayjs(selectedDate).subtract(6, "day").toISOString();

  return [startDate, endDate];
};

export const sovThirdPartyVisibilityKeywordQuery = (selectedDate, platformName) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    timeDimensions: [
      {
        dimension: "third_party_keyword.normalized_date",
        dateRange: [start, end],
      },
    ],
    order: {
      "third_party_keyword.overall_sov": "desc",
    },
    dimensions: [
      "third_party_keyword.sov_organic_percentage",
      "third_party_keyword.sov_ad_percentage",
      "third_party_keyword.brand",
      "third_party_keyword.keyword",
      "third_party_keyword.keyword_type",
      "third_party_keyword.area_name_lm",
      "third_party_keyword.city_lm",
      "third_party_keyword.product_name",
      "third_party_keyword.date",
      "third_party_keyword.normalized_date",
    ],
    filters: [
      {
        dimension: "third_party_keyword.platform",
        operator: "equals",
        values: [platformName],
      },
    ],
  };
};

export const sovThirdPartyVisibilityAreaNameQuery = (selectedDate, platformName) => {
  const { start, end } = getSingleDayDateRange(selectedDate);

  const query = {
    timeDimensions: [
      {
        dimension: "third_party_keyword.normalized_date",
        dateRange: [start, end],
      },
    ],
    order: {
      "third_party_keyword.overall_sov": "desc",
    },
    dimensions: [
      "third_party_keyword.sov_organic_percentage",
      "third_party_keyword.sov_ad_percentage",
      "third_party_keyword.brand",
      "third_party_keyword.keyword",
      "third_party_keyword.keyword_type",
      "third_party_keyword.area_name_lm",
      "third_party_keyword.city_lm",
      "third_party_keyword.product_name",
    ],
    filters: [
      {
        dimension: "third_party_keyword.platform",
        operator: "equals",
        values: [platformName],
      },
    ],
  };

  return query;
};

export const sovThirdPartyVisibilityWeeklyKeywordQuery = (selectedDate, platformName) => {
  const [start, end] = getLast7DaysRange(selectedDate);
  return {
    timeDimensions: [
      {
        dimension: "third_party_keyword.normalized_date",
        dateRange: [start, end],
      },
    ],
    order: {
      "third_party_keyword.overall_sov": "desc",
    },
    dimensions: [
      "third_party_keyword.sov_organic_percentage",
      "third_party_keyword.sov_ad_percentage",
      "third_party_keyword.brand",
      "third_party_keyword.keyword",
      "third_party_keyword.keyword_type",
      "third_party_keyword.area_name_lm",
      "third_party_keyword.city_lm",
      "third_party_keyword.product_name",
      "third_party_keyword.date",
      "third_party_keyword.normalized_date",
    ],
    filters: [
      {
        dimension: "third_party_keyword.platform",
        operator: "equals",
        values: [platformName],
      },
    ],
  };
};

export const sovThirdPartyVisibilityWeeklyAreaNameQuery = (selectedDate, platformName) => {
  const [start, end] = getLast7DaysRange(selectedDate);
  const query = {
    timeDimensions: [
      {
        dimension: "third_party_keyword.normalized_date",
        dateRange: [start, end],
      },
    ],
    order: {
      "third_party_keyword.overall_sov": "desc",
    },
    dimensions: [
      "third_party_keyword.sov_organic_percentage",
      "third_party_keyword.sov_ad_percentage",
      "third_party_keyword.brand",
      "third_party_keyword.keyword",
      "third_party_keyword.keyword_type",
      "third_party_keyword.area_name_lm",
      "third_party_keyword.city_lm",
      "third_party_keyword.product_name",
      "third_party_keyword.date",
      "third_party_keyword.normalized_date",
    ],
    filters: [
      {
        dimension: "third_party_keyword.platform",
        operator: "equals",
        values: [platformName],
      },
    ],
  };

  return query;
};
