import { Skeleton } from "@mui/material";
import React from "react";

const ChartSkeleton = () => {
  return (
    <div className="p-4">
      <Skeleton variant="rounded" sx={{ width: "100%", height: "400px", bgcolor: "grey.200" }} />
    </div>
  );
};

export default ChartSkeleton;
