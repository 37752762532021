import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const headers = ["Brand Name / Product Name", "Overall Sov", "Organic Sov", "Ad Sov"];

function groupAndAggregateByBrand(data) {
  return data.reduce((acc, item) => {
    const brand = item["third_party_keyword.brand"];
    const product = item["third_party_keyword.product_name"]; // Assuming there is a unique identifier for products

    if (!acc[brand]) {
      acc[brand] = {
        brand,
        products: {},
      };
    }

    const organicPercentage = parseFloat(item["third_party_keyword.totalSovOrgaincPercentage"]) || 0;
    const adPercentage = parseFloat(item["third_party_keyword.totalSovAdPercentage"]) || 0;

    // Aggregate product data
    if (!acc[brand].products[product]) {
      acc[brand].products[product] = {
        productName: product,
        totalOrganicPercentage: 0,
        totalAdPercentage: 0,
        countAd: 0,
        countOrganic: 0,
      };
    }
    acc[brand].products[product].totalOrganicPercentage += organicPercentage;
    acc[brand].products[product].totalAdPercentage += adPercentage;
    if (organicPercentage > 0) acc[brand].products[product].countOrganic += 1;
    if (adPercentage > 0) acc[brand].products[product].countAd += 1;

    return acc;
  }, {});
}

const ShareOfVoiceKeyInsightsTable = ({ tableData }) => {
  console.log("table==============", tableData);
  const user = JSON.parse(localStorage.getItem("user"));
  const ourBrand = user.organizationDetail.name;
  const finalTableData = groupAndAggregateByBrand(tableData);
  const [expandedBrands, setExpandedBrands] = useState({});

  useEffect(() => {
    setExpandedBrands({ [ourBrand]: true });
  }, []);

  const handleBrandClick = (brand) => {
    setExpandedBrands((prev) => ({
      ...prev,
      [brand]: !prev[brand], // Toggle expansion state for the clicked category
      [ourBrand]: true,
    }));
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <div className="border">
        <div className="p-2">
          <TableContainer sx={{ overflowX: "auto", borderRadius: "0.25rem" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        fontWeight: "500",
                        color: "#4d4d4d",
                        fontSize: "0.875rem",
                        textAlign: index == 0 ? "left" : "center",
                        minWidth: "150px",
                        maxWidth: "150px",
                        bgcolor: "#F9F9F9",
                        boxShadow: index === 0 ? "5px 0px 5px -5px rgba(16, 24, 40, 0.06)" : "",
                        zIndex: index === 0 ? "3" : "",
                        position: index === 0 ? "sticky" : "",
                        left: index === 0 ? 0 : "",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      right: 0,
                      bgcolor: "#F9F9F9",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.values(finalTableData).length > 0 ? (
                  Object.values(finalTableData).map((brandData, brandIndex) => {
                    const totalAdBrand = Object.values(brandData.products).reduce((acc, item) => {
                      return acc + (item.countAd > 0 ? item.totalAdPercentage / item.countAd : 0);
                    }, 0);

                    const totalOrganicBrand = Object.values(brandData.products).reduce((acc, item) => {
                      return acc + (item.countOrganic > 0 ? item.totalOrganicPercentage / item.countOrganic : 0);
                    }, 0);

                    return (
                      <React.Fragment key={brandIndex}>
                        <TableRow
                          onClick={() => handleBrandClick(brandData.brand)}
                          sx={{ bgcolor: brandIndex % 2 !== 0 ? "#F0F6FF" : "#fff" }}
                          className={`cursor-pointer ${expandedBrands[brandData.brand] ? "bg-[#f0f6ff]" : ""} `}
                        >
                          <TableCell
                            sx={{
                              // bgcolor: expandedBrands[brandData.brand] ? "#E5E5E5" : "white",
                              bgcolor: brandIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "1rem",
                              minWidth: "150px",
                              position: "sticky",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              left: 0,
                              display: "flex",
                              gap: "1rem",
                              justifyContent: "space-between",
                            }}
                          >
                            {brandData.brand === ourBrand ? "Total" : brandData.brand}
                            {expandedBrands[brandData.brand] ? (
                              <FaCaretUp className="text-[#667085] text-base" />
                            ) : (
                              <FaCaretDown className="text-[#667085] text-base" />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#4d4d4d",
                              fontWeight: "500",
                              minWidth: "100px",
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            {(totalAdBrand + totalOrganicBrand).toFixed(2)}%
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              minWidth: "100px",
                              flexGrow: 1,
                            }}
                          >
                            {totalOrganicBrand.toFixed(2)}%
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              minWidth: "100px",
                              flexGrow: 1,
                            }}
                          >
                            {totalAdBrand.toFixed(2)}%
                          </TableCell>
                          <TableCell
                            sx={{
                              zIndex: "3",
                              position: "sticky",
                              right: 0,
                              // bgcolor: expandedBrands[brandData.brand] ? "#E5E5E5" : "white",
                              bgcolor: brandIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            }}
                          ></TableCell>
                        </TableRow>
                        {expandedBrands[brandData.brand] &&
                          Object.values(brandData.products).map((item, index) => {
                            const ad = parseFloat(item.totalAdPercentage / item.countAd) || 0;
                            const organic = parseFloat(item.totalOrganicPercentage / item.countOrganic) || 0;
                            const overall = ad + organic;

                            return (
                              <TableRow key={index} className="cursor-pointer bg-[#F0F0F0] text-[#727278]">
                                <TableCell
                                  sx={{
                                    color: "#4d4d4d",
                                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    minWidth: "150px",
                                    maxWidth: "150px",
                                    position: "sticky",
                                    left: 0,
                                    bgcolor: expandedBrands[brandData.brand] ? "#F0F0F0" : "white",
                                    paddingLeft: "2rem",
                                  }}
                                >
                                  {item.productName}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center", minWidth: "100px" }}>
                                  {overall.toFixed(2)}%
                                </TableCell>
                                <TableCell sx={{ textAlign: "center", minWidth: "100px" }}>
                                  {organic.toFixed(2)}%
                                </TableCell>
                                <TableCell sx={{ textAlign: "center", minWidth: "100px" }}>{ad.toFixed(2)}%</TableCell>
                                <TableCell
                                  sx={{
                                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    zIndex: "3",
                                    position: "sticky",
                                    right: 0,
                                    bgcolor: expandedBrands[brandData.brand] ? "#F0F0F0" : "white",
                                  }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length} sx={{ textAlign: "center", mt: 2 }}>
                      <Typography>No data available</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Paper>
  );
};

export default ShareOfVoiceKeyInsightsTable;
