// import React from "react";
// import { Doughnut } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
// import {
//   categoryProfitabilityGroupingBlinkit,
//   categoryProfitabilityGroupingInstamart,
//   categoryProfitabilityGroupingZepto,
// } from "../../../utils/dataGrouping/OverallProfitability/CategoryProfitabilityGrouping";

// // Register the required components for Chart.js
// ChartJS.register(ArcElement, Tooltip, Legend, Title);

// const InsightsCategoriesSalesPieChart = ({ salesData, platformName }) => {
//   let groupedData;
//   // Set grouped data according to platform name
//   if (platformName === "Blinkit") {
//     groupedData = categoryProfitabilityGroupingBlinkit(salesData);
//   } else if (platformName === "Instamart") {
//     groupedData = categoryProfitabilityGroupingInstamart(salesData);
//   } else {
//     groupedData = categoryProfitabilityGroupingZepto(salesData);
//   }
//   console.log("cm111111111111", groupedData);
//   // Filter out the null category and calculate CM1
//   const filteredData = groupedData.filter((item) => item.category);

//   const categories = filteredData.map((item) => item.category);
//   const cm1 = filteredData.map((item) => item.cm1);

//   // Chart.js data structure
//   const data = {
//     labels: categories,
//     datasets: [
//       {
//         label: "CM1 in different Categories over 1 Month",
//         data: cm1,
//         backgroundColor: [
//           "#FF6384", // Customize colors for each slice
//           "#36A2EB",
//           "#FFCE56",
//           "#4BC0C0",
//           "#9966FF",
//           "#FF9F40",
//         ],
//         hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
//         borderWidth: 3,
//         // borderColor: "#000", // Border between slices
//         hoverBorderColor: "#fff",
//         cutout: "60%", // Make it a donut and increase the width of the cutout (default is 50%)
//       },
//     ],
//   };

//   // Chart.js options
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         position: "right", // Legend position
//         labels: {
//           color: "black", // Customize legend text color
//           font: {
//             size: 12, // Font size
//             weight: "bold", // Set the font to bold
//           },
//         },
//       },
//       title: {
//         display: true,
//         text: "CM1 in different Categories over 1 Month",
//         font: {
//           size: 18,
//           weight: "bold",
//         },
//         color: "#333",
//       },
//       tooltip: {
//         backgroundColor: "#333", // Tooltip dark theme
//         callbacks: {
//           label: (tooltipItem) => {
//             return `${tooltipItem.label}: ${tooltipItem.raw.toLocaleString("en-US", {
//               style: "currency",
//               currency: "INR",
//             })}`;
//           },
//         },
//       },
//       datalabels: {
//         color: "#fff", // Set the text color for the labels inside slices
//         formatter: (value) => value.toFixed(2), // Format the labels to 2 decimal places
//         font: {
//           weight: "bold",
//           size: 10,
//         },
//         anchor: "center", // Positioning of the labels
//         align: "center",
//       },
//     },
//   };

//   return (
//     <div style={{ height: "300px", width: "100%" }}>
//       {/* Fixing height of the chart */}
//       <Doughnut data={data} options={options} />
//     </div>
//   );
// };

// export default InsightsCategoriesSalesPieChart;

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import {
  categoryProfitabilityGroupingBlinkit,
  categoryProfitabilityGroupingInstamart,
  categoryProfitabilityGroupingZepto,
} from "../../../utils/dataGrouping/OverallProfitability/CategoryProfitabilityGrouping";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Register the required components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title);

const InsightsCategoriesSalesPieChart = ({ salesData, platformName }) => {
  let groupedData;
  // Set grouped data according to platform name
  if (platformName === "Blinkit") {
    groupedData = categoryProfitabilityGroupingBlinkit(salesData);
  } else if (platformName === "Instamart") {
    groupedData = categoryProfitabilityGroupingInstamart(salesData);
  } else {
    groupedData = categoryProfitabilityGroupingZepto(salesData);
  }

  // Filter out the null category and calculate CM1
  const filteredData = groupedData.filter((item) => item.category);

  const categories = filteredData.map((item) => item.category);
  const cm1 = filteredData.map((item) => item.cm1);

  // Chart.js data structure
  const data = {
    labels: categories,
    datasets: [
      {
        label: "CM1 in different Categories over 1 Month",
        data: cm1,
        backgroundColor: [
          "#FF6384", // Customize colors for each slice
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
        borderWidth: 0.7, // Make borders thicker
        borderRadius: 4,
        hoverBorderColor: "#fff", // Change the border color on hover to white
        cutout: "50%", // Make it a donut and increase the width of the cutout (default is 50%)
      },
    ],
  };

  // Chart.js options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderRadius: 8, // Adjust this value for more or less curvature
      },
    },
    plugins: {
      legend: {
        position: "right", // Legend position
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          color: "#606060", // Customize legend text color
          font: {
            size: 12, // Font size
            weight: "normal", // Set the font to bold
          },
        },
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw.toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
            })}`;
          },
        },
      },
      datalabels: {
        color: "#fff", // Set the text color for the labels inside slices
        formatter: (value) => value.toFixed(2), // Format the labels to 2 decimal places
        font: {
          weight: "bold",
          size: 10,
        },
        anchor: "center", // Positioning of the labels
        align: "center",
      },
    },
  };

  if (salesData.length == 0) {
    return (
      <div className="h-full">
        <div className="text-sm pl-7 text-[#4F4E69] font-medium font-body">
          CM1 in different Categories over 1 Month
        </div>
        <div className="flex justify-center items-center h-full">No Data in CM1 Trends for 1 Month</div>
      </div>
    );
  }

  return (
    <>
      <span className="text-sm pl-7 text-center text-[#4F4E69] font-medium font-body">
        CM1 in different Categories over 1 Month
      </span>

      <div className="flex  mt-4 flex-col justify-start" style={{ height: "220px", width: "80%" }}>
        {/* Fixing height of the chart */}
        <Doughnut data={data} options={options} />
      </div>
    </>
  );
};

export default InsightsCategoriesSalesPieChart;
