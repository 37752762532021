import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { BsCalendar2Month } from "react-icons/bs";
import { FaCity } from "react-icons/fa";

import { MdCategory } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  categoryProfitabilityGroupingBlinkit,
  categoryProfitabilityGroupingInstamart,
  categoryProfitabilityGroupingZepto,
} from "../../../utils/dataGrouping/OverallProfitability/CategoryProfitabilityGrouping";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const title =
  "This table presents profitability metrics for the all categories, including total sales with GST, GST amount, net sales (excluding GST), cost of goods sold (COGS), and CM1. Compare categories to assess profitability differences.";

const CategoryProfitability = ({ headings, platformName }) => {
  const { categoryProfitability } = useSelector((state) => state.profitability);
  const tableData = categoryProfitability.data;

  let groupedData;
  // Set grouped data according to platform name
  if (platformName === "Blinkit") {
    groupedData = categoryProfitabilityGroupingBlinkit(tableData);
  } else if (platformName === "Instamart") {
    groupedData = categoryProfitabilityGroupingInstamart(tableData);
  } else {
    groupedData = categoryProfitabilityGroupingZepto(tableData);
  }

  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Calculate data for current page
  const paginatedData = groupedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        marginTop: "2rem",
      }}
    >
      <>
        <div className="flex text-xl items-center gap-4 p-4 font-bold border-b">
          <MdCategory />
          Category Wise Profitability
          <CustomTooltip title={title}>
            <PiInfoLight />
          </CustomTooltip>
        </div>
        <div className="p-2">
          <TableContainer sx={{ borderRadius: "0.25rem" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headings.map((heading, index) => (
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: index == 0 ? "left" : "center",
                        minWidth: "200px",
                        boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: index === 0 && "3",
                        position: index === 0 && "sticky",
                        left: index === 0 && 0,
                        bgcolor: "#F9F9F9",
                      }}
                      key={heading}
                    >
                      {heading}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      right: 0,
                      bgcolor: "#F9F9F9",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, idx) => (
                  <TableRow key={idx} sx={{ bgcolor: idx % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                    <TableCell
                      sx={{
                        color: "#4D4D4D",
                        minWidth: "200px",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        position: "sticky",
                        textAlign: "left",
                        left: 0,
                        bgcolor: idx % 2 !== 0 ? "#F0F6FF" : "#fff",
                      }}
                    >
                      {row.category}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.mrp.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.gst.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.netSales.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.cogs.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.cm1.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: idx % 2 !== 0 ? "#F0F6FF" : "#fff",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Pagination controls */}
        <TablePagination
          component="div"
          count={groupedData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </>
    </Paper>
  );
};

export default CategoryProfitability;
