import React, { useState } from "react";
import { MdTableChart } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import CategoryRcaStatsCard from "./CategoryRcaStatsCard";
import CategoryRcaHeading from "./CategoryRcaHeading";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRcaCity, getFilteredRcaCity, getFilteredRcaSku } from "../../../redux/actions/categoryRcaActions";

// Utility function to format numbers with K, L (lakh), and Cr (crore)
const formatPrice = (value) => {
  if (value >= 10000000) {
    // If value is greater than or equal to 1 crore
    return `₹${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    // If value is greater than or equal to 1 lakh
    return `₹${(value / 100000).toFixed(2)} Lac`;
  } else if (value >= 1000) {
    // If value is greater than or equal to 1 thousand
    return `₹${(value / 1000).toFixed(2)} K`;
  } else {
    // For values below 1 thousand
    return `₹${value}`;
  }
};
const CategoryRcaCityWise = () => {
  const { filteredCities, selectedRcaCategory } = useSelector((state) => state.rcaCategory);
  console.log("filteredCities:", filteredCities);
  const [selectedSKU, setSelectedSKU] = useState(0);
  const handleSkuChange = (index) => {
    setSelectedSKU(index);
  };
  const handleOverallCitiesClick = (index) => {
    setSelectedSKU(index);
  };

  // Calculate overall GMV and Quantity
  const overallCategoryGMV = filteredCities.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_gmv"] || 0),
    0
  );

  const overallCategoryQty = filteredCities.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_qty_sold"] || 0),
    0
  );

  // Use formatted GMV
  const offtakesAtMrp = {
    title: "Offtake at",
    value: { heading: "All", totalGMV: formatPrice(overallCategoryGMV), totalQty: overallCategoryQty }, // Format the GMV value here
  };

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2">
          <div className="bg-slate-200 p-1 rounded-full">
            <MdTableChart className="text-xl" />
          </div>
          <span className="font-semibold text-lg">City Level Breakdown</span> for{" "}
          <span className="bg-navBarColour text-white px-1 rounded font-semibold">MRP</span> for
          <span className="bg-[#dae0ea] px-1 rounded font-semibold">{selectedRcaCategory}</span>
        </div>

        <div className="flex items-center gap-1 border rounded-lg py-1 px-2">
          <input type="text" name="" id="" className="border-none outline-none" />
          <FiSearch className="text-xl" />
        </div>
      </div>
      <hr />
      <div className="p-4">
        <div className="flex overflow-x-scroll gap-2">
          <div className="sticky left-0 flex gap-2 bg-white">
            <CategoryRcaHeading />
            <CategoryRcaStatsCard
              info={offtakesAtMrp}
              selectedSKU={selectedSKU}
              handleSkuChange={() => handleOverallCitiesClick(0)}
              code={0}
            />
          </div>

          {filteredCities.data.map((item, index) => (
            <CategoryRcaStatsCard
              key={index}
              info={{
                value: {
                  heading: item["category_rca_blinkit.brands_choice"],
                  totalGMV: formatPrice(item["category_rca_blinkit.total_gmv"]),
                  totalQty: item["category_rca_blinkit.total_qty_sold"],
                },
              }}
              selectedSKU={selectedSKU}
              handleSkuChange={() => handleSkuChange(index + 1)}
              code={index + 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryRcaCityWise;
