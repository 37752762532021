import dayjs from "dayjs";

// Format selectedDate without the timezone
const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  // Start of the day (00:00:00.000)
  const startOfDay = dayjs(date).startOf("day");
  // End of the day (23:59:59.999)
  const endOfDay = dayjs(date).endOf("day");

  // Format the start and end dates using the formatDateWithoutTimezone function
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);

  // Return the range as an object
  return {
    start: formattedStartOfDay,
    end: formattedEndOfDay,
  };
};

export const blinkitQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  // This will be undefined if not used
  return {
    dimensions: [
      "postgres_third_party_visibility.sku_name",
      "postgres_third_party_visibility.tenant_id",
      "postgres_third_party_visibility.account",
      "postgres_third_party_visibility.searchkeyword",
      "postgres_third_party_visibility.slot",
      "postgres_third_party_visibility.brand",
      "postgres_third_party_visibility.productname",
      "postgres_third_party_visibility.organic_rank",
      "postgres_third_party_visibility.sponsored_rank",
      "postgres_third_party_visibility.area_name_lm",
      "postgres_third_party_visibility.city_lm",
      "postgres_third_party_visibility.pincode_lm",
      "postgres_third_party_visibility.date",
    ],
    order: {
      "postgres_third_party_visibility.sku_name": "asc",
    },
    filters: [
      {
        member: "postgres_third_party_visibility.account",
        operator: "equals",
        values: ["Blinkit"],
      },
    ],
    timeDimensions: [
      {
        dimension: "postgres_third_party_visibility.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const instamartQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "postgres_third_party_visibility.sku_name",

      "postgres_third_party_visibility.tenant_id",
      "postgres_third_party_visibility.account",
      "postgres_third_party_visibility.searchkeyword",
      "postgres_third_party_visibility.slot",
      "postgres_third_party_visibility.brand",
      "postgres_third_party_visibility.productname",
      "postgres_third_party_visibility.organic_rank",
      "postgres_third_party_visibility.sponsored_rank",
      "postgres_third_party_visibility.area_name_lm",
      "postgres_third_party_visibility.city_lm",
      "postgres_third_party_visibility.pincode_lm",
      "postgres_third_party_visibility.date",
    ],
    order: {
      "postgres_third_party_visibility.sku_name": "asc",
    },
    filters: [
      {
        member: "postgres_third_party_visibility.account",
        operator: "equals",
        values: ["Instamart"],
      },
    ],
    timeDimensions: [
      {
        dimension: "postgres_third_party_visibility.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const zeptoQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "postgres_third_party_visibility.sku_name",
      "postgres_third_party_visibility.tenant_id",
      "postgres_third_party_visibility.account",
      "postgres_third_party_visibility.searchkeyword",
      "postgres_third_party_visibility.slot",
      "postgres_third_party_visibility.brand",
      "postgres_third_party_visibility.productname",
      "postgres_third_party_visibility.organic_rank",
      "postgres_third_party_visibility.sponsored_rank",
      "postgres_third_party_visibility.area_name_lm",
      "postgres_third_party_visibility.city_lm",
      "postgres_third_party_visibility.pincode_lm",
      "postgres_third_party_visibility.date",
    ],
    order: {
      "postgres_third_party_visibility.sku_name": "asc",
    },
    filters: [
      {
        member: "postgres_third_party_visibility.account",
        operator: "equals",
        values: ["Zepto"],
      },
    ],
    timeDimensions: [
      {
        dimension: "postgres_third_party_visibility.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};
