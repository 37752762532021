import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { groupedDataByCampaignName, groupedDataByKeywords } from "../../../utils/dataGrouping/productListingGrouping";
import CampaignsRevenueCard from "./CampaignsRevenueCard";
import { FaAd } from "react-icons/fa";
import { groupedDataByKeywordsSearchSuggestion } from "../../../utils/dataGrouping/searchSuggestionsGrouping";
import { groupedDataByKeywordsBannerListing } from "../../../utils/dataGrouping/bannerListingGrouping";
import {
  groupedDataByCampaignNameInstamart,
  groupedDataByKeywordsInstamart,
} from "../../../utils/dataGrouping/instamartMaketingReportGrouping";
import TopPerformingKeywords from "./TopPerformingKeywords";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import { Skeleton } from "@mui/material";
import { groupedDataByCampaignNameProductRcomm } from "../../../utils/dataGrouping/productRecommendationGrouping";
import { groupedDataByProductNameSKUPerformance } from "../../../utils/dataGrouping/skuPerformanceZeptoGrouping";
import { groupDatabyCampaignNameZepto } from "../../../utils/dataGrouping/campaignPerformanceZeptoGrouping";
import ShowSelectedDate from "../../common/ShowSelectedDate/ShowSelectedDate";

const AdsInsights = ({ platformName, selectedDate }) => {
  const {
    productListing,
    productRecommendation,
    skuAnalyticsZepto,
    skuPerformanceZepto,
    searchSuggestion,
    bannerListing,
    instamartReport,
  } = useSelector((state) => state.marketing);
  const { loading, data, error } = productListing;
  const [topRevenueCampaigns, setTopRevenueCampaigns] = useState([]);
  const [bottomRevenueCampaigns, setBottomRevenueCampaigns] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  console.log("instamartReport====", instamartReport.data);

  // Product Listing
  const groupedDataKeywordProductListing = productListing?.data ? groupedDataByKeywords(productListing.data) : {};
  const valuesArrayProductListing = Object.values(groupedDataKeywordProductListing || {});
  const productListingSortedArray = valuesArrayProductListing?.sort((a, b) => b.estimateBudget - a.estimateBudget);
  const productListingTop10Keyword = productListingSortedArray.slice(0, 10);

  // Marketing Report Instamart
  const groupedDataKeywordMarketingReport = instamartReport?.data
    ? groupedDataByKeywordsInstamart(instamartReport.data)
    : {};
  const valuesArrayMarketingReport = Object.values(groupedDataKeywordMarketingReport || {});
  const marketingReportSortedArray = valuesArrayMarketingReport?.sort((a, b) => b.estimateBudget - a.estimateBudget);
  const marketingReporytTop10Keyword = marketingReportSortedArray.slice(0, 10);

  // Search Suggestion Data
  const groupedDataByKeywordSearchSuggestion = searchSuggestion?.data
    ? groupedDataByKeywordsSearchSuggestion(searchSuggestion.data)
    : {};
  const valuesArray = Object.values(groupedDataByKeywordSearchSuggestion || {});
  const searchSuggestionSortedArray = valuesArray?.sort((a, b) => b.estimateBudget - a.estimateBudget);
  const searchSuggestionTop10keyword = searchSuggestionSortedArray.slice(0, 10);

  // Banner Listing Data
  const groupedData = bannerListing?.data ? groupedDataByKeywordsBannerListing(bannerListing.data) : {};
  const valuesArrayBannerListing = Object.values(groupedData || {});
  const bannerListingSortedArray = valuesArrayBannerListing?.sort((a, b) => b.estimateBudget - a.estimateBudget);
  const bannerListingTop10Keyword = bannerListingSortedArray.slice(0, 10);

  // Combine all top 10 keywords into one array
  const combinedTop10Keywords = [
    ...productListingTop10Keyword,
    ...marketingReporytTop10Keyword,
    ...searchSuggestionTop10keyword,
    ...bannerListingTop10Keyword,
  ];

  // Sort combinedTop10Keywords based on impressions and get the top 5
  const top5ByImpressions = combinedTop10Keywords.sort((a, b) => b.impressions - a.impressions).slice(0, 5);

  useEffect(() => {
    if (platformName && !productListing.loading && productListing.data) {
      let campaigns = [];

      if (platformName === "Blinkit") {
        const groupedDataProductListingBlinkit = productListing?.data
          ? groupedDataByCampaignName(productListing.data)
          : {};
        const groupedDataProductRecommandationBlinkit = productRecommendation?.data
          ? groupedDataByCampaignNameProductRcomm(productRecommendation.data)
          : {};
        campaigns = [
          ...Object.values(groupedDataProductListingBlinkit || {}),
          ...Object.values(groupedDataProductRecommandationBlinkit || {}),
        ];
      } else if (platformName === "Instamart") {
        if (instamartReport?.data) {
          const groupedDataMarekrtingReportInstamart = groupedDataByCampaignNameInstamart(instamartReport.data) || {};
          campaigns = Object.values(groupedDataMarekrtingReportInstamart);
        }
      } else if (platformName === "Zepto") {
        if (skuPerformanceZepto?.data && skuAnalyticsZepto?.data) {
          const groupedDataSkuPerformnaceZepto = groupedDataByProductNameSKUPerformance(skuPerformanceZepto.data) || {};
          const groupedDataCamapaignPerformanceZepto = groupDatabyCampaignNameZepto(skuAnalyticsZepto.data) || {};
          campaigns = [
            ...Object.values(groupedDataSkuPerformnaceZepto || {}),
            ...Object.values(groupedDataCamapaignPerformanceZepto || {}),
          ];
        }
      }

      if (campaigns.length > 0) {
        const topCampaigns = campaigns.sort((a, b) => b.directSales - a.directSales).slice(0, 5);
        const bottomCampaigns = campaigns.sort((a, b) => a.directSales - b.directSales).slice(0, 5);

        setTopRevenueCampaigns(topCampaigns);
        setBottomRevenueCampaigns(bottomCampaigns);
        setIsLoaded(true);
      }
    }
  }, [
    platformName,
    productListing?.data,
    productRecommendation?.data,
    instamartReport?.data,
    skuPerformanceZepto?.data,
    skuAnalyticsZepto?.data,
    productListing.loading,
  ]);

  console.log("topRevenueCampaigns====", topRevenueCampaigns);

  // Loading state
  if (
    productListing.loading ||
    productRecommendation.loading ||
    instamartReport.loading ||
    skuPerformanceZepto.loading ||
    skuAnalyticsZepto.loading ||
    productListing.loading
  ) {
    return (
      <div className="bg-white shadow-md rounded-xl p-4">
        <h1 className="flex gap-3 items-center text-lg font-medium pb-4 border-b">
          <div className="p-2 rounded-full bg-gray-200">
            <FaAd />
          </div>
          Ads Insights
        </h1>
        <div className="flex gap-4 px-4 h-[66vh] py-6">
          <Skeleton variant="rectangular" width="100%" height="100%" />
          <Skeleton variant="rectangular" width="100%" height="100%" />
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </div>
      </div>
    );
  }

  if (!data || data.length == 0) {
    return (
      <div className="bg-white rounded-lg my-2">
        <div className="flex gap-3 items-center justify-between text-xl p-4 border-b">
          <div className="flex gap-3 items-center text-lg font-medium">
            <FaAd />
            Ads Insights
          </div>
          <div>
            <ShowSelectedDate date={selectedDate} />
          </div>
        </div>
        <div className="h-96 flex justify-center items-center">
          <NoDataFound height={200} width={200} maximum={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-xl">
      <div>
        <div className="flex gap-3 items-center justify-between text-xl p-4 border-b">
          <div className="flex gap-3 items-center text-lg font-medium">
            <FaAd />
            Ads Insights
          </div>
          <div>
            <ShowSelectedDate date={selectedDate} />
          </div>
        </div>
        <div className="flex gap-4 px-4 py-6">
          <div className="flex-1">
            {/* Top Revenue Campaigns Card */}
            <CampaignsRevenueCard
              path={"/ads/performance-marketing-ads"}
              data={topRevenueCampaigns}
              type={"gainers"}
              heading={"Top Revenue Campaigns"}
            />
          </div>

          <div className="flex-1">
            {/* Bottom Revenue Campaigns Card */}
            <CampaignsRevenueCard
              path={"/ads/performance-marketing-ads"}
              data={bottomRevenueCampaigns}
              type={"drainers"}
              heading={"Top 5 Campaigns by Revenue"}
            />
          </div>

          <div className="flex-1">
            <TopPerformingKeywords data={top5ByImpressions} heading={"Top Performing Keywords"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsInsights;
