import {
  blinkitQuery,
  instamartQuery,
  zeptoQuery,
} from "../../utils/queries/salesReportQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchBlinkitData = async (selectedDateRange) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDateRange));
  return result.tablePivot();
};

export const fetchInstamartData = async (selectedDateRange) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDateRange));
  return result.tablePivot();
};

export const fetchZeptoData = async (selectedDateRange) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDateRange));
  return result.tablePivot();
};
