import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Papa from "papaparse";

const OverStockedTableZepto = ({ platformName }) => {
  // Pagination and Sorting state
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("inventoryDays");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { stockingInsightZepto } = useSelector((state) => state.stocking);

  const { loading, data } = stockingInsightZepto;
  // if (!stockingInsightZepto || stockingInsightZepto.length === 0) {
  //   return <div>No data available</div>;
  // }

  const dataWithTotalInventory = data.map((item) => {
    const totalInventory = parseInt(item["combined_inventory_data_zepto.total_quantity"], 10);
    return {
      ...item,
      totalInventory,
    };
  });

  const groupedData = {};
  dataWithTotalInventory.forEach((item) => {
    const key = `${item["combined_inventory_data_zepto.sku_name"]}_${item["combined_inventory_data_zepto.brand_internal_sku_code"]}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const newModifiedData = [];
  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];

    group.sort(
      (a, b) =>
        new Date(a["combined_inventory_data_zepto.normalized_date"]) -
        new Date(b["combined_inventory_data_zepto.normalized_date"])
    );

    const latestEntry = group[group.length - 1];

    let dailyRunRate = parseFloat(latestEntry["combined_sales_report_zepto.daily_run_rate"]) || 0;

    let inventoryDays = 0;
    if (dailyRunRate > 0) {
      inventoryDays = latestEntry.totalInventory / dailyRunRate;
    }

    newModifiedData.push({
      ...latestEntry,
      dailyRunRate,
      inventoryDays: isNaN(inventoryDays) ? 0 : inventoryDays,
    });
  });

  const filteredData = newModifiedData.filter((item) => item.inventoryDays > 10);

  // Sorting logic
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === "inventoryDays" || orderBy === "totalInventory") {
      return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else {
      return order === "asc" ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = sortedData.map((entry) => ({
      "Zepto SKU Name": entry["combined_inventory_data_zepto.sku_name"],
      City: entry["combined_inventory_data_zepto.brands_choice"],
      "Facility Name": entry["combined_inventory_data_zepto.store_name"],
      "Item Code": entry["combined_inventory_data_zepto.sku_id"],
      "Daily Run Rate": entry.dailyRunRate?.toFixed(2),
      "Total Inventory": entry.totalInventory,
      "Inventory Days": entry.inventoryDays?.toFixed(0),
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `currently_out_of_stock_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full">
      <div className="mb-4 h-[42px] min-w-32 flex justify-end">
        <span
          onClick={handleExportCSV}
          className=" p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
        >
          <span className="font-body font-normal text-sm">Export to CSV</span>
          <img width={16} height={16} src="/icons/download-circular-icon.svg" />
        </span>
      </div>

      <Paper className="w-full rounded-lg shadow" sx={{ width: "100%", mb: "2rem" }}>
        <>
          <div className="border-b p-4 text-lg font-medium flex gap-4 items-center">
            <img src="/logos/inventory-page-icon.svg" alt="stocking" />
            Overstocking
          </div>
          <div className="p-4">
            <div className="border rounded p-2">
              <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
                <Table stickyHeader aria-label="sticky table" sx={{ overflowX: "auto" }}>
                  <TableHead className="bg-white">
                    <TableRow>
                      {[
                        { id: "product_name", label: "Zepto SKU Name" },
                        { id: "brands_choice", label: "City" },
                        { id: "store_name", label: "Facility Name" },
                        { id: "item_code", label: "Item Code" },
                        { id: "dailyRunRate", label: "Daily Run Rate" },
                        { id: "totalInventory", label: "Total Inventory" },
                        { id: "inventoryDays", label: "Inventory Days" },
                      ].map((column) => (
                        <TableCell
                          key={column.id}
                          sortDirection={orderBy === column.id ? order : false}
                          sx={{
                            fontWeight: "500",
                            color: "#4d4d4d",
                            fontSize: "0.875rem",
                            textAlign: column.id === "product_name" ? "left" : "center",
                            boxShadow: `${column.id === "product_name" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                            zIndex: `${column.id === "product_name" && "3"}`,
                            minWidth: "200px",
                            position: `${column.id === "product_name" && "sticky"}`,
                            left: `${column.id === "product_name" && 0}`,
                            bgcolor: "#F9F9F9",
                          }}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={(event) => handleRequestSort(event, column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((item, index) => (
                        <TableRow key={index} className={` ${index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-[#fff]"}`}>
                          <TableCell
                            sx={{
                              color: "#4d4d4d",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              minWidth: "200px",
                              position: "sticky",
                              left: 0,
                              textAlign: "left",
                              bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                          >
                            {item["combined_inventory_data_zepto.sku_name"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item["combined_inventory_data_zepto.brands_choice"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item["combined_inventory_data_zepto.store_name"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item["combined_inventory_data_zepto.sku_id"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{item.dailyRunRate}</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{item.totalInventory}</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{item.inventoryDays}</TableCell>
                          <TableCell
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3",
                              position: "sticky",
                              right: 0,
                              bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                          ></TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center text-[0.75rem] text-[#727278]">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </div>
  );
};

export default OverStockedTableZepto;
