// import React, { useState } from "react";
// import SkuCard from "./SkuCard";
// import { FiSearch } from "react-icons/fi";
// import { MdTableChart } from "react-icons/md";
// import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
// import { PiInfoLight } from "react-icons/pi";
// import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

// const headers = ["SKU", "Price", "Discount Price", "Discount Price %", "Ppu", "Action"];

// const groupDataBySku = (chartData) => {
//   // Group data by SKU
//   return chartData.reduce((acc, item) => {
//     const sku = item.sku;
//     const discount = parseFloat(item.price) - parseFloat(item.discounted_price);
//     const discountPercent = (parseFloat(discount) / parseFloat(item.price)) * 100;
//     const pricePer1 = parseFloat(item.price) / parseFloat(item.unitWeight); // Calculate price for 1ml/1gm

//     if (!acc[sku]) {
//       acc[sku] = {
//         sku: sku,
//         product: item.product,
//         price: 0,
//         discountPrice: 0,
//         discountPercent: 0,
//         ppu: 0,
//         count: 0,
//       };
//     }

//     acc[sku].price += parseFloat(item.price);
//     acc[sku].discountPrice += parseFloat(item.discounted_price);
//     acc[sku].discountPercent += discountPercent;
//     acc[sku].ppu += pricePer1;
//     acc[sku].count += 1;

//     return acc;
//   }, {});
// };

// const SkuWiseData = ({ chartData, setSelectedSKU, selectedSKU }) => {
//   const [selectedCard, setSelectedCard] = useState(0);
//   const sortedData = chartData.sort((a, b) => new Date(b.date) - new Date(a.date));
//   const latestDate = sortedData[0].date;

//   const filteredData = chartData.filter(
//     (item) => new Date(item.date).toDateString() === new Date(latestDate).toDateString()
//   );

//   const groupedData = groupDataBySku(filteredData);

//   const itemsLength = Object.values(groupedData).length;
//   const totalPriceObj = Object.values(groupedData).reduce((acc, item) => {
//     return acc + (item.price > 0 ? item.price / item.count : 0);
//   }, 0);

//   const totalDiscountObj = Object.values(groupedData).reduce((acc, item) => {
//     return acc + (item.discountPrice > 0 ? item.discountPrice / item.count : 0);
//   }, 0);

//   const totalDiscountPercentObj = Object.values(groupedData).reduce((acc, item) => {
//     return acc + (item.discountPercent > 0 ? item.discountPercent / item.count : 0);
//   }, 0);
//   const totalppuObj = Object.values(groupedData).reduce((acc, item) => {
//     return acc + (item.ppu > 0 ? item.ppu / item.count : 0);
//   }, 0);

//   const allObj = {
//     price: totalPriceObj,
//     discountPrice: totalDiscountObj,
//     discountPercent: totalDiscountPercentObj,
//     ppu: totalppuObj,
//     count: itemsLength,
//   };

//   const handleCardChange = (i) => {
//     setSelectedCard(i);
//   };

//   return (
//     <div className="w-8/12">
//       <div className="px-4 pb-4">
//         <div className="border rounded-xl p-2">
//           <Table sx={{ borderCollapse: "separate", borderSpacing: "0 8px" }}>
//             <TableHead>
//               <TableRow>
//                 {headers.map((item, index) => (
//                   <TableCell key={index} sx={{ bgcolor: "#F9F9F9" }}>
//                     {item}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               <SkuCard
//                 selectedCard={selectedCard}
//                 handleCardChange={() => handleCardChange(0)}
//                 code={0}
//                 titleName={"All SKU's"}
//                 setSelectedSKU={setSelectedSKU}
//                 updateSKU={true}
//                 data={allObj}
//               />
//               {Object.keys(groupedData).length > 0 &&
//                 Object.values(groupedData).map((item, index) => (
//                   <SkuCard
//                     key={item.sku}
//                     selectedCard={selectedCard}
//                     handleCardChange={() => handleCardChange(index + 1)}
//                     code={index + 1}
//                     titleName={item.sku}
//                     setSelectedSKU={setSelectedSKU}
//                     updateSKU={true}
//                     data={item}
//                   />
//                 ))}
//             </TableBody>
//           </Table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SkuWiseData;

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";
import CompetitorPricingDrawer from "./CompetitorPricingDrawer";

const getGroupedData = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const sku = item.sku;
    const product = item.product;
    const city = item.city;
    const discount = parseFloat(item.price) - parseFloat(item.discounted_price);
    const discountPercent = (parseFloat(discount) / parseFloat(item.price)) * 100;
    const pricePer1 = parseFloat(item.price) / parseFloat(item.unitWeight); // Calculate price for 1ml/1gm

    if (!groupedData[product]) {
      groupedData[product] = {};
    }

    if (!groupedData[product][city]) {
      groupedData[product][city] = {
        sku: sku,
        city: city,
        product: product,
        price: 0,
        discountPrice: 0,
        discountPercent: 0,
        ppu: 0,
        count: 0,
      };
    }

    groupedData[product][city].price += parseFloat(item.price);
    groupedData[product][city].discountPrice += parseFloat(item.discounted_price);
    groupedData[product][city].discountPercent += discountPercent;
    groupedData[product][city].ppu += pricePer1;
    groupedData[product][city].count += 1;
  });

  return groupedData;
};

// Utility function to calculate averages for the main row
const calculateMainRow = (productData) => {
  const cities = Object.keys(productData).length;
  const totalPrice = Object.values(productData).reduce((acc, city) => acc + city.price / city.count, 0);
  const totalDiscountPrice = Object.values(productData).reduce((acc, city) => acc + city.discountPrice / city.count, 0);
  const totalDiscountPercent = Object.values(productData).reduce(
    (acc, city) => acc + city.discountPercent / city.count,
    0
  );
  const totalPPU = Object.values(productData).reduce((acc, city) => acc + city.ppu / city.count, 0);

  return {
    avgPrice: totalPrice / cities,
    avgDiscountPrice: totalDiscountPrice / cities,
    avgDiscountPercent: totalDiscountPercent / cities,
    avgPPU: totalPPU / cities,
  };
};

const calculateProductsTotal = (data) => {
  let allPrice = 0,
    allDiscountPrice = 0,
    allDiscountPercent = 0,
    allPPU = 0,
    allCount = 0;

  Object.keys(data).forEach((product) => {
    const mainRow = calculateMainRow(data[product]);
    allPrice += mainRow.avgPrice;
    allDiscountPrice += mainRow.avgDiscountPrice;
    allDiscountPercent += mainRow.avgDiscountPercent;
    allPPU += mainRow.avgPPU;
    allCount += 1;
  });

  return {
    allPrice: allPrice / allCount,
    allDiscountPrice: allDiscountPrice / allCount,
    allDiscountPercent: allDiscountPercent / allCount,
    allPPU: allPPU / allCount,
  };
};

const SkuWiseData = ({ chartData }) => {
  const [open, setOpen] = useState({});
  const [state, setState] = useState(false);
  const [sku, setSku] = useState("");

  const handleClick = (product) => {
    setOpen((prev) => ({ ...prev, [product]: !prev[product] }));
  };
  const data = getGroupedData(chartData);

  const toggleDrawer = (open, product) => (event) => {
    setSku(product);
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(open);
  };

  const skuAvgData = calculateProductsTotal(data);

  return (
    <div className="p-4 w-full">
      <div className="p-2 border rounded-xl">
        <TableContainer sx={{ borderRadius: "0.25rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: "left",
                    bgcolor: "#F9F9F9",
                  }}
                >
                  Product
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: "center",
                    bgcolor: "#F9F9F9",
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: "center",
                    bgcolor: "#F9F9F9",
                  }}
                >
                  Discount
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: "center",
                    bgcolor: "#F9F9F9",
                  }}
                >
                  Discount Percent
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: "center",
                    bgcolor: "#F9F9F9",
                  }}
                >
                  PPU
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: "center",
                    bgcolor: "#F9F9F9",
                  }}
                >
                  Insights
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#F0F6FF" }}>
                <TableCell
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick("All SKU's")}
                >
                  All SKU's
                </TableCell>
                <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>{skuAvgData.allPrice.toFixed(2)}</TableCell>
                <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>
                  {skuAvgData.allDiscountPrice.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>
                  {skuAvgData.allDiscountPercent.toFixed(2)}%
                </TableCell>
                <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>{skuAvgData.allPPU.toFixed(3)}</TableCell>
                <TableCell
                  sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => toggleDrawer(true, "All SKU's")({ type: "click" })}
                >
                  <div className="border border-[#387FF5] text-[#387FF5] rounded-[0.25rem] text-center text-xs p-2 text-medium w-fit cursor-pointer">
                    View Insights
                  </div>
                </TableCell>
              </TableRow>
              {Object.keys(data).map((product, index) => {
                const mainRow = calculateMainRow(data[product]);
                return (
                  <React.Fragment key={product}>
                    <TableRow sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(product)}
                      >
                        {open[product] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        {product}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>
                        {mainRow.avgPrice.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>
                        {mainRow.avgDiscountPrice.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>
                        {mainRow.avgDiscountPercent.toFixed(2)}%
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", cursor: "pointer" }}>{mainRow.avgPPU.toFixed(3)}</TableCell>
                      <TableCell
                        sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}
                        onClick={() => toggleDrawer(true, product)({ type: "click" })}
                      >
                        <div className="border border-[#387FF5] text-[#387FF5] rounded-[0.25rem] text-center text-xs p-2 text-medium w-fit cursor-pointer">
                          View Insights
                        </div>
                      </TableCell>
                    </TableRow>

                    {open[product] &&
                      Object.keys(data[product]).map((city, cityIndex) => {
                        const cityData = data[product][city];
                        return (
                          <TableRow key={city} sx={{ bgcolor: cityIndex % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                            <TableCell
                              sx={{
                                fontWeight: "500",
                                color: "#4D4D4D",
                                paddingLeft: "5rem",
                              }}
                            >
                              {city}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(cityData.price / cityData.count).toFixed(2)}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(cityData.discountPrice / cityData.count).toFixed(2)}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(cityData.discountPercent / cityData.count).toFixed(2)}%
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(cityData.ppu / cityData.count).toFixed(3)}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <CompetitorPricingDrawer toggleDrawer={toggleDrawer} state={state} sku={sku} />
    </div>
  );
};

export default SkuWiseData;
