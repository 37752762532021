import React, { useState, useEffect } from "react";
import { LuTrendingDown } from "react-icons/lu";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import Dropdown from "../../common/Dropdown/Dropdown";
import Papa from "papaparse";
import FilterDropdown from "../../common/Dropdown/FilterDropdown";

const tooltipTitle =
  "This indicates the estimated sales revenue that could have been lost due to various factors such as stockouts, missed opportunities, or delays. Analyzing this metric can help identify areas for improvement in sales strategies and inventory management.";

const calculateAverageAppointmentTurnAroundTime = (data) => {
  let diff = 0;
  let count = 0;
  for (let i = 0; i < data?.length; i++) {
    if (data[i]["fulfillment_report_blinkit.po_state"] === "Fulfilled") {
      const date1 = new Date(data[i]["fulfillment_report_blinkit.appointment_date"]);
      const date2 = new Date(data[i]["fulfillment_report_blinkit.order_date"]);

      const differenceInTime = date1.getTime() - date2.getTime(); // Difference in milliseconds
      const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days
      diff += differenceInDays;
      count++;
    }
  }
  return diff / count;
};

const PotentialSalesLossBlinkitTable = ({ platformName }) => {
  const { pslBlinkitFulfillment } = useSelector((state) => state.pslFulfillment);
  const { potentialSalesLossBlinkit } = useSelector((state) => state.potentailSalesLoss);
  const [selectedCity, setSelectedCity] = useState("All");
  const [pslData, setPslData] = useState([]);
  const { selectedDate } = useDate();

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const averageTime = calculateAverageAppointmentTurnAroundTime(pslBlinkitFulfillment.data);
  const [filteredPslData, setFilteredPslData] = useState([]); // State to hold the filtered data
  const [filters, setFilters] = useState({}); // Store applied filters
  console.log("🚀 ~ PotentialSalesLossBlinkitTable ~ filters:", filters);

  // Extract unique cities from pslData
  const cities = [...new Set(pslData.map((item) => item.city))];
  const skuNames = [...new Set(pslData.map((item) => item.internal_sku_code))];
  console.log("🚀 ~ PotentialSalesLossBlinkitTable ~ cities:", skuNames);

  const calculatePSL = (potentialSalesLoss) => {
    const pslResults = potentialSalesLoss
      .map((lossItem) => {
        const totalInventory =
          parseFloat(lossItem["combined_inventory_data_blinkit.frontend_inv_qty"]) +
          parseFloat(lossItem["combined_inventory_data_blinkit.backend_inv_qty"]);

        const dailyRunRate = parseFloat(lossItem["combined_sales_report_blinkit.daily_run_rate"]);

        const inventoryDays = totalInventory / dailyRunRate;
        const itemId = lossItem["combined_inventory_data_blinkit.item_id"];
        const itemFacilityName = lossItem["combined_inventory_data_blinkit.backend_facility_name"];

        const fulfillmentData = pslBlinkitFulfillment?.data?.filter(
          (fulfillmentItem) =>
            fulfillmentItem["fulfillment_report_blinkit.item_id"] === itemId &&
            fulfillmentItem["fulfillment_report_blinkit.facility_name"] === itemFacilityName
        );

        if (fulfillmentData.length === 0) {
          return null; // Skip processing this item if no fulfillment data
        }

        const lastPoRaisedDate = fulfillmentData
          .map((item) => item["fulfillment_report_blinkit.order_date"])
          .reduce((latest, current) => (dayjs(current).isAfter(latest) ? current : latest));

        const currentDate = dayjs(selectedDate);
        const lastPoDate = dayjs(lastPoRaisedDate);
        const poRaisedDays = currentDate.diff(lastPoDate, "day");
        const outOfStockDays = averageTime - (poRaisedDays + inventoryDays);

        // If outOfStockDays is negative, skip this item
        if (outOfStockDays <= 0) {
          return null;
        }

        // Use the MRP from the sales report
        const mrp = parseFloat(lossItem["combined_sales_report_blinkit.per_unit_price"]);

        const psl = outOfStockDays * dailyRunRate * mrp;

        return {
          internal_sku_code: lossItem["combined_inventory_data_blinkit.brand_internal_sku_code"],
          city: lossItem["combined_inventory_data_blinkit.brands_choice"],
          totalInventory,
          daily_run_rate: dailyRunRate,
          lastPoRaisedDate: lastPoDate,
          AVAT: averageTime.toFixed(2),
          outOfStockDays: outOfStockDays,
          PSL: psl,
        };
      })
      .filter((item) => item !== null); // Filter out null items

    setPslData(pslResults);
  };

  useEffect(() => {
    if (pslBlinkitFulfillment?.data?.length && potentialSalesLossBlinkit?.data?.length) {
      calculatePSL(potentialSalesLossBlinkit?.data);
    } else {
      console.log("pslBlinkitFulfillment or potentialSalesLossBlinkit is empty");
    }
  }, [pslBlinkitFulfillment?.data, potentialSalesLossBlinkit?.data]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportCSV = () => {
    const dataToExport = pslData.map((entry) => ({
      "SKU name": entry.internal_sku_code,
      City: entry.city,
      "Total Inventory": entry.totalInventory,
      "Daily Run Rate": entry.daily_run_rate,
      "Last PO Raised Date": dayjs(entry.lastPoRaisedDate).format("DD-MM-YYYY"),
      "Average Turn Around Time": entry.AVAT,
      "Out Of Stock Days": entry.outOfStockDays,
      "Potential Sales Loss": entry.PSL,
    }));

    const csvData = Papa.unparse(dataToExport); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `potential_sales_loss_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const computeMinMax = (pslData) => {
    if (!pslData.length) return { minPsl: 0, maxPsl: 0, minOutOfStockDays: 0, maxOutOfStockDays: 0 };

    let minPsl = Infinity;
    let maxPsl = -Infinity;
    let minOutOfStockDays = Infinity;
    let maxOutOfStockDays = -Infinity;

    pslData.forEach((item) => {
      if (item.PSL < minPsl) minPsl = item.PSL;
      if (item.PSL > maxPsl) maxPsl = item.PSL;

      if (item.outOfStockDays < minOutOfStockDays) minOutOfStockDays = item.outOfStockDays;
      if (item.outOfStockDays > maxOutOfStockDays) maxOutOfStockDays = item.outOfStockDays;
    });

    return { minPsl, maxPsl, minOutOfStockDays, maxOutOfStockDays };
  };
  const minMaxValues = computeMinMax(pslData);

  const filterOptions = [
    { field: "internal_sku_code", heading: "Select SKU", type: "select", options: skuNames },
    { field: "city", heading: "Select City", type: "select", options: cities },

    {
      field: "outOfStockDays",
      heading: "Select Out Of Stock Days range",
      type: "range",
      min: minMaxValues.minOutOfStockDays,
      max: minMaxValues.maxOutOfStockDays,
    },
    { field: "PSL", heading: "select PSL range", type: "range", min: minMaxValues.minPsl, max: minMaxValues.maxPsl },
  ];

  const applyFilters = (data, filters, selectedCity) => {
    if (!filters && selectedCity === "All") return data; // No filters applied

    return data.filter((item) => {
      // Check if city matches or if 'All' is selected
      const matchesCity = !filters.city || item.city === filters.city;

      // Check SKU filter
      const matchesSku = !filters?.internal_sku_code || item.internal_sku_code === filters.internal_sku_code;

      // Check Out of Stock Days range filter
      const matchesOutOfStockDays =
        !filters?.outOfStockDays ||
        (item.outOfStockDays >= filters.outOfStockDays[0] && item.outOfStockDays <= filters.outOfStockDays[1]);

      // Check PSL range filter
      const matchesPSL = !filters?.PSL || (item.PSL >= filters.PSL[0] && item.PSL <= filters.PSL[1]);

      return matchesCity && matchesSku && matchesOutOfStockDays && matchesPSL;
    });
  };

  // Handle filtered data when either filters or selectedCity changes
  useEffect(() => {
    const filtered = applyFilters(pslData, filters, selectedCity);
    setFilteredPslData(filtered);
  }, [pslData, filters, selectedCity]);

  console.log("filteredPslData====", filteredPslData);

  return (
    <div>
      <div className="flex sticky-container min-w-[100%] justify-end items-center gap-4 my-8">
        <div className="flex gap-4">
          <div className="w-auto min-w-32 h-[42px]  flex justify-end">
            <span
              onClick={handleExportCSV}
              className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
            >
              <span className="font-body font-normal text-sm">Export to CSV</span>
              <img width={16} height={16} src="/icons/download-circular-icon.svg" />
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-xl ">
        <div>
          <div>
            <h1 className="flex   items-center justify-between text-lg font-medium px-4 py-2 border-b leading-[23.44px]">
              <div className="flex gap-3 items-center text-lg font-medium  leading-[23.44px]">
                {/* Icon for Category Wise Sales */}
                <LuTrendingDown />
                Potential Sales Loss
                <CustomTooltip
                  title={
                    "Displays total sales for each category, helping you compare sales performance across different categories."
                  }
                >
                  <PiInfoLight />
                </CustomTooltip>
              </div>
              <FilterDropdown filters={filterOptions} onApply={setFilters} />
            </h1>
          </div>

          <div className="p-4">
            <div className="p-2 border rounded-xl">
              <TableContainer sx={{ borderRadius: "0.25rem" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          minWidth: "200px",
                          position: "sticky",
                          left: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        SKU Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        City
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      >
                        Total Inventory
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      >
                        Daily Run Rate
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      >
                        Last PO Raised Date
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      >
                        Average Turn Around Time
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      >
                        Out Of Stock Days
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      >
                        PSL
                      </TableCell>
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: "white",
                          bgcolor: "#F9F9F9",
                          textAlign: "center",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPslData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                        <TableCell
                          sx={{
                            color: "#4d4d4d",
                            fontWeight: "500",
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            minWidth: "200px",
                            position: "sticky",
                            left: 0,
                            textAlign: "left",
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          }}
                        >
                          {row.internal_sku_code}
                        </TableCell>
                        <TableCell align="center">{row.city}</TableCell>
                        <TableCell align="center" sx={{ color: "#667085" }}>
                          {row.totalInventory}
                        </TableCell>
                        <TableCell align="center">{row.daily_run_rate.toFixed(2)}</TableCell>
                        <TableCell align="center">{dayjs(row.lastPoRaisedDate).format("DD-MM-YYYY")}</TableCell>
                        <TableCell align="center">{row.AVAT}</TableCell>
                        <TableCell align="center">{row.outOfStockDays.toFixed(0)}</TableCell>
                        <TableCell align="center">{row.PSL.toFixed(2)}</TableCell>
                        <TableCell
                          sx={{
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3",
                            position: "sticky",
                            right: 0,
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          }}
                        ></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {/* Pagination Component */}
          <TablePagination
            component="div"
            count={pslData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
      </div>
    </div>
  );
};

export default PotentialSalesLossBlinkitTable;
