import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const primary = {
   main : '#387FF5'
  };

  export const secondary = {
    main : '#D2D5E2',
  };


  export function palette() {
    return {
        primary,
        secondary
    }};