import React from "react";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const TopPerformingKeywords = ({ data, heading }) => {
  const navigate = useNavigate();

  const handleViewMore = () => {
    navigate("/ads/display-ads"); // This will navigate to the /ads page
  };
  return (
    <div onClick={handleViewMore} className="hover:cursor-pointer bg-white h-full">
      <div className="flex justify-between items-center">
        <h2 className="text-md leading-5 text-[#030229] font-body font-medium text-start mb-2">{heading}</h2>
      </div>
      <div className="px-3 py-4 border  rounded-lg">
        <ul className="space-y-2  ">
          {data.length > 0 ? (
            data?.map((keywordData, index) => (
              <li
                key={index}
                className={`flex flex-col justify-between h-[100px] w-[320px] px-3 py-4 border-[0.4px] rounded-lg shadow-sm`}
              >
                <div className="flex flex-col items-start">
                  <strong className="text-md font-medium font-body text-[#727278] leading-6">
                    {keywordData.title}
                  </strong>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col ">
                    <div className="flex gap-3">
                      <span className="text-xs text-[#727278] text-medium font-body">CPM</span>
                      <span className="text-[#8E8E8E] text-xs text-medium font-body">
                        {keywordData.cpm} {/* Display total quantity sold */}
                      </span>
                    </div>

                    <div className="flex gap-3">
                      <span className="text-xs text-[#727278] text-medium font-body">Qty Sold:</span>
                      <span className="text-[#387FF5] text-xs text-medium font-body">
                        {keywordData.totalQuantitiesSold} {/* Display total quantity sold */}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex gap-3">
                      <span className="text-xs text-[#727278] text-medium font-body">Est. Budget</span>
                      <span className="text-[#8E8E8E] text-xs text-medium font-body">
                        {keywordData.estimateBudget} {/* Display total quantity sold */}
                      </span>
                    </div>

                    <div className="flex gap-3">
                      <span className="text-xs text-[#727278] text-medium font-body">Impressions</span>
                      <span className="text-[#8E8E8E] text-xs text-medium font-body">{keywordData.impressions}</span>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="h-full flex justify-center items-center">No Top Performing Keywords</div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopPerformingKeywords;
