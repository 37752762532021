import { Skeleton } from "@mui/material";
import React from "react";

const SelectBoxSkeleton = () => {
  return (
    <div className="max-w-full flex justify-end gap-4 py-4 items-center">
      <Skeleton variant="rounded" sx={{ width: "20%", height: "20px" }} />
      <Skeleton variant="rounded" sx={{ width: "20%", height: "50px" }} />
    </div>
  );
};

export default SelectBoxSkeleton;
