export const LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_REQUEST =
  "LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_REQUEST";
export const LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_SUCCESS =
  "LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_SUCCESS";
export const LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_FAILURE =
  "LEAST_PERFORMING_BLINKIT_PRODUCT_LISTING_FAILURE";

export const LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST =
  "LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST";
export const LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS =
  "LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS";
export const LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE =
  "LEAST_PERFORMING_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE";

export const LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_REQUEST =
  "LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_REQUEST";
export const LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_SUCCESS =
  "LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_SUCCESS";
export const LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_FAILURE =
  "LEAST_PERFORMING_SKU_PERFORMANCE_ZEPTO_FAILURE";

export const LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_REQUEST =
  "LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_REQUEST";
export const LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_SUCCESS =
  "LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_SUCCESS";
export const LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_FAILURE =
  "LEAST_PERFORMING_CAMPAIGN_PERFORMANCE_SKU_FAILURE";

export const LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_REQUEST =
  "LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_REQUEST";
export const LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_SUCCESS =
  "LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_SUCCESS";
export const LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_FAILURE =
  "LEAST_PERFORMING_MARKETING_REPORT_INSTAMART_FAILURE";
