import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

// ProductListing
const getTopLowROICampaigns = (groupedData) => {
  return Object.entries(groupedData)
    .flatMap(([campaignName, targets]) =>
      Object.entries(targets).map(([targetingValue, { totalSpend, totalRevenue, ROI }]) => ({
        campaignName,
        targetingValue,
        totalSpend,
        totalRevenue,
        ROI,
      }))
    )
    .filter(({ ROI }) => ROI < 4)
    .sort((a, b) => {
      if (b.totalSpend !== a.totalSpend) {
        return b.totalSpend - a.totalSpend; // Sort by total spend descending
      }
      return a.ROI - b.ROI; // Then sort by ROI ascending
    })
    .slice(0, 10);
};

// ProductRecommendation
const getTopLowROICampaignsRecommendation = (groupedData) => {
  return Object.entries(groupedData)
    .map(([campaignName, { totalSpend, totalRevenue, ROI }]) => ({
      campaignName,
      totalSpend,
      totalRevenue,
      ROI,
    }))
    .filter(({ ROI }) => ROI < 4)
    .sort((a, b) => b.totalSpend - a.totalSpend)
    .slice(0, 10);
};

const TopTenWorstPerformKeyword = ({ groupedData, option }) => {
  let topLowROICampaigns = [];
  let tableHeaders = [];
  let tableRows = [];

  switch (option) {
    case "ProductListing":
      topLowROICampaigns = getTopLowROICampaigns(groupedData);
      tableHeaders = ["Campaign Name", "Keyword", "Total Spend", "Total Revenue", "ROI"];
      tableRows = topLowROICampaigns.map((campaign) => [
        campaign.campaignName,
        campaign.targetingValue,
        campaign.totalSpend.toFixed(2),
        campaign.totalRevenue.toFixed(2),
        campaign.ROI.toFixed(2),
      ]);
      break;
    case "ProductRecommandation":
    case "CampaignPerformance":
    case "SkuPerformance":
      topLowROICampaigns = getTopLowROICampaignsRecommendation(groupedData);
      tableHeaders = ["Campaign Name", "Total Spend", "Total Revenue", "ROI"];
      tableRows = topLowROICampaigns.map((campaign) => [
        campaign.campaignName,
        campaign.totalSpend.toFixed(2),
        campaign.totalRevenue.toFixed(2),
        campaign.ROI.toFixed(2),
      ]);
      break;
    case "MarketingReport":
      topLowROICampaigns = getTopLowROICampaigns(groupedData);
      tableHeaders = ["Campaign Name", "Keyword", "Total Spend", "Total Revenue", "ROI"];
      tableRows = topLowROICampaigns.map((campaign) => [
        campaign.campaignName,
        campaign.targetingValue,
        campaign.totalSpend.toFixed(2),
        campaign.totalRevenue.toFixed(2),
        campaign.ROI.toFixed(2),
      ]);
      break;
    default:
      console.warn(`Unknown option: ${option}`);
  }

  return (
    <div className="p-4">
      <TableContainer sx={{ borderRadius: "0.25rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: index == 0 ? "left" : "center",
                    boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: index === 0 && "3",
                    position: index === 0 && "sticky",
                    bgcolor: "#F9F9F9",
                    left: index === 0 ? 0 : "auto",
                    maxWidth: index === 0 ? "200px" : "auto",
                  }}
                  style={{
                    minWidth: index === 0 ? 150 : "auto",
                  }}
                >
                  {header}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "#F9F9F9",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      color: cellIndex === 0 && "#4D4D4D",
                      fontWeight: cellIndex === 0 && "500",
                      fontSize: "1rem",
                      cursor: "pointer",
                      boxShadow: cellIndex === 0 ? "5px 0px 5px -5px rgba(16, 24, 40, 0.06)" : "none",
                      position: cellIndex === 0 ? "sticky" : "static",
                      bgcolor: rowIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                      left: cellIndex === 0 ? 0 : "auto",
                      maxWidth: cellIndex === 0 ? "200px" : "auto",
                      textAlign: cellIndex === 0 ? "left" : "center",
                    }}
                    style={{ minWidth: cellIndex === 0 ? 150 : "auto" }}
                  >
                    {cell}
                  </TableCell>
                ))}
                <TableCell
                  align="right"
                  sx={{
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    bgcolor: rowIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                    right: 0,
                  }}
                ></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TopTenWorstPerformKeyword;
