import React, { createContext, useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin

// Extend dayjs with the UTC plugin
dayjs.extend(utc);

// Create a Context for the date
const DateContext = createContext();

export const CustomSingleDateProvider = ({ children }) => {
  // Initialize the date as a UTC dayjs object at the start of the day (00:00:00.000)
  const initialDate = dayjs.utc().startOf("day");

  const [selectedCustomDate, setSelectedCustomDate] = useState(initialDate);

  useEffect(() => {
    // On component mount, reset selectedDate to current date (start of the day)
    setSelectedCustomDate(dayjs.utc().startOf("day"));
  }, []);

  return <DateContext.Provider value={{ selectedCustomDate, setSelectedCustomDate }}>{children}</DateContext.Provider>;
};

// Custom hook to use the date context
export const useCustomDate = () => useContext(DateContext);
