import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineSchedule } from "react-icons/ai";
import TopUpcomingAppointments from "./TopUpcomingAppointments";
import AvgAppointmnetTurnAroundTimeCard from "./AvgAppointmnetTurnAroundTimeCard";
import { Skeleton } from "@mui/material";
import ShowSelectedDate from "../../common/ShowSelectedDate/ShowSelectedDate";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const getUpcomingAppointmentsBlinkit = (data) => {
  let filteredData = {};
  data?.forEach((item) => {
    const poNumber = item["fulfillment_report_blinkit.po_number"];
    const facilityName = item["fulfillment_report_blinkit.facility_name"];
    const total_amount = parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0;
    const groupKey = `${poNumber}_${facilityName}`;
    if (item["fulfillment_report_blinkit.appointment_date"] === "" && !filteredData[groupKey]) {
      filteredData[groupKey] = {
        po_number: poNumber,
        facility_name: facilityName,
        item_name: item["fulfillment_report_blinkit.name"],
        total_amount: total_amount,
        po_state: item["fulfillment_report_blinkit.po_state"],
        order_date: item["fulfillment_report_blinkit.order_date"],
        appointment_date: item["fulfillment_report_blinkit.appointment_date"],
        expiry_date: item["fulfillment_report_blinkit.expiry_date"],
      };
    }

    // Ensure filteredData[groupKey] exists before updating its total_amount
    if (filteredData[groupKey]) {
      filteredData[groupKey].total_amount += parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0; // Add up the total_amount safely
    }
  });
  return Object.values(filteredData);
};

const getBookedAppointmentsBlinkit = (data) => {
  let filteredData = {};
  data?.forEach((item) => {
    const poNumber = item["fulfillment_report_blinkit.po_number"];
    const facilityName = item["fulfillment_report_blinkit.facility_name"];
    const total_amount = parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0;
    const groupKey = `${poNumber}_${facilityName}`;
    if (
      item["fulfillment_report_blinkit.po_state"] === "Created" &&
      item["fulfillment_report_blinkit.appointment_date"] === "" &&
      !filteredData[groupKey]
    ) {
      filteredData[groupKey] = {
        po_number: poNumber,
        facility_name: facilityName,
        item_name: item["fulfillment_report_blinkit.name"],
        total_amount: total_amount,
        po_state: item["fulfillment_report_blinkit.po_state"],
        order_date: item["fulfillment_report_blinkit.order_date"],
        appointment_date: item["fulfillment_report_blinkit.appointment_date"],
        expiry_date: item["fulfillment_report_blinkit.expiry_date"],
      };
    }

    // Ensure filteredData[groupKey] exists before updating its total_amount
    if (filteredData[groupKey]) {
      filteredData[groupKey].total_amount += parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0; // Add up the total_amount safely
    }
  });
  return Object.values(filteredData);
};

const calculateAverageAppointmentTurnAroundTimeBlinkit = (data) => {
  let diff = 0;
  let count = 0;
  for (let i = 0; i < data?.length; i++) {
    if (data[i]["fulfillment_report_blinkit.po_state"] === "Fulfilled") {
      const date1 = new Date(data[i]["fulfillment_report_blinkit.appointment_date"]);
      const date2 = new Date(data[i]["fulfillment_report_blinkit.order_date"]);

      const differenceInTime = date1.getTime() - date2.getTime(); // Difference in milliseconds
      const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days
      diff += differenceInDays;
      count++;
    }
  }
  return count > 0 ? diff / count : 0; // Prevent division by zero
};

// Function to group and calculate the average time difference between two dates
const groupedDataAverageTimeZepto = (data) => {
  // Function to calculate the difference in days between two dates
  function calculateDateDifference(lastInwardDateStr, poDateStr) {
    const lastInwardDate = new Date(lastInwardDateStr); // Convert last inward date string to Date object
    const poDate = new Date(poDateStr); // Convert PO date string to Date object
    const differenceInMillis = lastInwardDate - poDate; // Calculate the difference in milliseconds
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    return Math.round(differenceInMillis / millisecondsPerDay); // Return the difference in days
  }

  // Object to store the results for each PO code
  const result = {};

  // Loop through each entry in the data to calculate the date differences
  data?.forEach((entry) => {
    // Reverse the date strings from "dd-mm-yyyy" to "yyyy-mm-dd" for Date parsing
    const lastInwardDate = entry["fulfillment_report_zepto.grn_date"].split("-").reverse().join("-");
    const poDate = entry["fulfillment_report_zepto.po_date"].split("-").reverse().join("-");
    const pocode = entry["fulfillment_report_zepto.po_code"].split("-").reverse().join("-");

    // If both dates are available, calculate the difference
    if (lastInwardDate && poDate) {
      const difference = calculateDateDifference(lastInwardDate, poDate);

      // If the PO code is not in the result object, initialize it
      if (!result[pocode]) {
        result[pocode] = {
          diff: 0, // Initialize the total difference
          count: 0, // Initialize the count for this PO code
        };
      }

      // Add the difference to the total and increment the count
      result[pocode].diff += difference;
      result[pocode].count++;
    }
  });

  return result; // Return the object containing total differences and counts
};

// Function to calculate the average time difference between last inward date and purchase order date
const groupedDataAverageTimeInstamart = (data) => {
  // Helper function to calculate date difference in days
  function calculateDateDifference(lastInwardDateStr, poDateStr) {
    const lastInwardDate = new Date(lastInwardDateStr);
    const poDate = new Date(poDateStr);
    const differenceInMillis = lastInwardDate - poDate;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.round(differenceInMillis / millisecondsPerDay); // Return difference in days
  }

  const result = {}; // Object to store PO code and associated time difference

  // Loop through each entry in the data
  data?.forEach((entry) => {
    const lastInwardDate = entry["fulfillment_report_instamart.last_inward_date"]; // Extract last inward date
    const poDate = entry["fulfillment_report_instamart.podate"]; // Extract purchase order date
    const pocode = entry["fulfillment_report_instamart.pocode"]; // Extract purchase order code

    // If both dates exist, calculate the date difference
    if (lastInwardDate && poDate) {
      const difference = calculateDateDifference(lastInwardDate, poDate);
      if (!result[pocode]) {
        result[pocode] = {
          diff: 0,
          count: 0,
        };
      }
      // Add difference to result and increment count
      result[pocode].diff += difference;
      result[pocode].count++;
    }
  });

  return result; // Return the result containing PO codes and their associated differences
};

const FulfillmentInsights = ({ platformName, selectedDate }) => {
  const { blinkitFulfillment, zeptoFulfillment, instamartFulfillment } = useSelector((state) => state.fulfillment);
  console.log("zeptoFulfillment:", zeptoFulfillment.data);
  const [averageTime, setAverageTime] = useState(null);
  console.log("averageTime:", averageTime);
  const [titleHeading, setTitleHeading] = useState("");

  // Blinkit Fulfillment
  const filteredUpcomingData = getUpcomingAppointmentsBlinkit(blinkitFulfillment.data).slice(0, 5);
  const filteredBookedData = getBookedAppointmentsBlinkit(blinkitFulfillment.data).slice(0, 5);
  const averageAppointmentTurnAroundTime = calculateAverageAppointmentTurnAroundTimeBlinkit(blinkitFulfillment.data);
  console.log("averageAppointmentTurnAroundTime:", averageAppointmentTurnAroundTime);
  const averageTimeZepto = groupedDataAverageTimeZepto(zeptoFulfillment.data); // Get average time data
  console.log("averageTimeZepto===", averageTimeZepto);
  const averageTimeInstamart = groupedDataAverageTimeInstamart(instamartFulfillment.data); // Calculate the average turnaround time

  let countZepto = 0; // Initialize count of POs
  let totalZepto = 0; // Initialize total difference in days

  let countInstamart = 0;
  let totalInstamart = 0;

  // Loop through the averageTime object to calculate the overall average
  for (const key in averageTimeZepto) {
    if (averageTimeZepto.hasOwnProperty(key)) {
      countZepto++; // Increment the count for each PO code
      totalZepto += averageTimeZepto[key].diff / averageTimeZepto[key].countZepto; // Add the average for each PO code to the total
    }
  }

  // Calculate total average turnaround time from the averageTime object
  for (const key in averageTimeInstamart) {
    if (averageTimeInstamart.hasOwnProperty(key)) {
      countInstamart++; // Count the number of PO codes
      totalInstamart += averageTimeInstamart[key].diff / averageTimeInstamart[key].countInstamart; // Calculate average time for each PO code
    }
  }

  useEffect(() => {
    if (platformName === "Blinkit") {
      setAverageTime(averageAppointmentTurnAroundTime);
      setTitleHeading("Average Appointment Turn Around Time");
    } else if (platformName === "Zepto") {
      setAverageTime(totalZepto / countZepto);
      setTitleHeading("Average Turn Around Time");
    } else {
      setAverageTime(totalInstamart / countInstamart);
      setTitleHeading("Average Turn Around Time");
    }
  }, [platformName, averageAppointmentTurnAroundTime, countZepto, totalZepto, countInstamart, totalInstamart]);

  if (blinkitFulfillment.loading) {
    return (
      <div className="bg-white shadow-md rounded-xl">
        <h1 className="flex gap-3 items-center text-lg font-medium pb-4 border-b p-2">
          <div className="p-2 rounded-full bg-gray-200">
            <AiOutlineSchedule />
          </div>
          Fulfillment Insights
        </h1>
        <div className="flex flex-col gap-4 px-4 py-6">
          <div>
            <Skeleton variant="rectangular" width={300} height={200} />
          </div>
          <div className="flex gap-4 h-[82vh]">
            <Skeleton variant="rectangular" width="100%" height="100%" />
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        </div>
      </div>
    );
  }

  if (!blinkitFulfillment.data || blinkitFulfillment.data.length == 0) {
    return (
      <div className="bg-white rounded-lg my-2">
        <div className="flex gap-3 items-center justify-between  text-xl p-4 border-b">
          <div className="flex gap-3 items-center text-lg font-medium">
            <AiOutlineSchedule />
            Fulfillment Insights
          </div>
          <div>
            <ShowSelectedDate date={selectedDate} />
          </div>
        </div>
        <div className="h-96 flex justify-center items-center">
          <NoDataFound height={200} width={200} maximum={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-xl">
      <div>
        <div className="flex gap-3 items-center justify-between  text-xl p-4 border-b">
          <div className="flex gap-3 items-center text-lg font-medium">
            <AiOutlineSchedule />
            Fulfillment Insights
          </div>
          <div>
            <ShowSelectedDate date={selectedDate} />
          </div>
        </div>
        <div className="flex flex-col gap-4 px-4 py-6">
          <AvgAppointmnetTurnAroundTimeCard data={averageTime} title={titleHeading} />
          <div className="flex gap-4">
            <div className="flex-1">
              <TopUpcomingAppointments
                path={"/fulfillment/fulfillment-report"}
                data={filteredUpcomingData}
                heading={"Upcoming Appointments"}
              />
            </div>

            <div className="flex-1">
              <TopUpcomingAppointments
                path={"/fulfillment/fulfillment-report"}
                data={filteredBookedData}
                heading={"Booked Appointments"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FulfillmentInsights;
