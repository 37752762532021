import React, { useState } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

const ShareOfVoiceCityWiseStatsCard = ({ titleName, ad, organic, overall, adChange, organicChange }) => {
  // Function to determine the text color based on value
  const getChangeColor = (value) => {
    if (value === "No previous data") return "text-gray-500"; // Default color for no data
    return value >= 0 ? "text-green-600" : "text-red-600";
  };

  return (
    <TableRow>
      <TableCell sx={{ textAlign: "left", paddingLeft: "4rem" }}>{titleName}</TableCell>
      {/* Overall SOV */}
      <TableCell sx={{ textAlign: "center" }}>{overall}%</TableCell>
      {/* Organic SOV */}
      <TableCell>
        <div className="flex items-center w-full gap-2 text-center justify-center">
          {organic}%
          <div className={getChangeColor(organicChange)}>
            {organicChange >= 0 ? "▲" : "▼"} {organicChange}%
          </div>
        </div>
      </TableCell>
      {/* Ad SOV */}
      <TableCell>
        <div className="flex items-center w-full gap-2 text-center justify-center">
          {ad}%
          <div className={getChangeColor(adChange)}>
            {adChange >= 0 ? "▲" : "▼"} {adChange}%
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ShareOfVoiceCityWiseStatsCard;
