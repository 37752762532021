import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Papa from "papaparse";

// Table headers for the Inventory Days Instamart Table
const heading = ["Category", "Total Inventory", "Daily Run Rate", "Inventory Days"];

const InventoryDaysInstamartTable = ({ platformName }) => {
  // Access inventory report data from Redux store
  const { inventoryReportInstamart } = useSelector((state) => state.sales);

  // State to track expanded categories and subcategories for collapsing/expanding rows
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});

  // Destructuring loading state and data from inventory report
  const { loading, data } = inventoryReportInstamart;
  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust this based on how many rows you want to display per page

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Function to consolidate data by city and SKU code, and calculate totals
  function consolidateData(data) {
    const consolidated = {};

    data.forEach((entry) => {
      const city = entry["combined_inventory_data_instamart.brands_choice"];
      const skuCode = entry["combined_inventory_data_instamart.brand_internal_sku_code"];
      const dailyRunRate = parseFloat(entry["combined_sales_report_instamart.daily_run_rate"]);
      const totalFrontBackInv = parseInt(entry["combined_inventory_data_instamart.wh_soh"], 10);

      // Create a unique key based on city and SKU code
      const key = `${city}-${skuCode}`;

      // Initialize if not already present
      if (!consolidated[key]) {
        consolidated[key] = {
          ...entry, // Spread all other fields from the first entry for the city and sku
          "combined_sales_report_instamart.daily_run_rate": 0, // Initialize for daily run rate calculation
          totalFrontBackInv: 0, // Total inventory
          count: 0, // Counter for averaging
        };
      }

      // Accumulate daily run rate and total inventory
      consolidated[key]["combined_sales_report_instamart.daily_run_rate"] += dailyRunRate;
      consolidated[key].totalFrontBackInv += totalFrontBackInv;
      consolidated[key].count += 1; // Increment the count
    });

    // Calculate averages and return sorted data by SKU code
    return Object.values(consolidated)
      .map((entry) => ({
        ...entry, // Spread all other fields
        "combined_sales_report_instamart.daily_run_rate":
          entry["combined_sales_report_instamart.daily_run_rate"] / entry.count,
        totalInv: entry.totalFrontBackInv,
      }))
      .sort((a, b) => {
        // Sort entries with null SKU codes last
        const aSkuCode = a["combined_inventory_data_instamart.brand_internal_sku_code"];
        const bSkuCode = b["combined_inventory_data_instamart.brand_internal_sku_code"];

        if (aSkuCode === null || aSkuCode === undefined) return 1; // Or use `-1` to sort nulls first
        if (bSkuCode === null || bSkuCode === undefined) return -1; // Or use `1` to sort nulls last

        return aSkuCode.localeCompare(bSkuCode);
      });
  }

  // Function to aggregate data by SKU code and calculate totals/averages
  function aggregateBySkuCode(consolidatedData) {
    const aggregatedData = {};

    consolidatedData.forEach((entry) => {
      const skuCode = entry["combined_inventory_data_instamart.brand_internal_sku_code"];

      if (!aggregatedData[skuCode]) {
        aggregatedData[skuCode] = {
          ...entry, // Initialize with the first entry's data
          totalInv: 0, // Total inventory
          totalDailyRunRate: 0, // Total daily run rate
          count: 0, // Count for averaging
        };
      }

      // Accumulate total inventory and daily run rate
      aggregatedData[skuCode].totalInv += entry.totalInv;
      aggregatedData[skuCode].totalDailyRunRate += entry["combined_sales_report_instamart.daily_run_rate"];
      aggregatedData[skuCode].count += 1;
    });

    // After grouping, calculate the average daily run rate, inventory days, and finalize other fields
    return Object.values(aggregatedData).map((entry) => {
      const averageDailyRunRate = entry.totalDailyRunRate / entry.count;
      const totalInv = entry.totalInv;
      const inventoryDays = totalInv / averageDailyRunRate;

      return {
        ...entry,
        "combined_sales_report_instamart.daily_run_rate": averageDailyRunRate,
        totalInv, // Recalculate totalInv to ensure it's correct
        inventoryDays: inventoryDays || 0, // Prevent division by zero, set inventoryDays to 0 if averageDailyRunRate is 0
      };
    });
  }

  // Consolidate and aggregate data
  const consolidatedData = consolidateData(data); // Assuming this is the result from your consolidateData function
  const aggregatedData = aggregateBySkuCode(consolidatedData);

  // Handle category click to toggle expansion
  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Handle subcategory click to toggle expansion
  const handleSubCategoryClick = (subCategory) => {
    setExpandedSubCategories((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory],
    }));
  };

  // Group data by category and subcategory, and calculate totals
  const groupedByCategory = aggregatedData.reduce((acc, item) => {
    const category = item["combined_inventory_data_instamart.category"];
    const subCategory = item["combined_inventory_data_instamart.sub_category"];
    const productName = item["combined_inventory_data_instamart.product_name"];

    // Initialize category if not present
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if not present
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize productName if not present
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update product totals
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_instamart.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_instamart.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_instamart.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategory).forEach((category) => {
    const categoryData = groupedByCategory[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate =
      categoryData.totals.productCount > 0 ? categoryData.totals.dailyRunRateSum / categoryData.totals.productCount : 0;

    categoryData.totals.inventoryDays =
      categoryData.totals.productCount > 0
        ? categoryData.totals.inventoryDaysSum / categoryData.totals.productCount
        : 0;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.productCount > 0
          ? subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount
          : 0;

      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.productCount > 0
          ? subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount
          : 0;
    });
  });

  const uniqueCities = [...new Set(data.map((item) => item["combined_inventory_data_instamart.brands_choice"]))];
  const filteredUniqueCities = uniqueCities.filter((choice) => choice !== null && choice !== undefined);

  const slicedCategories = Object.keys(groupedByCategory).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = aggregatedData.map((entry) => ({
      "Product Name": entry["combined_inventory_data_instamart.product_name"],
      "Total Inventory": entry.totalInv,
      "Daily Run Rate": entry["combined_sales_report_instamart.daily_run_rate"],
      "Inventory Days": entry.inventoryDays?.toFixed(0),
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `inventory_data_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div>
      <div className="w-full mb-4 flex justify-end">
        <span
          onClick={handleExportCSV}
          className="w-40 gap-2 p-3 font-medium rounded bg-white text-[#727278] shadow flex"
        >
          <span>Export to CSV</span>
          <img width={25} height={15} src="/icons/download-circular-icon.svg" />
        </span>
      </div>
      <Paper sx={{ width: "100%", mb: "2rem" }}>
        <div className="p-3 text-lg font-medium border-b mb-2 flex gap-4">
          <img src="/logos/inventory-page-icon.svg" alt="inventory" /> Inventory Days
        </div>
        <div className="p-4 w-full mb-2">
          <div className="border rounded p-4">
            <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
              <Table stickyHeader aria-label="sticky table" sx={{ overflowX: "auto" }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#F9F9F9" }}>
                    {heading.map((head, index) => (
                      <TableCell
                        key={index}
                        className="text-left px-6 py-4 gap-[2.75rem] tracking-wider"
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: index == 0 ? "left" : "center",
                          boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: index === 0 && "3",
                          minWidth: "300px",
                          maxWidth: "300px",
                          position: index === 0 && "sticky",
                          left: index === 0 && 0,
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        {head}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedCategories.map((category, index) => (
                    <React.Fragment key={category}>
                      <TableRow
                        className={`cursor-pointer ${
                          expandedCategories[category] ? "bg-[#F3F8FF]" : index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-white"
                        } `}
                        onClick={() => handleCategoryClick(category)}
                      >
                        <TableCell
                          className="flex justify-between items-center text-left px-3 gap-4 py-4 font-medium font-body"
                          sx={{
                            color: "#4D4D4D",
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            minWidth: "300px",
                            maxWidth: "300px",
                            position: "sticky",
                            left: 0,
                            bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            display: "flex",
                            textAlign: "left",
                          }}
                        >
                          {category}
                          {expandedCategories[category] ? <FaCaretUp /> : <FaCaretDown />}
                        </TableCell>
                        <TableCell
                          className="px-6 py-4 whitespace-nowrap font-normal font-body"
                          sx={{
                            minWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          {groupedByCategory[category].totals.totalInventory}
                        </TableCell>
                        <TableCell
                          className="px-6 py-4 whitespace-nowrap font-normal font-body"
                          sx={{
                            textAlign: "center",
                            minWidth: "200px",
                          }}
                        >
                          {groupedByCategory[category]?.totals?.dailyRunRate}
                        </TableCell>
                        <TableCell
                          className="px-6 py-4 whitespace-nowrap text-left font-normal font-body"
                          sx={{
                            minWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          {groupedByCategory[category].totals.inventoryDays}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "black",
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3",
                            position: "sticky",
                            bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            right: 0,
                          }}
                        ></TableCell>
                      </TableRow>
                      {expandedCategories[category] &&
                        Object.keys(groupedByCategory[category].subCategories).map((subCategory) => (
                          <React.Fragment key={subCategory}>
                            <TableRow
                              className="cursor-pointer bg-[#F0F0F0] text-[#727278]"
                              onClick={() => handleSubCategoryClick(subCategory)}
                            >
                              <TableCell
                                className="flex justify-between pl-6 items-center px-3 gap-4 py-4 font-medium font-body"
                                sx={{
                                  color: "#4D4D4D",
                                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                  minWidth: "300px",
                                  maxWidth: "300px",
                                  position: "sticky",
                                  left: 0,
                                  bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                  display: "flex",
                                  paddingLeft: "2rem",
                                }}
                              >
                                {subCategory}
                                {expandedSubCategories[category]?.[subCategory] ? <FaCaretUp /> : <FaCaretDown />}
                              </TableCell>
                              <TableCell
                                className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                sx={{
                                  textAlign: "center",
                                  minWidth: "200px",
                                }}
                              >
                                {groupedByCategory[category].subCategories[subCategory].totals.totalInventory}
                              </TableCell>
                              <TableCell
                                className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                sx={{
                                  textAlign: "center",
                                  minWidth: "200px",
                                }}
                              >
                                {groupedByCategory[category].subCategories[subCategory].totals.dailyRunRate}
                              </TableCell>
                              <TableCell
                                className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                sx={{
                                  textAlign: "center",
                                  minWidth: "200px",
                                }}
                              >
                                {groupedByCategory[category].subCategories[subCategory].totals.inventoryDays}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                  zIndex: "3",
                                  position: "sticky",
                                  bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                  right: 0,
                                }}
                              ></TableCell>
                            </TableRow>
                            {expandedSubCategories[subCategory] &&
                              Object.keys(groupedByCategory[category].subCategories[subCategory].productNames).map(
                                (productName, index) => (
                                  <TableRow key={index} className="bg-white cursor-pointer">
                                    <TableCell
                                      className="px-6 py-4 pl-8 font-medium font-body"
                                      sx={{
                                        color: "#4D4D4D",
                                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                        minWidth: "300px",
                                        maxWidth: "300px",
                                        position: "sticky",
                                        left: 0,
                                        bgcolor: "white",
                                        display: "flex",
                                        paddingLeft: "3rem",
                                        textAlign: "left",
                                      }}
                                    >
                                      {productName}
                                    </TableCell>
                                    <TableCell
                                      className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {
                                        groupedByCategory[category].subCategories[subCategory].productNames[productName]
                                          .totalInventory
                                      }
                                    </TableCell>
                                    <TableCell className="px-6 py-4 whitespace-nowrap font-normal font-body">
                                      {
                                        groupedByCategory[category].subCategories[subCategory].productNames[productName]
                                          .dailyRunRate
                                      }
                                    </TableCell>
                                    <TableCell className="px-6 py-4 whitespace-nowrap font-normal font-body">
                                      {
                                        groupedByCategory[category].subCategories[subCategory].productNames[productName]
                                          ?.inventoryDays
                                      }
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                        zIndex: "3",
                                        position: "sticky",
                                        bgcolor: "white",
                                        right: 0,
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                )
                              )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Object.keys(groupedByCategory).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default InventoryDaysInstamartTable;
