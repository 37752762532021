import { ResponsiveFunnel } from "@nivo/funnel";

const FunnelChart = ({ obj }) => {
  console.log("obj====", obj);

  if (!obj) {
    return <div>No data...</div>;
  }

  // Data format for Nivo Funnel
  const data = [
    {
      id: "Impressions",
      value: obj.impressions,
      label: "Impressions",
    },
    {
      id: "Add to Cart",
      value: obj.addToCarts,
      label: "Add to Cart",
    },
    {
      id: "Total Quantity Sold",
      value: obj.totalQuantitiesSold,
      label: "Total Quantity Sold",
    },
  ];

  const customColors = ["#D4DFFA", "#A6BFF5", "#7A9EF0"]; // Example colors: pink, blue, yellow

  return (
    <div className="w-[30%] rounded-lg p-2 border bg-white">
      <h1 className="font-bold text-sm text-center text-[#387FF5] p-1 px-2 bg-[#EBF2FE] rounded-lg mt-1 capitalize">
        {obj.title}
      </h1>
      <h2 className="font-bold text-sm text-center">Total Spent: {obj.estimateBudget.toFixed(0)}</h2>
      <div className="font-black text-sm text-center">CPM : {(obj.cpm / obj.count).toFixed(0)}</div>
      <div style={{ height: "300px" }}>
        <ResponsiveFunnel
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          direction="horizontal"
          valueFormat=" >-.4s"
          colors={customColors} // Use custom colors here
          borderWidth={0}
          borderColor={{ from: "color", modifiers: [] }}
          labelColor={{
            from: "color",
            modifiers: [["darker", 3]],
          }}
          enableBeforeSeparators={false}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          enableAfterSeparators={false}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
        />
      </div>
    </div>
  );
};

export default FunnelChart;
