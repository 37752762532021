import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2"; // Import Line component from Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./common.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const metrics = [
  { id: "overall", name: "Overall SOV" },
  { id: "organic", name: "Organic SOV" },
  { id: "ad", name: "Ad SOV" },
];
const colors = [
  "#ff1493", // Deep Pink
  "#000080", // Navy
  "#ffa500", // Orange
  "#dc143c", // Crimson
  "#00fa9a", // Medium Spring Green
  "#ffb6c1", // Light Pink
  "#000000", // Black
  "#696969", // Dim Gray
  "#006400", // Dark Green
  "#808000", // Olive
  "#483d8b", // Dark Slate Blue
  "#3cb371", // Medium Sea Green
  "#008b8b", // Dark Cyan
  "#32cd32", // Lime Green
  "#800080", // Purple
  "#b03060", // Maroon 3
  "#00ff00", // Lime
  "#8a2be2", // Blue Violet
  "#0000ff", // Blue
  "#adff2f", // Green Yellow
  "#ffff54", // Laser Lemon
  "#add8e6", // Light Blue
  "#8b4513", // Saddle Brown
  "#7b68ee", // Medium Slate Blue
  "#fafad2", // Light Goldenrod
];

const groupByKeywordAreaAndBrandForWeeklyData = (weeklyData) => {
  const groupedWeeklyData = {};

  // Iterate through each date in the weeklyData object
  Object.keys(weeklyData).forEach((date) => {
    const dataForDate = weeklyData[date];
    dataForDate.forEach((item) => {
      const brand = item["third_party_keyword.brand"]; // Adding brand
      const keyword = item["third_party_keyword.keyword"];
      const keywordType = item["third_party_keyword.keyword_type"];
      const area = item["third_party_keyword.area_name_lm"];
      const organic = parseFloat(item["third_party_keyword.totalSovOrgaincPercentage"]) || 0;
      const ad = parseFloat(item["third_party_keyword.totalSovAdPercentage"]) || 0;

      // Create a unique key for keyword + area + brand + date combination
      const key = `${keyword}_${area}_${brand}_${date}`;
      if (!groupedWeeklyData[key]) {
        groupedWeeklyData[key] = {
          brand, // Add brand to the grouping object
          keyword,
          keywordType,
          area,
          date,
          totalSovOrgaincPercentage: 0,
          totalSovAdPercentage: 0,
        };
      }

      // Sum organic and ad percentages for each keyword in the same area for the same date
      groupedWeeklyData[key].totalSovOrgaincPercentage += organic;
      groupedWeeklyData[key].totalSovAdPercentage += ad;
    });
  });

  // Convert the groupedWeeklyData object back into an array
  return Object.values(groupedWeeklyData);
};

const calculateAveragesByKeywordAndBrandForWeeklyData = (groupedWeeklyDatas) => {
  const keywordSummedWeeklyData = {};

  groupedWeeklyDatas.forEach((item) => {
    const { brand, keyword, totalSovOrgaincPercentage, totalSovAdPercentage, keywordType, date, area } = item;

    // Create a unique key for keyword + brand + area + date combination
    const key = `${keyword}_${brand}_${area}_${date}`;

    // If the keyword doesn't exist in the keywordSummedWeeklyData, initialize it
    if (!keywordSummedWeeklyData[key]) {
      keywordSummedWeeklyData[key] = {
        brand,
        keyword,
        keywordType,
        date,
        area, // Add area to the data
        totalSovOrgaincPercentage: 0,
        totalSovAdPercentage: 0,
        totalCountAd: 0,
        totalCountOrganic: 0,
      };
    }

    // Sum up percentages and counts for each keyword, brand, and area
    keywordSummedWeeklyData[key].totalSovOrgaincPercentage += totalSovOrgaincPercentage;
    keywordSummedWeeklyData[key].totalSovAdPercentage += totalSovAdPercentage;
    if (totalSovOrgaincPercentage > 0) {
      keywordSummedWeeklyData[key].totalCountOrganic += 1;
    }
    if (totalSovAdPercentage > 0) {
      keywordSummedWeeklyData[key].totalCountAd += 1;
    }
  });

  // Now, calculate the average for each keyword, brand, and area, and add overallSov
  const averagedWeeklyData = Object.values(keywordSummedWeeklyData).map((item) => {
    const avgSovOrgaincPercentage = item.totalSovOrgaincPercentage / item.totalCountOrganic || 0;
    const avgSovAdPercentage = item.totalSovAdPercentage / item.totalCountAd || 0;

    return {
      brand: item.brand,
      keyword: item.keyword,
      keywordType: item.keywordType,
      date: item.date,
      area: item.area, // Include area in the result
      avgSovOrgaincPercentage,
      avgSovAdPercentage,
    };
  });

  return averagedWeeklyData;
};

const ShareOfVoiceCompetitionTrends = () => {
  const { selectedSovKeyword, sovKeywordWeeklyData } = useSelector((state) => state.sovThirdParty);
  console.log("selectedSovKeyword====", selectedSovKeyword);
  const user = JSON.parse(localStorage.getItem("user"));
  const ourBrand = user.organizationDetail.name;
  const [selectedMetric, setSelectedMetric] = useState(metrics[0].id);
  const [selectedBrands, setSelectedBrands] = useState([ourBrand]);

  // Create a state to store the filtered data
  const [filteredKeywordData, setFilteredKeywordData] = useState([]);
  console.log("filteredKeywordData====", filteredKeywordData);
  const [processedData, setProcessedData] = useState({});
  console.log("processedData=====", processedData);

  useEffect(() => {
    // Filtering logic for selectedSovKeyword
    const filteredData =
      selectedSovKeyword === null || selectedSovKeyword === "All Keyword"
        ? sovKeywordWeeklyData.data || []
        : sovKeywordWeeklyData.data?.filter((item) => item["third_party_keyword.keyword"] === selectedSovKeyword) || [];

    setFilteredKeywordData(filteredData);
  }, [selectedSovKeyword, sovKeywordWeeklyData.data]);

  // First, filter the data by current brand and then separate it by dates and brands
  const weeklyData = filteredKeywordData.reduce((acc, item) => {
    const date = item["third_party_keyword.date"];
    const brand = item["third_party_keyword.brand"];

    // Initialize the date if it doesn't exist
    if (!acc[date]) {
      acc[date] = {};
    }

    // Initialize the brand within the date if it doesn't exist
    if (!acc[date][brand]) {
      acc[date][brand] = [];
    }

    // Push the current item into the array for the corresponding date and brand
    acc[date][brand].push(item);

    return acc;
  }, {});

  const sumPercentage = (value1, value2) => (parseFloat(value1) + parseFloat(value2)).toFixed(2);

  // Now, let's group by `keyword`, `area_name`, and `product_name` and sum the percentages
  const addingAdandOrganicOfSameDataForWeekly = Object.keys(weeklyData).reduce((acc, date) => {
    const dateData = weeklyData[date]; // This is the object containing brand arrays for this date

    // Iterate over each brand in the dateData
    Object.keys(dateData).forEach((brand) => {
      const brandData = dateData[brand]; // This is the array of data for the specific brand on this date

      const groupedByKeywordAreaProduct = brandData.reduce((groupAcc, item) => {
        const {
          "third_party_keyword.keyword": keyword,
          "third_party_keyword.brand": brand,
          "third_party_keyword.keyword_type": keyword_type,
          "third_party_keyword.area_name_lm": area_name,
          "third_party_keyword.product_name": product_name,
          "third_party_keyword.sov_ad_percentage": sovAdPercentage,
          "third_party_keyword.sov_organic_percentage": sovOrganicPercentage,
        } = item;

        // Create a unique key for grouping by keyword, area_name, and product_name
        const groupKey = `${keyword}_${area_name}_${product_name}`;

        if (!groupAcc[groupKey]) {
          groupAcc[groupKey] = {
            "third_party_keyword.keyword": keyword,
            "third_party_keyword.brand": brand,
            "third_party_keyword.keyword_type": keyword_type,
            "third_party_keyword.area_name_lm": area_name,
            "third_party_keyword.product_name": product_name,
            "third_party_keyword.totalSovAdPercentage": sovAdPercentage,
            "third_party_keyword.totalSovOrgaincPercentage": sovOrganicPercentage,
          };
        } else {
          // Sum the `sov_ad_percentage` and `sov_organic_percentage`
          groupAcc[groupKey]["third_party_keyword.totalSovAdPercentage"] = sumPercentage(
            groupAcc[groupKey]["third_party_keyword.totalSovAdPercentage"],
            sovAdPercentage
          );
          groupAcc[groupKey]["third_party_keyword.totalSovOrgaincPercentage"] = sumPercentage(
            groupAcc[groupKey]["third_party_keyword.totalSovOrgaincPercentage"],
            sovOrganicPercentage
          );
        }

        return groupAcc;
      }, {});

      // Add the processed data for the brand back into the accumulator for this date
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date] = acc[date].concat(Object.values(groupedByKeywordAreaProduct));
    });

    return acc;
  }, {});

  const groupedWeeklyDatas = groupByKeywordAreaAndBrandForWeeklyData(addingAdandOrganicOfSameDataForWeekly);
  console.log("groupedWeeklyDatas====", groupedWeeklyDatas);
  const averagedWeeklyData = calculateAveragesByKeywordAndBrandForWeeklyData(groupedWeeklyDatas);
  console.log("averagedWeeklyData====", averagedWeeklyData);

  const groupedDataByDateAndBrand = averagedWeeklyData.reduce((acc, item) => {
    const date = item.date;
    const brand = item.brand;

    // Initialize the date if it doesn't exist
    if (!acc[date]) {
      acc[date] = {};
    }

    // Initialize the brand within the date if it doesn't exist
    if (!acc[date][brand]) {
      acc[date][brand] = [];
    }

    // Push the current item into the array for the corresponding date and brand
    acc[date][brand].push(item);

    return acc;
  }, {});

  const processGroupedData = (groupedDataByDateAndBrand) => {
    const result = {};

    Object.keys(groupedDataByDateAndBrand).forEach((date) => {
      result[date] = {};

      Object.keys(groupedDataByDateAndBrand[date]).forEach((brand) => {
        const brandData = groupedDataByDateAndBrand[date][brand];

        // Group by keyword only, not by area
        const groupedByKeyword = brandData.reduce((acc, item) => {
          const { keyword, avgSovOrgaincPercentage, avgSovAdPercentage, brand, date } = item;
          const key = keyword; // Group by keyword only

          if (!acc[key]) {
            acc[key] = {
              keyword,
              brand,
              date,
              totalOrgaincPercentage: avgSovOrgaincPercentage,
              totalAdPercentage: avgSovAdPercentage,
              countOrganic: avgSovOrgaincPercentage > 0 ? 1 : 0,
              countAd: avgSovAdPercentage > 0 ? 1 : 0,
            };
          } else {
            acc[key].totalOrgaincPercentage += avgSovOrgaincPercentage;
            acc[key].totalAdPercentage += avgSovAdPercentage;
            if (avgSovOrgaincPercentage > 0) acc[key].countOrganic += 1;
            if (avgSovAdPercentage > 0) acc[key].countAd += 1;
          }

          return acc;
        }, {});

        // Now calculate the averages and overallSov for each keyword
        result[date][brand] = Object.values(groupedByKeyword).map((item) => {
          const avgSovOrgaincPercentage = item.countOrganic > 0 ? item.totalOrgaincPercentage / item.countOrganic : 0;
          const avgSovAdPercentage = item.countAd > 0 ? item.totalAdPercentage / item.countAd : 0;
          const overallSov = (avgSovOrgaincPercentage + avgSovAdPercentage).toFixed(2);

          return {
            keyword: item.keyword,
            brand: item.brand,
            date: item.date,
            avgSovOrgaincPercentage: avgSovOrgaincPercentage.toFixed(2),
            avgSovAdPercentage: avgSovAdPercentage.toFixed(2),
            overallSov,
          };
        });
      });
    });

    return result;
  };

  useEffect(() => {
    const newProcessedData = processGroupedData(groupedDataByDateAndBrand);
    setProcessedData(newProcessedData);
  }, [groupedDataByDateAndBrand]);

  // ======================================================================

  const dates = Object.keys(groupedDataByDateAndBrand);
  const competitorBrandNames = Object.keys(groupedDataByDateAndBrand).flatMap((key) =>
    Object.keys(groupedDataByDateAndBrand[key])
  );

  // Using Set to get unique values
  const uniqueCompetitorBrandNames = [...new Set(competitorBrandNames)];

  // Filtering out brands that are equal to ourBrand
  const filteredCompetitorBrandNames = uniqueCompetitorBrandNames.filter((brand) => brand !== ourBrand);

  const chartData = () => {
    const seriesData = selectedBrands.map((brand) => {
      return {
        label: brand,
        data: dates.map((date) => {
          const brandDataArray = processedData[date]?.[brand] || [];
          const brandData = brandDataArray[0]; // Assuming there is only one item per brand per date

          if (selectedMetric === "ad") {
            return brandData?.avgSovAdPercentage || 0;
          } else if (selectedMetric === "organic") {
            return brandData?.avgSovOrgaincPercentage || 0;
          } else {
            return brandData?.overallSov || 0;
          }
        }),
      };
    });

    return {
      labels: dates,
      datasets: seriesData.map((data, index) => ({
        label: data.label,
        data: data.data,
        borderColor: colors[index % colors.length],
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        fill: true,
        tension: 0.4,
        borderWidth: 2,
        pointBackgroundColor: colors[index % colors.length],
      })),
    };
  };

  const data = chartData();

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const handleBrandChange = (brand) => {
    if (selectedBrands.includes(brand)) {
      setSelectedBrands(selectedBrands.filter((item) => item != brand));
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  return (
    <div>
      {/* matircs options */}
      <div className="flex  border-b-2  gap-4  p-2">
        <div className="text-base font-normal text-[#9E9E9E]">Metrics :</div>
        {metrics.map((item) => (
          <label htmlFor={item.id} className="flex text-xs text-[#555555] font-body font-medium items-center gap-2">
            <input
              type="radio"
              name="metric"
              id={item.id}
              value={item.id}
              checked={selectedMetric === item.id}
              onChange={handleMetricChange}
            />
            {item.name}
          </label>
        ))}
      </div>

      {/* heading */}
      <div className="mt-8">
        <span className="text-base text-[#5B5A73] font-medium">Competition Trends for Keyword : </span>

        <span className="bg-[#EBF2FE] text-[#387FF5] py-2 px-2 rounded">
          {selectedSovKeyword == null ? "All Keywords" : selectedSovKeyword}
        </span>
      </div>

      <div className="mt-4 b p-2  rounded bg-white">
        {/* brands mapping */}
        <div className="flex gap-x-3 gap-y-2 items-center flex-wrap">
          <div
            className="bg-white font-normal font-body text-base leading-5  p-2 rounded cursor-pointer"
            style={{
              border: selectedBrands.includes(ourBrand) ? `1px solid #FF6384` : "1px solid #9E9E9E",
              backgroundColor: selectedBrands.includes(ourBrand) ? "#FFEFF3" : "#ffff",
              color: selectedBrands.includes(ourBrand) ? `#FF6384` : "#9E9E9E",
            }}
            onClick={() => handleBrandChange(ourBrand)}
          >
            Our Brand
          </div>
          {filteredCompetitorBrandNames.map((brand, index) => (
            <div
              key={brand}
              className="bg-white font-normal font-body text-base leading-5 text-[#9E9E9E] p-2 rounded cursor-pointer"
              style={{
                border: selectedBrands.includes(brand) ? `1px solid #FF6384` : "1px solid #9E9E9E",
                backgroundColor: selectedBrands.includes(brand) ? "#FFEFF3" : "#ffff",
                color: selectedBrands.includes(brand) ? `#FF6384` : "#9E9E9E",
              }}
              onClick={() => handleBrandChange(brand)}
            >
              {brand}
            </div>
          ))}
        </div>

        <div className="flex border rounded mt-4">
          {/* line chart */}
          <div className="p-2 flex-1 mt-4">
            <Line
             height={120}
              data={data}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                    labels: {
                      usePointStyle: true,
                      pointStyle: "circle",
                      font: {
                        size: 10,
                        weight: 400,
                      },
                    },
                  },
                  tooltip: {
                    mode: "index",
                    intersect: false,
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Date",
                    },
                    grid: {
                      display: false, // Remove horizontal grid lines
                    },
                  },

                  y: {
                    title: {
                      display: true,
                      text: "Percentage",
                    },
                    beginAtZero: true,
                    grid: {
                      display: false, // Remove horizontal grid lines
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareOfVoiceCompetitionTrends;
