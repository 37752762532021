import { Chart as ChartJS, registerables } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

const colors = [
  "#FF6384",
  "#22CFCF",
  "#4597DB",
  "#FEBE40",

  // "#ffb6c1", // Light Pink
  // "#000000", // Black
  // "#696969", // Dim Gray
  // "#006400", // Dark Green
  // "#808000", // Olive
  // "#483d8b", // Dark Slate Blue
  // "#3cb371", // Medium Sea Green
  // "#008b8b", // Dark Cyan
  // "#32cd32", // Lime Green
  // "#800080", // Purple
  // "#b03060", // Maroon 3
  // "#00ff00", // Lime
  // "#8a2be2", // Blue Violet
  // "#0000ff", // Blue
  // "#adff2f", // Green Yellow
  // "#ffff54", // Laser Lemon
  // "#add8e6", // Light Blue
  // "#8b4513", // Saddle Brown
  // "#7b68ee", // Medium Slate Blue
  // "#fafad2", // Light Goldenrod
];

ChartJS.register(...registerables);
// Function to format date to dd/mm/yyyy
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const CitySkuSplitLineChart = ({ allCategories, cityChartData, city }) => {
  const categories = new Set();
  const dates = Object.keys(cityChartData);

  dates.forEach((date) => {
    Object.keys(cityChartData[date]).forEach((category) => {
      if (cityChartData[date][category].totalSales) {
        categories.add(category);
      }
    });
  });

  // Sort categories alphabetically
  const sortedCategories = Array.from(categories).sort();

  const datasets = sortedCategories.map((category) => ({
    label: category,
    data: [],
    backgroundColor: colors[allCategories.indexOf(category)],
    borderRadius: 5,
    barThickness: 24,
  }));

  // Populate dataset with quantities
  dates.forEach((date) => {
    const dayData = cityChartData[date];
    datasets.forEach((dataset) => {
      dataset.data.push(dayData[dataset.label]?.totalSales);
    });
  });

  // Format dates for chart labels
  const formattedDates = dates.map(formatDate);

  const chartData = {
    labels: formattedDates,
    datasets: datasets,
  };

  if (!Array.from(categories).length) {
    return null;
  }

  return (
    <div className="min-w-[48.5%] max-w-[48.5%] font-nunito border px-4 pt-5 py-7 custom-shadow min-h-96 rounded-lg">
      <div className="text-start font-body mb-8 text-[#414141] text-sm font-semibold ">{city}</div>
      <Bar
        className="max-h-96 min-h-96 min-w-full font-nunito max-w-full"
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                font: {
                  size: 12,
                  weight: 400,
                  // Adjust the font size to make the circles smaller
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default CitySkuSplitLineChart;
