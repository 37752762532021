import { getCubeJsApiInstance } from "../../utils/apiConfig";
import { blinkitQuery, zeptoQuery, instamartQuery } from "../../utils/queries/competitorPricingQueries";

export const fetchCompetitorPricingDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDate));
  return result.tablePivot();
};

export const fetchCompetitorPricingDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDate));
  return result.tablePivot();
};

export const fetchCompetitorPricingDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDate));
  return result.tablePivot();
};
