import React, { createContext, useContext, useState } from "react";
import dayjs from "dayjs";

// Create the context
const DateRangePickerContext = createContext();

// Create a provider component
export const DateRangePickerProvider = ({ children }) => {
  const today = dayjs();
  const thirtyDaysAgo = today.subtract(30, "day");
  
  const [selectedDateRange, setSelectedDateRange] = useState([thirtyDaysAgo, today]);

  return (
    <DateRangePickerContext.Provider value={{ selectedDateRange, setSelectedDateRange }}>
      {children}
    </DateRangePickerContext.Provider>
  );
};

// Custom hook to use the DateRangePickerContext
export const useDateRange = () => {
  const context = useContext(DateRangePickerContext);
  if (!context) {
    throw new Error("useDate must be used within a DateRangePickerProvider");
  }
  return context;
};
