import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { RiDiscountPercentLine } from "react-icons/ri";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const dateAndSkuWiseGroupedData = (data) => {
  return data.reduce((acc, item) => {
    const { date, sku, discounted_price, price } = item;
    const discountPercent = ((parseFloat(price) - parseFloat(discounted_price)) / price) * 100;

    // Initialize date if not present
    if (!acc[date]) {
      acc[date] = {};
    }

    // Initialize sku if not present for the date
    if (!acc[date][sku]) {
      acc[date][sku] = {
        discount: 0,
        count: 0,
      };
    }

    // Calculate discount (price - discounted_price) and count occurrences
    acc[date][sku].discount += discountPercent;
    acc[date][sku].count += 1;

    return acc;
  }, {});
};

const groupedByDate = (data) => {
  const groupedData = {};

  for (const date in data) {
    let totalDiscountPercent = 0;
    let totalCount = 0;

    for (const sku in data[date]) {
      const { discount, count } = data[date][sku];
      totalDiscountPercent += discount / count; // Sum of discounts
      totalCount += 1; // Sum of counts
    }

    // Calculate the average discount percentage if totalCount is not zero
    const averageDiscountPercent = totalCount > 0 ? totalDiscountPercent / totalCount : 0;

    groupedData[date] = {
      totalDiscountPercent: averageDiscountPercent,
    };
  }
  return groupedData;
};

const PricePerUnitOverWeek = ({ chartData }) => {
  const groupedData = dateAndSkuWiseGroupedData(chartData);
  const dateData = groupedByDate(groupedData);
  const labels = Object.keys(dateData);
  console.log("🚀 ~ PricePerUnitOverWeek ~ labels:", labels);
  const dataValues = labels.map((date) => dateData[date].totalDiscountPercent);

  // Format the dates to show only DD-MM
  const formattedDates = labels.map((date) => {
    const [day, month] = date.split("-");
    return `${day}/${month}`;
  });

  const shadowPlugin = {
    id: "shadowLine",
    beforeDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save();

      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);

        // Apply shadow only to line elements
        if (meta.type === "line") {
          ctx.shadowColor = "#387FF529"; // Shadow color
          ctx.shadowBlur = 6; // Blur radius for the shadow
          ctx.shadowOffsetX = 0; // No horizontal shadow offset
          ctx.shadowOffsetY = 5; // Vertical shadow only (bottom side)
        }
      });
    },
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.restore(); // Restore the context to avoid affecting other elements
    },
  };

  const data = {
    labels: formattedDates,
    datasets: [
      {
        label: "Total Discount Percentage",
        data: dataValues,
        borderColor: "#387FF5",
        tension: 0.4,
        borderWidth: 2,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#2B62BC",
        pointBorderWidth: 2,
        pointRadius: 3,
        pointHoverCursor: "pointer",
        pointHoverRadius: 4,
        backgroundColor: "#3881f508",
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}%`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },

        ticks: {
          beginAtZero: true,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
        title: {
          display: true,
          text: "Date", // X-axis title
          color: "#87878F",
          font: {
            size: 12,
            family: "DM Sans Variable",
            weight: 600,
          },
          padding: {
            top: 10, // Add some margin at the top
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 20,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
        title: {
          display: true,
          text: "Total Sales", // Y-axis title
          color: "#87878F",

          font: {
            size: 12,
            family: "DM Sans Variable",
            weight: 600,
          },
          padding: {
            // Adjust margin to the right
            bottom: 12, // Add some margin below the title
          },
        },
        min: 0,
        max: 100,
      },
    },
  };

  return (
    <div className="bg-white shadow-md rounded-[0.625rem] h-[25.8rem] w-[49%]">
      <div className="flex py-5 border-b border-[#EAEDF3]">
        <span className="flex  gap-4 items-center leading-6 text-[#4F4E69] text-lg  font-body font-medium ml-5">
          <RiDiscountPercentLine />
          Discounting Over the Week
          <CustomTooltip title={"Analyze Self-Brand Dscounting This Week"}>
            <PiInfoLight />
          </CustomTooltip>
        </span>
      </div>

      <div className="px-4 mt-[3.92rem] h-[15.954rem] w-full">
        <Line className="" data={data} options={options} plugins={[shadowPlugin]} />
      </div>
    </div>
  );
};

export default PricePerUnitOverWeek;
