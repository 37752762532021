import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoutesWrapper = ({ element: Element, ...rest }) => {
  const isAuthenticated = localStorage.getItem("token") !== null;

  return isAuthenticated ? (
    <Navigate to="/revenue/sales-report" />
  ) : (
    <Element {...rest} />
  );
};

export default PublicRoutesWrapper;
