import {
  GET_SOV_BY_KEYWORD_REQUEST,
  GET_SOV_BY_KEYWORD_SUCCESS,
  GET_SOV_BY_KEYWORD_FAILURE,
  GET_SOV_BY_AREANAME_REQUEST,
  GET_SOV_BY_AREANAME_SUCCESS,
  GET_SOV_BY_AREANAME_FAILURE,
  GET_SOV_BY_KEYWORD_WEEKLY_REQUEST,
  GET_SOV_BY_KEYWORD_WEEKLY_SUCCESS,
  GET_SOV_BY_KEYWORD_WEEKLY_FAILURE,
  GET_SOV_BY_AREANAME_WEEKLY_REQUEST,
  GET_SOV_BY_AREANAME_WEEKLY_SUCCESS,
  GET_SOV_BY_AREANAME_WEEKLY_FAILURE,
  SELECTED_SOA_KEYWORD,
  SELECTED_SOA_AREANAME,
  FILTERED_SOA_AREANAME,
  SELECTED_SOA_WEEKLY_KEYWORD,
  SELECTED_SOA_WEEKLY_AREANAME,
  FILTERED_SOA_WEEKLY_AREANAME,
} from "../constants/sovThirdPartyConstants";

// Actions for getting SOV by Keyword
export const getSovByKeywordRequest = () => {
  return {
    type: GET_SOV_BY_KEYWORD_REQUEST,
  };
};

export const getSovByKeywordSuccess = (keywordData) => {
  return {
    type: GET_SOV_BY_KEYWORD_SUCCESS,
    payload: keywordData,
  };
};

export const getSovByKeywordFailure = (error) => {
  return {
    type: GET_SOV_BY_KEYWORD_FAILURE,
    payload: error,
  };
};

// Actions for getting SOV by Area Name
export const getSovByAreaNameRequest = () => {
  return {
    type: GET_SOV_BY_AREANAME_REQUEST,
  };
};

export const getSovByAreaNameSuccess = (areaNameData) => {
  return {
    type: GET_SOV_BY_AREANAME_SUCCESS,
    payload: areaNameData,
  };
};

export const getSovByAreaNameFailure = (error) => {
  return {
    type: GET_SOV_BY_AREANAME_FAILURE,
    payload: error,
  };
};

// Action to set selected SOV Keyword
export const setSelectedSovKeyword = (keyword) => {
  return {
    type: SELECTED_SOA_KEYWORD,
    payload: keyword,
  };
};

// Action to set selected SOV Area Name
export const setSelectedSovAreaName = (areaName) => {
  return {
    type: SELECTED_SOA_AREANAME,
    payload: areaName,
  };
};

// Action to filter SOV by Area Name
export const getFilteredSovAreaName = (areaNameData) => {
  return {
    type: FILTERED_SOA_AREANAME,
    payload: areaNameData,
  };
};


// Actions for getting SOV by Keyword Weekly
export const getSovByKeywordWeeklyRequest = () => {
  return {
    type: GET_SOV_BY_KEYWORD_WEEKLY_REQUEST,
  };
};

export const getSovByKeywordWeeklySuccess = (keywordWeeklyData) => {
  return {
    type: GET_SOV_BY_KEYWORD_WEEKLY_SUCCESS,
    payload: keywordWeeklyData,
  };
};

export const getSovByKeywordWeeklyFailure = (error) => {
  return {
    type: GET_SOV_BY_KEYWORD_WEEKLY_FAILURE,
    payload: error,
  };
};

// Actions for getting SOV by Area Name Weekly
export const getSovByAreaNameWeeklyRequest = () => {
  return {
    type: GET_SOV_BY_AREANAME_WEEKLY_REQUEST,
  };
};

export const getSovByAreaNameWeeklySuccess = (areaNameWeeklyData) => {
  return {
    type: GET_SOV_BY_AREANAME_WEEKLY_SUCCESS,
    payload: areaNameWeeklyData,
  };
};

export const getSovByAreaNameWeeklyFailure = (error) => {
  return {
    type: GET_SOV_BY_AREANAME_WEEKLY_FAILURE,
    payload: error,
  };
};

// Action to set selected SOV Weekly Keyword
export const setSelectedSovWeeklyKeyword = (keyword) => {
  return {
    type: SELECTED_SOA_WEEKLY_KEYWORD,
    payload: keyword,
  };
};

// Action to set selected SOV Weekly Area Name
export const setSelectedSovWeeklyAreaName = (areaName) => {
  return {
    type: SELECTED_SOA_WEEKLY_AREANAME,
    payload: areaName,
  };
};

// Action to filter SOV by Weekly Area Name
export const getFilteredSovWeeklyAreaName = (areaNameData) => {
  return {
    type: FILTERED_SOA_WEEKLY_AREANAME,
    payload: areaNameData,
  };
};
