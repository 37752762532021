import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Papa from "papaparse";

// Define the headings for the table columns
const heading = [
  { label: "Category", key: "category" },
  { label: "Total Inventory", key: "totalInventory" },
  { label: "Daily Run Rate", key: "dailyRunRate" },
  { label: "Inventory Days", key: "inventoryDays" },
];

// Function to consolidate data by grouping entries based on city and sku code
function consolidateData(data) {
  const consolidated = {};

  data.forEach((entry) => {
    const city = entry["combined_inventory_data_zepto.brands_choice"];
    const skuCode = entry["combined_inventory_data_zepto.brand_internal_sku_code"];
    const dailyRunRate = parseFloat(entry["combined_sales_report_zepto.daily_run_rate"]);
    const totalFrontBackInv = parseInt(entry["combined_inventory_data_zepto.total_quantity"], 10);
    // Create a unique key based on city and skuCode
    const key = `${city}-${skuCode}`;

    // Initialize the consolidated entry if it doesn't exist
    if (!consolidated[key]) {
      consolidated[key] = {
        ...entry, // Spread all other fields from the first entry for the city and sku
        "combined_sales_report_zepto.daily_run_rate": 0, // Initialize for calculation
        totalFrontBackInv: 0, // Initialize total inventory
        count: 0, // Initialize count for averaging
      };
    }

    // Accumulate daily run rate and total inventory
    consolidated[key]["combined_sales_report_zepto.daily_run_rate"] += dailyRunRate;
    consolidated[key].totalFrontBackInv += totalFrontBackInv;
    consolidated[key].count += 1;
  });

  // Return the consolidated data and calculate the average daily run rate
  return Object.values(consolidated)
    .map((entry) => ({
      ...entry, // Spread all other fields
      "combined_sales_report_zepto.daily_run_rate": entry["combined_sales_report_zepto.daily_run_rate"] / entry.count,
      totalInv: entry.totalFrontBackInv, // Assign total inventory
    }))
    .sort((a, b) =>
      a["combined_inventory_data_zepto.brand_internal_sku_code"].localeCompare(
        b["combined_inventory_data_zepto.brand_internal_sku_code"]
      )
    ); // Sort by SKU code
}

// Function to aggregate consolidated data by SKU code
function aggregateBySkuCode(consolidatedData) {
  const aggregatedData = {};

  consolidatedData.forEach((entry) => {
    const skuCode = entry["combined_inventory_data_zepto.brand_internal_sku_code"];

    // Initialize the aggregated entry if it doesn't exist
    if (!aggregatedData[skuCode]) {
      aggregatedData[skuCode] = {
        ...entry, // Initialize with the first entry's data
        totalInv: 0, // Initialize total inventory
        totalDailyRunRate: 0, // Initialize daily run rate
        count: 0, // Initialize count for averaging
      };
    }

    // Sum up the frontend and backend inventory, total inventory, and daily run rate
    aggregatedData[skuCode].totalInv += entry.totalInv;
    aggregatedData[skuCode].totalDailyRunRate += entry["combined_sales_report_zepto.daily_run_rate"];
    aggregatedData[skuCode].count += 1;
  });

  // After grouping, calculate the average daily run rate, inventory days, and finalize other fields
  return Object.values(aggregatedData).map((entry) => {
    const averageDailyRunRate = entry.totalDailyRunRate / entry.count;
    const totalInv = entry.totalInv;
    const inventoryDays = totalInv / averageDailyRunRate;

    return {
      ...entry,
      "combined_sales_report_zepto.daily_run_rate": averageDailyRunRate,
      totalInv, // Recalculate totalInv to ensure it's correct
      inventoryDays: inventoryDays || 0, // Prevent division by zero, set inventoryDays to 0 if averageDailyRunRate is 0
    };
  });
}

const InventoryDaysZeptoTable = ({ platformName }) => {
  // Access the inventory report data from the Redux store
  const { inventoryReportZepto } = useSelector((state) => state.sales);

  // State for tracking expanded categories and subcategories
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});

  // Destructure loading and data from inventoryReportZepto
  const { loading, data } = inventoryReportZepto;
  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust this based on how many rows you want to display per page
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Example usage of consolidate and aggregate functions
  const consolidatedData = consolidateData(data); // Assuming this is the result from your consolidateData function
  const aggregatedData = aggregateBySkuCode(consolidatedData); // Then aggregate the consolidated data by SKU code
  console.log("aggregatedData===", aggregatedData);

  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };
  // Toggle the expansion of subcategories
  const handleSubCategoryClick = (subCategory) => {
    setExpandedSubCategories((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory], // Toggle the expanded state
    }));
  };

  // Group data by category, subcategory, and product name
  const groupedByCategory = aggregatedData.reduce((acc, item) => {
    const category = item["combined_inventory_data_zepto.category"];
    const subCategory = item["combined_inventory_data_zepto.sub_category"];
    const productName = item["combined_inventory_data_zepto.sku_name"];

    // Initialize category if it doesn't exist
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if it doesn't exist
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize product name if it doesn't exist
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update the product, sub-category, and category totals
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_zepto.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_zepto.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_zepto.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategory).forEach((category) => {
    const categoryData = groupedByCategory[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate = categoryData.totals.dailyRunRateSum / categoryData.totals.productCount;
    categoryData.totals.inventoryDays = categoryData.totals.inventoryDaysSum / categoryData.totals.productCount;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount;
      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount;
    });
  });

  // Slicing data to show based on pagination
  const slicedCategories = Object.keys(groupedByCategory).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortedCategories = useMemo(() => {
    const sortableItems = [...slicedCategories];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let aValue, bValue;

        if (sortConfig.key === "category") {
          aValue = a;
          bValue = b;
        } else {
          aValue = groupedByCategory[a].totals[sortConfig.key];
          bValue = groupedByCategory[b].totals[sortConfig.key];
        }

        if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
    }

    return sortableItems;
  }, [slicedCategories, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = aggregatedData.map((entry) => ({
      "Product Name": entry["combined_inventory_data_zepto.sku_name"],
      "Total Inventory": entry.totalInv,
      "Daily Run Rate": entry["combined_sales_report_zepto.daily_run_rate"],
      "Inventory Days": entry.inventoryDays?.toFixed(0),
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `inventory_data_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <TableSkeleton />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full">
      <div className="mb-4 h-[42px] min-w-32 flex justify-end">
        <span
          onClick={handleExportCSV}
          className=" p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
        >
          <span className="font-body font-normal text-sm">Export to CSV</span>
          <img width={16} height={16} src="/icons/download-circular-icon.svg" />
        </span>
      </div>

      <Paper sx={{ width: "100%", mb: "2rem" }}>
        <div className="p-3 text-lg font-medium border-b mb-2 flex gap-4">
          <img src="/logos/inventory-page-icon.svg" alt="inventory" /> Inventory Days
        </div>
        <div className="p-4 w-full mb-2">
          <div className="border rounded p-4">
            <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
              <Table stickyHeader aria-label="sticky table" sx={{ overflowX: "auto" }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#F9F9F9" }}>
                    {heading.map((head, index) => (
                      <TableCell
                        key={index}
                        onClick={() => handleSort(head.key)}
                        className="text-left px-6 py-4 gap-[2.75rem] tracking-wider"
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: index == 0 ? "left" : "center",
                          boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: index === 0 && "3",
                          minWidth: "300px",
                          maxWidth: "300px",
                          position: index === 0 && "sticky",
                          left: index === 0 && 0,
                          bgcolor: "#F9F9F9",
                          cursor: "pointer",
                        }}
                      >
                        {head.label}
                        {sortConfig.key === head.key && <span>{sortConfig.direction === "ascending" ? "↑" : "↓"}</span>}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedCategories.map((category, index) => (
                    <React.Fragment key={category}>
                      <TableRow
                        className={`cursor-pointer ${
                          expandedCategories[category] ? "bg-[#F3F8FF]" : index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-white"
                        } `}
                        onClick={() => handleCategoryClick(category)}
                      >
                        <TableCell
                          className="flex justify-between items-center text-left px-3 gap-4 py-4 font-medium font-body"
                          sx={{
                            color: "#4D4D4D",
                            fontWeight: "500",
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            minWidth: "300px",
                            maxWidth: "300px",
                            position: "sticky",
                            left: 0,
                            bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            display: "flex",
                            textAlign: "left",
                          }}
                        >
                          {category}
                          {expandedCategories[category] ? <FaCaretUp /> : <FaCaretDown />}
                        </TableCell>
                        <TableCell
                          className="px-6 py-4 whitespace-nowrap text-left font-normal font-body"
                          sx={{
                            minWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          {groupedByCategory[category].totals.totalInventory}
                        </TableCell>
                        <TableCell
                          className="px-6 py-4 whitespace-nowrap text-left text-[0.7rem] font-normal font-body text-[#727278]"
                          sx={{
                            minWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          {groupedByCategory[category].totals.dailyRunRate.toFixed(2)}
                        </TableCell>
                        <TableCell
                          className="px-6 py-4 whitespace-nowrap text-left text-[0.7rem] font-normal font-body"
                          sx={{
                            minWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          {groupedByCategory[category].totals.inventoryDays.toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3",
                            position: "sticky",
                            bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            right: 0,
                          }}
                        ></TableCell>
                      </TableRow>
                      {expandedCategories[category] &&
                        Object.keys(groupedByCategory[category].subCategories).map((subCategory) => (
                          <React.Fragment key={subCategory}>
                            <TableRow
                              className="cursor-pointer bg-[#F0F0F0] text-[#727278]"
                              onClick={() => handleSubCategoryClick(subCategory)}
                            >
                              <TableCell
                                className="flex justify-between pl-6 items-center px-3 gap-4 py-4 text-[0.75rem] font-medium font-body text-[#727278]"
                                sx={{
                                  color: "#667085",
                                  fontSize: "1rem",
                                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                  minWidth: "300px",
                                  maxWidth: "300px",
                                  position: "sticky",
                                  left: 0,
                                  bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                  display: "flex",
                                  paddingLeft: "2rem",
                                  textAlign: "left",
                                }}
                              >
                                {subCategory}
                                {expandedSubCategories[category]?.[subCategory] ? <FaCaretUp /> : <FaCaretDown />}
                              </TableCell>
                              <TableCell
                                className="px-6 py-4 whitespace-nowrap text-left text-[0.75rem] font-normal font-body"
                                sx={{
                                  color: "#667085",
                                  fontSize: "1rem",
                                  minWidth: "200px",
                                  textAlign: "center",
                                }}
                              >
                                {groupedByCategory[category].subCategories[subCategory].totals.totalInventory}
                              </TableCell>
                              <TableCell
                                className="px-6 py-4 whitespace-nowrap text-left text-[0.75rem] font-normal font-body"
                                sx={{
                                  color: "#667085",
                                  fontSize: "1rem",
                                  minWidth: "200px",
                                  textAlign: "center",
                                }}
                              >
                                {groupedByCategory[category].subCategories[subCategory].totals.dailyRunRate.toFixed(2)}
                              </TableCell>
                              <TableCell
                                className="px-6 py-4 whitespace-nowrap text-left text-[0.75rem] font-normal font-body"
                                sx={{
                                  color: "#667085",
                                  fontSize: "1rem",
                                  minWidth: "200px",
                                  textAlign: "center",
                                }}
                              >
                                {groupedByCategory[category].subCategories[subCategory].totals.inventoryDays.toFixed(2)}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  color: "black",
                                  fontSize: "1rem",
                                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                  zIndex: "3",
                                  position: "sticky",
                                  bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                  right: 0,
                                }}
                              ></TableCell>
                            </TableRow>
                            {expandedSubCategories[subCategory] &&
                              Object.keys(groupedByCategory[category].subCategories[subCategory].productNames).map(
                                (productName, index) => (
                                  <TableRow key={index} className="bg-white cursor-pointer">
                                    <TableCell
                                      className="px-6 py-4 pl-8 text-[0.75rem] font-medium font-body"
                                      sx={{
                                        color: "#667085",
                                        fontSize: "1rem",
                                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                        minWidth: "300px",
                                        maxWidth: "300px",
                                        position: "sticky",
                                        left: 0,
                                        bgcolor: "white",
                                        display: "flex",
                                        paddingLeft: "3rem",
                                        textAlign: "left",
                                      }}
                                    >
                                      {productName}
                                    </TableCell>
                                    <TableCell
                                      className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body"
                                      sx={{
                                        color: "#667085",
                                        fontSize: "1rem",
                                        textAlign: "center",
                                      }}
                                    >
                                      {
                                        groupedByCategory[category].subCategories[subCategory].productNames[productName]
                                          .totalInventory
                                      }
                                    </TableCell>
                                    <TableCell
                                      className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body"
                                      sx={{
                                        color: "#667085",
                                        fontSize: "1rem",
                                        textAlign: "center",
                                      }}
                                    >
                                      {groupedByCategory[category].subCategories[subCategory].productNames[
                                        productName
                                      ].dailyRunRate.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body"
                                      sx={{
                                        color: "#667085",
                                        fontSize: "1rem",
                                        textAlign: "center",
                                      }}
                                    >
                                      {groupedByCategory[category].subCategories[subCategory].productNames[
                                        productName
                                      ].inventoryDays.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        color: "black",
                                        fontSize: "1rem",
                                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                        zIndex: "3",
                                        position: "sticky",
                                        bgcolor: "white",
                                        right: 0,
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                )
                              )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* Add Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Object.keys(groupedByCategory).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default InventoryDaysZeptoTable;
