import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RxCross1 } from "react-icons/rx";
import { RiMenu2Line } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "90%", // Set max height for the modal
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
  overflow: "hidden", // Ensure the modal does not overflow
};

const MapModal = ({ city, pincode, cityData, onClose, open, handleClose }) => {
  const skuData = cityData?.skus || {};
  const getBackgroundColor = (percentage) => {
    if (percentage < 30) return "bg-[#F53E3E1A] text-[#F53E3E]"; // red
    if (percentage >= 30 && percentage <= 70) return "bg-[#FEBE401A] text-[#FEBE40]"; // yellow
    return "bg-[#22C55E1A] text-[#22C55E]"; // green
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* Heading container */}
          <div className="flex justify-between items-center mb-4 bg-white border-b text-black p-4 rounded-t-2xl">
            <h2 className="text-lg font-medium flex items-center gap-2">
              <RiMenu2Line className="text-3xl" />
              City Based Availability - {city}
            </h2>
            <RxCross1 className="text-xl cursor-pointer" onClick={handleClose} />
          </div>

          <div
            className="bg-white p-6 rounded-lg shadow-lg w-full overflow-y-auto"
            style={{ maxHeight: "calc(90vh - 100px)" }}
          >
            <TableContainer sx={{ borderRadius: "0.25rem" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: "#F9F9F9",
                        left: 0,
                        maxWidth: "200px",
                      }}
                    >
                      SKU Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.825rem",
                        textAlign: "center",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Availability Percentage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(skuData).map(([sku, data], index) => {
                    const backgroundColor = getBackgroundColor(data.availabilityPercentage);
                    return (
                      <TableRow key={sku}>
                        <TableCell
                          sx={{
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            position: "sticky",
                            bgcolor: "white",
                            left: 0,
                            maxWidth: "300px",
                            textAlign: "left",
                            color: "#4D4D4D",
                            fontWeight: "500",
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          }}
                        >
                          {sku}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            cursor: "pointer",
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          }}
                          style={{ textAlign: "center" }}
                        >
                          <div className={`inline-block ${backgroundColor} px-2 py-1 rounded-full`}>
                            {Math.ceil(data.availabilityPercentage)}%
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MapModal;
