import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllTargetRequest,
  getAllTargetSuccess,
  getAllTargetFailure,
  getAllTargetDataRequest,
  getAllTargetDataSuccess,
  getAllTargetDataFailure,
} from "../../redux/actions/targetTrackerActions";
import DailyTarget from "../../components/revenue/TargetTrackerComponents/DailyTarget";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  fetchAllTargetSetByUser,
  fetchTargetTrackerDataBlinkit,
  fetchTargetTrackerDataInstamart,
  fetchTargetTrackerDataZepto,
} from "../../services/revenueServices/targetTrackerServices";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

// Options array to show inside ComponentSwitch component
const options = ["Daily Target"];

// Object mapping platform names to their respective data fetch functions
const dataFetchFunctions = {
  Blinkit: fetchTargetTrackerDataBlinkit,
  Zepto: fetchTargetTrackerDataZepto,
  Instamart: fetchTargetTrackerDataInstamart,
};

const TargetTrackerScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDate } = useDate();
  const dispatch = useDispatch();

  // Reset selected option to the first option whenever the platformName changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Fetch user-defined targets with retry logic
  useEffect(() => {
    const fetchTargets = async () => {
      dispatch(getAllTargetRequest());
      await fetchWithRetry(
        fetchAllTargetSetByUser.bind(null, platformName), // Bind platformName to the fetch function
        getAllTargetSuccess,
        getAllTargetFailure,
        selectedDate,
        dispatch
      );
    };

    fetchTargets();
  }, [platformName, selectedOption, selectedDate]);

  // Fetch sales data with retry logic
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAllTargetDataRequest());
      await fetchWithRetry(
        dataFetchFunctions[platformName].bind(null, selectedDate), // Bind selectedDate to the fetch function
        getAllTargetDataSuccess,
        getAllTargetDataFailure,
        selectedDate,
        dispatch
      );
    };

    fetchData();
  }, [platformName, selectedOption, selectedDate]);

  return (
    <>
      {/* HeadingBar for platform selection and date picker */}
      <HeadingBar
        setPlatformName={setPlatformName}
        selectedDate={selectedDate}
        platformName={platformName}
      />
      <Toolbar>
        <div className="w-full mb-10">
          {/* ComponentSwitch to toggle between different tabs */}
          <ComponentSwitch
            options={options}
            targetBy={selectedOption}
            setTargetBy={setSelectedOption}
          />
          <div className="max-w-full h-fit mt-[1rem] rounded-2xl small-shadow">
            {/* Render the DailyTarget component if selectedOption is 0 */}
            {selectedOption === 0 && <DailyTarget platformName={platformName} />}
          </div>
        </div>
      </Toolbar>
    </>
  );
};

export default TargetTrackerScreen;
