import React, { useMemo } from "react";
import { RiAdvertisementLine } from "react-icons/ri";
import CampaignAccordian from "./CampaignAccordian";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const AdCampaigns = ({ groupedDataAd }) => {
  let count = 0;
  return (
    <div className="bg-white shadow-md rounded-xl ">
      <div>
        <h1 className="flex gap-3 items-center text-lg font-medium p-4 border-b">
          <RiAdvertisementLine />
          Campaigns Details
          <CustomTooltip
            title={
              "Explore a comprehensive table view of your campaigns, displaying date-wise performance metrics. Analyze key details to optimize your advertising strategy effectively."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
        <div className="flex p-4 flex-col justify-between gap-4">
          {Object.entries(groupedDataAd)?.map(([campaignTitle, details], index) => {
            count++;

            return <CampaignAccordian key={index} campaignTitle={campaignTitle} details={details} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default AdCampaigns;
