import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sidebarItems from "../../../utils/sidebarItems"; // Adjusted import path
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";

import { useSelector } from "react-redux";


const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userDetail = localStorage.getItem("user");
  const parsedUser = JSON.parse(userDetail);
  const { setSelectedDate } = useDate();
  const { accessControl } = useSelector((state) => state.rbac);
  
  // Get the organizationId from user details
  const organizationId = parsedUser?.organizationDetail?.organizationId;
  const userRole = parsedUser?.roles;

  const getFilteredSidebarItems = (userRole) => {

    if (userRole !== accessControl?.role_name) return sidebarItems;

    return sidebarItems
      .map((section) => ({
        ...section,
        children: section.children.filter((item) => accessControl?.pagesPath?.includes(item.path)),
      }))
      .filter((section) => section.children.length > 0); // Only include sections with children
  };

  const filteredSidebarItems = getFilteredSidebarItems(userRole);

  const navigateHandler = (event, pathname) => {
    event.preventDefault();
    const currentDate = dayjs.utc().startOf("day");
    setSelectedDate(currentDate);
    navigate(pathname);
  };

  if (pathname === "/login") {
    return null;
  }

  return (
    <div
      className={`z-50 fixed top-0 left-0 h-screen overflow-y-scroll bg-white text-[#5E5E63] min-w-[256px] max-w-[256px] mr-4 shadow-[6px_0_6px_-1px_rgba(0,0,0,0.1),_4px_0_4px_-1px_rgba(0,0,0,0.06)] p-6"`}
    >
      <div className="flex p-4 justify-center items-center sticky top-0 left-0 bg-white w-full">
        <div className="flex flex-col mt-6">
          <div className="justify-between">
            <img className="w-40 -mt-4" src={parsedUser?.organizationDetail?.brandLogo} alt="Brand Logo" />
          </div>
          <div className="flex items-center gap-2 mt-3">
            <span className="text-sm text-black font-medium">Powered By</span>
            <img src="/logos/brandLogoBlue.svg" alt="Brand Logo" className="w-6" />
            <span className="text-[#387FF5] font-semibold text-base">Muul</span>
          </div>
        </div>
      </div>

      <div className="p-2">
        {filteredSidebarItems.map((item) => (
          <Fragment key={item.name}>
            <div className="font-semibold uppercase text-[0.625rem] text-black mt-4 py-2 px-6">{item.name}</div>
            {item.children.map((child) => (
              <Link onClick={(event) => navigateHandler(event, child.path)} className="cursor-pointer" key={child.name}>
                <div
                  className={`flex gap-4 items-center py-2 px-6 hover:bg-[#387FF51A] hover:text-[#387FF5] rounded-md mb-2 ${
                    pathname === child.path ? "bg-[#387FF5] text-white" : ""
                  }`}
                >
                  {pathname == child.path ? (
                    <span className="w-4 h-4 text-sm">
                      <img
                        src={`/icons/navbarIcons/${child.icon}-white.svg`}
                        alt="dark-icon"
                        className="h-full w-full"
                      />
                    </span>
                  ) : (
                    <span className="w-4 h-4 text-sm">
                      <img
                        src={`/icons/navbarIcons/${child.icon}-dark.svg`}
                        alt="white-icon"
                        className="h-full w-full"
                      />
                    </span>
                  )}
                  <span>{child.name}</span>
                </div>
              </Link>
            ))}
          </Fragment>
        ))}
      </div>

    </div>
  );
};

export default Sidebar;
