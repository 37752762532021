import {
  GET_RCA_CATEGORY_REQUEST,
  GET_RCA_CATEGORY_SUCCESS,
  GET_RCA_CATEGORY_FAILURE,
  GET_RCA_CITY_REQUEST,
  GET_RCA_CITY_SUCCESS,
  GET_RCA_CITY_FAILURE,
  GET_RCA_SKU_REQUEST,
  GET_RCA_SKU_SUCCESS,
  GET_RCA_SKU_FAILURE,
  SELECTED_RCA_CATEGORY,
  SELECTED_RCA_CITY,
  SELECTED_RCA_SKU,
  FILTERED_RCA_CITY,
  FILTERED_RCA_SKU,
} from "../constants/categoryRcaConstants";

// Category RCA Actions
export const getRCACategoryRequest = () => {
  return {
    type: GET_RCA_CATEGORY_REQUEST,
  };
};

export const getRCACategorySuccess = (categoryData) => {
  return {
    type: GET_RCA_CATEGORY_SUCCESS,
    payload: categoryData,
  };
};

export const getRCACategoryFailure = (error) => {
  return {
    type: GET_RCA_CATEGORY_FAILURE,
    payload: error,
  };
};

// City RCA Actions
export const getRCACityRequest = () => {
  return {
    type: GET_RCA_CITY_REQUEST,
  };
};

export const getRCACitySuccess = (cityData) => {
  return {
    type: GET_RCA_CITY_SUCCESS,
    payload: cityData,
  };
};

export const getRCACityFailure = (error) => {
  return {
    type: GET_RCA_CITY_FAILURE,
    payload: error,
  };
};

// SKU RCA Actions
export const getRCASKURequest = () => {
  return {
    type: GET_RCA_SKU_REQUEST,
  };
};

export const getRCASKUSuccess = (skuData) => {
  return {
    type: GET_RCA_SKU_SUCCESS,
    payload: skuData,
  };
};

export const getRCASKUFailure = (error) => {
  return {
    type: GET_RCA_SKU_FAILURE,
    payload: error,
  };
};

export const setSelectedRcaCategory = (category) => {
  return {
    type: SELECTED_RCA_CATEGORY,
    payload: category,
  };
};

export const setSelectedRcaCity = (city) => {
  return {
    type: SELECTED_RCA_CITY,
    payload: city,
  };
};

export const setSelectedRcaSku = (sku) => {
  return {
    type: SELECTED_RCA_SKU,
    payload: sku,
  };
};

export const getFilteredRcaCity = (city) => {
  return {
    type: FILTERED_RCA_CITY,
    payload: city,
  };
};

export const getFilteredRcaSku = (sku) => {
  return {
    type: FILTERED_RCA_SKU,
    payload: sku,
  };
};
