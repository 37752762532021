import React from "react";
import { Line } from "react-chartjs-2"; // Import Line chart
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from "chart.js";
import { GoInfo } from "react-icons/go";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const ShareOfVoiceOverviewChartCard = ({ title, dates, total, values, percentageChange }) => {
  const shadowPlugin = {
    id: "shadowLine",
    beforeDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save();

      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);

        // Apply shadow only to line elements
        if (meta.type === "line") {
          ctx.shadowColor = "rgba(96, 91, 255, 0.25)"; // Shadow color
          ctx.shadowBlur = 10; // Blur radius for the shadow
          ctx.shadowOffsetX = 0; // No horizontal shadow offset
          ctx.shadowOffsetY = 10; // Vertical shadow only (bottom side)
        }
      });
    },
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.restore(); // Restore the context to avoid affecting other elements
    },
  };

  // Data and options configuration for Chart.js
  const chartData = {
    labels: dates,
    datasets: [
      {
        label: title,
        data: values,
        fill: true, // Enable gradient fill
        backgroundColor: "#F0F6FF",
        borderColor: "#387FF5", // Line color
        tension: 0.4,
        fill: false,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#387FF5",
        pointBorderWidth: 2,
        borderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Adjust the height and width
    elements: {
      line: {
        tension: 0.4, // Smooth curves
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#1E293B", // Tooltip background
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove X-axis grid
        },
        ticks: {
          color: "#333", // X-axis labels color
          font: {
            size: 10, // Adjust font size of the x-axis labels
          
          },
          
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove Y-axis grid
        },
        ticks: {
          callback: (value) => value, // Format Y-axis values
        },
      },
    },
  };

  return (
    <div className="min-w-[22rem]  min-h-[18.5rem] flex flex-col">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-2">
          <span className="text-base font-body leading-5 font-semibold text-[#747380]">{title}</span>
        </div>
      </div>

      <div className=" border border-[#EAEDF3] px-3 py-2 rounded-lg flex-1 flex flex-col">
        <div className="flex justify-between items-center">
          <div>
            <span className="text-xs font-semibold font-body leading-4 text-[#747380]">₹{total}</span>
            <span className="ml-1 text-[10px] text-[#8D8D9D]">for MTD</span>
          </div>

          <div className={`font-semibold text-xs ${percentageChange >= 0 ? "text-[#22C55E]" : "text-red-600"}`}>
            {percentageChange >= 0 ? (
              <span>▲{percentageChange.toFixed(2)}%</span>
            ) : (
              <span>▼{Math.abs(percentageChange).toFixed(2)}%</span>
            )}{" "}
            <span className="font-medium text-[10px] text-[#8D8D9D]">VS Last 7 Days</span>
          </div>
        </div>

        <div className="mt-8 font-nunito  flex-1">
          <Line className="font-nunito" data={chartData} options={chartOptions} plugins={[shadowPlugin]} />
        </div>
      </div>
    </div>
  );
};

export default ShareOfVoiceOverviewChartCard;
