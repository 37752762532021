import React from "react";
import { useSelector } from "react-redux";
import AdsInsights from "./AdsInsights";
import FulfillmentInsights from "./FulfillmentInsights";
import InventoryInsights from "./InventoryInsights";
import ProfitabilityInsights from "./ProfitabilityInsights";
import SalesReportInsights from "./SalesReportInsights";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const InsightsSectionsContainer = ({ platformName, selectedDate }) => {
  const { monthlyProfitability } = useSelector((state) => state.profitability);

  if (monthlyProfitability.loading) {
    return (
      <>
        <ChartSkeleton />
        <ChartSkeleton />
      </>
    );
  }

  // if (!monthlyProfitability.data || monthlyProfitability.data.length == 0) {
  //   return <NoDataFound />;
  // }

  return (
    <div className="w-full mt-10 flex flex-col mb-4">
      <ProfitabilityInsights platformName={platformName} />
      <SalesReportInsights platformName={platformName} />

      <div className="mt-4">
        <AdsInsights platformName={platformName} selectedDate={selectedDate} />
      </div>

      <div className="mt-4">
        <FulfillmentInsights platformName={platformName} selectedDate={selectedDate} />
      </div>

      <div className="mt-4">
        <InventoryInsights platformName={platformName} selectedDate={selectedDate} />
      </div>
    </div>
  );
};

export default InsightsSectionsContainer;
