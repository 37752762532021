// utils/PrivateRoutesWrapper.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoutesWrapper = ({ element: Element, ...rest }) => {
  const { accessControl } = useSelector((state) => state.rbac);
  const isAuthenticated = localStorage.getItem("token") !== null;
  const userDetail = localStorage.getItem("user");
  const parsedUser = JSON.parse(userDetail);
  const organizationId = parsedUser?.organizationDetail?.organizationId;
  const location = useLocation();

  // Get the allowed routes for the organization, or undefined if not listed
  const allowedRoutes = parsedUser?.roles === accessControl?.role_name ;
  
  // If the organizationId is in the map, check access; otherwise allow access to all pages
  const hasAccess = !allowedRoutes || accessControl?.pagesPath?.includes(location.pathname);


  if (!isAuthenticated) {
    localStorage.removeItem("cubeToken");
    localStorage.removeItem("token");
    return <Navigate to="/login" replace />;
  }

  if (!hasAccess) {
    localStorage.removeItem("cubeToken");
    localStorage.removeItem("token");
    return <Navigate to="/login" replace />; // Redirect or show an "Unauthorized" page
  }

  return <Element {...rest} />;
};

export default PrivateRoutesWrapper;