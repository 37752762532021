import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <div className="flex w-screen h-auto bg-bodyBackgroundColor">
      <Sidebar />
      <div
        className="flex flex-col flex-grow h-auto ml-[262px] max-w-[calc(100vw-262px)]
         overflow-x-hidden"
      >
        {pathname !== "/login" && <Navbar />}
        {pathname !== "/login" && <hr />}
        {children}
      </div>
    </div>
  );
};

export default React.memo(Layout);
