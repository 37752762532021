import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { FaAngleDown } from "react-icons/fa6";
import { useSelector } from "react-redux";
import "./css/pricingDetailBarChart.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to group data by SKU and keyword while calculating parentBrand and competitor price details
const groupDataByKeywords = (data, parsedUserData) => {
  let result = {};
  data.forEach((item) => {
    const SKU = item["postgres_third_party_visibility.sku_name"];
    const keyword = item["postgres_third_party_visibility.searchkeyword"];
    const isParentBrand = item["postgres_third_party_visibility.brand"] === parsedUserData?.organizationDetail?.name;
    const price = parseFloat(item["postgres_third_party_visibility.price"]);
    const discountPrice = parseFloat(item["postgres_third_party_visibility.discountprice"]);
    const discountPercent = ((price - discountPrice) / price) * 100;

    // Initializing SKU and keyword in the result object if not already present
    if (!result[SKU]) {
      result[SKU] = {};
    }

    if (!result[SKU][keyword]) {
      result[SKU][keyword] = {
        parentBrand: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
        other: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
      };
    }

    // Accumulating parentBrand brand details or competitor details based on the item data
    if (isParentBrand) {
      result[SKU][keyword].parentBrand.count += 1;
      result[SKU][keyword].parentBrand.price += price;
      result[SKU][keyword].parentBrand.discountPrice += discountPrice;
      result[SKU][keyword].parentBrand.discountPercent += discountPercent;
    } else {
      result[SKU][keyword].other.count += 1;
      result[SKU][keyword].other.price += price;
      result[SKU][keyword].other.discountPrice += discountPrice;
      result[SKU][keyword].other.discountPercent += discountPercent;
    }
  });
  return result;
};

const PricingDetailBarChart = ({ selectedAreaName , selectedSKUs}) => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);

  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);
  // Filtering data based on the selected areaname
  const filteredDataByPincode = thirdPartyVisibility.data?.filter(
    (item) => item["postgres_third_party_visibility.area_name_lm"] == selectedAreaName
  );
  // Grouping data by SKU and keywords
  const data = groupDataByKeywords(filteredDataByPincode, parsedUserData);




  // Filter data based on the selected SKUs
  const filteredData = selectedSKUs.length
    ? Object.keys(data).reduce((acc, sku) => {
        if (selectedSKUs.includes(sku)) {
          acc[sku] = data[sku];
        }
        return acc;
      }, {})
    : data;

  // Preparing chart data for rendering the Bar chart
  const chartData = {
    labels: Object.keys(filteredData),
    datasets: [
      {
        label: "Own Price",
        data: Object.keys(filteredData).map((sku) => {
          const keywords = Object.keys(filteredData[sku]);
          return (
            filteredData[sku][keywords[0]]?.parentBrand?.price / filteredData[sku][keywords[0]]?.parentBrand?.count || 0
          );
        }),
        backgroundColor: "#FEBE40",
        borderRadius: 3,
        barThickness: 24,
      },
      {
        label: "Competitor's Average Price",
        data: Object.keys(filteredData).map((sku) => {
          const keywords = Object.keys(filteredData[sku]);
          return filteredData[sku][keywords[0]]?.other?.price / filteredData[sku][keywords[0]]?.other?.count || 0;
        }),
        backgroundColor: "#387FF5",
        borderRadius: 3,
        barThickness: 24,
      },
    ],
  };

  return (
    <div className="chart-container-pricing-detail  ">

      <div className="" style={{ maxWidth: "100%", height: "500px", overflowX: "scroll" }}>
        <div
          className=" w-full"
          style={{
            minWidth: "100%",
            width: `${Object.keys(filteredData).length * 100}px`,
            height: "100%",
          }}
        >
          <div className="  w-full h-full p-6">
            {selectedSKUs.length ? (
              <Bar
                className="border p-2  rounded-lg"
                height={200} // Increased height valu
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",

                      labels: {
                        usePointStyle: true,
                        pointStyle: "circle",
                        font: {
                          size: 10,
                        },
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => `${context.dataset.label}: ${context.raw.toFixed(2)}`,
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: { display: false },
                      title: {
                        display: true,
                        text: "SKU",
                      },
                      ticks : {
                        font : {
                          size: 8.5
                        },
                      },
                     
                      categoryPercentage: 2,
                      barPercentage: 0.9,

                      
                    },
                    y: {
                      grid: { display: false },
                      title: {
                        display: true,
                        text: "Price",
                      },
                    },
                  },
                }}
              />
            ) : (
              <div className="w-[calc(100vw-280px)] h-full flex justify-center items-center">No SKU Selected</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingDetailBarChart;
