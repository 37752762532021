import {
  MONTHLY_PROFITABILITY_REQUEST,
  MONTHLY_PROFITABILITY_SUCCESS,
  MONTHLY_PROFITABILITY_FAILURE,
  CITY_PROFITABILITY_REQUEST,
  CITY_PROFITABILITY_SUCCESS,
  CITY_PROFITABILITY_FAILURE,
  CATEGORY_PROFITABILITY_REQUEST,
  CATEGORY_PROFITABILITY_SUCCESS,
  CATEGORY_PROFITABILITY_FAILURE,
} from "../constants/profitabilityConstants";

//actions

//monthly
export const getMonthyProfitabilityRequest = () => {
  return {
    type: MONTHLY_PROFITABILITY_REQUEST,
  };
};
export const getMonthyProfitabilitySuccess = (payload) => {
  return {
    type: MONTHLY_PROFITABILITY_SUCCESS,
    payload: payload,
  };
};

export const getMonthyProfitabilityError = (error) => {
  return {
    type: MONTHLY_PROFITABILITY_FAILURE,
    error: error,
  };
};

//city
export const getCityProfitabilityRequest = () => {
  return {
    type: CITY_PROFITABILITY_REQUEST,
  };
};
export const getCityProfitabilitySuccess = (payload) => {
  return {
    type: CITY_PROFITABILITY_SUCCESS,
    payload: payload,
  };
};

export const getCityProfitabilityError = (error) => {
  return {
    type: CITY_PROFITABILITY_FAILURE,
    error: error,
  };
};

//category
export const getCategoryProfitabilityRequest = () => {
  return {
    type: CATEGORY_PROFITABILITY_REQUEST,
  };
};
export const getCategoryProfitabilitySuccess = (payload) => {
  return {
    type: CATEGORY_PROFITABILITY_SUCCESS,
    payload: payload,
  };
};

export const getCategoryProfitabilityError = (error) => {
  return {
    type: CATEGORY_PROFITABILITY_FAILURE,
    error: error,
  };
};
