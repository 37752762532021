import {
  GET_RCA_CATEGORY_REQUEST,
  GET_RCA_CATEGORY_SUCCESS,
  GET_RCA_CATEGORY_FAILURE,
  GET_RCA_CITY_REQUEST,
  GET_RCA_CITY_SUCCESS,
  GET_RCA_CITY_FAILURE,
  GET_RCA_SKU_REQUEST,
  GET_RCA_SKU_SUCCESS,
  GET_RCA_SKU_FAILURE,
  SELECTED_RCA_CATEGORY,
  SELECTED_RCA_CITY,
  SELECTED_RCA_SKU,
  FILTERED_RCA_CITY,
  FILTERED_RCA_SKU,
} from "../constants/categoryRcaConstants";

const initialState = {
  rcaCategoriesData: {
    loading: false,
    data: [],
    error: null,
  },
  rcaCitiesData: {
    loading: false,
    data: [],
    error: null,
  },
  rcaSkusData: {
    loading: false,
    data: [],
    error: null,
  },

  filteredCities: {
    loading: false,
    data: [],
    error: null,
  },

  filteredSkus: {
    loading: false,
    data: [],
    error: null,
  },

  selectedRcaCategory: null,
  selectedRcaCity: null,
  selectedRcaSku: null,
};

export const rcaCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    // Category RCA cases
    case GET_RCA_CATEGORY_REQUEST:
      return {
        ...state,
        rcaCategoriesData: {
          ...state.rcaCategoriesData,
          loading: true,
          error: null,
        },
      };
    case GET_RCA_CATEGORY_SUCCESS:
      return {
        ...state,
        rcaCategoriesData: {
          ...state.rcaCategoriesData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_RCA_CATEGORY_FAILURE:
      return {
        ...state,
        rcaCategoriesData: {
          ...state.rcaCategoriesData,
          loading: false,
          error: action.payload,
        },
      };

    // City RCA cases
    case GET_RCA_CITY_REQUEST:
      return {
        ...state,
        rcaCitiesData: {
          ...state.rcaCitiesData,
          loading: true,
          error: null,
        },
      };
    case GET_RCA_CITY_SUCCESS:
      return {
        ...state,
        rcaCitiesData: {
          ...state.rcaCitiesData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_RCA_CITY_FAILURE:
      return {
        ...state,
        rcaCitiesData: {
          ...state.rcaCitiesData,
          loading: false,
          error: action.payload,
        },
      };

    // SKU RCA cases
    case GET_RCA_SKU_REQUEST:
      return {
        ...state,
        rcaSkusData: {
          ...state.rcaSkusData,
          loading: true,
          error: null,
        },
      };
    case GET_RCA_SKU_SUCCESS:
      return {
        ...state,
        rcaSkusData: {
          ...state.rcaSkusData,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_RCA_SKU_FAILURE:
      return {
        ...state,
        rcaSkusData: {
          ...state.rcaSkusData,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case SELECTED_RCA_CATEGORY:
      return {
        ...state,
        selectedRcaCategory: action.payload,
      };
    case SELECTED_RCA_CITY:
      return {
        ...state,
        selectedRcaCity: action.payload,
      };
    case SELECTED_RCA_SKU:
      return {
        ...state,
        selectedRcaSku: action.payload,
      };

    case FILTERED_RCA_CITY:
      return {
        ...state,
        filteredCities: {
          ...state.filteredCities,
          loading: false,
          data: action.payload,
        },
      };

    case FILTERED_RCA_SKU:
      return {
        ...state,
        filteredSkus: {
          ...state.filteredSkus,
          loading: false,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};
