import * as React from "react";
import Button from "@mui/material/Button";

const PrimaryButton = ({ buttonText, onClick, sx }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={onClick}
      sx={{ textTransform: "none", ...sx }}
    >
      {buttonText}
    </Button>
  );
};

export default PrimaryButton;
