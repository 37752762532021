import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"; // Import useLocation
import { setPlatformMenu } from "../../../redux/actions/platformActions";

const PlatformMenu = ({ setPlatformName }) => {
  const [selectedPlatform, setSelectedPlatform] = useState("Blinkit");
  const dispatch = useDispatch();
  const location = useLocation(); // Get current location

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    setPlatformName(platform);
    dispatch(setPlatformMenu(platform));
  };

  // Check if the current path is '/ads/display-ads'
  const shouldHideInstamart = location.pathname === "/ads/display-ads";

  return (
    <div className="flex items-center bg-backgroundWhite h-11 rounded-lg py-2 text-base font-medium font-poppins text-black">
      <span className="mr-5">Channel :</span>
      <div className="flex gap-4">
        <label htmlFor="blinkit" className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="platform"
            id="blinkit"
            onChange={() => handlePlatformChange("Blinkit")}
            checked={selectedPlatform === "Blinkit"}
          />
          <img src="/logos/blinkit-logo.svg" alt="blinkit-image" />
        </label>
        <label htmlFor="zepto" className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="platform"
            id="zepto"
            onChange={() => handlePlatformChange("Zepto")}
            checked={selectedPlatform === "Zepto"}
          />
          <img src="/logos/zepto-logo.svg" alt="zepto-image" />
        </label>
        {/* Conditionally render the Instamart section */}
        {!shouldHideInstamart && (
          <label htmlFor="instamart" className="flex items-center gap-2 cursor-pointer">
            <input
              type="radio"
              name="platform"
              id="instamart"
              onChange={() => handlePlatformChange("Instamart")}
              checked={selectedPlatform === "Instamart"}
            />
            <img src="/logos/instamart-logo.svg" alt="instamart-image" />
          </label>
        )}
      </div>
    </div>
  );
};

export default PlatformMenu;
