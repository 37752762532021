import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import "./css/CityWiseSales.css";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Papa from "papaparse";
import Dropdown from "../../common/Dropdown/Dropdown";
import { IoPieChart } from "react-icons/io5";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import FilterDropdown from "../../common/Dropdown/FilterDropdown";

// Function to normalize data based on the platform name
const normalizeData = (data, platformName) => {
  const parseOrDefault = (value, defaultValue = 0) => (isNaN(parseFloat(value)) ? defaultValue : parseFloat(value));

  if (platformName === "Zepto") {
    return {
      platform: "zepto",
      category: data["combined_sales_report_zepto.category"] || "Other",
      subCategory: data["combined_sales_report_zepto.sub_category"] || "Other",
      itemName: data["combined_sales_report_zepto.sku_name"] || "Other Item",
      mrp: parseOrDefault(data["combined_sales_report_zepto.gmv"]),
      qtySold: parseOrDefault(data["combined_sales_report_zepto.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_zepto.brands_choice"] || "Other",
    };
  } else if (platformName === "Blinkit") {
    return {
      platform: "blinkit",
      category: data["combined_sales_report_blinkit.category"] || "Other",
      subCategory: data["combined_sales_report_blinkit.sub_category"] || "Other",
      itemName: data["combined_sales_report_blinkit.item_name"] || "Other Item",
      mrp: parseOrDefault(data["combined_sales_report_blinkit.gmv"]),
      qtySold: parseOrDefault(data["combined_sales_report_blinkit.qty_sold"]),
      gstRate: 0.18,
      city: data["combined_sales_report_blinkit.brands_choice"] || "Other",
    };
  } else if (platformName === "Instamart") {
    return {
      platform: "instamart",
      category: data["combined_sales_report_instamart.brand_internal_sku_code"] || "Other",
      subCategory: data["combined_sales_report_instamart.sub_category"] || "Other",
      itemName: data["combined_sales_report_instamart.product_name"] || "Other Item",
      mrp: parseOrDefault(data["combined_sales_report_instamart.gmv"]),
      qtySold: parseOrDefault(data["combined_sales_report_instamart.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_instamart.brands_choice"] || "Other",
    };
  }

  // Handle unsupported platform or missing platformName
  console.warn(`Unsupported platform: ${platformName}`);
  return {
    platform: "unknown",
    category: "Other",
    subCategory: "Other",
    itemName: "Other Item",
    mrp: 0,
    qtySold: 0,
    gstRate: 0.18,
    city: "Other",
  };
};

// Function to group data by city
const groupDataByCity = (data, platformName) => {
  const grouped = {};

  data.forEach((item) => {
    const normalizedItem = normalizeData(item, platformName);
    if (!normalizedItem) return;

    const { city, qtySold, mrp } = normalizedItem;
    if (!city) return; // Ensure 'city' exists

    if (!grouped[city]) {
      grouped[city] = {
        totalQtySold: 0,
        totalMrp: 0,
      };
    }

    // Accumulate totals for each city
    grouped[city].totalQtySold += qtySold;
    grouped[city].totalMrp += mrp;
  });

  return grouped;
};

// Function to calculate overall totals from grouped data
const calculateTotals = (groupedData) => {
  return Object.values(groupedData).reduce(
    (totals, cityData) => {
      totals.totalQtySold += cityData?.totalQtySold;
      totals.totalMrp += cityData?.totalMrp;

      return totals;
    },
    {
      totalQtySold: 0,
      totalMrp: 0,
    }
  );
};

// Function to format numbers with locale-specific formatting
const formatNumber = (number) => {
  return number.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// Main component for displaying city-wise sales data
const CityWiseSalesTable = ({ platformName }) => {
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [categories, setCategories] = useState([]);
  const { totalSales } = useSelector((state) => state.sales);
  const [groupedData123, setGroupedData123] = useState([]);
  const [page, setPage] = useState(0); // Pagination state for current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Pagination state for rows per page
  const [sortColumn, setSortColumn] = useState("city"); // Column to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // Sort order

  const [filteredDataset, setFilteredDataset] = useState([]);
  const [filters, setFilters] = useState({}); // Store applied filters

  useEffect(() => {
    const groupData = () => {
      const result = totalSales.data.reduce((acc, item) => {
        const brand = item["combined_sales_report_blinkit.brands_choice"];
        const gmv = parseFloat(item["combined_sales_report_blinkit.gmv"]);

        if (!acc[brand]) {
          acc[brand] = { brand, totalGmv: 0 };
        }

        acc[brand].totalGmv += gmv;
        return acc;
      }, {});

      setGroupedData123(Object.values(result));
    };

    const timer = setTimeout(groupData, 10000);

    return () => clearTimeout(timer);
  }, [totalSales]);

  useEffect(() => {
    if (totalSales?.data?.length) {
      const categorySet = new Set();
      totalSales.data.forEach((item) => {
        const normalizedItem = normalizeData(item, platformName);
        if (normalizedItem?.category) {
          categorySet.add(normalizedItem.category);
        }
      });
      setCategories(["All Categories", ...Array.from(categorySet)]);
    }
  }, [totalSales]);

  const applyFilters = (groupedData, filters) => {
    const filteredData = Object.entries(groupedData).filter(([city, data]) => {
      let matches = true;

      // City Filter
      if (filters.city && filters.city !== city) {
        matches = false;
      }

      // Quantity Sold Range Filter
      if (filters.qtySold && filters.qtySold.length === 2) {
        const [minQty, maxQty] = filters.qtySold;
        if (data.totalQtySold < minQty || data.totalQtySold > maxQty) {
          matches = false;
        }
      }

      // MRP Range Filter
      if (filters.mrp && filters.mrp.length === 2) {
        const [minMrp, maxMrp] = filters.mrp;
        if (data.totalMrp < minMrp || data.totalMrp > maxMrp) {
          matches = false;
        }
      }

      return matches;
    });

    // Convert filtered data back to an object
    return Object.fromEntries(filteredData);
  };

  const cities = [...new Set(totalSales.data.map((item) => normalizeData(item, platformName)?.city))].filter(Boolean);
  console.log("🚀 ~ CityWiseSalesTable ~ cities:", cities);

  const filteredData = totalSales.data.filter((item) => {
    const normalizedItem = normalizeData(item, platformName);
    return selectedCategory === "All Categories" || normalizedItem?.category === selectedCategory;
  });
  console.log("🚀 ~ filteredData ~ filteredData:", filteredData);

  const groupedData = React.useMemo(() => groupDataByCity(filteredData, platformName), [filteredData, platformName]);

  console.log("🚀 ~ CityWiseSalesTable ~ groupedData:", groupedData);
  const totals = calculateTotals(groupedData);
  console.log("🚀 ~ CityWiseSalesTable ~ totals:", totals);

  const filteredResult = React.useMemo(() => applyFilters(groupedData, filters), [groupedData, filters]);

  useEffect(() => {
    if (filteredDataset !== filteredResult) {
      setFilteredDataset(filteredResult);
    }
  }, [filteredResult, filteredDataset]);
  


  // Compute min and max values for totalQtySold and totalMrp
  const computeMinMax = (groupedData) => {
    const values = Object.values(groupedData);

    const minMaxValues = {
      minQtySold: Math.min(...values.map((item) => item.totalQtySold)),
      maxQtySold: Math.max(...values.map((item) => item.totalQtySold)),
      minMrp: Math.min(...values.map((item) => item.totalMrp)),
      maxMrp: Math.max(...values.map((item) => item.totalMrp)),
    };

    return minMaxValues;
  };

  const minMaxValues = computeMinMax(groupedData);
  console.log("🚀 ~ MinMax Values:", minMaxValues);

  const filterOptions = [
    { field: "city", heading: "Select city", type: "select", options: cities },
    {
      field: "qtySold",
      heading: "Select quantity range",
      type: "range",
      min: minMaxValues.minQtySold,
      max: minMaxValues.maxQtySold,
    },
    { field: "mrp", heading: "select mrp range", type: "range", min: minMaxValues.minMrp, max: minMaxValues.maxMrp },
  ];

  console.log("filterOptions====", filterOptions);

  // Sorting Logic
  const handleSort = (column) => {
    const newOrder = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const sortedCities = React.useMemo(() => {
    return Object.keys(groupedData).sort((a, b) => {
      let aValue, bValue;
  
      if (sortColumn === "city") {
        aValue = a;
        bValue = b;
      } else if (sortColumn === "totalQtySold") {
        aValue = groupedData[a]?.totalQtySold || 0;
        bValue = groupedData[b]?.totalQtySold || 0;
      } else if (sortColumn === "totalMrp") {
        aValue = groupedData[a]?.totalMrp || 0;
        bValue = groupedData[b]?.totalMrp || 0;
      }
  
      return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
    });
  }, [groupedData, sortColumn, sortOrder]);
  
  console.log("🚀 ~ sortedCities ~ sortedCities:", sortedCities);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedCities = Object.keys(filteredDataset).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  console.log("🚀 ~ CityWiseSalesTable ~ paginatedCities:", paginatedCities);

  // CSV Export Function
  const handleExportCSV = () => {
    // Prepare the data for CSV
    const csvData = Object.keys(groupedData).map((city) => ({
      City: city,
      "Total Quantity Sold": groupedData[city].totalQtySold,
      "Total MRP": groupedData[city].totalMrp,
    }));

    const csv = Papa.unparse(csvData); // Convert data to CSV format
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `city_wise_sales_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!totalSales?.data?.length) {
    return <TableSkeleton />;
  }

  return (
    <div>
      <div className="flex sticky-container min-w-[100%] justify-end gap-4 items-center my-8">
        <div>
          {/* filter by category */}
          <Dropdown options={[...categories]} value={selectedCategory} onChange={setSelectedCategory} />
        </div>
        <div className="w-auto min-w-32 h-[42px]  flex justify-end">
          <span
            onClick={handleExportCSV}
            className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
          >
            <span className="font-body font-normal text-sm">Export to CSV</span>
            <img width={16} height={16} src="/icons/download-circular-icon.svg" />
          </span>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-xl max-w-full">
        <div>
          <h1 className="flex   items-center justify-between text-lg font-medium px-4 py-2 border-b leading-[23.44px]">
            <div className="flex gap-3 items-center text-lg font-medium  leading-[23.44px]">
              <div>
                {/* Icon for City Wise Sales */}
                <IoPieChart />
              </div>
              City Wise Sales
              <CustomTooltip
                title={
                  "Shows total sales across different cities, allowing you to analyze sales distribution by location."
                }
              >
                <PiInfoLight />
              </CustomTooltip>
            </div>

            <FilterDropdown filters={filterOptions} onApply={setFilters} />
          </h1>
        </div>

        <div className="px-4 pt-4">
          <div className="border p-4 rounded-md">
            <TableContainer className="max-w-full overflow-x-scroll" sx={{ borderRadius: "0.25rem" }}>
              <Table stickyHeader aria-label="sticky table" sx={{ overflowX: "auto" }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#F9F9F9" }}>
                    <TableCell
                      onClick={() => handleSort("city")}
                      sx={{
                        width: "300px",
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        cursor: "pointer",
                        position: "sticky",
                        left: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      City
                      {sortColumn === "city" && (sortOrder === "asc" ? "↑" : "↓")}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("totalQtySold")}
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        cursor: "pointer",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Total Quantity Sold
                      {sortColumn === "totalQtySold" && (sortOrder === "asc" ? "↑" : "↓")}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("totalMrp")}
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        cursor: "pointer",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Total MRP
                      {sortColumn === "totalMrp" && (sortOrder === "asc" ? "↑" : "↓")}
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCities.map((city, index) => {
                    const cityData = groupedData[city] || { totalQtySold: 0, totalMrp: 0 };
                    return (
                      <TableRow key={city} className={`${index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-white"}`}>
                        <TableCell
                          sx={{
                            width: "200px",
                            color: "#4D4D4D",
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#FFF",
                            position: "sticky",
                            left: 0,
                            zIndex: "3",
                            fontWeight: "500",
                          }}
                        >
                          {city}
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">{formatNumber(cityData.totalQtySold)}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">{formatNumber(cityData.totalMrp)}</div>
                        </TableCell>
                        <TableCell
                          sx={{
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3",
                            position: "sticky",
                            right: 0,
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#FFF",
                          }}
                        ></TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow sx={{ bgcolor: "#D0E3FF", fontWeight: "500" }}>
                    <TableCell
                      sx={{
                        width: "250px",
                        color: "#323C47",
                        fontSize: "1rem",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        position: "sticky",
                        left: 0,
                        zIndex: "3",
                        bgcolor: "#D0E3FF",
                        fontWeight: "500",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell align="right">
                      <div className="text-center text-[#323C47] font-medium">{formatNumber(totals?.totalQtySold)}</div>
                    </TableCell>
                    <TableCell align="right">
                      <div className="text-center text-[#323C47] font-medium">{formatNumber(totals?.totalMrp)}</div>
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#D0E3FF",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* Pagination Component */}
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={Object.keys(groupedData).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default CityWiseSalesTable;

// Use Cases
// --CityWiseSalesTable Component

// View Sales Data by City: Displays sales metrics (total quantity sold and MRP) grouped by city.
// Filter Sales Data by Category: Allows filtering the sales data based on selected categories.
// Update Data with Delay: Processes and updates sales data with a delay for recent changes.
// Adapt to Different Platforms: Handles sales data for various platforms like Zepto, Blinkit, and Instamart.
// Show Loading State: Displays a loading skeleton when data is being fetched or processed.
