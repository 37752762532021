import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Dropdown from "../../common/Dropdown/Dropdown";

const heading = ["Category", "Total Inventory", "Daily Run Rate", "Inventory Days"];

const CityWiseDOHInstamartTable = () => {
  const { inventoryReportInstamart } = useSelector((state) => state.sales);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [selectedBrandChoice, setSelectedBrandChoice] = useState(""); // Initial selected brand choice
  const { loading, data } = inventoryReportInstamart;
  const groupedData = {};

  function consolidateData(data) {
    const consolidated = {};

    data.forEach((entry) => {
      const city = entry["combined_inventory_data_instamart.brands_choice"];
      const skuCode = entry["combined_inventory_data_instamart.brand_internal_sku_code"];
      const dailyRunRate = parseFloat(entry["combined_sales_report_instamart.daily_run_rate"]);
      const totalFrontBackInv = parseInt(entry["combined_inventory_data_instamart.wh_soh"], 10);
      // Create a unique key based on city and skuCode
      const key = `${city}-${skuCode}`;

      if (!consolidated[key]) {
        consolidated[key] = {
          ...entry, // Spread all other fields from the first entry for the city and sku
          "combined_sales_report_instamart.daily_run_rate": 0, // Initialize for calculation

          totalFrontBackInv: 0,
          count: 0,
        };
      }

      consolidated[key]["combined_sales_report_instamart.daily_run_rate"] += dailyRunRate;

      consolidated[key].totalFrontBackInv += totalFrontBackInv;
      consolidated[key].count += 1;
    });

    return Object.values(consolidated)
      .map((entry) => ({
        ...entry, // Spread all other fields
        "combined_sales_report_instamart.daily_run_rate":
          entry["combined_sales_report_instamart.daily_run_rate"] / entry.count,
        totalInv: entry.totalFrontBackInv,
      }))
      .sort((a, b) => {
        const skuA = a["combined_inventory_data_instamart.brand_internal_sku_code"] || "";
        const skuB = b["combined_inventory_data_instamart.brand_internal_sku_code"] || "";
        return skuA.localeCompare(skuB);
      });
  }

  function aggregateBySkuCode(consolidatedData) {
    const aggregatedData = {};

    consolidatedData.forEach((entry) => {
      const skuCode = entry["combined_inventory_data_instamart.brand_internal_sku_code"];

      if (!aggregatedData[skuCode]) {
        aggregatedData[skuCode] = {
          ...entry, // Initialize with the first entry's data
          totalInv: 0,
          totalDailyRunRate: 0,
          count: 0,
        };
      }

      // Sum up the frontend and backend inventory, total inventory, and daily run rate

      aggregatedData[skuCode].totalInv += entry.totalInv;
      aggregatedData[skuCode].totalDailyRunRate += entry["combined_sales_report_instamart.daily_run_rate"];
      aggregatedData[skuCode].count += 1;
    });

    // After grouping, calculate the average daily run rate, inventory days, and finalize other fields
    return Object.values(aggregatedData).map((entry) => {
      const averageDailyRunRate = entry.totalDailyRunRate / entry.count;
      const totalInv = entry.totalInv;
      const inventoryDays = totalInv / averageDailyRunRate;

      return {
        ...entry,
        "combined_sales_report_instamart.daily_run_rate": averageDailyRunRate,
        totalInv, // Recalculate totalInv to ensure it's correct
        inventoryDays: inventoryDays || 0, // Prevent division by zero, set inventoryDays to 0 if averageDailyRunRate is 0
      };
    });
  }
  // Example usage:
  const consolidatedData = consolidateData(data); // Assuming this is the result from your consolidateData function
  const aggregatedData = aggregateBySkuCode(consolidatedData);

  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleSubCategoryClick = (subCategory) => {
    setExpandedSubCategories((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory],
    }));
  };

  const filteredData = !selectedBrandChoice
    ? aggregatedData
    : aggregatedData.filter((item) => item["combined_inventory_data_instamart.brands_choice"] === selectedBrandChoice);

  const groupedByCategory = filteredData.reduce((acc, item) => {
    const category = item["combined_inventory_data_instamart.category"];
    const subCategory = item["combined_inventory_data_instamart.sub_category"];
    const productName = item["combined_inventory_data_instamart.product_name"];

    // Initialize category if not present
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if not present
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        brandChoices: [],
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize productName if not present
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        wh_soh: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update product totals
    acc[category].subCategories[subCategory].productNames[productName].wh_soh += parseInt(
      item["combined_inventory_data_instamart.wh_soh"],
      10
    );
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate += item.dailyRunRate;
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].brandChoices.push(item);
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInventory;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum += item.dailyRunRate; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.totalInventory += item.totalInventory;
    acc[category].totals.dailyRunRateSum += item.dailyRunRate; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategory).forEach((category) => {
    const categoryData = groupedByCategory[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate =
      categoryData.totals.productCount > 0 ? categoryData.totals.dailyRunRateSum / categoryData.totals.productCount : 0;

    categoryData.totals.inventoryDays =
      categoryData.totals.productCount > 0
        ? categoryData.totals.inventoryDaysSum / categoryData.totals.productCount
        : 0;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.productCount > 0
          ? subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount
          : 0;

      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.productCount > 0
          ? subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount
          : 0;
    });
  });

  const handleBrandChoiceChange = (event) => {
    setSelectedBrandChoice(event.target.value);
  };

  const uniqueCities = [...new Set(data.map((item) => item["combined_inventory_data_instamart.brands_choice"]))];
  const filteredUniqueCities = uniqueCities.filter((choice) => choice !== null && choice !== undefined);

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div>
      <div className=" py-4 flex items-center gap-4 justify-end">
        <Dropdown
          options={[...filteredUniqueCities]}
          value={selectedBrandChoice}
          onChange={setSelectedBrandChoice}
          initalTitle={"Select City"}
        />

        {/* <div className="w-auto min-w-32 h-[42px]  flex justify-end">
          <span
            onClick={handleExportCSV}
            className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
          >
            <span className="font-body font-normal text-sm">Export to CSV</span>
            <img width={16} height={16} src="/icons/download-circular-icon.svg" />
          </span>
        </div> */}
      </div>
      <Paper sx={{ width: "100%", mb: "2rem" }}>
        <div className="p-3 text-lg font-medium border-b mb-2 flex gap-4">
          <img src="/logos/inventory-page-icon.svg" alt="inventory" />
          Days on Hand
        </div>
        <div className="p-4 w-full mb-2">
          <div className="border rounded p-4">
            <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
              <Table stickyHeader aria-label="sticky table" sx={{ overflowX: "auto" }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#F9F9F9" }}>
                    {heading.map((head, index) => (
                      <TableCell
                        key={index}
                        className="px-6 py-3 font-medium tracking-wider"
                        sx={{
                          fontWeight: "500",
                          color: "#4d4d4d",
                          fontSize: "0.875rem",
                          textAlign: index == 0 ? "left" : "center",
                          boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: index === 0 && "3",
                          minWidth: "300px",
                          maxWidth: "300px",
                          position: index === 0 && "sticky",
                          left: index === 0 && 0,
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        {head}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(groupedByCategory).length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} className="text-center py-6">
                        <div className="flex justify-center items-center text-gray-500 text-lg">
                          No data available for this city .
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    Object.keys(groupedByCategory).map((category, index) => (
                      <React.Fragment key={category}>
                        <TableRow
                          className={`cursor-pointer ${
                            expandedCategories[category]
                              ? "bg-[#E5E5E5]"
                              : index % 2 !== 0
                              ? "bg-[#F0F6FF]"
                              : "bg-white"
                          } `}
                          onClick={() => handleCategoryClick(category)}
                        >
                          <TableCell
                            className="flex justify-between items-center text-left px-3 gap-4 py- font-medium font-body"
                            sx={{
                              color: "#4d4d4d",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              minWidth: "300px",
                              maxWidth: "300px",
                              position: "sticky",
                              left: 0,
                              bgcolor: expandedCategories[category] ? "#E5E5E5" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                              display: "flex",
                            }}
                          >
                            {category}
                            {expandedCategories[category] ? <FaCaretUp /> : <FaCaretDown />}
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 whitespace-nowrap font-normal font-body"
                            sx={{
                              textAlign: "center",
                              minWidth: "200px",
                            }}
                          >
                            {groupedByCategory[category].totals.totalInventory}
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 whitespace-nowrap font-normal font-body"
                            sx={{
                              textAlign: "center",
                              minWidth: "200px",
                            }}
                          >
                            {groupedByCategory[category].totals.dailyRunRate.toFixed(2)}
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 whitespace-nowrap font-normal font-body"
                            sx={{
                              textAlign: "center",
                              minWidth: "200px",
                            }}
                          >
                            {groupedByCategory[category].totals.inventoryDays.toFixed(2)}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3",
                              position: "sticky",
                              bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                              right: 0,
                            }}
                          ></TableCell>
                        </TableRow>
                        {expandedCategories[category] &&
                          Object.keys(groupedByCategory[category].subCategories).map((subCategory) => (
                            <React.Fragment key={subCategory}>
                              <TableRow
                                className="cursor-pointer bg-[#F0F0F0] text-[#727278]"
                                onClick={() => handleSubCategoryClick(subCategory)}
                              >
                                <TableCell
                                  className="flex justify-between pl-6 items-center  px-3 gap-4 py-4 font-medium font-body"
                                  sx={{
                                    color: "#4d4d4d",
                                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    minWidth: "300px",
                                    minWidth: "300px",
                                    position: "sticky",
                                    left: 0,
                                    bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                    display: "flex",
                                    paddingLeft: "2rem",
                                    textAlign: "left",
                                  }}
                                >
                                  {subCategory}
                                  {expandedCategories[category] ? <FaCaretUp /> : <FaCaretDown />}
                                </TableCell>
                                <TableCell
                                  className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                  sx={{
                                    textAlign: "center",
                                    minWidth: "200px",
                                  }}
                                >
                                  {groupedByCategory[category].subCategories[subCategory].totals.totalInventory}
                                </TableCell>
                                <TableCell
                                  className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                  sx={{
                                    textAlign: "center",
                                    minWidth: "200px",
                                  }}
                                >
                                  {groupedByCategory[category].subCategories[subCategory].totals.dailyRunRate.toFixed(
                                    2
                                  )}
                                </TableCell>
                                <TableCell
                                  className="px-6 py-4 whitespace-nowrap font-normal font-body"
                                  sx={{
                                    textAlign: "center",
                                    minWidth: "200px",
                                  }}
                                >
                                  {groupedByCategory[category].subCategories[subCategory].totals.inventoryDays.toFixed(
                                    2
                                  )}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    zIndex: "3",
                                    position: "sticky",
                                    bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                    right: 0,
                                  }}
                                ></TableCell>
                              </TableRow>
                              {expandedSubCategories[subCategory] &&
                                Object.keys(groupedByCategory[category].subCategories[subCategory].productNames).map(
                                  (productName, index) => (
                                    <TableRow key={index} className="bg-white cursor-pointer">
                                      <TableCell
                                        className="px-6 py-4 pl-8 text-[0.75rem] font-medium font-body"
                                        sx={{
                                          color: "#667085",
                                          fontSize: "1rem",
                                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                          minWidth: "300px",
                                          maxWidth: "300px",
                                          position: "sticky",
                                          left: 0,
                                          bgcolor: "white",
                                          display: "flex",
                                          paddingLeft: "3rem",
                                        }}
                                      >
                                        {productName}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body">
                                        {
                                          groupedByCategory[category].subCategories[subCategory].productNames[
                                            productName
                                          ].wh_soh
                                        }
                                      </TableCell>
                                      <TableCell className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body">
                                        {groupedByCategory[category].subCategories[subCategory].productNames[
                                          productName
                                        ].dailyRunRate.toFixed(2)}
                                      </TableCell>
                                      <TableCell className="px-6 py-4 whitespace-nowrap text-[0.75rem] font-normal font-body">
                                        {groupedByCategory[category].subCategories[subCategory].productNames[
                                          productName
                                        ].inventoryDays.toFixed(2)}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        sx={{
                                          color: "black",
                                          fontSize: "1rem",
                                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                          zIndex: "3",
                                          position: "sticky",
                                          bgcolor: "white",
                                          right: 0,
                                        }}
                                      ></TableCell>
                                    </TableRow>
                                  )
                                )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CityWiseDOHInstamartTable;
