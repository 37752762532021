import { SET_PLATFORM_MENU } from "../constants/platformMenuConstants";

const initialState = {
  platformName: "Blinkit",
};

export const platformReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLATFORM_MENU:
      return {
        ...state,
        platformName: action.payload,
      };

    default:
      return state;
  }
};
