import React from "react";
import { useSelector } from "react-redux";
import TopTenCompaignBySpend from "./TopTenCompaignBySpend";
import AdCampaigns from "./AdCampaigns";
import TopTenKeywordsByBudget from "./TopTenKeywordsByBudget";
import {
  groupedDataByCampaignNameInstamart,
  groupedDataByCampaignName,
  groupedDataByDateInstamart,
  groupedDataByKeywordsInstamart,
} from "../../../utils/dataGrouping/instamartMaketingReportGrouping";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";

const MarketingReportSectionsContainer = () => {
  const { instamartReport } = useSelector((state) => state.marketing);
  const { loading, data, error } = instamartReport;

  if (loading) {
    return (
      <div>
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>

        <div className="bg-white rounded-xl">
          <ChartSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }

  if (error) {
    return <NoDataFound />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedData = groupedDataByCampaignNameInstamart(instamartReport.data);

  const grouped10Keywords = groupedDataByKeywordsInstamart(instamartReport.data);
  console.log("grouped10Keywords=====", grouped10Keywords)
  // chart grouping
  const groupedProductsListingChartData = groupedDataByDateInstamart(instamartReport.data);
  // table grouping
  const groupedByCampaign = groupedDataByCampaignName(instamartReport.data);
  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      <div>
        <div>
          <TopTenCompaignBySpend groupedData={groupedData} />
        </div>
        <div className="mt-4">
          <TopTenKeywordsByBudget groupedData={grouped10Keywords} />
        </div>
        <div className="mt-4">
          <AdCampaigns groupedDataAd={groupedByCampaign} />
        </div>
      </div>
    </div>
  );
};

export default MarketingReportSectionsContainer;
