import {
  blinkitDailyTargetsQuery,
  instamartDailyTargetsQuery,
  zeptoDailyTargetsQuery,
} from "../../utils/queries/targetTrackerQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";
import { BASE_URL } from "../../utils/assets";

export const fetchTargetTrackerDataBlinkit = async (selectedDate) => {
  console.log("selectedDate==target=", selectedDate)
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitDailyTargetsQuery(selectedDate));
  return result.tablePivot();
};

export const fetchTargetTrackerDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartDailyTargetsQuery(selectedDate));
  return result.tablePivot();
};

export const fetchTargetTrackerDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoDailyTargetsQuery(selectedDate));
  return result.tablePivot();
};

export const fetchAllTargetSetByUser = async (platformName) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const url = `${BASE_URL}/getTargets/${user.id}`;
  const response = await fetch(url);
  const data = await response.json();
  const filteredTargetsByPlatform = data.data
    .filter((item) => item.platform === platformName)
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  return filteredTargetsByPlatform;
};
