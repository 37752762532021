import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
} from "@mui/material";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Papa from "papaparse";

const UnderStockedTableInstamart = ({ platformName }) => {
  const { stockingInsightInstamart } = useSelector((state) => state.stocking);

  const { loading, data } = stockingInsightInstamart;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("inventoryDays");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dataWithTotalInventory = data.map((item) => ({
    ...item,
    totalInventory: parseInt(item["combined_inventory_data_instamart.wh_soh"], 10),
  }));

  const groupedData = {};
  dataWithTotalInventory.forEach((item) => {
    const key = `${item["combined_inventory_data_instamart.wh_name"]}_${item["combined_inventory_data_instamart.brand_internal_sku_code"]}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const newModifiedData = [];
  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];

    group.sort(
      (a, b) =>
        new Date(a["combined_inventory_data_instamart.dt"]) - new Date(b["combined_inventory_data_instamart.dt"])
    );

    const earliestEntry = group[0];
    const latestEntry = group[group.length - 1];

    const earliestEntryDate = new Date(earliestEntry["combined_inventory_data_instamart.dt"]);
    const latestEntryDate = new Date(latestEntry["combined_inventory_data_instamart.dt"]);

    let diffInTime = latestEntryDate.getTime() - earliestEntryDate.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

    let dailyRunRate = (earliestEntry.totalInventory - latestEntry.totalInventory) / diffInDays;
    if (isNaN(dailyRunRate)) {
      dailyRunRate = 0;
    }

    let inventoryDays = 0;
    if (dailyRunRate > 0) {
      inventoryDays = latestEntry.totalInventory / dailyRunRate;
    }

    newModifiedData.push({
      ...earliestEntry,
      dailyRunRate,
      inventoryDays,
    });
  });

  const filteredData = newModifiedData.filter((item) => item.inventoryDays > 0 && item.inventoryDays <= 10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = filteredData.sort((a, b) => {
    if (orderBy === "dailyRunRate" || orderBy === "inventoryDays") {
      return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else {
      const aValue = a[orderBy] || ""; // Default to empty string if undefined
      const bValue = b[orderBy] || ""; // Default to empty string if undefined
      return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
  });

  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = sortedData.map((entry) => ({
      "Instamart SKU Name": entry["combined_inventory_data_instamart.item_code"],
      City: entry["combined_inventory_data_instamart.brands_choice"],
      "Facility Name": entry["combined_inventory_data_instamart.wh_name"],
      "Item Code": entry["combined_inventory_data_instamart.product_name"],
      "Daily Run Rate": entry.dailyRunRate?.toFixed(2),
      "Total Inventory": entry.totalInventory,
      "Inventory Days": entry.inventoryDays?.toFixed(0),
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `currently_out_of_stock_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full">
      <div className="mb-4 h-[42px] min-w-32 flex justify-end">
        <span
          onClick={handleExportCSV}
          className=" p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
        >
          <span className="font-body font-normal text-sm">Export to CSV</span>
          <img width={16} height={16} src="/icons/download-circular-icon.svg" />
        </span>
      </div>
      <Paper sx={{ width: "100%", mb: "2rem" }}>
        <>
          <div className="border-b p-4 text-lg font-medium flex gap-4 items-center">
            <img src="/logos/inventory-page-icon.svg" alt="stocking" />
            Understocking
          </div>
          <div className="p-4">
            <div className="border rounded p-2">
              <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
                <Table stickyHeader aria-label="sticky table" sx={{ overflowX: "auto" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sortDirection={orderBy === "productName" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "left",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          minWidth: "200px",
                          position: "sticky",
                          left: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "productName"}
                          direction={orderBy === "productName" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "productName")}
                        >
                          Instamart SKU Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={orderBy === "city" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "city"}
                          direction={orderBy === "city" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "city")}
                        >
                          City
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        sortDirection={orderBy === "city" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "city"}
                          direction={orderBy === "city" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "city")}
                        >
                          Facility Name
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        sortDirection={orderBy === "itemCode" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "itemCode"}
                          direction={orderBy === "itemCode" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "itemCode")}
                        >
                          Item Code
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={orderBy === "dailyRunRate" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "dailyRunRate"}
                          direction={orderBy === "dailyRunRate" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "dailyRunRate")}
                        >
                          Daily Run Rate
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={orderBy === "totalInventory" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "totalInventory"}
                          direction={orderBy === "totalInventory" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "totalInventory")}
                        >
                          Total Inventory
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={orderBy === "inventoryDays" ? order : false}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "inventoryDays"}
                          direction={orderBy === "inventoryDays" ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, "inventoryDays")}
                        >
                          Inventory Days
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((item, index) => (
                        <TableRow key={index} className={` ${index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-[#fff]"}`}>
                          <TableCell
                            sx={{
                              color: "#4d4d4d",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              minWidth: "200px",
                              position: "sticky",
                              left: 0,
                              bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                              textAlign: "left",
                            }}
                          >
                            {item["combined_inventory_data_instamart.product_name"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item["combined_inventory_data_instamart.brands_choice"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item["combined_inventory_data_instamart.wh_name"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {item["combined_inventory_data_instamart.item_code"]}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{item.dailyRunRate}</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{item.totalInventory}</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>{item.inventoryDays}</TableCell>
                          <TableCell
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3",
                              position: "sticky",
                              right: 0,
                              bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                          ></TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center text-[0.75rem] text-[#727278]">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </div>
  );
};

export default UnderStockedTableInstamart;
