import React from "react";
import { useSelector } from "react-redux";
import {
  groupByDateSKUPerformance,
  groupBySKUPerformanceTable,
  groupedDataByProductNameSKUPerformance,
} from "../../../utils/dataGrouping/skuPerformanceZeptoGrouping";
import { AiFillFunnelPlot } from "react-icons/ai";
import AdsFunnel from "./AdsFunnel";
import AdCampaigns from "./AdCampaigns";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const SkuPerformanceZeptoSectionsContainer = () => {
  const { skuPerformanceZepto } = useSelector((state) => state.marketing);
  const { loading, data, error } = skuPerformanceZepto;

  if (loading) {
    return (
      <div className="w-full">
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>
        <div className="bg-white rounded-xl">
          <ChartSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }

  if (error) {
    return <NoDataFound />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedData = groupedDataByProductNameSKUPerformance(skuPerformanceZepto.data);
  const valuesArray = Object.values(groupedData);

  // Sort the array in descending order based on the budget
  const sortedArray = valuesArray.sort((a, b) => b.spend - a.spend);

  // Get the top 10 objects with the highest budget
  const top10 = sortedArray.slice(0, 10);
  const skuDateData = groupByDateSKUPerformance(skuPerformanceZepto.data);

  const tableData = groupBySKUPerformanceTable(skuPerformanceZepto.data);
  console.log("tableData sku performance===", tableData);

  return (
    <div className="w-full">
      <div className="bg-white shadow-md rounded-xl">
        <h1 className="flex gap-3 items-center text-lg font-medium p-4 border-b mb-2">
          <AiFillFunnelPlot />
          Top 10 Products By Spend
          <CustomTooltip title={" Displays the top 10 products based on ad spend, helping you assess SKU performance."}>
            <PiInfoLight />
          </CustomTooltip>
        </h1>
        <div className="flex justify-evenly flex-wrap gap-4 p-2">
          {top10.map((item, index) => (
            <AdsFunnel obj={item} key={index} />
          ))}
        </div>
      </div>
      <div className="mt-4">
        <AdCampaigns groupedDataAd={tableData} />
      </div>
    </div>
  );
};

export default SkuPerformanceZeptoSectionsContainer;
