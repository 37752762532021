import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { BsCalendar2Month } from "react-icons/bs";
import { PiInfoLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import {
  monthlyProfitabilityGroupingBlinkit,
  monthlyProfitabilityGroupingInstamart,
  monthlyProfitabilityGroupingZepto,
} from "../../../utils/dataGrouping/OverallProfitability/MonthlyProfitabilityGrouping";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";

const title =
  "This table shows key profitability metrics for 6 months, including total sales with GST, GST amount, net sales (excluding GST), cost of goods sold (COGS), and CM1. Analyze these to understand month-wise profitability and performance";

const MonthlyProfitability = ({ headings, platformName }) => {
  const { monthlyProfitability } = useSelector((state) => state.profitability);
  const tableData = monthlyProfitability.data;

  let groupedData;
  //set grouped data according to platform name
  if (platformName == "Blinkit") {
    groupedData = monthlyProfitabilityGroupingBlinkit(tableData);
  } else if (platformName == "Instamart") {
    groupedData = monthlyProfitabilityGroupingInstamart(tableData);
  } else {
    groupedData = monthlyProfitabilityGroupingZepto(tableData);
  }

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        marginTop: "2rem",
      }}
    >
      <>
        <div className="flex text-xl items-center gap-4 p-4 font-bold border-b">
          <BsCalendar2Month />
          Monthly Profitability
          <CustomTooltip title={title}>
            <PiInfoLight />
          </CustomTooltip>
        </div>
        <div className="p-2">
          <TableContainer sx={{ borderRadius: "0.25rem" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headings.map((heading, index) => (
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: index == 0 ? "left" : "center",
                        minWidth: "200px",
                        boxShadow: index == 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: index == 0 && "3",
                        position: index == 0 && "sticky",
                        left: index === 0 && 0,
                        bgcolor: "#F9F9F9",
                      }}
                      key={heading}
                    >
                      {heading}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3",
                      position: "sticky",
                      right: 0,
                      bgcolor: "#F9F9F9",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedData?.map((row, idx) => (
                  <TableRow key={idx} sx={{ bgcolor: idx % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                    <TableCell
                      sx={{
                        color: "#4D4D4D",
                        fontWeight: "500",
                        minWidth: "200px",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        position: "sticky",
                        left: 0,
                        bgcolor: idx % 2 !== 0 ? "#F0F6FF" : "#fff",
                      }}
                    >
                      {row.month}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.mrp.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.gst.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.netSales.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.cogs.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "200px",
                        textAlign: "center",
                      }}
                    >
                      {row.cm1.toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3", // Left shadow for this cell,
                        position: "sticky",
                        bgcolor: idx % 2 !== 0 ? "#F0F6FF" : "#fff",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    </Paper>
  );
};

export default MonthlyProfitability;
