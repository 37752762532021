import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDateRange } from "../../../hooks/CustomDateRange/CustomDateRangePickerProvider";

export default function CustomDateRangePicker() {
  const { selectedDateRange, setSelectedDateRange } = useDateRange();

  const handleStartDateChange = (date) => {
    setSelectedDateRange([date, selectedDateRange[1]]);
  };

  const handleEndDateChange = (date) => {
    setSelectedDateRange([selectedDateRange[0], date]);
  };

  return (
    <div className="flex gap-2 items-center">
      <div>Select Date Range : </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          display="flex"
          gap={2}
          sx={{
            width: 330,
            "& .MuiSvgIcon-root": {
              color: "#387FF5" /* Change the icon color */,
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: 1,
              padding: "0 4px",
              height: 36,
            },
            "& .MuiOutlinedInput-input": {
              height: "100%",
              fontSize: ".95rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)",
              border: "none",
            },
            "& .MuiInputLabel-root": {
              color: "#387FF5",
              fontSize: "0.75rem",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              top: "0",
              left: "0",
              right: "0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#387FF5",
              top: "4px",
              fontSize: "0.95rem",
            },
            "& .MuiInputAdornment-root": {
              marginRight: "12px",
              color: "#387FF5",
            },
          }}
        >
          <DatePicker
            label="Start Date"
            value={selectedDateRange[0]}
            onChange={handleStartDateChange}
            maxDate={dayjs()} // Restrict to current date or earlier
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 1,
                padding: "0 4px",
                height: "36px",
                backgroundColor: "#F3F8FF",
                color: "#387FF5",
              },
              "& .MuiInputLabel-root": {
                color: "#387FF5",
                top: "0",
                fontSize: "0.95rem",
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={selectedDateRange[1]}
            onChange={handleEndDateChange}
            maxDate={dayjs()} // Restrict to current date or earlier
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 1,
                padding: "0 4px",
                height: "36px",
                backgroundColor: "#F3F8FF",
                color: "#387FF5",
              },
              "& .MuiInputLabel-root": {
                color: "#387FF5",
                top: "0",
                fontSize: "0.95rem",
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    </div>
  );
}
