import React, { useState } from "react";

const MultiSelectDropdown = ({ options, selectedValues, onChange, initialTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    if (selectedValues.includes(option)) {
      // If already selected, remove it from the array
      onChange(selectedValues.filter((v) => v !== option));
    } else {
      // Otherwise, add it to the array
      onChange([...selectedValues, option]);
    }
  };

  return (
    <div className="relative inline-block w-auto min-w-32 h-[42px]">
      {/* Trigger Button */}
      <button
        className="w-full p-3 shadow rounded bg-white text-[#666666] flex gap-3 justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-body font-medium text-sm">{initialTitle}</span>
        <img
          width={10}
          height={10}
          src="/icons/dropdownArrowIcon.svg"
          className={`mr-1 transition-transform duration-300 ${isOpen ? "rotate-180" : "rotate-0"}`}
        />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul className="absolute z-20 w-full mt-1 bg-white border rounded shadow-lg max-h-56 overflow-y-auto">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              <input
                type="checkbox"
                checked={selectedValues.includes(option)}
                onChange={() => handleSelect(option)}
                className="mr-2"
              />
              <span
                className={`text-sm font-body ${selectedValues.includes(option) ? "text-[#387FF5]" : "text-gray-700"}`}
              >
                {option}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
