import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import dayjs from "dayjs";

const ShowSelectedDate = ({ date }) => {
  const formatDate = (date) => dayjs(date).format("DD MMM, YYYY");

  return (
    <div
      className={`flex w-auto items-center p-2 gap-1 text-xs rounded-md border border-[#387FF5] bg-[#F3F8FF] text-[#387FF5]`}
    >
      <IoIosInformationCircleOutline className="font-semibold text-base" />
      <span>{Array.isArray(date) ? "Data From :" : "Data For"} </span>
      <span className="text-medium">
        {Array.isArray(date) ? `${formatDate(date[0])} - ${formatDate(date[1])}` : formatDate(date)}
      </span>
    </div>
  );
};

export default ShowSelectedDate;
