import React from "react";
import TextField from "@mui/material/TextField";

const InputField = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  sx,
  name,
  error,
  helperText,
}) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      fullWidth
      margin="normal"
      style={{ marginTop: 0 }}
      inputProps={{
        style: {
          height: "5px",
          fontSize: "12px",
        },
      }}
      sx={sx}
      error={!!error} // Convert error to boolean
      helperText={helperText} // Display error message
    />
  );
};

export default InputField;
