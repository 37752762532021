import axios from "axios";
import { BASE_URL } from "../../utils/assets";

export const rbacService = {
  getAllRbac: async (data) => {
    try {
      const response = await axios.get(`${BASE_URL}/getAllRoles`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};
