import dayjs from "dayjs";

const formatDateWithoutTimezone = (date) => {
  const formattedDate = dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  return formattedDate;
};

export const blinkitQuery = (selectedDateRange) => {
  console.log("selectedDateRange===", selectedDateRange);
  return {
    dimensions: [
      "combined_sales_report_blinkit.brand_internal_sku_code",
      "combined_sales_report_blinkit.item_name",
      "combined_sales_report_blinkit.category",
      "combined_sales_report_blinkit.sub_category",
      "combined_sales_report_blinkit.date",
      "combined_sales_report_blinkit.brands_choice",
      "combined_sales_report_blinkit.qty_sold",
      "combined_sales_report_blinkit.gmv",
      "combined_sales_report_blinkit.blinkit_sku_code",
      "combined_sales_report_blinkit.city_name",
    ],
    order: [["combined_sales_report_blinkit.date", "asc"]],

    timeDimensions: [
      {
        dimension: "combined_sales_report_blinkit.normalized_date",
        dateRange: [formatDateWithoutTimezone(selectedDateRange[0]), formatDateWithoutTimezone(selectedDateRange[1])],
      },
    ],
  };
};

export const instamartQuery = (selectedDateRange) => {
  return {
    dimensions: [
      "combined_sales_report_instamart.item_code",
      "combined_sales_report_instamart.date",
      "combined_sales_report_instamart.product_name",
      "combined_sales_report_instamart.qty",
      "combined_sales_report_instamart.mrp",
      "combined_sales_report_instamart.city",
      "combined_sales_report_instamart.gmv",
      "combined_sales_report_instamart.brand_internal_sku_code",
      "combined_sales_report_instamart.category",
      "combined_sales_report_instamart.sub_category",
      "combined_sales_report_instamart.brands_choice",
    ],
    order: [["combined_sales_report_instamart.date", "asc"]],

    timeDimensions: [
      {
        dimension: "combined_sales_report_instamart.normalized_date",
        dateRange: [formatDateWithoutTimezone(selectedDateRange[0]), formatDateWithoutTimezone(selectedDateRange[1])],
      },
    ],
  };
};

export const zeptoQuery = (selectedDateRange) => {
  return {
    dimensions: [
      "combined_sales_report_zepto.date",
      "combined_sales_report_zepto.sku_number",
      "combined_sales_report_zepto.brand_internal_sku_code",
      "combined_sales_report_zepto.sku_name",
      "combined_sales_report_zepto.category",
      "combined_sales_report_zepto.sub_category",
      "combined_sales_report_zepto.city",
      "combined_sales_report_zepto.brands_choice",
      "combined_sales_report_zepto.mrp",
      "combined_sales_report_zepto.qty",
      "combined_sales_report_zepto.gmv",
    ],
    order: [["combined_sales_report_zepto.date", "asc"]],
    timeDimensions: [
      {
        dimension: "combined_sales_report_zepto.normalized_date",
        dateRange: [formatDateWithoutTimezone(selectedDateRange[0]), formatDateWithoutTimezone(selectedDateRange[1])],
      },
    ],
  };
};
