import React from "react";
import CategoryRcaOverview from "./CategoryRcaOverview";
import CategoryRcaCategoryWise from "./CategoryRcaCategoryWise";
import CategoryRcaCityWise from "./CategoryRcaCityWise";
import CategoryRcaSkuWise from "./CategoryRcaSkuWise";
import CategoryRcaFlowChart from "./CategoryRcaFlowChart";
import { useSelector } from "react-redux";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const CategoryRcaSectionsContainer = () => {
  const { rcaSkusData, rcaCitiesData } = useSelector((state) => state.rcaCategory);
  console.log("skyudata======================", rcaSkusData, rcaCitiesData);
  if (rcaSkusData.loading || rcaCitiesData.loading) {
    return (
      <div>
        <ChartSkeleton />
        <ChartSkeleton />
        <ChartSkeleton />
        <ChartSkeleton />
      </div>
    );
  }

  if (!rcaSkusData.data || rcaSkusData.data.length === 0) {
    return <NoDataFound />;
  }
  // Calculate overall GMV and Quantity
  const overallCategoryGMV = rcaSkusData.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_gmv"] || 0),
    0
  );

  const overallCategoryQty = rcaSkusData.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_qty_sold"] || 0),
    0
  );

  // Format rcaSkusData with total GMV and Qty
  const formattedSkus = {
    totalGMV: overallCategoryGMV,
    totalQty: overallCategoryQty,
    data: rcaSkusData.data,
  };

  return (
    <div className="py-4 flex flex-col gap-y-4">
      <CategoryRcaOverview />
      {/* <CategoryRcaCategoryWise /> */}
      <CategoryRcaSkuWise />
      <CategoryRcaCityWise />
      <div className="p-4">
        {/* Render the dynamic flow chart */}
        <CategoryRcaFlowChart filteredSkus={formattedSkus} />
      </div>
    </div>
  );
};

export default CategoryRcaSectionsContainer;
