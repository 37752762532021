// FilterDropdown.js
import React, { useState } from "react";
import { Dialog, TextField, Button, MenuItem, Select, Box, Slider, IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";

const FilterDropdown = ({ filters, onApply }) => {
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle value changes dynamically for different filters
  const handleChange = (name, value) => {
    setFilterValues((prev) => ({ ...prev, [name]: value }));
  };

  // Handle filter application
  const handleApply = () => {
    onApply(filterValues);
    handleClose();
  };

  return (
    <div className="relative inline-block w-auto min-w-32 h-14">
      <button
        className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-3 justify-between items-center"
        onClick={handleOpen}
      >
        <span className="font-body font-medium text-sm ">Filter</span>
        <img width={10} height={10} src="/icons/filterIcon.svg" />
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{ "& .MuiDialog-paper": { width: "300px", maxWidth: "none" } }}
      >
        <Box padding={3} display="flex" flexDirection="column" gap={3}>
          <IconButton onClick={handleClose} sx={{ alignSelf: "flex-end" }}>
            <CloseIcon />
          </IconButton>

          {filters.map((filter) => (
            <div key={filter.field}>
              <div>{filter.heading}</div>

              {filter.type === "select" ? (
                <Select
                  fullWidth
                  value={filterValues[filter.field] || ""}
                  onChange={(e) => handleChange(filter.field, e.target.value)}
                >
                  {filter.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              ) : filter.type === "range" ? (
                <Slider
                  value={filterValues[filter.field] || [0, 100]}
                  onChange={(e, value) => handleChange(filter.field, value)}
                  valueLabelDisplay="auto"
                  min={filter.min}
                  max={filter.max}
                />
              ) : (
                <TextField
                  fullWidth
                  label={filter.label}
                  value={filterValues[filter.field] || ""}
                  onChange={(e) => handleChange(filter.field, e.target.value)}
                />
              )}
            </div>
          ))}

          <Button variant="contained" onClick={handleApply}>
            Apply Filters
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default FilterDropdown;
