export const blinkitMonthlyInsightsQuery = (startDate, endDate) => {
  return {
    measures: ["combined_sales_report_blinkit.total_gmv"],
    dimensions: ["combined_sales_report_blinkit.item_name"],
    timeDimensions: [
      {
        dimension: "combined_sales_report_blinkit.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
    order: {
      "combined_sales_report_blinkit.normalized_date": "asc",
    },
  };
};

export const zeptoMonthlyInsightsQuery = (startDate, endDate) => ({
  measures: ["combined_sales_report_zepto.total_gross_merchandise_value"],
  dimensions: ["combined_sales_report_zepto.sku_name"],
  timeDimensions: [
    {
      dimension: "combined_sales_report_zepto.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_zepto.normalized_date": "asc",
  },
});

export const instamartMonthlyInsightsQuery = (startDate, endDate) => ({
  measures: ["combined_sales_report_instamart.total_gmv"],
  dimensions: ["combined_sales_report_instamart.product_name"],
  timeDimensions: [
    {
      dimension: "combined_sales_report_instamart.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_instamart.normalized_date": "asc",
  },
});

export const blinkitCityInsightsQuery = (startDate, endDate) => ({
  measures: ["combined_sales_report_blinkit.total_gmv"],
  timeDimensions: [
    {
      dimension: "combined_sales_report_blinkit.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_blinkit.total_gmv": "desc",
  },
  dimensions: ["combined_sales_report_blinkit.brands_choice"],
});

export const zeptoCityInsightsQuery = (startDate, endDate) => ({
  measures: ["combined_sales_report_zepto.total_gross_merchandise_value"],
  timeDimensions: [
    {
      dimension: "combined_sales_report_zepto.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_zepto.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_zepto.brands_choice"],
});

export const instamartCityInsightsQuery = (startDate, endDate) => ({
  measures: ["combined_sales_report_instamart.total_gmv"],
  timeDimensions: [
    {
      dimension: "combined_sales_report_instamart.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_instamart.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_instamart.brands_choice"],
});

export const blinkitProductsInsightsQuery = (startDate, endDate) => ({
  order: {
    "combined_sales_report_blinkit.total_gmv": "desc",
  },
  measures: [
    "combined_sales_report_blinkit.total_gmv",
    "combined_sales_report_blinkit.total_taxes_paid",
    "combined_sales_report_blinkit.total_cogs",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_blinkit.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  dimensions: ["combined_sales_report_blinkit.item_name"],
});

export const instamartProductsInsightsQuery = (startDate, endDate) => ({
  order: {
    "combined_sales_report_instamart.normalized_date": "asc",
  },
  timeDimensions: [
    {
      dimension: "combined_sales_report_instamart.normalized_date",
      granularity: "day",
      dateRange: [startDate, endDate],
    },
  ],
  dimensions: ["combined_sales_report_instamart.product_name"],
  measures: [
    "combined_sales_report_instamart.total_gmv",
    "combined_sales_report_instamart.total_cogs",
    "combined_sales_report_instamart.total_taxes_paid",
  ],
});

export const zeptoProductsInsightsQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_zepto.total_cogs",
    "combined_sales_report_zepto.total_taxes_paid",
    "combined_sales_report_zepto.total_gross_merchandise_value",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_zepto.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_zepto.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_zepto.sku_name"],
});
