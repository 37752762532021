import dayjs from "dayjs";
import {
  blinkitCityInsightsQuery,
  blinkitMonthlyInsightsQuery,
  blinkitProductsInsightsQuery,
  instamartCityInsightsQuery,
  instamartMonthlyInsightsQuery,
  instamartProductsInsightsQuery,
  zeptoCityInsightsQuery,
  zeptoMonthlyInsightsQuery,
  zeptoProductsInsightsQuery,
} from "../../utils/queries/insightsQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

//monthly
export const fetchMonthlyInsightsDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const dataArray = [];
  // Fetch data for the current month
  const currentMonthName = dayjs(selectedDate).format("MMMM");
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  const result1 = await cubejsApi.load(blinkitMonthlyInsightsQuery(startDate1, endDate1));
  if (result1 && result1.tablePivot().length > 0) {
    if (
      result1.tablePivot()[0]["combined_sales_report_blinkit.total_gmv"] ||
      result1.tablePivot()[0]["combined_sales_report_blinkit.total_taxes_paid"] ||
      result1.tablePivot()[0]["combined_sales_report_blinkit.total_cogs"]
    ) {
      dataArray.push({
        month: currentMonthName,
        data: result1.tablePivot(),
      });
    } else {
      console.log("no data for ", currentMonthName);
    }
  }
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");

  const monthName = dayjs(startDate).format("MMMM");
  const monthlyData = await cubejsApi.load(blinkitMonthlyInsightsQuery(startDate, endDate));
  if (
    monthlyData.tablePivot()[0]["combined_sales_report_blinkit.total_gmv"] ||
    monthlyData.tablePivot()[0]["combined_sales_report_blinkit.total_taxes_paid"] ||
    monthlyData.tablePivot()[0]["combined_sales_report_blinkit.total_cogs"]
  ) {
    dataArray.push({
      month: monthName,
      data: monthlyData.tablePivot(),
    });
  }

  return dataArray;
};
export const fetchMonthlyInsightsDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const dataArray = [];
  // Fetch data for the current month
  const currentMonthName = dayjs(selectedDate).format("MMMM");
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  const result1 = await cubejsApi.load(zeptoMonthlyInsightsQuery(startDate1, endDate1));
  if (
    result1.tablePivot()[0]["combined_sales_report_zepto.total_gross_merchandise_value"] ||
    result1.tablePivot()[0]["combined_sales_report_zepto.total_taxes_paid"] ||
    result1.tablePivot()[0]["combined_sales_report_zepto.total_cogs"]
  ) {
    dataArray.push({
      month: currentMonthName,
      data: result1.tablePivot(),
    });
  }

  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");
  const monthName = dayjs(startDate).format("MMMM");
  const monthlyData = await cubejsApi.load(zeptoMonthlyInsightsQuery(startDate, endDate));
  if (
    monthlyData.tablePivot()[0]["combined_sales_report_zepto.total_gross_merchandise_value"] ||
    monthlyData.tablePivot()[0]["combined_sales_report_zepto.total_taxes_paid"] ||
    monthlyData.tablePivot()[0]["combined_sales_report_zepto.total_cogs"]
  ) {
    dataArray.push({
      month: monthName,
      data: monthlyData.tablePivot(),
    });
  }

  return dataArray;
};
export const fetchMonthlyInsightsDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const dataArray = [];
  // Fetch data for the current month
  const currentMonthName = dayjs(selectedDate).format("MMMM");
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  const result1 = await cubejsApi.load(instamartMonthlyInsightsQuery(startDate1, endDate1));
  if (
    result1.tablePivot()[0]["combined_sales_report_instamart.total_gmv"] ||
    result1.tablePivot()[0]["combined_sales_report_instamart.total_taxes_paid"] ||
    result1.tablePivot()[0]["combined_sales_report_instamart.total_cogs"]
  ) {
    dataArray.push({
      month: currentMonthName,
      data: result1.tablePivot(),
    });
  }

  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");
  const monthName = dayjs(startDate).format("MMMM");
  const monthlyData = await cubejsApi.load(instamartMonthlyInsightsQuery(startDate, endDate));
  if (
    monthlyData.tablePivot()[0]["combined_sales_report_instamart.total_gmv"] ||
    monthlyData.tablePivot()[0]["combined_sales_report_instamart.total_taxes_paid"] ||
    monthlyData.tablePivot()[0]["combined_sales_report_instamart.total_cogs"]
  ) {
    dataArray.push({
      month: monthName,
      data: monthlyData.tablePivot(),
    });
  }

  return dataArray;
};

//city
export const fetchCityInsightsDataBlinkit = async (selectedDate) => {
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss");

  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitCityInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchCityInsightsDataZepto = async (selectedDate) => {
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoCityInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchCityInsightsDataInstamart = async (selectedDate) => {
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartCityInsightsQuery(startDate, endDate));
  return result.tablePivot();
};

//product
export const fetchProductInsightsDataBlinkit = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitProductsInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchProductInsightsDataZepto = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoProductsInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchProductsInsightsDataInstamart = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartProductsInsightsQuery(startDate, endDate));
  return result.tablePivot();
};

export const fetchProductInsightsDataPreviousBlinkit = async (selectedDate) => {
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitProductsInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchProductInsightsDataPreviousZepto = async (selectedDate) => {
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoProductsInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchProductsInsightsDataPreviousInstamart = async (selectedDate) => {
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartProductsInsightsQuery(startDate, endDate));
  return result.tablePivot();
};
