import {
  productListingQuery,
  productRecommendationQuery,
  skuPerformanceQuery,
  campaignPerformanceQuery,
  marketingReportQuery,
} from "../../utils/queries/leastPerformingQueries";

import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchLeastPerformingProductListingDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(productListingQuery(selectedDate));
  return result.tablePivot();
};

export const fetchLeastPerformingProductRecommendationDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(productRecommendationQuery(selectedDate));
  return result.tablePivot();
};

export const fetchLeastPerformingSkuPerformanceDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(skuPerformanceQuery(selectedDate));
  return result.tablePivot();
};

export const fetchLeastPerformingCampaignPerformanceDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(campaignPerformanceQuery(selectedDate));
  return result.tablePivot();
};

export const fetchLeastPerformingMarketingReportDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(marketingReportQuery(selectedDate));
  return result.tablePivot();
};
