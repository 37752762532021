import React from "react";
import { FiSearch } from "react-icons/fi";
import { MdTableChart } from "react-icons/md";
import CompetitorPricingGainers from "./CompetitorPricingGainers";
import CompetitorPricingDrainers from "./CompetitorPricingDrainers";
import { dummyPricingData } from "../../../utils/dummyPricingData";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const CompetitorPricingKeyInsights = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2">
          <MdTableChart className="text-xl" />

          <span className="font-medium text-lg flex gap-4 items-center">
            Key Insights
            <CustomTooltip title={"Analyze Key Insights on the basis of Top Gainers and Top Drainers"}>
              <PiInfoLight />
            </CustomTooltip>
          </span>
        </div>
      </div>
      <hr />
      <div className="flex gap-4 p-4">
        <CompetitorPricingGainers />
        <CompetitorPricingDrainers />
      </div>
    </div>
  );
};

export default CompetitorPricingKeyInsights;
