import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getThirdPartyVisibilityRequest,
  getThirdPartyVisibilitySuccess,
  getThirdPartyVisibilityFailure,
} from "../../redux/actions/thirdPartyActions";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import {
  fetchPricingDetailDataBlinkit,
  fetchPricingDetailDataInstamart,
  fetchPricingDetailDataZepto,
} from "../../services/adsServices/pricingDetailServices";
import PricingDetailSectionsContainer from "../../components/ads/PricingDetailComponents/PricingDetailSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import Toolbar from "../../components/Toolbar/Toolbar";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

// List of available options for the component switch
const options = ["Pricing Details"];

const PricingDetailScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const dispatch = useDispatch();
  const { selectedDate } = useDate();

  // Reset the selected option to the first one whenever the platform name changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchPricing: fetchPricingDetailDataBlinkit,
      pricingActions: {
        request: getThirdPartyVisibilityRequest,
        success: getThirdPartyVisibilitySuccess,
        failure: getThirdPartyVisibilityFailure,
      },
    },

    Zepto: {
      fetchPricing: fetchPricingDetailDataZepto,
      pricingActions: {
        request: getThirdPartyVisibilityRequest,
        success: getThirdPartyVisibilitySuccess,
        failure: getThirdPartyVisibilityFailure,
      },
    },

    Instamart: {
      fetchPricing: fetchPricingDetailDataInstamart,
      pricingActions: {
        request: getThirdPartyVisibilityRequest,
        success: getThirdPartyVisibilitySuccess,
        failure: getThirdPartyVisibilityFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];

    dispatch(platform.pricingActions.request());
    fetchWithRetry(
      platform.fetchPricing,
      platform.pricingActions.success,
      platform.pricingActions.failure,
      selectedDate,
      dispatch,
      1
    );
  }, [platformName, selectedDate]);

  return (
    <>
      {/* Heading bar component to switch platforms and display selected date */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />

      {/* Toolbar containing the ComponentSwitch and other UI elements */}
      <Toolbar>
        {/* ComponentSwitch to toggle between options (only "Pricing Details" is available now) */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Render PricingDetailSectionsContainer if the selected option is the first one (0) */}
        {selectedOption === 0 && <PricingDetailSectionsContainer platformName={platformName} />}
      </Toolbar>
    </>
  );
};

export default PricingDetailScreen;
