import { CircularProgress } from "@mui/material";
import React from "react";
import { useAlert } from "../common/Alert/AlertProvider ";
import { BASE_URL } from "../../utils/assets";
import dayjs from "dayjs";
import { useDateRange } from "../../hooks/CustomDateRange/CustomDateRangePickerProvider";
import ShowSelectedDate from "../common/ShowSelectedDate/ShowSelectedDate";
import CustomDateRangePicker from "../DatePickers/CustomDateRangePicker/CustomDateRangePicker";

const CategoriesTarget = ({ categories, selectedDate, target, setTarget, catTargets, setCatTargets, platformName }) => {
  const { showAlert } = useAlert();
  const { selectedDateRange } = useDateRange();

  // Helper function to format date without timezone information
  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Format the start and end dates using your formatDateWithoutTimezone function
  const formattedStartDate = formatDateWithoutTimezone(selectedDate[0]);
  const formattedEndDate = formatDateWithoutTimezone(selectedDate[1]);

  // Show a loading spinner if categories are not defined or empty
  if (categories[0] == "undefined" || categories.length == 0) {
    return (
      <div className="w-full h-80 flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  // Handle changes to the overall target input field
  const handleTargetChange = (e) => {
    const newTarget = e.target.value;
    const obj = {};
    setTarget(newTarget);

    // Distribute the total target equally among categories
    categories.forEach((element) => {
      if (!obj[element]) {
        obj[element] = newTarget / categories.length;
      }
    });

    // Set the distributed target for each category
    setCatTargets({ ...obj });
  };

  // Handle changes to individual category target input fields
  const handleCatTargetChange = (category, value) => {
    const newCatTargets = { ...catTargets, [category]: Number(value) };
    setCatTargets(newCatTargets);

    // Recalculate the total target based on individual category values
    const newTotalTarget = Object.keys(newCatTargets).reduce((acc, val) => acc + Number(newCatTargets[val]), 0);
    setTarget(newTotalTarget);
  };

  // Handle form submission
  const handleSubmit = () => {
    const token = localStorage.getItem("token");

    // Make a POST request to add targets
    fetch(BASE_URL + "/addTargets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: formattedStartDate, // Pass formatted start date
        endDate: formattedEndDate, // Pass formatted end date
        subCategories: catTargets,
        platform: platformName,
        totalTarget: target,
        token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showAlert("success", "Targets Saved successfully!!!!");
        } else {
          showAlert("warning", data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="flex flex-col justify-center items-center relative max-w-full">
      <div className="flex flex-col justify-center items-center text-[#030229] gap-4 w-2/3 py-10 bg-white rounded-[0.625rem] shadow-xl">
        <div className="text-[#504F64] font-medium text-[1.75rem] mb-20">Set Your Target</div>
        <CustomDateRangePicker />
        <table>
          <tbody>
            <tr>
              <td className="font-medium pr-4">Enter Your Target : </td>
              <td className="pl-2 py-2">
                <input
                  value={target}
                  type="number"
                  id="full-target"
                  placeholder="50,000"
                  className="border p-2 w-80 outline-0 text-[#387FF5]"
                  onChange={(e) => handleTargetChange(e)}
                />
              </td>
            </tr>
            {categories.length &&
              categories.map((item) => (
                <tr key={item}>
                  <td className="text-right pr-4 font-light">{item} : </td>
                  <td className="pl-2 py-2 text-left">
                    <input
                      type="number"
                      value={catTargets[item] || ""}
                      name=""
                      id=""
                      className="border p-2 w-80 outline-0 text-[#387FF5]"
                      onChange={(e) => {
                        handleCatTargetChange(item, e.target.value);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="max-w-full min-w-[80%] rounded-xl shadow-xl bg-white flex justify-between items-center px-10 py-4 fixed bottom-6">
        <div className="font-medium text-xl">
          Your Target : <span className="text-[#387FF5]">{target ? target.toLocaleString() : 0}</span>
        </div>
        <div
          className="bg-[#387FF5] text-white text-[0.875] w-[10.25rem] h-[2.625rem] rounded-md cursor-pointer flex justify-center items-center"
          onClick={handleSubmit}
        >
          Set Target
        </div>
      </div>
    </div>
  );
};

export default CategoriesTarget;
