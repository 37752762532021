import {
  COMPETITOR_PRICING_FAILURE,
  COMPETITOR_PRICING_REQUEST,
  COMPETITOR_PRICING_SUCCESS,
} from "../constants/competitorPricingConstants";

const initialState = {
  competitorPricing: {
    loading: false,
    data: [],
    error: null,
  },
};

export const competitorPricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPETITOR_PRICING_REQUEST:
      return {
        ...state,
        competitorPricing: {
          ...state.competitorPricing,
          loading: true,
          error: null,
          data: [],
        },
      };

    case COMPETITOR_PRICING_SUCCESS:
      return {
        ...state,
        competitorPricing: {
          ...state.competitorPricing,
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case COMPETITOR_PRICING_FAILURE:
      return {
        ...state,
        competitorPricing: {
          ...state.competitorPricing,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
