import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  TablePagination,
  Paper, // Import TextField for the search bar
} from "@mui/material";
import "./css/CategoryWiseSales.css";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Papa from "papaparse";
import Dropdown from "../../common/Dropdown/Dropdown";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import FilterPopup from "../../common/Dropdown/FilterPopup";
import FilterDropdown from "../../common/Dropdown/FilterDropdown";

// Normalize sales data based on the platform
const normalizeData = (data, platformName) => {
  if (platformName === "Zepto") {
    return {
      platform: "zepto",
      category: data["combined_sales_report_zepto.category"],
      subCategory: data["combined_sales_report_zepto.sub_category"],
      itemName: data["combined_sales_report_zepto.sku_name"],
      mrp: parseFloat(data["combined_sales_report_zepto.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_zepto.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_zepto.brands_choice"],
    };
  } else if (platformName === "Blinkit") {
    return {
      platform: "blinkit",
      category: data["combined_sales_report_blinkit.category"],
      subCategory: data["combined_sales_report_blinkit.sub_category"],
      itemName: data["combined_sales_report_blinkit.item_name"],
      mrp: parseFloat(data["combined_sales_report_blinkit.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_blinkit.qty_sold"]),
      gstRate: 0.18,
      city: data["combined_sales_report_blinkit.brands_choice"],
    };
  } else if (platformName === "Instamart") {
    return {
      platform: "instamart",
      category: data["combined_sales_report_instamart.category"],
      subCategory: data["combined_sales_report_instamart.sub_category"],
      itemName: data["combined_sales_report_instamart.product_name"],
      mrp: parseFloat(data["combined_sales_report_instamart.gmv"]),
      qtySold: parseFloat(data["combined_sales_report_instamart.qty"]),
      gstRate: 0.18,
      city: data["combined_sales_report_instamart.brands_choice"],
    };
  }
  return null;
};

// Group sales data by category, sub-category, and item
const groupData = (data, platformName) => {
  const grouped = {};

  data.forEach((item) => {
    const normalizedItem = normalizeData(item, platformName);
    if (!normalizedItem) return;

    const { category, subCategory, itemName, mrp, qtySold, gstRate } = normalizedItem;

    const gstAmount = mrp * gstRate;
    const mrpExGst = mrp - gstAmount;

    // Initialize Category
    if (!grouped[category]) {
      grouped[category] = {
        totalQtySold: 0,
        totalMrp: 0,
        totalGst: 0,
        totalMrpExGst: 0,
        subCategories: {},
      };
    }

    // Initialize Sub-Category
    if (!grouped[category].subCategories[subCategory]) {
      grouped[category].subCategories[subCategory] = {
        totalQtySold: 0,
        totalMrp: 0,
        totalGst: 0,
        totalMrpExGst: 0,
        items: {},
      };
    }

    // Initialize Item
    if (!grouped[category].subCategories[subCategory].items[itemName]) {
      grouped[category].subCategories[subCategory].items[itemName] = {
        qtySold,
        mrp,
        gst: gstAmount,
        mrpExGst,
      };
    } else {
      // Accumulate Item Values if duplicate entries exist
      grouped[category].subCategories[subCategory].items[itemName].qtySold += qtySold;
      grouped[category].subCategories[subCategory].items[itemName].mrp += mrp;
      grouped[category].subCategories[subCategory].items[itemName].gst += gstAmount;
      grouped[category].subCategories[subCategory].items[itemName].mrpExGst += mrpExGst;
    }

    // Accumulate Sub-Category Totals
    grouped[category].subCategories[subCategory].totalQtySold += qtySold;
    grouped[category].subCategories[subCategory].totalMrp += mrp;
    grouped[category].subCategories[subCategory].totalGst += gstAmount;
    grouped[category].subCategories[subCategory].totalMrpExGst += mrpExGst;

    // Accumulate Category Totals
    grouped[category].totalQtySold += qtySold;
    grouped[category].totalMrp += mrp;
    grouped[category].totalGst += gstAmount;
    grouped[category].totalMrpExGst += mrpExGst;
  });

  return grouped;
};

// Calculate overall totals from grouped data
const calculateOverallTotals = (groupedData) => {
  let overallTotals = {
    totalQtySold: 0,
    totalMrp: 0,
    totalGst: 0,
    totalMrpExGst: 0,
  };

  Object.keys(groupedData).forEach((category) => {
    overallTotals.totalQtySold += groupedData[category].totalQtySold;
    overallTotals.totalMrp += groupedData[category].totalMrp;
    overallTotals.totalGst += groupedData[category].totalGst;
    overallTotals.totalMrpExGst += groupedData[category].totalMrpExGst;
  });

  return overallTotals;
};

const CategoryWiseSalesTable = ({ platformName }) => {
  // Get sales data from Redux store
  const { totalSales } = useSelector((state) => state.sales);

  // Initialize state for category and sub-category open states, city filter, and search query
  const [openCategories, setOpenCategories] = useState({});
  const [openSubCategories, setOpenSubCategories] = useState({});
  const [selectedCity, setSelectedCity] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filters, setFilters] = useState({}); // Store applied filters

  console.log("filters===", filters);

  // Extract unique cities from sales data
  const cities = [...new Set(totalSales.data.map((item) => normalizeData(item, platformName)?.city))].filter(Boolean);

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Toggle category open state
  const handleCategoryClick = (category) => {
    setOpenCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Toggle sub-category open state
  const handleSubCategoryClick = (category, subCategory) => {
    setOpenSubCategories((prev) => ({
      ...prev,
      [`${category}-${subCategory}`]: !prev[`${category}-${subCategory}`],
    }));
  };

  // Format numbers for display
  const formatNumber = (number) => {
    return number.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Filter data based on selected city and search query
  const filteredData = totalSales.data
    .filter((item) => (selectedCity === "All" ? true : normalizeData(item, platformName)?.city === selectedCity))
    .filter((item) =>
      (normalizeData(item, platformName)?.itemName?.toLowerCase() || "").includes(searchQuery.toLowerCase())
    )
    .filter((item) => {
      const normalizedItem = normalizeData(item, platformName);
      const selectedCategory = filters.category;
      // Filter by selected category
      if (selectedCategory && normalizedItem.category !== selectedCategory) {
        return false; // Exclude this item if it doesn't match the selected category
      }

      // Filter by qtySold range if set
      if (filters.qtySold) {
        const [minQty, maxQty] = filters.qtySold;
        if (normalizedItem.qtySold < minQty || normalizedItem.qtySold > maxQty) {
          return false; // Exclude if qtySold is out of range
        }
      }

      // Filter by MRP range if set
      if (filters.mrp) {
        const [minMrp, maxMrp] = filters.mrp;
        if (normalizedItem.mrp < minMrp || normalizedItem.mrp > maxMrp) {
          return false; // Exclude if MRP is out of range
        }
      }

      return true; // Include this item if all conditions are met
    });

  // Group filtered data by category, sub-category, and item
  const groupedData = groupData(filteredData, platformName);
  console.log("groupedData===", groupedData);

  // Slicing data to show based on pagination
  const slicedCategories = Object.keys(groupedData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortedCategories = React.useMemo(() => {
    if (!sortConfig.key) return slicedCategories;

    return [...slicedCategories].sort((a, b) => {
      const aValue = groupedData[a][sortConfig.key];
      const bValue = groupedData[b][sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [slicedCategories, sortConfig, groupedData]);

  // Calculate overall totals for all categories
  const overallTotals = calculateOverallTotals(groupedData);

  console.log("groupedData:", groupedData);

  // Compute min and max values for totalQtySold and totalMrp from groupedData
  const computeMinMax = (groupedData) => {
    let minQtySold = Infinity;
    let maxQtySold = -Infinity;
    let minMrp = Infinity;
    let maxMrp = -Infinity;

    // Iterate through categories
    Object.values(groupedData).forEach((category) => {
      // Check totals for each subCategory
      Object.values(category.subCategories).forEach((subCategory) => {
        // Iterate through items to find min/max for each product
        Object.values(subCategory.items).forEach((item) => {
          // Compare and set min/max for qtySold
          minQtySold = Math.min(minQtySold, item.qtySold);
          maxQtySold = Math.max(maxQtySold, item.qtySold);

          // Compare and set min/max for mrp
          minMrp = Math.min(minMrp, item.mrp);
          maxMrp = Math.max(maxMrp, item.mrp);
        });
      });
    });

    return {
      minQtySold: minQtySold === Infinity ? 0 : minQtySold, // Default to 0 if no values
      maxQtySold: maxQtySold === -Infinity ? 0 : maxQtySold,
      minMrp: minMrp === Infinity ? 0 : minMrp,
      maxMrp: maxMrp === -Infinity ? 0 : maxMrp,
    };
  };

  // Calculate min and max values
  const minMaxValues = computeMinMax(groupedData);
  console.log("Min and Max Values from category:", minMaxValues);

  // Generate filter options dynamically based on groupedData
  const filterOptions = [
    {
      field: "category",
      heading: "Select Category",
      type: "select",
      options: Object.keys(groupedData), // Dynamically extract categories from groupedData
    },
    {
      field: "qtySold",
      heading: "Select Quantity Range",
      type: "range",
      min: minMaxValues.minQtySold,
      max: minMaxValues.maxQtySold,
    },
    {
      field: "mrp",
      heading: "Select MRP Range",
      type: "range",
      min: minMaxValues.minMrp,
      max: minMaxValues.maxMrp,
    },
  ];
  console.log("🚀 ~ CategoryWiseSalesTable ~ filterOptions:", filterOptions);

  const handleExportCSV = () => {
    // Flatten the nested groupedData
    const flattenData = [];

    // Helper function to process each item in the groupedData
    const processItem = (category, subCategory, itemName, itemData) => {
      flattenData.push({
        Category: category || "N/A",
        Subcategory: subCategory || "N/A",
        "Product Name": itemName || "N/A",
        "Quantity Sold": itemData.qtySold,
        "MRP (Including GST)": itemData.mrp,
        GST: itemData.gst,
        "MRP (Excluding GST)": itemData.mrpExGst,
      });
    };

    // Iterate through groupedData categories
    Object.keys(groupedData).forEach((categoryKey) => {
      const categoryData = groupedData[categoryKey];

      // Iterate through subcategories in each category
      Object.keys(categoryData.subCategories).forEach((subCategoryKey) => {
        const subCategoryData = categoryData.subCategories[subCategoryKey];

        // Iterate through items in each subcategory
        Object.keys(subCategoryData.items).forEach((itemKey) => {
          const itemData = subCategoryData.items[itemKey];

          // Process each item
          processItem(categoryKey, subCategoryKey, itemKey, itemData);
        });
      });
    });

    // Convert the flattened data to CSV
    const csvData = Papa.unparse(flattenData);

    // Create a downloadable CSV file
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `category_wise_sales_${platformName.toLowerCase()}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Display skeleton loader if no sales data
  if (!totalSales?.data?.length) {
    return <TableSkeleton />;
  }

  return (
    <div>
      <div className="flex sticky-container min-w-[100%] justify-end items-center gap-4 my-8">
        <TextField
          size="medium"
          variant="outlined"
          placeholder="Search Category, Sub-category, Product Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "400px",
            "& input::placeholder": {
              animation: "pulse 2s infinite",
            },
            "& .MuiInputBase-root": {
              height: 45, // Set desired height here
              border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove border from the outline
            },
          }}
          className="search-bar bg-white shadow rounded-md"
        />
        <div className="flex gap-4">
          <Dropdown options={["All", ...cities]} value={selectedCity} onChange={setSelectedCity} />

          <div className="w-auto min-w-32 h-[42px]  flex justify-end">
            <span
              onClick={handleExportCSV}
              className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
            >
              <span className="font-body font-normal text-sm">Export to CSV</span>
              <img width={16} height={16} src="/icons/download-circular-icon.svg" />
            </span>
          </div>
        </div>
      </div>
      <div className="max-w-full bg-white shadow-md rounded-xl">
        <div>
          <h1 className="flex   items-center justify-between text-lg font-medium px-4 py-2 border-b leading-[23.44px]">
            <div className="flex gap-3 items-center text-lg font-medium  leading-[23.44px]">
              {/* Icon for Category Wise Sales */}
              <BiSolidCategoryAlt />
              Category Wise Overview
              <CustomTooltip
                title={
                  "Displays total sales for each category, helping you compare sales performance across different categories."
                }
              >
                <PiInfoLight />
              </CustomTooltip>
            </div>
            <FilterDropdown filters={filterOptions} onApply={setFilters} />
          </h1>
        </div>

        <div className="px-4 pt-4">
          <div className="p-4 border rounded-md">
            <TableContainer sx={{ borderRadius: "0.25rem" }}>
              <Table
                className="table-auto min-w-full"
                stickyHeader
                aria-label="sticky table"
                sx={{ fontFamily: "DM Sans Variable, sans-serif", overflowX: "auto" }}
              >
                <TableHead className="bg-white sticky top-0 z-10">
                  <TableRow sx={{ bgcolor: "#F9F9F9", color: "#4D4D4D" }}>
                    <TableCell
                      onClick={() => handleSort("category")}
                      sx={{
                        width: "250px", // Set your desired width here
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        cursor: "pointer",
                        position: "sticky",
                        bgcolor: "#F9F9F9",
                        left: 0,
                        zIndex: "3",
                      }}
                    >
                      Category {sortConfig.key === "category" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("totalQtySold")}
                      sx={{
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Total Quantity Sold{" "}
                      {sortConfig.key === "totalQtySold" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("totalMrp")}
                      sx={{
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Total MRP {sortConfig.key === "totalMrp" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("totalGst")}
                      sx={{
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      GST {sortConfig.key === "totalGst" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort("totalMrpExGst")}
                      sx={{
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        bgcolor: "#F9F9F9",
                        position: "sticky",
                      }}
                    >
                      Total MRP ex GST{" "}
                      {sortConfig.key === "totalMrpExGst" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "white",
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedCategories?.map((category, index) => {
                    const categoryData = groupedData[category];
                    return (
                      <React.Fragment key={category}>
                        <TableRow
                          onClick={() => handleCategoryClick(category)}
                          className={`${
                            openCategories[category] ? "bg-[#F3F8FF]" : index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-white"
                          } cursor-pointer flex items-center `}
                        >
                          <TableCell
                            sx={{
                              width: "250px",
                              color: "#4D4D4D",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              position: "sticky",
                              left: 0,
                              zIndex: "3",
                              bgcolor: openCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                            className="flex items-center justify-between"
                          >
                            <div className="flex flex-row justify-between">
                              <div className="text-left text-[#4D4D4D] font-medium flex items-center">{category}</div>
                              <div>
                                {/* Conditionally render the icons based on the state */}
                                {openCategories[category] ? (
                                  <FaCaretUp className="text-[#4D4D4D] text-base" />
                                ) : (
                                  <FaCaretDown className="text-[#4D4D4D] text-base" />
                                )}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: "center" }}>
                            {formatNumber(categoryData.totalQtySold)}
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: "center" }}>
                            {formatNumber(categoryData.totalMrp)}
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: "center" }}>
                            {formatNumber(categoryData.totalGst)}
                          </TableCell>
                          <TableCell align="right" sx={{ textAlign: "center" }}>
                            {formatNumber(categoryData.totalMrpExGst)}
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3", // Left shadow for this cell,
                              position: "sticky",
                              right: 0,
                              bgcolor: openCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                          ></TableCell>
                        </TableRow>

                        {openCategories[category] &&
                          Object.keys(categoryData.subCategories).map((subCategory) => {
                            const subCategoryData = categoryData.subCategories[subCategory];
                            return (
                              <React.Fragment>
                                <TableRow
                                  key={subCategory}
                                  // hover
                                  onClick={() => handleSubCategoryClick(category, subCategory)}
                                  className={`cursor-pointer ${openSubCategories[category] ? "bg-gray-100" : ""}`}
                                >
                                  <TableCell
                                    sx={{
                                      width: "250px", // Set the same width for the body cells as the header cell
                                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                      position: "sticky",
                                      left: 0,
                                      zIndex: "3",
                                      bgcolor: openCategories[category] ? "#F0F0F0" : "#fff",
                                    }}
                                    className="flex items-center justify-between"
                                  >
                                    <div className="flex flex-row justify-between">
                                      <div className="text-left ml-4">{subCategory}</div>
                                      <div>
                                        {/* Conditionally render the icons based on the state */}
                                        {openSubCategories[`${category}-${subCategory}`] ? (
                                          <FaCaretUp className="text-base" />
                                        ) : (
                                          <FaCaretDown className="text-base" />
                                        )}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      bgcolor: openCategories[category] ? "#F0F0F0" : "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatNumber(subCategoryData.totalQtySold)}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      bgcolor: openCategories[category] ? "#F0F0F0" : "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatNumber(subCategoryData.totalMrp)}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      bgcolor: openCategories[category] ? "#F0F0F0" : "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatNumber(subCategoryData.totalGst)}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      bgcolor: openCategories[category] ? "#F0F0F0" : "#fff",
                                      textAlign: "center",
                                    }}
                                  >
                                    {formatNumber(subCategoryData.totalMrpExGst)}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                      zIndex: "3",
                                      position: "sticky",
                                      right: 0,
                                      bgcolor: openCategories[category] ? "#F0F0F0" : "#fff",
                                    }}
                                  ></TableCell>
                                </TableRow>

                                {openSubCategories[`${category}-${subCategory}`] &&
                                  Object.keys(subCategoryData.items).map((itemName) => {
                                    const itemData = subCategoryData.items[itemName];
                                    return (
                                      <TableRow key={itemName}>
                                        <TableCell
                                          sx={{
                                            width: "200px", // Set the same width for the body cells as the header cell
                                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                            position: "sticky",
                                            left: 0,
                                            zIndex: "3",
                                          }}
                                        >
                                          <div className="text-left ml-8">{itemName}</div>
                                        </TableCell>
                                        <TableCell align="right">
                                          <div className="text-center">{formatNumber(itemData.qtySold)}</div>
                                        </TableCell>
                                        <TableCell align="right">
                                          <div className="text-center">{formatNumber(itemData.mrp)}</div>
                                        </TableCell>
                                        <TableCell align="right">
                                          <div className="text-center">{formatNumber(itemData.gst)}</div>
                                        </TableCell>
                                        <TableCell align="right">
                                          <div className="text-center">{formatNumber(itemData.mrpExGst)}</div>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                            zIndex: "3",
                                            position: "sticky",
                                            right: 0,
                                            bgcolor: "white",
                                          }}
                                        ></TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </React.Fragment>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
                  {/* Total Row fot Total */}
                  <TableRow sx={{ bgcolor: "#D0E3FF" }}>
                    <TableCell
                      className="sticky left-0"
                      sx={{
                        bgcolor: "#D0E3FF",
                        color: "#323C47",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        fontWeight: "500",
                      }}
                    >
                      Total
                    </TableCell>

                    <TableCell align="right" sx={{ color: "black", fontWeight: "500" }}>
                      <div className="text-center text-[#323C47]">{formatNumber(overallTotals.totalQtySold)}</div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontWeight: "500" }}>
                      <div className="text-center text-[#323C47]">{formatNumber(overallTotals.totalMrp)}</div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontWeight: "500" }}>
                      <div className="text-center text-[#323C47]">{formatNumber(overallTotals.totalGst)}</div>
                    </TableCell>
                    <TableCell align="right" sx={{ color: "black", fontWeight: "500" }}>
                      <div className="text-center text-[#323C47]">{formatNumber(overallTotals.totalMrpExGst)}</div>
                    </TableCell>
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#D0E3FF",
                        fontWeight: "500",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* Add Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Object.keys(groupedData).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default CategoryWiseSalesTable;

// Use Case:

// Imports: Import necessary components and styles.
// Normalization Function: normalizeData standardizes data based on the platform.
// Grouping Function: groupData organizes data by category and sub-category.
// Totals Calculation: calculateOverallTotals computes aggregate metrics.
// Component State: Manages category, sub-category, city, and search query states.
// Rendering: Displays filtered and grouped data in a table format.
