import React, { Fragment, useState, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from "@mui/material";
import KeywordInfoModal from "./KeywordInfoModal";

const PricingCard = ({ selectedSKU, data, pricingData }) => {
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("keyword");

  // Function to open modal with selected keyword
  const handleOpen = (keyword) => {
    setSelectedKeyword(keyword);
    setOpen(true);
  };

  // Function to close modal
  const handleClose = () => setOpen(false);

  // Function to handle sorting logic, toggling between ascending and descending for each column
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Filter the data to get keywords related to the selected SKU

  const filteredData = useMemo(() => {
    if (!selectedSKU || !data || !data[selectedSKU]) return [];
    return Object.keys(data[selectedSKU]);
  }, [selectedSKU, data]);

  // Sort the data based on the selected column (keyword or pricing information)
  const sortedData = useMemo(() => {
    if (!data[selectedSKU]) return [];

    return filteredData.sort((a, b) => {
      if (orderBy === "keyword") {
        return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
      }

      const keywordDataA = data[selectedSKU][a];
      const keywordDataB = data[selectedSKU][b];

      if (!keywordDataA || !keywordDataB) return 0;

      const ownBrandA = keywordDataA.parentBrand;
      const ownBrandB = keywordDataB.parentBrand;
      // Calculate average values for the selected column and sort accordingly
      const ownBrandValueA = ownBrandA[orderBy] / ownBrandA.count || 0;
      const ownBrandValueB = ownBrandB[orderBy] / ownBrandB.count || 0;

      return order === "asc" ? ownBrandValueA - ownBrandValueB : ownBrandValueB - ownBrandValueA;
    });
  }, [filteredData, order, orderBy, selectedSKU, data]);

  console.log("sortedDate====", sortedData);

  // Display message when no SKU is selected
  if (!selectedSKU) {
    return (
      <div className="h-[35rem] w-full flex justify-center items-center text-gray-500">
        Please select a SKU to view pricing details.
      </div>
    );
  }

  return (
    <TableContainer
      sx={{ borderRadius: "0.25rem", maxHeight: 400 }}
      className="bg-white h-full overflow-y-scroll rounded-lg"
    >
      <div>
        {/* Adjust maxHeight as needed */}
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  textAlign: "left",
                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  minWidth: "200px",
                  position: "sticky",
                  left: 0,
                  bgcolor: "#F9F9F9",
                }}
              >
                <TableSortLabel
                  active={orderBy === "keyword"}
                  direction={orderBy === "keyword" ? order : "asc"}
                  onClick={() => handleRequestSort("keyword")}
                >
                  Keywords
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "price" ? order : false}
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  bgcolor: "#F9F9F9",
                  textAlign: "center",
                }}
              >
                <TableSortLabel
                  active={orderBy === "price"}
                  direction={orderBy === "price" ? order : "asc"}
                  onClick={() => handleRequestSort("price")}
                >
                  Our Brand Price
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  bgcolor: "#F9F9F9",
                  textAlign: "center",
                }}
              >
                Our Brand Discount Price
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  bgcolor: "#F9F9F9",
                  textAlign: "center",
                }}
              >
                Our Brand Discount Percent
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  bgcolor: "#F9F9F9",
                  textAlign: "center",
                }}
              >
                Competitor's Avg. Price
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  bgcolor: "#F9F9F9",
                  textAlign: "center",
                }}
              >
                Competitor's Avg. Discount Price
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "500",
                  color: "#4D4D4D",
                  fontSize: "1rem",
                  bgcolor: "#F9F9F9",
                  textAlign: "center",
                }}
              >
                Competitor's Avg. Discount Percent
              </TableCell>
              <TableCell
                sx={{
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  bgcolor: "#F9F9F9",
                  right: 0,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((keyword, index) => {
              // Calculating average prices, discount prices, and discount percentages
              const keywordData = data[selectedSKU][keyword];
              if (!keywordData) return null;

              const ownBrand = keywordData.parentBrand || {};
              const otherBrand = keywordData.other || {};

              // Calculating average prices, discount prices, and discount percentages
              const ownBrandAveragePrice = (ownBrand.price / ownBrand.count || 0).toFixed(2);
              const ownBrandAverageDiscountedPrice = (ownBrand.discountPrice / ownBrand.count || 0).toFixed(2);
              const ownBrandAverageDiscountPercentage =
                (ownBrand.discountPercent / ownBrand.count || 0).toFixed(2) + "%";

              const otherBrandAveragePrice = (otherBrand.price / otherBrand.count || 0).toFixed(2);
              const otherBrandAverageDiscountedPrice = (otherBrand.discountPrice / otherBrand.count || 0).toFixed(2);
              const otherBrandAverageDiscountPercent =
                (otherBrand.discountPercent / otherBrand.count || 0).toFixed(2) + "%";

              return (
                <Fragment key={keyword}>
                  <TableRow
                    onClick={() => handleOpen(keyword)}
                    className="cursor-pointer"
                    sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}
                  >
                    <TableCell
                      sx={{
                        color: "#4D4D4D",
                        fontWeight: "500",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        minWidth: "200px",
                        position: "sticky",
                        left: 0,
                        bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                        zIndex: 1,
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {keyword}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {ownBrandAveragePrice !== "NaN" ? ownBrandAveragePrice : "NA"}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {ownBrandAverageDiscountedPrice !== "NaN" ? ownBrandAverageDiscountedPrice : "NA"}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {ownBrandAverageDiscountPercentage !== "NaN%" ? ownBrandAverageDiscountPercentage : "NA"}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {otherBrandAveragePrice !== "NaN" ? otherBrandAveragePrice : "NA"}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {otherBrandAverageDiscountedPrice !== "NaN" ? otherBrandAverageDiscountedPrice : "NA"}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {otherBrandAverageDiscountPercent !== "NaN%" ? otherBrandAverageDiscountPercent : "NA"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <KeywordInfoModal
        open={open}
        handleClose={handleClose}
        keyword={selectedKeyword}
        pricingData={pricingData}
        selectedSKU={selectedSKU}
      />
    </TableContainer>
  );
};

export default PricingCard;
