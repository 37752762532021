import React from "react";
import {
  productInsightsGroupingBlinkit,
  productInsightsGroupingInstamart,
  productInsightsGroupingZepto,
} from "../../../utils/dataGrouping/Insights/InsightsGrouping";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ProductSalesComparison = ({ platformName }) => {
  const { productInsights, productInsightsPrevious } = useSelector((state) => state.insights);
  let groupedData;
  let groupedDataPrevious;
  // Set grouped data according to platform name
  if (platformName === "Blinkit") {
    groupedData = productInsightsGroupingBlinkit(productInsights.data);
    groupedDataPrevious = productInsightsGroupingBlinkit(productInsightsPrevious.data);
  } else if (platformName === "Instamart") {
    groupedData = productInsightsGroupingInstamart(productInsights.data);
    groupedDataPrevious = productInsightsGroupingInstamart(productInsightsPrevious.data);
  } else {
    groupedData = productInsightsGroupingZepto(productInsights.data);
    groupedDataPrevious = productInsightsGroupingZepto(productInsightsPrevious.data);
  }

  const labels = groupedDataPrevious.map((item) => item.product); // Assuming both arrays have the same products

  // Prepare datasets for both arrays (e.g., 'mrp')
  const dataset1 = groupedData.map((item) => item.mrp);
  const dataset2 = groupedDataPrevious.map((item) => item.mrp);

  const data = {
    labels: labels, // Product names for the X-axis
    datasets: [
      {
        label: "Current", // Label for the first array
        data: dataset1, // Data for the first array
        borderColor: "#22C55E",
        fill: false,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: "#22C55E",
        pointBorderColor: "#22C55E",
        pointBorderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 4,
      },
      {
        label: "Previous", // Label for the second array
        data: dataset2, // Data for the second array
        borderColor: "#387FF5",
        fill: false,
        tension: 0.3,
        borderWidth: 2,
        pointBackgroundColor: "#387FF5",
        pointBorderColor: "#387FF5",
        pointBorderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#333", // Customize legend text color
          font: {
            size: 12, // Font size
            weight: "bold", // Set the font to bold
          },
          pointStyle: "circle", // This makes the labels round
          usePointStyle: true, // Ensures that the point style (circle) is used for the legend
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Products",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
        ticks: {
          beginAtZero: true,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
          autoSkip: false, // Do not skip labels
          maxRotation: 90, // Set maximum rotation to 90 degrees
          minRotation: 90, // Set minimum rotation to 90 degrees
          padding: 10, // Padding around ticks for better visibility
        },
        grid: {
          display: false, // Show grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "MRP",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
        ticks: {
          callback: (val) => `${(val / 1000).toFixed(0)}k`,
          beginAtZero: true,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
      },
    },
  };

  if (groupedData.length == 0) {
    return <div className="flex justify-center items-center h-full">No Product Comparison Data</div>;
  }

  return (
    <div className="w-[100%] h-[52rem]">
      <Line data={data} options={options} />
    </div>
  );
};

export default ProductSalesComparison;
