import { toast } from "react-toastify";
import moment from "moment"; // Import Moment.js
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";

export const fetchWithRetry = async (
  fetchFunction,
  successAction,
  failureAction,
  date,
  dispatch,
  attempt = 1,
  maxAttempts = 180
) => {
  if (attempt > maxAttempts) {
    dispatch(failureAction(new Error("No data available for the past 15 days.")));
    return;
  }

  try {
    console.log("Attempt:", attempt, "Date:", date);
    const result = await fetchFunction(date); // Call API with the provided date

    if (result && result.length > 0) {
      dispatch(successAction(result)); // Success case
      if (attempt > 1) {
        const formattedDate = moment(date).format("DD MMM, YYYY"); // Moment.js date formatting
        // Toast message with custom class
        toast.success(`Data is not found for the previous date. The new date is ${formattedDate}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast", // Custom class for styling
          bodyClassName: "custom-toast-body", // Styling for the body
          theme: "light", // Optional theme adjustment
        });
      }
    } else {
      throw new Error("No data found"); // Trigger retry if no data
    }
  } catch (error) {
    console.warn(`Retrying with previous date due to: ${error.message}`);

    // Clone the date object before modifying it
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1); // Go to previous day

    // Retry with the new date
    await fetchWithRetry(fetchFunction, successAction, failureAction, previousDate, dispatch, attempt + 1, maxAttempts);
  }
};

export const fetchWithRetryRange = async (
  fetchFunction, // API function to fetch data
  successAction, // Redux action on success
  failureAction, // Redux action on failure
  dateRange, // Initial date range [startDate, endDate]
  dispatch, // Redux dispatch function
  attempt = 1, // Current attempt number
  maxAttempts = 6 // Maximum attempts (each covering 30 days)
) => {
  if (attempt > maxAttempts) {
    dispatch(failureAction(new Error("No data available for the last 150 days."))); // Exceeds max retries
    return;
  }

  try {
    console.log(`Attempt ${attempt}: Date Range`, dateRange);
    const result = await fetchFunction(dateRange); // Call API with date range
    if (result && result.length > 0) {
      dispatch(successAction(result)); // Dispatch success if data is found
      if (attempt > 1) {
        // Toast message with custom class
        toast.success(
          `Data is not found for the previous date. The new date is ${moment(dateRange[0]).format(
            "DD MMM, YYYY"
          )}-${moment(dateRange[1]).format("DD MMM, YYYY")}`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "custom-toast", // Custom class for styling
            bodyClassName: "custom-toast-body", // Styling for the body
            theme: "light", // Optional theme adjustment
          }
        );
      }
    } else {
      throw new Error("No data found"); // Retry if no data is found
    }
  } catch (error) {
    console.warn(`Retrying with previous range due to: ${error.message}`);

    // Calculate the new date range by shifting it back 30 days
    const [startDate, endDate] = dateRange.map((date) => new Date(date));
    startDate.setDate(startDate.getDate() - 30);
    endDate.setDate(endDate.getDate() - 30);

    // Retry with the new date range
    await fetchWithRetryRange(
      fetchFunction,
      successAction,
      failureAction,
      [startDate.toISOString(), endDate.toISOString()],
      dispatch,
      attempt + 1,
      maxAttempts
    );
  }
};

export const fetchWithRetryPlatformName = async (
  fetchFunction,
  successAction,
  failureAction,
  date,
  dispatch,
  attempt = 1,
  maxAttempts = 180,
  platformName
) => {
  if (attempt > maxAttempts) {
    dispatch(failureAction(new Error("No data available for the past 15 days.")));
    return;
  }

  try {
    console.log("Attempt:", attempt, "Date:", date);
    const result = await fetchFunction(date, platformName); // Call API with the provided date
    if (result && result.length > 0) {
      dispatch(successAction(result)); // Success case
      if (attempt > 1) {
        const formattedDate = moment(date).format("DD MMM, YYYY"); // Moment.js date formatting
        // Toast message with custom class
        toast.success(`Data is not found for the previous date. The new date is ${formattedDate}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast", // Custom class for styling
          bodyClassName: "custom-toast-body", // Styling for the body
          theme: "light", // Optional theme adjustment
        });
      }
    } else {
      throw new Error("No data found"); // Trigger retry if no data
    }
  } catch (error) {
    console.warn(`Retrying with previous date due to: ${error.message}`);

    // Clone the date object before modifying it
    const previousDate = new Date(date);
    previousDate.setDate(previousDate.getDate() - 1); // Go to previous day

    // Retry with the new date
    await fetchWithRetryPlatformName(
      fetchFunction,
      successAction,
      failureAction,
      previousDate,
      dispatch,
      attempt + 1,
      maxAttempts,
      platformName
    );
  }
};
