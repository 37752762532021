import React from "react";
import { useSelector } from "react-redux";
import { FaChartArea } from "react-icons/fa"; // Importing chart icon
import { BiSolidCategoryAlt } from "react-icons/bi"; // Importing category icon
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton"; // Loading skeleton for charts
import TableSkeleton from "../../common/Skeletons/TableSkeleton"; // Loading skeleton for tables
import NoDataFound from "../../common/NoDataFound/NoDataFound"; // Component to display when there's no data
import DateWiseSalesLineChart from "./DateWiseSalesLineChart"; // Component for date-wise sales chart
import CategoryWiseSalesTable from "./CategoryWiseSalesTable"; // Component for category-wise sales table
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";

const CategorySkuSplitSectionsContainer = ({ platformName }) => {
  // Get total sales data from Redux store
  const { totalSales } = useSelector((state) => state.sales);

  // Show loading skeletons if data is still being fetched
  if (totalSales.loading) {
    return (
      <>
        {/* Display loading placeholders for both chart and table */}
        <ChartSkeleton />
        <TableSkeleton />
      </>
    );
  }

  // Show 'No Data Found' component if there's no data available.
  if (!totalSales.data || totalSales.data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      {/* Date Wise Overview Section */}
      <div className="bg-white shadow-md rounded-xl mb-4 mt-4">
        <div className="flex p-4 border-b">
          <h1 className="flex gap-4 items-center text-lg font-medium">
            {/* Icon for Date Wise Overview */}
            <FaChartArea />
            Date Wise Overview
            <CustomTooltip
              title={"Shows total sales data for each of the last 30 days, helping you track daily sales trends."}
            >
              <PiInfoLight />
            </CustomTooltip>
          </h1>
        </div>
        <div>
          {/* Date-wise sales line chart */}
          <DateWiseSalesLineChart />
        </div>
      </div>

      {/* Category Wise Sales Section */}
      <div className="mb-4 ">
        {/* Category-wise sales table based on selected platformName */}
        <CategoryWiseSalesTable platformName={platformName} />
      </div>
    </div>
  );
};

export default CategorySkuSplitSectionsContainer;

// Indexing of the code:

// --Date Wise Overview Section:
// A component displays a date-wise line chart (DateWiseSalesLineChart).
// Use case: Visualize sales trends over time.

// --Category Wise Sales Section:
// A second component shows a table of category-wise sales (CategoryWiseSalesTable).
// Use case: Display detailed sales breakdown by category based on the selected platform.
