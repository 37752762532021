export const dummyPricingData = [
  {
    sku: "101",
    product: "ghee",
    price: "40",
    discounted_price: "28",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "22",
    discounted_price: "19",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "57",
    discounted_price: "11",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "18",
    discounted_price: "13",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "10",
    discounted_price: "8",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "12",
    discounted_price: "9",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "55",
    discounted_price: "41",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "28",
    discounted_price: "23",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "45",
    discounted_price: "13",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "01-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "87",
    discounted_price: "14",
    brand: "Fortune",
    city: "Gurugram",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "80",
    discounted_price: "18",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "52",
    discounted_price: "19",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "25",
    discounted_price: "22",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "01-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "27",
    discounted_price: "24",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "01-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "90",
    discounted_price: "28",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "32",
    discounted_price: "30",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "35",
    discounted_price: "32",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "38",
    discounted_price: "34",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "01-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "42",
    discounted_price: "30",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "24",
    discounted_price: "20",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "60",
    discounted_price: "12",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "19",
    discounted_price: "14",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "12",
    discounted_price: "9",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "13",
    discounted_price: "10",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "58",
    discounted_price: "43",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "30",
    discounted_price: "25",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "48",
    discounted_price: "15",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "02-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "90",
    discounted_price: "16",
    brand: "Fortune",
    city: "Golf Course Road",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "85",
    discounted_price: "20",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "54",
    discounted_price: "21",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "27",
    discounted_price: "24",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "02-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "29",
    discounted_price: "26",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "02-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "92",
    discounted_price: "30",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "34",
    discounted_price: "32",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "38",
    discounted_price: "34",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "41",
    discounted_price: "36",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "02-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "44",
    discounted_price: "32",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "26",
    discounted_price: "21",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "62",
    discounted_price: "14",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "20",
    discounted_price: "15",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "13",
    discounted_price: "10",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "14",
    discounted_price: "11",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "60",
    discounted_price: "45",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "32",
    discounted_price: "27",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "50",
    discounted_price: "17",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "03-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "92",
    discounted_price: "18",
    brand: "Fortune",
    city: "Golf Course Road",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "87",
    discounted_price: "22",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "56",
    discounted_price: "23",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "29",
    discounted_price: "26",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "03-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "31",
    discounted_price: "28",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "03-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "94",
    discounted_price: "32",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "36",
    discounted_price: "34",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "40",
    discounted_price: "36",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "43",
    discounted_price: "38",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "03-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "48",
    discounted_price: "36",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "30",
    discounted_price: "24",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "65",
    discounted_price: "17",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "22",
    discounted_price: "17",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "15",
    discounted_price: "12",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "16",
    discounted_price: "13",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "65",
    discounted_price: "50",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "35",
    discounted_price: "30",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "53",
    discounted_price: "20",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "04-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "97",
    discounted_price: "22",
    brand: "Fortune",
    city: "Golf Course Road",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "90",
    discounted_price: "25",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "60",
    discounted_price: "26",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "32",
    discounted_price: "29",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "04-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "34",
    discounted_price: "31",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "04-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "97",
    discounted_price: "35",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "39",
    discounted_price: "37",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "44",
    discounted_price: "40",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "47",
    discounted_price: "42",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "04-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "50",
    discounted_price: "38",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "32",
    discounted_price: "25",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "67",
    discounted_price: "18",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "24",
    discounted_price: "19",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "18",
    discounted_price: "13",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "18",
    discounted_price: "15",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "68",
    discounted_price: "53",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "37",
    discounted_price: "32",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "57",
    discounted_price: "25",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "05-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "100",
    discounted_price: "25",
    brand: "Fortune",
    city: "Golf Course Road",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "93",
    discounted_price: "28",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "65",
    discounted_price: "30",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "35",
    discounted_price: "32",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "05-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "37",
    discounted_price: "33",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "05-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "100",
    discounted_price: "38",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "42",
    discounted_price: "39",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "48",
    discounted_price: "44",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "50",
    discounted_price: "45",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "05-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "55",
    discounted_price: "43",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "35",
    discounted_price: "28",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "73",
    discounted_price: "24",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "27",
    discounted_price: "22",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "22",
    discounted_price: "17",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "21",
    discounted_price: "17",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "72",
    discounted_price: "57",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "42",
    discounted_price: "37",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "60",
    discounted_price: "30",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "06-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "105",
    discounted_price: "30",
    brand: "Fortune",
    city: "Golf Course Road",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "98",
    discounted_price: "32",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "70",
    discounted_price: "35",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "38",
    discounted_price: "34",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "06-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "40",
    discounted_price: "36",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "06-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "105",
    discounted_price: "43",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "47",
    discounted_price: "44",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "53",
    discounted_price: "48",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "55",
    discounted_price: "50",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "06-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "58",
    discounted_price: "46",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "30",
    discounted_price: "26",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "75",
    discounted_price: "28",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "29",
    discounted_price: "25",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "25",
    discounted_price: "19",
    brand: "Anveshan",
    city: "Jaipur",
    sku_mapping: "",
    unitWeight: 100,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "24",
    discounted_price: "20",
    brand: "Amul",
    city: "Jaipur",
    sku_mapping: "101",
    unitWeight: 100,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "101",
    product: "ghee",
    price: "80",
    discounted_price: "63",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "102",
    product: "ghee",
    price: "45",
    discounted_price: "40",
    brand: "Amul",
    city: "Mumbai",
    sku_mapping: "101",
    unitWeight: 500,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "103",
    product: "oil",
    price: "65",
    discounted_price: "35",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "ml",
    date: "07-10-2024",
  },
  {
    sku: "104",
    product: "oil",
    price: "110",
    discounted_price: "35",
    brand: "Fortune",
    city: "Golf Course Road",
    sku_mapping: "103",
    unitWeight: 1000,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "105",
    product: "butter",
    price: "90",
    discounted_price: "28",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "106",
    product: "butter",
    price: "60",
    discounted_price: "29",
    brand: "Britannia",
    city: "Bangalore",
    sku_mapping: "105",
    unitWeight: 500,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "107",
    product: "milk",
    price: "35",
    discounted_price: "31",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 250,
    unitType: "ml",
    date: "07-10-2024",
  },
  {
    sku: "108",
    product: "milk",
    price: "36",
    discounted_price: "32",
    brand: "Mother Dairy",
    city: "Mumbai",
    sku_mapping: "107",
    unitWeight: 500,
    unitType: "ml",
    date: "07-10-2024",
  },
  {
    sku: "109",
    product: "yogurt",
    price: "110",
    discounted_price: "45",
    brand: "Anveshan",
    city: "Mumbai",
    sku_mapping: "",
    unitWeight: 1000,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "110",
    product: "yogurt",
    price: "50",
    discounted_price: "48",
    brand: "Nestle",
    city: "Mumbai",
    sku_mapping: "109",
    unitWeight: 400,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "111",
    product: "honey",
    price: "55",
    discounted_price: "52",
    brand: "Anveshan",
    city: "Bangalore",
    sku_mapping: "",
    unitWeight: 500,
    unitType: "gm",
    date: "07-10-2024",
  },
  {
    sku: "112",
    product: "honey",
    price: "58",
    discounted_price: "54",
    brand: "Dabur",
    city: "Jaipur",
    sku_mapping: "111",
    unitWeight: 600,
    unitType: "gm",
    date: "07-10-2024",
  },
];
