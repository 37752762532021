import React from "react";
import { useSelector } from "react-redux";
import TopTenKeywordsByBudget from "./TopTenKeywordsByBudget";
import { groupedDataByDate, groupedDataByKeywords } from "../../../utils/dataGrouping/productListingGrouping";
import ProductListingChart from "./ProductListingChart";
import TopTenCompaignBySpend from "./TopTenCompaignBySpend";
import { groupedDataByCampaignName, groupedDataByCampaign } from "../../../utils/dataGrouping/productListingGrouping";
import AdCampaigns from "./AdCampaigns";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Error from "../../common/Error/Error";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const ProductListingCampaignSectionsContainer = () => {
  const { productListing } = useSelector((state) => state.marketing);
  const { loading, data, error } = productListing;
  console.log("ProductListingdata===", data);
  if (loading) {
    return (
      <div>
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>
        <div className="bg-white rounded-xl">
          <ChartSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }

  if (error) {
    return <NoDataFound />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedData = groupedDataByCampaignName(data);
  console.log("groupedData===", groupedData);
  const groupedByCampaign = groupedDataByCampaign(data);
  const groupedDataKeyword = groupedDataByKeywords(data);
  const groupedProductsListingChartData = groupedDataByDate(data);

  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      <div>
        <div>
          <TopTenCompaignBySpend groupedData={groupedData} />
        </div>

        <div className="mt-4">
          <TopTenKeywordsByBudget groupedData={groupedDataKeyword} />
        </div>
        <div className="mt-4">
          <ProductListingChart groupedData={groupedProductsListingChartData} />
        </div>
        <div className="mt-4">
          <AdCampaigns groupedDataAd={groupedByCampaign} />
        </div>
      </div>
    </div>
  );
};

export default ProductListingCampaignSectionsContainer;
