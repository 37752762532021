import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export default function CustomTooltip({ title, children }) {
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <Tooltip
      sx={{
        cursor: "pointer",
      }}
      title={title}
      placement="top"
      arrow
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(positionRef.current.x, areaRef.current.getBoundingClientRect().y, 0, 0);
          },
        },
        sx: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: "#000", // Your desired background color
            color: "#fff", // Change the text color if needed
            borderRadius: "4px", // Optional: add border-radius
          },
          "& .MuiTooltip-arrow": {
            color: "#000", // Change the arrow color to match the tooltip background
          },
        },
      }}
    >
      <Box ref={areaRef} onMouseMove={handleMouseMove}>
        {children}
      </Box>
    </Tooltip>
  );
}
