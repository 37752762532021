import React from "react";
import { TbAlarmAverage } from "react-icons/tb";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const FulfillmentCard = ({ total, text, card }) => {
  const tooltipTitle =
    card == "first"
      ? "This shows the total number of purchase orders (POs) that were fulfilled ahead of the specified lead time, indicating efficient supply chain performance and early order completion."
      : card == "second"
      ? "This shows the total number of purchase orders (POs) that were fulfilled beyond the specified lead time, highlighting potential delays in the supply chain and areas for improvement in order fulfillment."
      : "This represents the average time taken to complete an appointment from scheduling to conclusion. A lower value indicates faster processing and better efficiency.";
  return (
    <div className="p-4 border bg-white rounded-lg shadow-md flex items-center space-x-4 w-[25%] h-40 relative">
      <span className="absolute right-1 top-1">
        <CustomTooltip title={tooltipTitle}>
          <PiInfoLight />
        </CustomTooltip>
      </span>
      <div className="text-3xl">
        <TbAlarmAverage />
      </div>
      <div>
        <h2 className="text-xl font-semibold">{text}</h2>
        <p className="text-lg">{total} </p>
      </div>
    </div>
  );
};

export default FulfillmentCard;
