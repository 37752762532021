import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getThirdPartyVisibilityRequest,
  getThirdPartyVisibilitySuccess,
  getThirdPartyVisibilityFailure,
} from "../../redux/actions/thirdPartyActions";
import {
  fetchKeywordRankingDataBlinkit,
  fetchKeywordRankingDataInstamart,
  fetchKeywordRankingDataZepto,
} from "../../services/adsServices/keywordRankingServices";
import SkuVsKeywordsSectionsContainer from "../../components/ads/KeywordRankingComponents/SkuVsKeywordsSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

// Define options for the ComponentSwitch
const options = ["Sku vs keyword"];

// Object mapping platforms to their respective data fetch functions
// const dataFetchFunctions = {
//   Blinkit: fetchKeywordRankingDataBlinkit,
//   Zepto: fetchKeywordRankingDataZepto,
//   Instamart: fetchKeywordRankingDataInstamart,
// };

const KeywordRankingScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);

  // Get the selected date from the SingleDatePickerProvider context
  const { selectedDate } = useDate();
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedOption(0); // Reset to "Sku vs keyword" view when platform changes
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchKeywordRanking: fetchKeywordRankingDataBlinkit,
      keywordActions: {
        request: getThirdPartyVisibilityRequest,
        success: getThirdPartyVisibilitySuccess,
        failure: getThirdPartyVisibilityFailure,
      },
    },
    Zepto: {
      fetchKeywordRanking: fetchKeywordRankingDataZepto,
      keywordActions: {
        request: getThirdPartyVisibilityRequest,
        success: getThirdPartyVisibilitySuccess,
        failure: getThirdPartyVisibilityFailure,
      },
    },
    Instamart: {
      fetchKeywordRanking: fetchKeywordRankingDataInstamart,
      keywordActions: {
        request: getThirdPartyVisibilityRequest,
        success: getThirdPartyVisibilitySuccess,
        failure: getThirdPartyVisibilityFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];

    if (platform) {
      dispatch(platform.keywordActions.request());

      fetchWithRetry(
        platform.fetchKeywordRanking,
        platform.keywordActions.success,
        platform.keywordActions.failure,
        selectedDate,
        dispatch,
        1
      );
    }
  }, [platformName, selectedDate]);

  return (
    <>
      {/* HeadingBar component handles platform selection and date display */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />

      <Toolbar>
        {/* ComponentSwitch allows switching between different views */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Conditionally render the SkuVsKeywordsSectionsContainer based on selectedOption */}
        {selectedOption === 0 && <SkuVsKeywordsSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default KeywordRankingScreen;
