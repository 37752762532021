import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

const ComponentSwitch = ({ options, targetBy, setTargetBy }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <div className="border-y bg-white shadow-sm">
        <Tabs
          value={targetBy}
          onChange={(event, newValue) => setTargetBy(newValue)}
          aria-label="secondary tabs example"
        >
          {options.map((item, index) => (
            <Tab
              value={index}
              label={item}
              key={item}
              sx={{
                textTransform: "none",
                padding: 2,
                "&.Mui-selected": {
                  backgroundColor: "#dee7f5",
                  borderBottom: "4px solid #387FF5",
                  color: "#387FF5",
                },
              }}
            />
          ))}
        </Tabs>
      </div>
    </Box>
  );
};

export default ComponentSwitch;
