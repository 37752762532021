import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShareOfVoiceCityWiseStatsCard from "./ShareOfVoiceCityWiseStatsCard";

const groupByKeywordAndArea = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const keyword = item["third_party_keyword.keyword"];
    const keywordType = item["third_party_keyword.keyword_type"];
    const city = item["third_party_keyword.city_lm"];
    const organic = parseFloat(item["third_party_keyword.totalSovOrgaincPercentage"]) || 0;
    const ad = parseFloat(item["third_party_keyword.totalSovAdPercentage"]) || 0;

    // Create a unique key for keyword + area combination
    const key = `${keyword}_${city}`;

    if (!groupedData[key]) {
      groupedData[key] = {
        keyword,
        keywordType,
        city,
        totalSovOrgaincPercentage: 0,
        totalSovAdPercentage: 0,
        count: 0, // For averaging
      };
    }

    // Sum organic and ad percentages and increase the count
    groupedData[key].totalSovOrgaincPercentage += organic;
    groupedData[key].totalSovAdPercentage += ad;
    groupedData[key].count += 1;
  });

  // Convert the groupedData object back into an array
  return Object.values(groupedData);
};

const calculateAveragesByArea = (groupedDatas) => {
  const areaSummedData = {};

  groupedDatas.forEach((item) => {
    const { city, totalSovOrgaincPercentage, totalSovAdPercentage, keywordType } = item;

    // If the area doesn't exist in areaSummedData, initialize it
    if (!areaSummedData[city]) {
      areaSummedData[city] = {
        city,
        keywordType,
        totalSovOrgaincPercentage: 0,
        totalSovAdPercentage: 0,
        totalCountAd: 0,
        totalCountOrganic: 0,
      };
    }

    // Sum up percentages and counts for each area
    areaSummedData[city].totalSovOrgaincPercentage += totalSovOrgaincPercentage;
    areaSummedData[city].totalSovAdPercentage += totalSovAdPercentage;

    // Increment counts based on organic/ad percentages
    if (totalSovOrgaincPercentage > 0) {
      areaSummedData[city].totalCountOrganic += 1;
    }
    if (totalSovAdPercentage > 0) {
      areaSummedData[city].totalCountAd += 1;
    }
  });

  // Now, calculate the average for each area
  const averagedData = Object.values(areaSummedData).map((item) => ({
    city: item.city,
    keywordType: item.keywordType,
    avgSovOrgaincPercentage: item.totalSovOrgaincPercentage / (item.totalCountOrganic || 1), // Avoid division by 0
    avgSovAdPercentage: item.totalSovAdPercentage / (item.totalCountAd || 1), // Avoid division by 0
  }));

  return averagedData;
};

const groupByKeywordAndAreaForWeeklyData = (weeklyData) => {
  const groupedWeeklyData = {};
  Object.keys(weeklyData).forEach((date) => {
    const dataForDate = weeklyData[date];
    dataForDate.forEach((item) => {
      const keyword = item["third_party_keyword.keyword"];
      const keywordType = item["third_party_keyword.keyword_type"];
      const city = item["third_party_keyword.city_lm"];
      const organic = parseFloat(item["third_party_keyword.totalSovOrgaincPercentage"]) || 0;
      const ad = parseFloat(item["third_party_keyword.totalSovAdPercentage"]) || 0;

      // Create a unique key for keyword + area + date combination
      const key = `${keyword}_${city}_${date}`;

      if (!groupedWeeklyData[key]) {
        groupedWeeklyData[key] = {
          keyword,
          keywordType,
          city,
          date,
          totalSovOrgaincPercentage: 0,
          totalSovAdPercentage: 0,
        };
      }
      // Sum organic and ad percentages for each keyword in the same area for the same date
      groupedWeeklyData[key].totalSovOrgaincPercentage += organic;
      groupedWeeklyData[key].totalSovAdPercentage += ad;
    });
  });
  // Convert the groupedWeeklyData object back into an array
  return Object.values(groupedWeeklyData);
};

// Function to calculate averages based on the grouped weekly data
const calculateAveragesByKeywordForWeeklyData = (groupedWeeklyDatas) => {
  const keywordSummedWeeklyData = {};

  groupedWeeklyDatas.forEach((item) => {
    const { city, keyword, totalSovOrgaincPercentage, totalSovAdPercentage, keywordType, date } = item;

    // Create a unique key for keyword + date combination
    const key = `${city}_${date}`;

    // If the keyword doesn't exist in the keywordSummedWeeklyData, initialize it
    if (!keywordSummedWeeklyData[key]) {
      keywordSummedWeeklyData[key] = {
        city,
        keyword,
        keywordType,
        date,
        totalSovOrgaincPercentage: 0,
        totalSovAdPercentage: 0,
        totalCountAd: 0,
        totalCountOrganic: 0,
      };
    }

    // Sum up percentages and counts for each keyword
    keywordSummedWeeklyData[key].totalSovOrgaincPercentage += totalSovOrgaincPercentage;
    keywordSummedWeeklyData[key].totalSovAdPercentage += totalSovAdPercentage;
    if (totalSovOrgaincPercentage > 0) {
      keywordSummedWeeklyData[key].totalCountOrganic += 1;
    }
    if (totalSovAdPercentage > 0) {
      keywordSummedWeeklyData[key].totalCountAd += 1;
    }
  });

  // Now, calculate the average for each keyword
  const averagedWeeklyData = Object.values(keywordSummedWeeklyData).map((item) => ({
    city: item.city,
    keyword: item.keyword,
    keywordType: item.keywordType,
    date: item.date,
    avgSovOrgaincPercentage: item.totalSovOrgaincPercentage / item.totalCountOrganic || 0,
    avgSovAdPercentage: item.totalSovAdPercentage / item.totalCountAd || 0,
  }));

  return averagedWeeklyData;
};

const getOldestDateData = (averagedWeeklyData) => {
  // Check if averagedWeeklyData exists and has elements
  if (!averagedWeeklyData || averagedWeeklyData.length === 0) {
    return [];
  }

  // Sort the data by date in ascending order (oldest first)
  const sortedData = averagedWeeklyData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Get the oldest date
  const oldestDate = sortedData[0]?.date;

  // Return only the data that matches the oldest date
  return sortedData.filter((item) => item.date === oldestDate);
};

const calculatePercentageChange = (oldestData, averagedData) => {
  return averagedData.map((currentItem) => {
    const matchingOldItem = oldestData.find((oldItem) => oldItem.area === currentItem.area);

    // If we found a matching keyword in both datasets
    if (matchingOldItem) {
      const organicChange =
        ((currentItem.avgSovOrgaincPercentage - matchingOldItem.avgSovOrgaincPercentage) /
          matchingOldItem.avgSovOrgaincPercentage) *
          100 || 0;
      const adChange =
        ((currentItem.avgSovAdPercentage - matchingOldItem.avgSovAdPercentage) / matchingOldItem.avgSovAdPercentage) *
          100 || 0;

      return {
        city: currentItem.city,
        keywordType: currentItem.keywordType,
        organicChange: isFinite(organicChange) ? organicChange.toFixed(2) : "No previous data",
        adChange: isFinite(adChange) ? adChange.toFixed(2) : "No previous data",
      };
    } else {
      // If no matching keyword found in oldest data, return "No previous data"
      return {
        city: currentItem.city,
        keywordType: currentItem.keywordType,
        organicChange: "No previous data",
        adChange: "No previous data",
      };
    }
  });
};

const ShareOfVoiceCityWise = ({ keyword }) => {
  const { sovAreaNameData, filteredAreaNames, filteredWeeklyAreaNames } = useSelector((state) => state.sovThirdParty);

  const [selectedCard, setSelectedCard] = useState(0);
  const dispatch = useDispatch();

  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);
  const data = filteredAreaNames.data.filter(
    (entry) =>
      entry["third_party_keyword.brand"] &&
      parsedUserData?.organizationDetail?.name &&
      entry["third_party_keyword.brand"] === parsedUserData?.organizationDetail?.name
  );

  const sumPercentage = (value1, value2) => (parseFloat(value1) + parseFloat(value2)).toFixed(2);

  // Group by keyword, area_name_lm, and product_name and add sov_ad_percentage + sov_organic_percentage for the same entry
  const addingAdandOrganicOfSameData = data.reduce((acc, curr) => {
    const keyword = curr["third_party_keyword.keyword"];
    const city = curr["third_party_keyword.city_lm"];
    const product = curr["third_party_keyword.product_name"];
    const keyword_type = curr["third_party_keyword.keyword_type"];

    // Create a unique key based on the grouping criteria
    const key = `${keyword}_${city}_${product}`;

    if (!acc[key]) {
      acc[key] = {
        "third_party_keyword.keyword": keyword,
        "third_party_keyword.keyword_type": keyword_type,
        "third_party_keyword.city_lm": city,
        "third_party_keyword.product_name": product,
        "third_party_keyword.totalSovAdPercentage": parseFloat(curr["third_party_keyword.sov_ad_percentage"]) || 0,
        "third_party_keyword.totalSovOrgaincPercentage":
          parseFloat(curr["third_party_keyword.sov_organic_percentage"]) || 0,
      };
    } else {
      // Add percentages for matching entries
      acc[key]["third_party_keyword.totalSovAdPercentage"] = sumPercentage(
        acc[key]["third_party_keyword.totalSovAdPercentage"],
        curr["third_party_keyword.sov_ad_percentage"]
      );
      acc[key]["third_party_keyword.totalSovOrgaincPercentage"] = sumPercentage(
        acc[key]["third_party_keyword.totalSovOrgaincPercentage"],
        curr["third_party_keyword.sov_organic_percentage"]
      );
    }

    return acc;
  }, {});

  // Convert the grouped object back to an array
  const clubedDataBySameEntries = Object.values(addingAdandOrganicOfSameData);

  // first filtering the data by current brand and then seprate the data by dates.
  const weeklyData = filteredWeeklyAreaNames.data
    .filter(
      (entry) =>
        entry["third_party_keyword.brand"] &&
        parsedUserData?.organizationDetail?.name &&
        entry["third_party_keyword.brand"] === parsedUserData.organizationDetail.name
    )
    .reduce((acc, item) => {
      const date = item["third_party_keyword.date"];

      // If the date doesn't exist in the accumulator, initialize it as an empty array
      if (!acc[date]) {
        acc[date] = [];
      }

      // Push the current item into the array for this date
      acc[date].push(item);

      return acc;
    }, {});

  // Now, let's group by `keyword`, `area_name`, and `product_name` and sum the percentages
  const addingAdandOrganicOfSameDataForWeekly = Object.keys(weeklyData).reduce((acc, date) => {
    const groupedByKeywordAreaProduct = weeklyData[date].reduce((groupAcc, item) => {
      const {
        "third_party_keyword.keyword": keyword,
        "third_party_keyword.keyword_type": keyword_type,
        "third_party_keyword.city_lm": city_name,
        "third_party_keyword.product_name": product_name,
        "third_party_keyword.sov_ad_percentage": sovAdPercentage,
        "third_party_keyword.sov_organic_percentage": sovOrganicPercentage,
      } = item;

      // Create a unique key for grouping by keyword, area_name, and product_name
      const groupKey = `${keyword}_${city_name}_${product_name}`;

      if (!groupAcc[groupKey]) {
        groupAcc[groupKey] = {
          "third_party_keyword.keyword": keyword,
          "third_party_keyword.keyword_type": keyword_type,
          "third_party_keyword.city_lm": city_name,
          "third_party_keyword.product_name": product_name,
          "third_party_keyword.totalSovAdPercentage": sovAdPercentage,
          "third_party_keyword.totalSovOrgaincPercentage": sovOrganicPercentage,
        };
      } else {
        // Sum the `sov_ad_percentage` and `sov_organic_percentage`
        groupAcc[groupKey]["third_party_keyword.totalSovAdPercentage"] = sumPercentage(
          groupAcc[groupKey]["third_party_keyword.totalSovAdPercentage"],
          sovAdPercentage
        );
        groupAcc[groupKey]["third_party_keyword.totalSovOrgaincPercentage"] = sumPercentage(
          groupAcc[groupKey]["third_party_keyword.totalSovOrgaincPercentage"],
          sovOrganicPercentage
        );
      }

      return groupAcc;
    }, {});

    acc[date] = Object.values(groupedByKeywordAreaProduct);
    return acc;
  }, {});

  const groupedDatas = groupByKeywordAndArea(clubedDataBySameEntries);
  const groupedWeeklyDatas = groupByKeywordAndAreaForWeeklyData(addingAdandOrganicOfSameDataForWeekly);

  const averagedData = calculateAveragesByArea(groupedDatas);
  const averagedWeeklyData = calculateAveragesByKeywordForWeeklyData(groupedWeeklyDatas);

  // Example usage:
  const oldestData = getOldestDateData(averagedWeeklyData);

  // Example usage:
  const percentageChangeData = calculatePercentageChange(oldestData, averagedData);

  const handleCardChange = (index) => {
    setSelectedCard(index + 1);
  };
  console.log("Gde==========", groupedDatas);
  // Convert values from strings to numbers for the total calculations
  // Convert values from strings to numbers for the total calculations
  const totalAd = averagedData.reduce((total, item) => total + item.avgSovAdPercentage, 0) || 0;
  const totalOrganic = averagedData.reduce((total, item) => total + item.avgSovOrgaincPercentage, 0) || 0;
  const countOfAd = averagedData.filter((item) => item.avgSovAdPercentage > 0).length || 0;
  const countOfOrganic = averagedData.filter((item) => item.avgSovOrgaincPercentage > 0).length || 0;
  const ad = totalAd / countOfAd || 0;
  const organic = totalOrganic / countOfOrganic || 0;
  const overall = ad + organic || 0;

  // Calculate percentage changes for overall (Pan India)
  const totalOldestAd = oldestData.reduce((total, item) => total + item.avgSovAdPercentage, 0) || 0;
  const totalOldestOrganic = oldestData.reduce((total, item) => total + item.avgSovOrgaincPercentage, 0) || 0;
  const oldestAd = totalOldestAd / (oldestData.filter((item) => item.avgSovAdPercentage > 0).length || 1);
  const oldestOrganic =
    totalOldestOrganic / (oldestData.filter((item) => item.avgSovOrgaincPercentage > 0).length || 1);
  const overallOldest = oldestAd + oldestOrganic || 0;

  const adChangeOverall = ((ad - oldestAd) / oldestAd) * 100 || 0;
  const organicChangeOverall = ((organic - oldestOrganic) / oldestOrganic) * 100 || 0;
  const overallChange = ((overall - overallOldest) / overallOldest) * 100 || 0;

  return (
    <>
      {groupedDatas
        .filter((item) => item.keyword == keyword)
        .map((item, index) => {
          const percentageChange = percentageChangeData.find((changeItem) => changeItem.area === item.area);

          return (
            <ShareOfVoiceCityWiseStatsCard
              key={index}
              titleName={item.city}
              ad={item.totalSovAdPercentage}
              organic={item.totalSovOrgaincPercentage}
              overall={item.totalSovAdPercentage + item.totalSovOrgaincPercentage}
              adChange={percentageChange?.adChange || "No previous data"}
              organicChange={percentageChange?.organicChange || "No previous data"}
            />
          );
        })}
    </>
  );
};

export default ShareOfVoiceCityWise;
