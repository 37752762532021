export const GET_RCA_CATEGORY_REQUEST = "GET_RCA_CATEGORY_REQUEST";
export const GET_RCA_CATEGORY_SUCCESS = "GET_RCA_CATEGORY_SUCCESS";
export const GET_RCA_CATEGORY_FAILURE = "GET_RCA_CATEGORY_FAILURE";

export const GET_RCA_CITY_REQUEST = "GET_RCA_CITY_REQUEST";
export const GET_RCA_CITY_SUCCESS = "GET_RCA_CITY_SUCCESS";
export const GET_RCA_CITY_FAILURE = "GET_RCA_CITY_FAILURE";

export const GET_RCA_SKU_REQUEST = "GET_RCA_SKU_REQUEST";
export const GET_RCA_SKU_SUCCESS = "GET_RCA_SKU_SUCCESS";
export const GET_RCA_SKU_FAILURE = "GET_RCA_SKU_FAILURE";

export const SELECTED_RCA_CATEGORY = "SELECTED_RCA_CATEGORY";
export const SELECTED_RCA_CITY = "SELECTED_RCA_CITY";
export const SELECTED_RCA_SKU = "SELECTED_RCA_SKU";

export const FILTERED_RCA_CITY = "FILTERED_RCA_CITY";
export const FILTERED_RCA_SKU = "FILTERED_RCA_SKU";