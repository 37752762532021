import {
  POTENTIAL_SALES_LOSS_BLINKIT_REQUEST,
  POTENTIAL_SALES_LOSS_BLINKIT_SUCCESS,
  POTENTIAL_SALES_LOSS_BLINKIT_FAILURE,
  POTENTIAL_SALES_LOSS_ZEPTO_REQUEST,
  POTENTIAL_SALES_LOSS_ZEPTO_SUCCESS,
  POTENTIAL_SALES_LOSS_ZEPTO_FAILURE,
  POTENTIAL_SALES_LOSS_INSTAMART_REQUEST,
  POTENTIAL_SALES_LOSS_INSTAMART_SUCCESS,
  POTENTIAL_SALES_LOSS_INSTAMART_FAILURE,
} from "../constants/potentialSalesLossConstants.js";

const initialState = {
  potentialSalesLossBlinkit: {
    loading: false,
    data: [],
    error: null,
  },
  potentialSalesLossZepto: {
    loading: false,
    data: [],
    error: null,
  },
  potentialSalesLossInstamart: {
    loading: false,
    data: [],
    error: null,
  },
};

export const potentialSalesLossReducer = (state = initialState, action) => {
  switch (action.type) {
    // Potential sales loss blinkit cases
    case POTENTIAL_SALES_LOSS_BLINKIT_REQUEST:
      return {
        ...state,
        potentialSalesLossBlinkit: {
          ...state.potentialSalesLossBlinkit,
          loading: true,
          error: null,
        },
      };
    case POTENTIAL_SALES_LOSS_BLINKIT_SUCCESS:
      return {
        ...state,
        potentialSalesLossBlinkit: {
          ...state.potentialSalesLossBlinkit,
          loading: false,
          data: action.payload,
        },
      };
    case POTENTIAL_SALES_LOSS_BLINKIT_FAILURE:
      return {
        ...state,
        potentialSalesLossBlinkit: {
          ...state.potentialSalesLossBlinkit,
          loading: false,
          error: action.payload,
        },
      };

    // Potential sales loss zepto cases
    case POTENTIAL_SALES_LOSS_ZEPTO_REQUEST:
      return {
        ...state,
        potentialSalesLossZepto: {
          ...state.potentialSalesLossZepto,
          loading: true,
          error: null,
        },
      };
    case POTENTIAL_SALES_LOSS_ZEPTO_SUCCESS:
      return {
        ...state,
        potentialSalesLossZepto: {
          ...state.potentialSalesLossZepto,
          loading: false,
          data: action.payload,
        },
      };
    case POTENTIAL_SALES_LOSS_ZEPTO_FAILURE:
      return {
        ...state,
        potentialSalesLossZepto: {
          ...state.potentialSalesLossZepto,
          loading: false,
          error: action.payload,
        },
      };

    // Potential sales loss Instamart cases
    case POTENTIAL_SALES_LOSS_INSTAMART_REQUEST:
      return {
        ...state,
        potentialSalesLossInstamart: {
          ...state.potentialSalesLossInstamart,
          loading: true,
          error: null,
        },
      };
    case POTENTIAL_SALES_LOSS_INSTAMART_SUCCESS:
      return {
        ...state,
        potentialSalesLossInstamart: {
          ...state.potentialSalesLossInstamart,
          loading: false,
          data: action.payload,
        },
      };
    case POTENTIAL_SALES_LOSS_INSTAMART_FAILURE:
      return {
        ...state,
        potentialSalesLossInstamart: {
          ...state.potentialSalesLossInstamart,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
