import dayjs from "dayjs";
import React from "react";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const TopUpcomingAppointments = ({ path, data, heading }) => {
  const navigate = useNavigate();

  const handleViewMore = () => {
    navigate(path); // This will navigate to the /ads page
  };

  return (
    <div onClick={handleViewMore} className="hover:cursor-pointer bg-white h-full ">
      <div className="flex justify-between items-center">
        <h2 className="text-md leading-5 text-[#030229] font-body font-medium text-start mb-2">{heading}</h2>
      </div>

      <div className="p-4 border  rounded-lg">
        <ul className="space-y-2  ">
          {data.length > 0 ? (
            data?.map((appointment, index) => (
              <li
                key={index}
                className={` h-[148px] w-full  flex flex-col justify-between px-3 py-4 border rounded-lg shadow-sm`}
              >
                <span className="text-md font-medium font-body text-[#727278]">{appointment.item_name}</span>
                <div className=" ">
                  <div className="gap-1 mt-2">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-3 items-center">
                        <span className="text-xs text-[#727278] font-medium font-body">PO Number :</span>
                        <span className="text-[#8E8E8E] text-xs font-medium font-body">{appointment.po_number}</span>
                      </div>
                      <div className="flex gap-3 items-center">
                        <span className="text-xs text-[#727278] font-medium font-body">Total Value : :</span>
                        <span className="text-[#8E8E8E] text-xs font-medium font-body">
                          {" "}
                          {appointment.total_amount}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="gap-1 mt-2">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-3 items-center">
                        <span className="text-xs text-[#727278] font-medium font-body">Order Date :</span>
                        <span className="text-[#22C55E] text-xs font-medium font-body">
                          {" "}
                          {dayjs(appointment.order_date).format("DD-MMM-YYYY")} {/* Display total quantity sold */}
                        </span>
                      </div>
                      <div className="flex gap-3 items-center">
                        <span className="text-xs text-[#727278] font-medium font-body">Expiry Date :</span>
                        <span className="text-[#F53E3E] text-xs font-medium font-body">
                          {" "}
                          {dayjs(appointment.expiry_date).format("DD-MMM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="gap-1 mt-2">
                    <div className="flex justify-between items-center ">
                      <div className="flex gap-3 items-center">
                        <span className="text-xs text-[#727278] font-medium font-body">Post State -</span>
                        <span className="text-[#387FF5] p-1 rounded bg-[#387FF51A] text-xs font-medium font-body">
                          {" "}
                          {appointment.po_state} {/* Display total quantity sold */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="h-full flex justify-center items-center">No {heading}</div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopUpcomingAppointments;
