import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TopPerformingCities from "./TopPerformingCities";
import TopGainers from "./TopGainers";
import TopDrainers from "./TopDrainers";
import {
  monthlyInsightsGroupingBlinkit,
  monthlyInsightsGroupingInstamart,
  monthlyInsightsGroupingZepto,
} from "../../../utils/dataGrouping/Insights/InsightsGrouping";
import { BsGraphUpArrow } from "react-icons/bs";
import { Link } from "react-router-dom";
import ProductSalesComparison from "./ProductSalesComparison";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import ShowSelectedDate from "../../common/ShowSelectedDate/ShowSelectedDate";
import dayjs from "dayjs";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const SalesReportInsights = ({ platformName }) => {
  const { cityProfitability } = useSelector((state) => state.profitability);
  const { monthlyInsights, cityInsights } = useSelector((state) => state.insights);
  const [previous, setPrevious] = useState(null);
  const [current, setCurrent] = useState(null);
  const { selectedDate } = useDate();
  const startDate1 = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(startDate1).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const startDate = dayjs(endDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");

  const { data } = monthlyInsights || {}; // Safely access data
  const currentMonthDataApi = data?.[data.length - 1];
  const previousMonthDataApi = data?.[data.length - 2] || currentMonthDataApi;

  useEffect(() => {
    if (!data) return; // Check if data exists
    let currentMonthData, previousMonthData;

    if (platformName === "Blinkit") {
      currentMonthData = monthlyInsightsGroupingBlinkit(currentMonthDataApi);
      previousMonthData = monthlyInsightsGroupingBlinkit(previousMonthDataApi);
    } else if (platformName === "Instamart") {
      currentMonthData = monthlyInsightsGroupingInstamart(currentMonthDataApi);
      previousMonthData = monthlyInsightsGroupingInstamart(previousMonthDataApi);
    } else {
      currentMonthData = monthlyInsightsGroupingZepto(currentMonthDataApi);
      previousMonthData = monthlyInsightsGroupingZepto(previousMonthDataApi);
    }

    setCurrent(currentMonthData);
    setPrevious(previousMonthData);
  }, [monthlyInsights?.data, platformName]);

  if (!cityProfitability.data || cityProfitability.data.length == 0) {
    return (
      <div className="bg-white rounded-lg my-2">
        <div className="p-4 text-lg font-medium border-b flex gap-4 items-center justify-between">
          <span className="flex items-center gap-4">
            <BsGraphUpArrow className="text-xl" />
            Sales Report Insights
          </span>
          <ShowSelectedDate date={[startDate, endDate1]} />
        </div>
        <div className="h-96 flex justify-center items-center">
          <NoDataFound height={200} width={200} maximum={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="my-2 bg-white rounded shadow-md">
      <div className="p-4 text-lg font-medium border-b flex gap-4 items-center justify-between">
        <span className="flex items-center gap-4">
          <BsGraphUpArrow className="text-xl" />
          Sales Report Insights
        </span>
        <ShowSelectedDate date={[startDate, endDate1]} />
      </div>
      <div className="p-4">
        <div className="flex gap-4">
          <Link to={"/revenue/sales-report"} className="cursor-pointer flex-1">
            <TopPerformingCities
              salesData={cityProfitability?.data || []}
              cityInsights={cityInsights?.data || []}
              platformName={platformName}
            />
          </Link>
          {previous && current && (
            <Link to={"/revenue/sales-report"} className="cursor-pointer flex-1">
              <TopDrainers previous={previous} current={current} />
            </Link>
          )}
          {previous && current && (
            <Link to={"/revenue/sales-report"} className="cursor-pointer flex-1">
              <TopGainers previous={previous} current={current} />
            </Link>
          )}
        </div>
        <div className="flex flex-col w-full mt-10">
          <span className="text-base ml-1 font-body text-[#4F4E69] font-medium">MRP Comparison</span>

          <Link
            to={"/revenue/sales-report"}
            className="w-full mt-2 h-[54.5rem] overflow-x-scroll rounded-[0.625rem] cursor-pointer border p-4"
          >
            <ProductSalesComparison platformName={platformName} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SalesReportInsights;
