import {
  THIRDPARTY_AVAILABILITY_REQUEST,
  THIRDPARTY_AVAILABILITY_SUCCESS,
  THIRDPARTY_AVAILABILITY_FAILURE,
  THIRDPARTY_VISIBILITY_REQUEST,
  THIRDPARTY_VISIBILITY_SUCCESS,
  THIRDPARTY_VISIBILITY_FAILURE,
  FETCH_AVAILABILITY_WEEKLY_REQUEST,
  FETCH_AVAILABILITY_WEEKLY_SUCCESS,
  FETCH_AVAILABILITY_WEEKLY_FAILURE,
} from "../constants/thirdPartyConstants";

export const getThirdPartyAvailabilityRequest = () => {
  return {
    type: THIRDPARTY_AVAILABILITY_REQUEST,
  };
};

export const getThirdPartyAvailabilitySuccess = (data) => {
  return {
    type: THIRDPARTY_AVAILABILITY_SUCCESS,
    payload: data,
  };
};

export const getThirdPartyAvailabilityFailure = (error) => {
  return {
    type: THIRDPARTY_AVAILABILITY_FAILURE,
    payload: error,
  };
};

export const getThirdPartyVisibilityRequest = () => {
  return {
    type: THIRDPARTY_VISIBILITY_REQUEST,
  };
};

export const getThirdPartyVisibilitySuccess = (data) => {
  return {
    type: THIRDPARTY_VISIBILITY_SUCCESS,
    payload: data,
  };
};

export const getThirdPartyVisibilityFailure = (error) => {
  return {
    type: THIRDPARTY_VISIBILITY_FAILURE,
    payload: error,
  };
};

export const fetchAvailabilityWeeklyRequest = () => ({
  type: FETCH_AVAILABILITY_WEEKLY_REQUEST,
});
export const fetchAvailabilityWeeklySuccess = (data) => ({
  type: FETCH_AVAILABILITY_WEEKLY_SUCCESS,
  payload: data,
});
export const fetchAvailabilityWeeklyFailure = (error) => ({
  type: FETCH_AVAILABILITY_WEEKLY_FAILURE,
  payload: error,
});
