import React, { createContext, useContext } from 'react';
import useDateRange from './useDateRange';

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
  const dateRangeState = useDateRange();
  
  return (
    <DateRangeContext.Provider value={dateRangeState}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRangeContext = () => useContext(DateRangeContext);
