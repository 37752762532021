import {
  blinkitFullfillmentReportQuery,
  instamartFulfillmentQuery,
  zeptoFulfillmentQuery,
} from "../../utils/queries/fulfillmentReportQueries"; // Import query functions for each platform
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchFulfillmentReportBlinkitData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitFullfillmentReportQuery(selectedDate));
  return result.tablePivot();
};

export const fetchFulfillmentReportInstamartData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartFulfillmentQuery(selectedDate));
  return result.tablePivot();
};

export const fetchFulfillmentReportZeptoData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoFulfillmentQuery(selectedDate));
  return result.tablePivot();
};
