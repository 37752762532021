import React from "react";
import { BiSolidChart } from "react-icons/bi";
import ShareOfVoiceOverviewChartCard from "./ShareOfVoiceOverviewChartCard";
import { dummyShareofVoiceData } from "./dummyShareofVoiceData";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs"; // Import dayjs
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import { IoIosInformationCircleOutline } from "react-icons/io";

const groupByDate = (data) => {
  return data.reduce((acc, curr) => {
    const { date, avgSovOrgaincPercentage, avgSovAdPercentage } = curr;

    // Initialize the date group if it doesn't exist
    if (!acc[date]) {
      acc[date] = {
        ad: 0,
        organic: 0,
        ad_count: 0,
        organic_count: 0,
      };
    }

    // Sum up the values for each date
    acc[date].organic += avgSovOrgaincPercentage || 0;
    acc[date].ad += avgSovAdPercentage || 0;

    // Increment counts for valid sov values
    if (avgSovAdPercentage) {
      acc[date].ad_count++;
    }

    if (avgSovOrgaincPercentage) {
      acc[date].organic_count++;
    }

    return acc;
  }, {});
};

// Function to format dates (day and month only)
const formatDate = (dateString) => {
  const options = { day: "numeric", month: "short" }; // e.g., "24 Sept"
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

// Helper function to calculate percentage change
const calculatePercentageChange = (current, previous) => {
  if (previous === 0) return 0; // Avoid division by zero
  return ((current - previous) / previous) * 100;
};

const ShareOfVoiceOverview = ({ averagedData, averagedWeeklyData }) => {
  console.log("averagedData===:", averagedData);
  const { selectedDate } = useDate();
  // Group data by date
  const groupedWeeklyData = groupByDate(averagedWeeklyData);
  console.log(" groupedWeeklyData:", groupedWeeklyData);

  // Get all the dates
  const dates = Object.keys(groupedWeeklyData).map((date) => formatDate(date));
  // Convert values from strings to numbers for the total calculations
  const totalAd = averagedData.reduce((total, item) => total + item.avgSovAdPercentage, 0) || 0;
  const totalOrganic = averagedData.reduce((total, item) => total + item.avgSovOrgaincPercentage, 0) || 0;
  const countOfAd = averagedData.filter((item) => item.avgSovAdPercentage > 0).length || 0;
  const countOfOrganic = averagedData.filter((item) => item.avgSovOrgaincPercentage > 0).length || 0;
  const ad = totalAd / countOfAd || 0;
  const organic = totalOrganic / countOfOrganic || 0;
  const overall = ad + organic || 0;
  // Map the grouped data into chart values
  const organicValues = Object.values(groupedWeeklyData).map((item) => item.organic / item.organic_count || 0);
  const adValues = Object.values(groupedWeeklyData).map((item) => item.ad / item.ad_count || 0);
  const overallValues = Object.values(groupedWeeklyData).map(
    (item) => item.ad / item.ad_count + item.organic / item.organic_count || 0
  );

  // Calculate percentage changes for the most recent vs older data
  const lastAdValue = adValues[adValues.length - 1] || 0;
  const previousAdValue = adValues[adValues.length - 2] || 0;
  const adPercentageChange = calculatePercentageChange(lastAdValue, previousAdValue);

  const lastOrganicValue = organicValues[organicValues.length - 1] || 0;
  const previousOrganicValue = organicValues[organicValues.length - 2] || 0;
  const organicPercentageChange = calculatePercentageChange(lastOrganicValue, previousOrganicValue);

  const lastOverallValue = overallValues[overallValues.length - 1] || 0;
  const previousOverallValue = overallValues[overallValues.length - 2] || 0;
  const overallPercentageChange = calculatePercentageChange(lastOverallValue, previousOverallValue);

  return (
    <div className="bg-white shadow-md rounded-xl mb-4 mt-4">
      <div className="flex justify-between py-4 px-5 border-b">
        <h1 className="flex gap-4 items-center text-lg font-medium ">
          <BiSolidChart />
          <span className="font-medium text-lg text-[#4f4e69]">Overview</span>
          <CustomTooltip
            title={"Shows total sales data for each of the last 30 days, helping you track daily sales trends."}
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>

        <div
          className={`flex w-auto items-center p-2 gap-1 text-xs font-semibold rounded-sm bg-[#F3F8FF] text-[#387FF5]`}
        >
          <IoIosInformationCircleOutline className="font-semibold text-xs" />
          <span className="text-medium">{dayjs(selectedDate).format("D MMM YYYY")}</span> <span>vs</span>
          <span className="text-medium">Last 7 Days</span>
        </div>
      </div>
      <div className="py-6">
        <div className="flex items-center gap-5 justify-center overflow-x-scroll flex-wrap ">
          <ShareOfVoiceOverviewChartCard
            title={"Overall Sov"}
            dates={dates}
            total={overall}
            values={overallValues}
            percentageChange={overallPercentageChange}
          />
          <ShareOfVoiceOverviewChartCard
            title={"Ad Sov"}
            dates={dates}
            total={ad}
            values={adValues}
            percentageChange={adPercentageChange}
          />
          <ShareOfVoiceOverviewChartCard
            title={"Organic Sov"}
            dates={dates}
            total={organic}
            values={organicValues}
            percentageChange={organicPercentageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareOfVoiceOverview;
