import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { FaRegCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import Papa from "papaparse";
const tooltipTitle =
  "This table displays all confirmed appointments with details like order date and other relevant information. Use this to track and manage upcoming commitments efficiently.";

// Helper function for sorting
const sortData = (data, orderBy, orderDirection) => {
  return data.sort((a, b) => {
    const dateA = dayjs(a[orderBy]);
    const dateB = dayjs(b[orderBy]);
    if (orderDirection === "asc") {
      return dateA.isBefore(dateB) ? -1 : 1;
    } else {
      return dateA.isBefore(dateB) ? 1 : -1;
    }
  });
};

const getAppointmentsOn = (data) => {
  // const last10Days = getLastNDays(
  //   10,
  //   data[0]["fulfillment_report_blinkit.order_date"]
  // );
  let filteredData = {};
  data?.forEach((item) => {
    const poNumber = item["fulfillment_report_blinkit.po_number"];
    const facilityName = item["fulfillment_report_blinkit.facility_name"];
    const total_amount = parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0;
    const groupKey = `${poNumber}_${facilityName}`;
    if (
      item["fulfillment_report_blinkit.po_state"] === "Created" &&
      item["fulfillment_report_blinkit.appointment_date"] === "" &&
      !filteredData[groupKey]
      // last10Days.includes(
      //   item["fulfillment_report_blinkit.order_date"].slice(0, 10)
      // )
    ) {
      filteredData[groupKey] = {
        po_number: poNumber,
        facility_name: facilityName,
        item_name: item["fulfillment_report_blinkit.name"],
        total_amount: total_amount,
        po_state: item["fulfillment_report_blinkit.po_state"],
        order_date: item["fulfillment_report_blinkit.order_date"],
        appointment_date: item["fulfillment_report_blinkit.appointment_date"],
        expiry_date: item["fulfillment_report_blinkit.expiry_date"],
      };
    }

    // Ensure filteredData[groupKey] exists before updating its total_amount
    if (filteredData[groupKey]) {
      filteredData[groupKey].total_amount += parseFloat(item["fulfillment_report_blinkit.total_amount"]) || 0; // Add up the total_amount safely
    }
  });
  return Object.values(filteredData);
};

const BookAppointments = ({ data, platformName }) => {
  const filteredData = getAppointmentsOn(data);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [orderBy, setOrderBy] = useState("order_date");
  const [orderDirection, setOrderDirection] = useState("asc");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log("booked appoin====", filteredData);

  // Handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  // Sorted data based on the selected column and direction
  const sortedData = sortData(filteredData, orderBy, orderDirection);

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = sortedData.map((entry) => ({
      "Po Number/City": entry.po_number + "_" + entry.facility_name,
      "SKU List": entry.item_name,
      "Total Value": entry.total_amount,
      "Po State": entry.po_state,
      "Order Date": entry.order_date,
      "Expiry Date": entry.expiry_date,
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `booked_appointment_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!data.length) {
    return <div>No Data Found</div>;
  }

  return (
    <div>
      <div className="mb-4 h-[42px] min-w-32 flex justify-end">
        <span
          onClick={handleExportCSV}
          className=" p-3 shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
        >
          <span className="font-body font-normal text-sm">Export to CSV</span>
          <img width={16} height={16} src="/icons/download-circular-icon.svg" />
        </span>
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          mb: "2rem",
          mt: "1rem",
          borderRadius: "0.75rem",
        }}
      >
        <div className="flex gap-4 items-center p-4 font-medium text-lg border-b">
          <FaRegCalendarAlt />
          Booked Appointments for these Dates
          <CustomTooltip title={tooltipTitle}>
            <PiInfoLight />
          </CustomTooltip>
        </div>
        <div className="p-4">
          <div className="p-2 border rounded-xl">
            <TableContainer sx={{ borderRadius: "0.25rem" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {[
                      {
                        id: "po_number_city",
                        label: "PO Number/City",
                      },
                      {
                        id: "sku_list",
                        label: "SKU List",
                      },
                      {
                        id: "total_value",
                        label: "Total Value",
                      },
                      {
                        id: "po_state",
                        label: "PO State",
                      },
                      {
                        id: "order_date",
                        label: "Order Date",
                        sortable: true, // Mark this as sortable
                      },
                      {
                        id: "expiry_date",
                        label: "Expiry Date",
                        sortable: true, // Mark this as sortable
                      },
                    ].map((headCell) => (
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: headCell.id === "po_number_city" ? "left" : "center",
                          boxShadow: `${headCell.id === "po_number_city" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                          zIndex: `${headCell.id === "po_number_city" && "3"}`,
                          minWidth: "200px",
                          position: `${headCell.id === "po_number_city" && "sticky"}`,
                          left: `${headCell.id === "po_number_city" && 0}`,
                          bgcolor: "#F9F9F9",
                          cursor: headCell.sortable ? "pointer" : "default", // Enable cursor change for sortable columns
                        }}
                        key={headCell.id}
                        onClick={() => headCell.sortable && handleSort(headCell.id)} // Handle sort on click
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (orderDirection === "asc" ? " ▲" : " ▼") : null}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3", // Left shadow for this cell,
                        position: "sticky",
                        bgcolor: "#F9F9F9",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                    <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                      <TableCell
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "500",
                          minWidth: "200px",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          position: "sticky",
                          left: 0,
                          textAlign: "left",
                          bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                        }}
                      >
                        {item.po_number + "_" + item.facility_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                      >
                        {item.item_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                      >
                        {item.total_amount.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ width: "200px", textAlign: "center" }}>{item.po_state}</TableCell>
                      <TableCell
                        sx={{
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                      >
                        {dayjs(item.order_date).format("DD-MMM-YYYY")}
                      </TableCell>

                      <TableCell
                        sx={{
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                      >
                        {item.expiry_date}
                      </TableCell>

                      <TableCell
                        align="right"
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3", // Left shadow for this cell,
                          position: "sticky",
                          bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          right: 0,
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default BookAppointments;
