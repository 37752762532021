import {
  COMPETITOR_PRICING_FAILURE,
  COMPETITOR_PRICING_REQUEST,
  COMPETITOR_PRICING_SUCCESS,
} from "../constants/competitorPricingConstants";

export const fetchCompetitorPricingRequest = () => ({
  type: COMPETITOR_PRICING_REQUEST,
});
export const fetchCompetitorPricingSuccess = (data) => ({
  type: COMPETITOR_PRICING_SUCCESS,
  payload: data,
});
export const fetchCompetitorPricingFailure = (error) => ({
  type: COMPETITOR_PRICING_FAILURE,
  payload: error,
});
