import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import TablePagination from "@mui/material/TablePagination"; // Import TablePagination
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Error from "../../common/Error/Error";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import FilterDropdown from "../../common/Dropdown/FilterDropdown";

const DailyTarget = ({ platformName }) => {
  // Retrieve targetData and allTargets from the Redux store
  const { targetData, allTargets } = useSelector((state) => state.target);
  const [filters, setFilters] = useState({}); // Store applied filters
  console.log("🚀 ~ DailyTarget ~ filters:", filters);

  // State for pagination
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows to show per page

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  // Extract GMV based on the platform name
  const getGmv = (item) =>
    platformName === "Blinkit"
      ? item["combined_sales_report_blinkit.total_gmv"]
      : platformName === "Zepto"
      ? item["combined_sales_report_zepto.total_gross_merchandise_value"]
      : item["combined_sales_report_instamart.total_gmv"];

  const filteredData = useMemo(() => {
    if (!filters.salesRange) return targetData.data;

    const [min, max] = filters.salesRange;
    console.log("Min:", min, "Max:", max); // Debugging sales range

    return targetData.data.filter((item) => {
      const gmv = parseFloat(getGmv(item)); // Ensure gmv is treated as a number
      console.log("GMV:", gmv, "Valid Comparison:", gmv >= min && gmv <= max);

      // Check if gmv is a valid number before comparison
      return !isNaN(gmv) && gmv >= min && gmv <= max;
    });
  }, [filters, targetData.data]);

  console.log("🚀 ~ filteredData:", filteredData);

  // Generate filter options dynamically
  const computeMinMaxSales = (data) => {
    let minSales = Infinity,
      maxSales = -Infinity;
    data.forEach((item) => {
      const gmv = getGmv(item);
      minSales = Math.min(minSales, gmv);
      maxSales = Math.max(maxSales, gmv);
    });
    return { minSales: minSales || 0, maxSales: maxSales || 0 };
  };

  const filterOptions = useMemo(() => {
    const { minSales, maxSales } = computeMinMaxSales(targetData.data);
    return [
      {
        field: "salesRange",
        heading: "Select Sales Range",
        type: "range",
        min: minSales,
        max: maxSales,
      },
    ];
  }, [targetData.data]);

  // Paginate the filtered data
  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  console.log("🚀 ~ DailyTarget ~ paginatedData:", filteredData);

  // Show loading skeleton if the data is still loading from API
  if (targetData.loading || allTargets.loading) {
    return <TableSkeleton />;
  }

  // Show error message if there's an error
  if (targetData.error) {
    return <Error />;
  }

  // Show "No Data Found" message if there's no data available
  if (!targetData.data || targetData.data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          mb: "2rem",
          borderRadius: "0.75rem",
        }}
      >
        <div className="py-2 px-4 bg-white w-full flex justify-between border-b leading-[0.375rem] items-center">
          {/* Header for the table, including the title and an icon */}
          <div className="font-medium text-lg flex gap-4 items-center leading-[0.375rem]">
            <img src="/logos/target-tracker2.svg" alt="target-tracker" /> Target Tracker
          </div>
          <FilterDropdown filters={filterOptions} onApply={setFilters} />
        </div>

        <div className="px-4 pt-4">
          <div className="border p-4 rounded-xl">
            <TableContainer className="max-w-full overflow-x-scroll" sx={{ borderRadius: "0.25rem" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* Sticky Date column with customized styling */}
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        minWidth: "200px",
                        position: "sticky",
                        left: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Date
                    </TableCell>

                    {/* Other column headers with general styling */}
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        minWidth: "200px",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Total Sales
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        minWidth: "200px",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Target
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "center",
                        minWidth: "200px",
                        bgcolor: "#F9F9F9",
                      }}
                    >
                      Percent Achieved
                    </TableCell>

                    {/* Empty sticky column to maintain table alignment */}
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {/* Display paginated rows */}
                  {paginatedData.map((item, index) => {
                    // Extract the date based on the platform name
                    let date =
                      platformName === "Blinkit"
                        ? item["combined_sales_report_blinkit.normalized_date"]
                        : platformName === "Zepto"
                        ? item["combined_sales_report_zepto.normalized_date"]
                        : item["combined_sales_report_instamart.normalized_date"];
                    date = date?.substring(0, 10); // Format date to YYYY-MM-DD

                    // Find the corresponding target for this date from allTargets
                    const target = allTargets.data?.find((targetItem) => {
                      let day = targetItem["date"]?.substring(0, 10);
                      return day === date;
                    });

                    // Extract GMV (Gross Merchandise Value) based on the platform name
                    const gmv =
                      platformName === "Blinkit"
                        ? item["combined_sales_report_blinkit.total_gmv"]
                        : platformName === "Zepto"
                        ? item["combined_sales_report_zepto.total_gross_merchandise_value"]
                        : item["combined_sales_report_instamart.total_gmv"];

                    return (
                      <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                        {/* Sticky Date cell with styling */}
                        <TableCell
                          sx={{
                            color: "#4D4D4D",
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            minWidth: "200px",
                            position: "sticky",
                            left: 0,
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            fontWeight: "500",
                          }}
                        >
                          {date}
                        </TableCell>

                        {/* GMV (Total Sales) cell */}
                        <TableCell
                          align="right"
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {gmv}
                        </TableCell>

                        {/* Target value cell */}
                        <TableCell
                          align="right"
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {target ? target.totalTarget : 0}
                        </TableCell>

                        {/* Percent Achieved cell with dynamic background color based on performance */}
                        <TableCell
                          align="center"
                          sx={{
                            minWidth: "200px",
                            textAlign: "center",
                          }}
                        >
                          {target ? (
                            <div
                              className={`inline-block px-2 py-1 rounded-full text-left ${
                                gmv / (target.totalTarget / 100) >= 50
                                  ? "bg-[#22C55E1A] text-[#22C55E]"
                                  : gmv / (target.totalTarget / 100) > 20
                                  ? "bg-[#FEBE401A] text-[#FEBE40]"
                                  : "bg-[#F53E3E1A] text-[#F53E3E]"
                              }`}
                            >
                              {(gmv / (target.totalTarget / 100)).toFixed(2)}%
                            </div>
                          ) : (
                            <div className="inline-block px-2 py-1 rounded-full text-left bg-[#F53E3E1A] text-[#F53E3E]">
                              0%
                            </div>
                          )}
                        </TableCell>

                        {/* Empty sticky cell for alignment */}
                        <TableCell
                          align="right"
                          sx={{
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3",
                            position: "sticky",
                            bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            right: 0,
                          }}
                        ></TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* Pagination Controls */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={targetData.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default DailyTarget;
