import React, { useEffect, useState } from "react"; // Importing React and its hooks
import {
  fetchFulfillmentReportBlinkitData,
  fetchFulfillmentReportInstamartData,
  fetchFulfillmentReportZeptoData,
} from "../../services/fulfillmentServices/fulfillmentReportServices";
import { useDispatch } from "react-redux"; // Redux hooks to dispatch actions and select state
import {
  getBlinkitFulfillmentRequest,
  getBlinkitFulfillmentSuccess,
  getBlinkitFulfillmentFailure,
  getZeptoFulfillmentRequest,
  getZeptoFulfillmentSuccess,
  getZeptoFulfillmentFailure,
  getInstamartFulfillmentRequest,
  getInstamartFulfillmentSuccess,
  getInstamartFulfillmentFailure,
} from "../../redux/actions/fulfillmentActions"; // Redux action creators for handling fulfillment report data
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider"; // Custom hook to get selected date
import dayjs from "dayjs"; // Importing dayjs for date manipulation
import FulfillmentBlinkitSectionsContainer from "../../components/Fulfillment/FulfillmentReportComponents/FulfillmentBlinkitSectionsContainer";
import FulfillmentInstamartSectionsContainer from "../../components/Fulfillment/FulfillmentReportComponents/FulfillmentInstamartSectionsContainer";
import FulfillmentZeptoSectionsContainer from "../../components/Fulfillment/FulfillmentReportComponents/FulfillmentZeptoSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

const FulfillmentReportScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit"); // State to track the selected platform, default is "Blinkit"
  const dispatch = useDispatch(); // Dispatch function from Redux
  const { selectedDate } = useDate(); // Get the selected date from custom hook

  const platformDetails = {
    Blinkit: {
      fetchProfitability: fetchFulfillmentReportBlinkitData,
      profitabilityAction: {
        request: getBlinkitFulfillmentRequest,
        success: getBlinkitFulfillmentSuccess,
        failure: getBlinkitFulfillmentFailure,
      },
    },

    Zepto: {
      fetchProfitability: fetchFulfillmentReportZeptoData,
      profitabilityAction: {
        request: getZeptoFulfillmentRequest,
        success: getZeptoFulfillmentSuccess,
        failure: getZeptoFulfillmentFailure,
      },
    },

    Instamart: {
      fetchProfitability: fetchFulfillmentReportInstamartData,
      profitabilityAction: {
        request: getInstamartFulfillmentRequest,
        success: getInstamartFulfillmentSuccess,
        failure: getInstamartFulfillmentFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];
    if (platform) {
      dispatch(platform.profitabilityAction.request());
      fetchWithRetry(
        platform.fetchProfitability,
        platform.profitabilityAction.success,
        platform.profitabilityAction.failure,
        selectedDate,
        dispatch,
        1
      );
    }
  }, [platformName, selectedDate]);

  // Helper function to format date without timezone

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Helper function to calculate the date range (last 30 days)
  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate); // End date is the selected date
    const startDate = formatDateWithoutTimezone(
      dayjs(selectedDate).subtract(30, "day") // Start date is 30 days before the selected date
    );
    return [startDate, endDate]; // Return the date range as an array
  };

  return (
    <>
      {/* HeadingBar component to display page heading and allow platform selection */}
      <HeadingBar
        setPlatformName={setPlatformName}
        selectedDate={calculateDateRange(selectedDate)} // Pass calculated date range
        platformName={platformName}
      />
      <Toolbar>
        {/* Render specific sections based on the selected platform */}
        {platformName == "Blinkit" && <FulfillmentBlinkitSectionsContainer platformName={platformName} />}
        {platformName == "Instamart" && <FulfillmentInstamartSectionsContainer platformName={platformName} />}
        {platformName == "Zepto" && <FulfillmentZeptoSectionsContainer platformName={platformName} />}
      </Toolbar>
    </>
  );
};

export default FulfillmentReportScreen;
