import React, { useState, useCallback, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import { BsGraphUpArrow } from "react-icons/bs";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import "./css/camapaginCharts.css";

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const DisplayAdsChart = ({ groupedData }) => {
  const [hiddenDatasets, setHiddenDatasets] = useState([]);
  const [showLeftYAxis, setShowLeftYAxis] = useState(true);
  const [showRightYAxis, setShowRightYAxis] = useState(true);

  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const [dayA, monthA, yearA] = a.split("-").map(Number);
    const [dayB, monthB, yearB] = b.split("-").map(Number);
    return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
  });

  // Format the dates to show only DD-MM
  const formattedDates = sortedDates.map((date) => {
    const [year, month, day] = date.split("-"); // Split the date into year, month, and day
    return `${day}-${month}`; // Return the formatted date as DD-MM-YYYY
  });

  const chartData = {
    labels: formattedDates,
    datasets: [
      {
        label: "Total Spent",
        data: sortedDates.map((date) => groupedData[date].spend),
        borderColor: "#FF6384",
        pointBorderColor: "#FF6384",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "left-y-axis",
        hidden: hiddenDatasets.includes("Total Spent"),
      },
      {
        label: "Total Impressions",
        data: sortedDates.map((date) => groupedData[date].impressions),
        borderColor: "#22CFCF",
        pointBorderColor: "#22CFCF",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "left-y-axis",
        hidden: hiddenDatasets.includes("Total Impressions"),
      },
      {
        label: "Total Clicks",
        data: sortedDates.map((date) => groupedData[date].clicks),
        borderColor: "#FEBE40",
        pointBorderColor: "#FEBE40",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("Total Clicks"),
      },
      {
        label: "ROI",
        data: sortedDates.map((date) => groupedData[date].revenue / groupedData[date].spend),
        borderColor: "#8142FF",
        pointBackgroundColor: "white",
        pointBorderColor: "#8142FF",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("ROI"),
      },
      {
        label: "CPC",
        data: sortedDates.map((date) => groupedData[date].spend / groupedData[date].clicks),
        borderColor: "#0000ff",
        pointBorderColor: "#0000ff",
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 4,
        fill: false,
        tension: 0.4,
        yAxisID: "right-y-axis",
        hidden: hiddenDatasets.includes("CPC"),
      },
    ],
  };

  const getAxisTitles = useCallback(() => {
    const leftAxisLabels = chartData.datasets
      .filter((dataset) => dataset.yAxisID === "left-y-axis" && !dataset.hidden)
      .map((dataset) => dataset.label)
      .join(" & ");

    const rightAxisLabels = chartData.datasets
      .filter((dataset) => dataset.yAxisID === "right-y-axis" && !dataset.hidden)
      .map((dataset) => dataset.label)
      .join(" & ");

    return {
      left: leftAxisLabels || "Total Spent & Total Impressions",
      right: rightAxisLabels || "Total Clicks, ROI & CPC",
    };
  }, [hiddenDatasets, chartData.datasets]);

  const updateAxisVisibility = useCallback(() => {
    const leftYAxisHidden = chartData.datasets.every(
      (dataset) => dataset.yAxisID === "left-y-axis" && hiddenDatasets.includes(dataset.label)
    );

    const rightYAxisHidden = chartData.datasets.every(
      (dataset) => dataset.yAxisID === "right-y-axis" && hiddenDatasets.includes(dataset.label)
    );

    setShowLeftYAxis(!leftYAxisHidden);
    setShowRightYAxis(!rightYAxisHidden);
  }, [hiddenDatasets, chartData.datasets]);

  useEffect(() => {
    updateAxisVisibility();
  }, [hiddenDatasets, updateAxisVisibility]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            size: 12,
            weight: 400,
          },
        },
        onClick: (event, legendItem) => {
          const datasetLabel = legendItem.text;
          setHiddenDatasets((prev) =>
            prev.includes(datasetLabel) ? prev.filter((label) => label !== datasetLabel) : [...prev, datasetLabel]
          );
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0,0,0,0.7)",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 12,
        },
        cornerRadius: 4,
        padding: 10,
      },
    },
    scales: {
      "left-y-axis": {
        display: showLeftYAxis,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: getAxisTitles().left,
          color: "#333",
          font: {
            size: 12,
          },
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
          font: {
            family: "Nunito", // Use the Nunito font family here
            size: 12, // Optional: set the font size
            weight: 400,
            style: "normal", // Optional: set the font style
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        suggestedMin: 0, // Adjust to ensure visibility
        suggestedMax: Math.max(
          ...chartData.datasets
            .filter((dataset) => dataset.yAxisID === "left-y-axis")
            .flatMap((dataset) => dataset.data)
        ),
      },
      "right-y-axis": {
        display: showRightYAxis,
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: getAxisTitles().right,
          color: "#333",
          font: {
            size: 12,
          },
        },
        ticks: {
          beginAtZero: true,
          color: "#333",
          font: {
            family: "Nunito", // Use the Nunito font family here
            size: 12, // Optional: set the font size
            weight: 400,
            style: "normal", // Optional: set the font style
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        suggestedMin: 0, // Adjust to ensure visibility
        suggestedMax: Math.max(
          ...chartData.datasets
            .filter((dataset) => dataset.yAxisID === "right-y-axis")
            .flatMap((dataset) => dataset.data)
        ),
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Dates",
          font: {
            size: 12,
          },
          color: "#333",
        },
        ticks: {
          color: "#333",
          font: {
            family: "Nunito", // Use the Nunito font family here
            size: 12.5, // Optional: set the font size
            weight: 400,
            style: "normal", // Optional: set the font style
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
      line: {
        borderWidth: 3,
      },
    },
  };

  return (
    <div className="shadow-md rounded-xl mb-8 mt-4 bg-white">
      <div className="flex py-5 border-b">
        <h1 className="flex  gap-4 items-center text-lg font-bold ml-5">
          {/* Orders Per Day section with icon */}
          <div>
            <BsGraphUpArrow />
          </div>
          Date Wise Overview
          <CustomTooltip
            title={
              "Analyze campaign performance over time with a detailed breakdown of key metrics including Add to Cart, Total Spend, Total Quantities Sold, and Cost Per Mille (CPM). Gain insights into trends and make informed decisions."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
      </div>

      <div className="chart-container-camapigns">
        <div className="w-full h-full p-6 font-nunito ">
          <Line className="border p-6 font-nunito  rounded-lg" data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default DisplayAdsChart;
