import {
  MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_FAILURE,
  MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_REQUEST,
  MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_SUCCESS,
  MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE,
  MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST,
  MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS,
  MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_REQUEST,
  MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_SUCCESS,
  MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_FAILURE,
  MARKETING_REPORT_BLINKIT_BANNER_LISTING_REQUEST,
  MARKETING_REPORT_BLINKIT_BANNER_LISTING_SUCCESS,
  MARKETING_REPORT_BLINKIT_BANNER_LISTING_FAILURE,
  MARKETING_REPORT_INSTAMART_REQUEST,
  MARKETING_REPORT_INSTAMART_SUCCESS,
  MARKETING_REPORT_INSTAMART_FAILURE,
  SKU_PERFORMANCE_ZEPTO_REQUEST,
  SKU_PERFORMANCE_ZEPTO_SUCCESS,
  SKU_PERFORMANCE_ZEPTO_FAILURE,
  SKU_ANALYTICS_ZEPTO_REQUEST,
  SKU_ANALYTICS_ZEPTO_SUCCESS,
  SKU_ANALYTICS_ZEPTO_FAILURE,
  CAMPAIGN_PERFORMANCE_SKU_REQUEST,
  CAMPAIGN_PERFORMANCE_SKU_SUCCESS,
  CAMPAIGN_PERFORMANCE_SKU_FAILURE,
  AWARENESS_PERFORMANCE_DATA_SUCCESS,
  AWARENESS_PERFORMANCE_DATA_REQUEST,
  AWARENESS_PERFORMANCE_DATA_FAILURE,
} from "../constants/marketingConstants";

export const getAllMarketingReportBlinkitProductListingRequest = () => {
  return {
    type: MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_REQUEST,
  };
};

export const getAllMarketingReportBlinkitProductListingSuccess = (report) => {
  return {
    type: MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportBlinkitProductListingFailure = (error) => {
  return {
    type: MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_FAILURE,
    payload: error,
  };
};

export const getAllMarketingReportBlinkitProductRecommandationRequest = () => {
  return {
    type: MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST,
  };
};

export const getAllMarketingReportBlinkitProductRecommandationSuccess = (
  report
) => {
  return {
    type: MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportBlinkitProductRecommandationFailure = (
  error
) => {
  return {
    type: MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE,
    payload: error,
  };
};

// Search Suggestion Actions
export const getAllMarketingReportBlinkitSearchSuggestionRequest = () => {
  return {
    type: MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_REQUEST,
  };
};

export const getAllMarketingReportBlinkitSearchSuggestionSuccess = (report) => {
  return {
    type: MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportBlinkitSearchSuggestionFailure = (error) => {
  return {
    type: MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_FAILURE,
    payload: error,
  };
};

// Banner Listing Actions
export const getAllMarketingReportBlinkitBannerListingRequest = () => {
  return {
    type: MARKETING_REPORT_BLINKIT_BANNER_LISTING_REQUEST,
  };
};

export const getAllMarketingReportBlinkitBannerListingSuccess = (report) => {
  return {
    type: MARKETING_REPORT_BLINKIT_BANNER_LISTING_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportBlinkitBannerListingFailure = (error) => {
  return {
    type: MARKETING_REPORT_BLINKIT_BANNER_LISTING_FAILURE,
    payload: error,
  };
};

// Instamart Actions
export const getAllMarketingReportInstamartRequest = () => {
  return {
    type: MARKETING_REPORT_INSTAMART_REQUEST,
  };
};

export const getAllMarketingReportInstamartSuccess = (report) => {
  return {
    type: MARKETING_REPORT_INSTAMART_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportInstamartFailure = (error) => {
  return {
    type: MARKETING_REPORT_INSTAMART_FAILURE,
    payload: error,
  };
};

// SKU Performance Actions
export const getAllMarketingReportZeptoSkuPerformanceRequest = () => {
  return {
    type: SKU_PERFORMANCE_ZEPTO_REQUEST,
  };
};

export const getAllMarketingReportZeptoSkuPerformanceSuccess = (report) => {
  return {
    type: SKU_PERFORMANCE_ZEPTO_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportZeptoSkuPerformanceFailure = (error) => {
  return {
    type: SKU_PERFORMANCE_ZEPTO_FAILURE,
    payload: error,
  };
};

// SKU Analytics Actions
export const getAllMarketingReportZeptoSkuAnalyticsRequest = () => {
  return {
    type: SKU_ANALYTICS_ZEPTO_REQUEST,
  };
};

export const getAllMarketingReportZeptoSkuAnalyticsSuccess = (report) => {
  return {
    type: SKU_ANALYTICS_ZEPTO_SUCCESS,
    payload: report,
  };
};

export const getAllMarketingReportZeptoSkuAnalyticsFailure = (error) => {
  return {
    type: SKU_ANALYTICS_ZEPTO_FAILURE,
    payload: error,
  };
};

// Campaign Performance SKU Actions
export const getAllCampaignPerformanceSkuRequest = () => {
  return {
    type: CAMPAIGN_PERFORMANCE_SKU_REQUEST,
  };
};

export const getAllCampaignPerformanceSkuSuccess = (report) => {
  return {
    type: CAMPAIGN_PERFORMANCE_SKU_SUCCESS,
    payload: report,
  };
};

export const getAllCampaignPerformanceSkuFailure = (error) => {
  return {
    type: CAMPAIGN_PERFORMANCE_SKU_FAILURE,
    payload: error,
  };
};

export const getAllAwarenessPerformanceDataRequest = () => {
  return {
    type: AWARENESS_PERFORMANCE_DATA_REQUEST,
  };
};

export const getAllAwarenessPerformanceDataSuccess = (data) => ({
  type: AWARENESS_PERFORMANCE_DATA_SUCCESS,
  payload: data,
});

export const getAllAwarenessPerformanceDataFailure = (error) => {
  return {
    type: AWARENESS_PERFORMANCE_DATA_FAILURE,
    payload: error,
  };
};
