import React from "react";
import { useSelector } from "react-redux";
import DisplayAdsFunnel from "./DisplayAdsFunnel";
import {
  groupedDataByCampaignSearchSuggestion,
  groupedDataByDateSearchSuggestions,
  groupedDataByKeywordsSearchSuggestion,
  groupedDataByCampaignNameSearchSuggestion,
} from "../../../utils/dataGrouping/searchSuggestionsGrouping";
import { AiFillFunnelPlot } from "react-icons/ai";
import DisplayAdsChart from "./DisplayAdsChart";
import AdCampaigns from "../PerformanceMarketingAdsComponents/AdCampaigns";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const SearchSuggesionSectionsContainer = () => {
  const { searchSuggestion } = useSelector((state) => state.marketing);
  const { loading, error, data } = searchSuggestion;

  if (loading) {
    return (
      <div>
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>

        <div className="bg-white rounded-xl">
          <ChartSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }
  if (error) {
    return <NoDataFound />;
  }
  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedData = groupedDataByKeywordsSearchSuggestion(searchSuggestion.data);
  const groupedAdsChartData = groupedDataByDateSearchSuggestions(searchSuggestion.data);

  const groupedCampaign = groupedDataByCampaignSearchSuggestion(searchSuggestion.data);

  const groupedByCampaign = groupedDataByCampaignNameSearchSuggestion(searchSuggestion.data);

  const valuesArray = Object.values(groupedData);
  // Sort the array in descending order based on the budget
  const searchSuggestionSortedArray = valuesArray?.sort((a, b) => b.estimateBudget - a.estimateBudget);

  // Get the top 10 objects with the highest budget
  const searchSuggestionTop10 = searchSuggestionSortedArray.slice(0, 10);
  console.log("searchSuggestionTop10====", searchSuggestionTop10);

  const campaignValuesArray = Object.values(groupedCampaign);
  // Sort the array in descending order based on the budget
  const searchSuggestionCampaignSortedArray = campaignValuesArray?.sort((a, b) => b.estimateBudget - a.estimateBudget);

  // Get the top 10 objects with the highest budget
  const searchSuggestionCampaignTop10 = searchSuggestionCampaignSortedArray.slice(0, 10);

  return (
    <div className=" w-full">
      <div className="bg-white shadow-md rounded-xl mb-4">
        <h1 className="flex gap-3 items-center text-lg font-medium p-4 border-b">
          <AiFillFunnelPlot />
          Top 10 Keywords By Campaign
          <CustomTooltip
            title={
              "View the top 10 campaigns ranked by budget allocation, helping you identify the most impactful campaigns based on spending."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
        <div className="flex p-4 flex-wrap justify-evenly gap-4">
          {searchSuggestion &&
            searchSuggestionCampaignTop10?.map((item, index) => <DisplayAdsFunnel obj={item} key={index} />)}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-xl ">
        <h1 className="flex gap-3 items-center text-lg font-medium p-4 border-b">
          <AiFillFunnelPlot />
          Top 10 Keywords By Spend
          <CustomTooltip
            title={
              "Explore the top 10 keywords ranked by budget allocation, allowing you to see which keywords are driving the most investment and potentially the best returns."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
        <div className="flex p-4 flex-wrap justify-evenly gap-4">
          {searchSuggestion && searchSuggestionTop10?.map((item, index) => <DisplayAdsFunnel obj={item} key={index} />)}
        </div>
      </div>
      <DisplayAdsChart groupedData={groupedAdsChartData} />

      <div>
        <AdCampaigns groupedDataAd={groupedByCampaign} />
      </div>
    </div>
  );
};

export default SearchSuggesionSectionsContainer;
