import React, { useEffect, useState } from "react";
import Toolbar from "../../components/Toolbar/Toolbar";

import {
  fetchBlinkitData,
  fetchInstamartData,
  fetchZeptoData,
} from "../../services/revenueServices/salesReportServices";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalesSuccess, getAllSalesFailure, getAllSalesRequest } from "../../redux/actions/salesActions";
import { MdAreaChart } from "react-icons/md";
import { useDateRange } from "../../hooks/CustomDateRange/CustomDateRangePickerProvider";
import ChartSkeleton from "../../components/common/Skeletons/ChartSkeleton";
import NoDataFound from "../../components/common/NoDataFound/NoDataFound";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import CategorySkuSplitSectionsContainer from "../../components/revenue/salesReportScreen/CategorySkuSplitSectionsContainer";
import CityWiseSkuSplitSectionsContainer from "../../components/revenue/salesReportScreen/CityWiseSkuSplitSectionsContainer";
import DailySalesAndOrdersPerDay from "../../components/revenue/salesReportScreen/DailySalesAndOrdersPerDay";
import CategoryFilterSelector from "../../components/revenue/salesReportScreen/CategoryFilterSelector";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../components/common/CustomTooltip/CustomTooltip";
import { fetchWithRetryRange } from "../../utils/queries/fetchWithDateFallback";

// Array containing the different options for viewing the data
const options = ["Category Wise SKU Split", "City Wise SKU Split", "Orders Per Day", "Sales Per Day"];

const SalesReportScreen = () => {
  const { totalSales } = useSelector((state) => state.sales); // Accessing sales data from Redux store
  console.log("totalSales=====", totalSales);
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDateRange } = useDateRange();
  console.log("🚀 ~ SalesReportScreen ~ selectedDateRange:", selectedDateRange);

  const dispatch = useDispatch();

  // Reset selectedOption to 0 (Category Wise SKU Split) whenever the platformName changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchSales: fetchBlinkitData,
      salesActions: {
        request: getAllSalesRequest,
        success: getAllSalesSuccess,
        failure: getAllSalesFailure,
      },
    },

    Zepto: {
      fetchSales: fetchZeptoData,
      salesActions: {
        request: getAllSalesRequest,
        success: getAllSalesSuccess,
        failure: getAllSalesFailure,
      },
    },

    Instamart: {
      fetchSales: fetchInstamartData,
      salesActions: {
        request: getAllSalesRequest,
        success: getAllSalesSuccess,
        failure: getAllSalesFailure,
      },
    },
    // Add configurations for other platforms/screens similarly
  };

  useEffect(() => {
    const platform = platformDetails[platformName];

    if (platform) {
      dispatch(platform.salesActions.request());
      fetchWithRetryRange(
        platform.fetchSales,
        platform.salesActions.success,
        platform.salesActions.failure,
        selectedDateRange,
        dispatch,
        1
      );
    }
  }, [platformName, selectedDateRange, selectedOption]);

  return (
    <>
      {/* HeadingBar allows platform and date selection */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDateRange} platformName={platformName} />

      <Toolbar selectedDate={selectedDateRange}>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Render category-wise SKU split if selectedOption is 0 */}
        {selectedOption === 0 && <CategorySkuSplitSectionsContainer platformName={platformName} />}

        {/* Render city-wise SKU split if selectedOption is 1 */}
        {selectedOption === 1 && <CityWiseSkuSplitSectionsContainer platformName={platformName} />}

        {/* Render orders per day if selectedOption is 2 */}
        {selectedOption === 2 && (
          <>
            {/* Show loading skeleton if the data is still loading from api */}
            {totalSales.loading ? (
              <ChartSkeleton />
            ) : !totalSales.data || totalSales.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <>
                <div className="mt-10">
                  <CategoryFilterSelector platformName={platformName} />
                </div>

                <div className="bg-white mt-4 shadow-md rounded-xl mb-4">
                  <div className="flex p-4 border-b">
                    <h1 className="flex  gap-4 items-center text-lg font-medium">
                      {/* Orders Per Day section with icon */}
                      <div>
                        <MdAreaChart />
                      </div>
                      Orders Per Day
                      <CustomTooltip
                        title={
                          "View the total number of orders placed per day, with the option to filter by specific categories for more detailed insights."
                        }
                      >
                        <PiInfoLight />
                      </CustomTooltip>
                    </h1>
                  </div>

                  <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
                    <DailySalesAndOrdersPerDay selectedOption={selectedOption} platformName={platformName} />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {/* Render sales per day if selectedOption is 3 */}
        {selectedOption === 3 && (
          <>
            {/* Show loading skeleton if the data is still loading from api */}
            {totalSales.loading ? (
              <ChartSkeleton />
            ) : !totalSales.data || totalSales.data.length === 0 ? (
              <NoDataFound />
            ) : (
              <>
                {/* Category filter for the Sales Per Day view */}
                <div className="mt-10">
                  <CategoryFilterSelector platformName={platformName} />
                </div>
                <div className="bg-white mt-4 shadow-md rounded-xl mb-4">
                  <h1 className="flex gap-3 items-center text-lg font-medium pb-4 border-b p-4">
                    {/* Sales Per Day section with icon */}
                    <div>
                      <MdAreaChart />
                    </div>
                    Sales Per Day
                    <CustomTooltip
                      title={
                        "Track daily sales performance and use the category filter to focus on specific product categories for detailed sales insights."
                      }
                    >
                      <PiInfoLight />
                    </CustomTooltip>
                  </h1>

                  <div className=" flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
                    <DailySalesAndOrdersPerDay selectedOption={selectedOption} platformName={platformName} />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Toolbar>
    </>
  );
};

export default SalesReportScreen;

// Use Case:
/*
This code defines a SalesReportScreen component that allows users to view sales data for different platforms (Blinkit, Zepto, Instamart). 
Users can toggle between different views such as "Category Wise SKU Split" and "Sales Per Day", while fetching and displaying data based on the selected platform and date range.
*/
