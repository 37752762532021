import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dropdown from "../../common/Dropdown/Dropdown";

export default function SelectGroup({ text, selectFields, setField, field }) {
  return (
    <div>
      {/* Custom Dropdown component */}
      <Dropdown
        options={selectFields}
        value={field}
        onChange={(value) => setField(value)}
        initalTitle={`Select ${text}`}
      />
    </div>
  );
}
