import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { dummyPricingData } from "../../../utils/dummyPricingData";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const headers = ["Brand Name / Product Name", "Price", "Discounted Price", "Discount Percent", "Ppu"];

const brandAndSkuWiseGroupedData = (data) => {
  return data.reduce((acc, item) => {
    const brand = item.brand;
    const product = item.product;
    const discount = item.price - item.discounted_price;
    const discountPercent = (discount / item.price) * 100;
    const pricePer1 = parseFloat(item.price) / parseFloat(item.unitWeight); // Calculate price for 1ml/1gm

    // Initialize brand if not present
    if (!acc[brand]) {
      acc[brand] = {
        brand: brand,
        products: {},
      };
    }

    // Initialize sku if not present for the brand
    if (!acc[brand].products[product]) {
      acc[brand].products[product] = {
        product: product,
        price: 0,
        discountPrice: 0,
        discountPercent: 0,
        ppu: 0,
        count: 0,
      };
    }

    // Calculate discount (price - discounted_price) and count occurrences
    acc[brand].products[product].price += parseFloat(item.price);
    acc[brand].products[product].discountPrice += parseFloat(item.discounted_price);
    acc[brand].products[product].discountPercent += discountPercent;
    acc[brand].products[product].ppu += pricePer1;
    acc[brand].products[product].count += 1;

    return acc;
  }, {});
};

const CompetitorDetailedView = ({ sku }) => {
  const [expandedBrands, setExpandedBrands] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const ourBrand = user.organizationDetail.name;
  // Step 1: Sort the data by date in descending order
  const sortedData = dummyPricingData.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Step 2: Get unique dates
  const uniqueDates = [...new Set(sortedData.map((item) => item.date))];

  // Step 3: Filter data for the last date
  const lastDate = uniqueDates[0];
  const lastDateData = sortedData.filter((item) => item.date === lastDate);
  const competitorData = lastDateData.filter((item) => item.brand != ourBrand);
  let skuData;
  if (sku == "All SKU's") {
    skuData = competitorData;
  } else {
    // skuData = competitorData.filter((item) => item.sku_mapping == sku);
    skuData = competitorData.filter((item) => item.product == sku);
  }
  const finalData = brandAndSkuWiseGroupedData(skuData);
  const handleBrandClick = (brand) => {
    setExpandedBrands((prev) => ({
      ...prev,
      [brand]: !prev[brand], // Toggle expansion state for the clicked category
      [ourBrand]: true,
    }));
  };

  return (
    <div className="w-full overflow-hidden border rounded-xl p-2 bg-white">
      <TableContainer sx={{ overflowX: "auto", borderRadius: "0.25rem" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: "500",
                    color: "#4D4D4D",
                    fontSize: "0.875rem",
                    textAlign: index == 0 ? "left" : "center",
                    minWidth: "150px",
                    maxWidth: "150px",
                    bgcolor: "#F9F9F9",
                    boxShadow: index === 0 ? "5px 0px 5px -5px rgba(16, 24, 40, 0.06)" : "",
                    zIndex: index === 0 ? "3" : "",
                    position: index === 0 ? "sticky" : "",
                    left: index === 0 ? 0 : "",
                  }}
                >
                  {header}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                  zIndex: "3",
                  position: "sticky",
                  right: 0,
                  bgcolor: "#F9F9F9",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.values(finalData).length > 0 ? (
              Object.values(finalData).map((brandData, brandIndex) => {
                const itemsLength = Object.values(brandData.products).length;
                const totalPriceObj = Object.values(brandData.products).reduce((acc, item) => {
                  return acc + (item.price > 0 ? item.price / item.count : 0);
                }, 0);

                const totalDiscountObj = Object.values(brandData.products).reduce((acc, item) => {
                  return acc + (item.discountPrice > 0 ? item.discountPrice / item.count : 0);
                }, 0);

                const totalDiscountPercentObj = Object.values(brandData.products).reduce((acc, item) => {
                  return acc + (item.discountPercent > 0 ? item.discountPercent / item.count : 0);
                }, 0);
                const totalppuObj = Object.values(brandData.products).reduce((acc, item) => {
                  return acc + (item.ppu > 0 ? item.ppu / item.count : 0);
                }, 0);
                const totalPrice = totalPriceObj / itemsLength;
                const totalDiscount = totalDiscountObj / itemsLength;
                const totalDiscountPercent = totalDiscountPercentObj / itemsLength;
                const totalppu = totalppuObj / itemsLength;

                return (
                  <React.Fragment key={brandIndex}>
                    <TableRow
                      onClick={() => handleBrandClick(brandData.brand)}
                      className={`cursor-pointer ${
                        expandedBrands[brandData.brand]
                          ? "bg-[#E5E5E5]"
                          : brandIndex % 2 !== 0
                          ? "bg-[#F0F6FF]"
                          : "bg-[#fff]"
                      } `}
                    >
                      <TableCell
                        sx={{
                          bgcolor: expandedBrands[brandData.brand]
                            ? "#E5E5E5"
                            : brandIndex % 2 !== 0
                            ? "#F0F6FF"
                            : "#fff",
                          color: "#4D4D4D",
                          fontWeight: "500",
                          fontSize: "1rem",
                          minWidth: "150px",
                          maxWidth: "150px",
                          position: "sticky",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          left: 0,
                          textAlign: "left",
                        }}
                      >
                        <div className="flex justify-between items-center">
                          {brandData.brand}
                          {/* Conditionally render the icons based on the state */}
                          {expandedBrands[brandData.brand] ? (
                            <FaCaretUp className="text-[#4D4D4D] text-base" />
                          ) : (
                            <FaCaretDown className="text-[#4D4D4D] text-base" />
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "100px",
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        {totalPrice.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "100px",
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        {totalDiscount.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "100px",
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        {totalDiscountPercent.toFixed(2)}%
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "100px",
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        {totalppu.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: expandedBrands[brandData.brand]
                            ? "#E5E5E5"
                            : brandIndex % 2 !== 0
                            ? "#F0F6FF"
                            : "#fff",
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        }}
                      ></TableCell>
                    </TableRow>
                    {expandedBrands[brandData.brand] &&
                      Object.values(brandData.products).map((item, index) => {
                        const price = parseFloat(item.price / item.count) || 0;
                        const discount = parseFloat(item.discountPrice / item.count) || 0;
                        const discountPercent = parseFloat(item.discountPercent / item.count) || 0;
                        const ppu = parseFloat(item.ppu / item.count) || 0;
                        return (
                          <TableRow key={index} className="cursor-pointer bg-[#F0F0F0] text-[#727278]">
                            <TableCell
                              sx={{
                                boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                minWidth: "150px",
                                maxWidth: "150px",
                                position: "sticky",
                                left: 0,
                                bgcolor: expandedBrands[brandData.brand] ? "#F0F0F0" : "white",
                                paddingLeft: "2rem",
                                textAlign: "left",
                              }}
                            >
                              {item.product}
                            </TableCell>
                            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>{price.toFixed(2)}</TableCell>
                            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>{discount.toFixed(2)}</TableCell>
                            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>
                              {discountPercent.toFixed(2)}%
                            </TableCell>
                            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>{ppu.toFixed(2)}</TableCell>
                            <TableCell
                              sx={{
                                boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                zIndex: "3",
                                position: "sticky",
                                right: 0,
                                bgcolor: expandedBrands[brandData.brand] ? "#F0F0F0" : "white",
                              }}
                            ></TableCell>
                          </TableRow>
                        );
                      })}
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length} sx={{ textAlign: "center", mt: 2 }}>
                  <Typography>No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CompetitorDetailedView;
