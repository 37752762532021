// FilterPopup.js
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Typography,
  IconButton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const FilterPopup = ({ onApplyFilters }) => {
  const [open, setOpen] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [mrpRange, setMrpRange] = useState([1, 4000]);
  const [soldRange, setSoldRange] = useState([1, 4000]);

  const cities = ["Banglore", "Mumbai", "Lucknow", "Delhi", "NCR", "Pune"];

  const toggleDialog = () => setOpen(!open);

  const handleCityChange = (city) => {
    setSelectedCities((prev) => (prev.includes(city) ? prev.filter((c) => c !== city) : [...prev, city]));
  };

  const handleSliderChange = (setter) => (event, newValue) => {
    setter(newValue);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ cities: selectedCities, mrpRange, soldRange });
    toggleDialog();
  };

  return (
    <>
      <Button variant="outlined" startIcon={<FilterListIcon />} onClick={toggleDialog}>
        Filter
      </Button>

      <Dialog open={open} onClose={toggleDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          Filter
          <IconButton aria-label="close" onClick={toggleDialog} style={{ position: "absolute", right: 8, top: 8 }}>
            ✕
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Range
          </Typography>
          <FormGroup>
            {cities.map((city) => (
              <FormControlLabel
                key={city}
                control={<Checkbox checked={selectedCities.includes(city)} onChange={() => handleCityChange(city)} />}
                label={city}
              />
            ))}
          </FormGroup>

          <Typography variant="subtitle2" style={{ marginTop: "16px" }}>
            Total MRP
          </Typography>
          <Slider
            value={mrpRange}
            onChange={handleSliderChange(setMrpRange)}
            valueLabelDisplay="auto"
            min={1}
            max={4000}
          />
          <Typography variant="body2">
            ₹{mrpRange[0]} - ₹{mrpRange[1]}
          </Typography>

          <Typography variant="subtitle2" style={{ marginTop: "16px" }}>
            Total Sold
          </Typography>
          <Slider
            value={soldRange}
            onChange={handleSliderChange(setSoldRange)}
            valueLabelDisplay="auto"
            min={1}
            max={4000}
          />
          <Typography variant="body2">
            ₹{soldRange[0]} - ₹{soldRange[1]}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "16px" }}
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FilterPopup;
