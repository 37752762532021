import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const tooltipTitle =
  "This table displays the list of upcoming PO's. Review the appointment date, time, and relevant details to ensure timely preparation and follow-up.";

const getGroupedData = (data) => {
  const currentDate = new Date();
  let filteredData = {};
  data.forEach((item) => {
    const poNumber = item["fulfillment_report_instamart.pocode"];
    const city = item["fulfillment_report_instamart.city"];
    const groupKey = `${poNumber}_${city}`;
    const podate = new Date(item["fulfillment_report_instamart.podate"]);
    const expirydate = new Date(item["fulfillment_report_instamart.expirydate"]);
    const lastInwardDate = item["fulfillment_report_instamart.last_inward_date"];

    if (expirydate > podate && podate < currentDate && lastInwardDate === "") {
      if (!filteredData[groupKey]) {
        filteredData[groupKey] = {
          po_number: poNumber,
          city: city,
          item_name: item["fulfillment_report_instamart.skuname"],
          po_state: item["fulfillment_report_instamart.postatus"],
          order_date: item["fulfillment_report_instamart.podate"],
          last_inward_date: item["fulfillment_report_instamart.last_inward_date"],
          expiry_date: item["fulfillment_report_instamart.expirydate"],
        };
      }
    }
  });
  return Object.values(filteredData);
};

const InstamartUpcomingPO = () => {
  const { instamartFulfillment } = useSelector((state) => state.fulfillment);
  const filteredData = getGroupedData(instamartFulfillment.data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        mb: "2rem",
        mt: "1rem",
      }}
    >
      <div className="flex gap-4 items-center p-4 font-bold text-xl border-b">
        <FaRegCalendarAlt />
        Upcoming PO's
        <CustomTooltip title={tooltipTitle}>
          <PiInfoLight />
        </CustomTooltip>
      </div>
      <div className="P-2">
        <TableContainer sx={{ borderRadius: "0.25rem" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {[
                  {
                    id: "po_number_city",
                    label: "PO Number/City",
                  },
                  {
                    id: "sku_list",
                    label: "SKU List",
                  },

                  {
                    id: "po_state",
                    label: "PO State",
                  },
                  {
                    id: "order_date",
                    label: "Order Date",
                  },

                  {
                    id: "last_inward_date",
                    label: "Last Inward Date",
                  },

                  {
                    id: "expiry_date",
                    label: "Expiry Date",
                  },
                ].map((headCell) => (
                  <TableCell
                    sx={{
                      fontWeight: "500",
                      color: "#4D4D4D",
                      fontSize: "0.875rem",
                      textAlign: headCell.id == "po_number_city" ? "left" : "center",
                      boxShadow: `${headCell.id === "po_number_city" && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)"}`,
                      zIndex: `${headCell.id === "po_number_city" && "3"}`,
                      minWidth: "200px",
                      position: `${headCell.id === "po_number_city" && "sticky"}`,
                      left: `${headCell.id === "po_number_city" && 0}`,
                      bgcolor: "#F9F9F9",
                    }}
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "#F9F9F9",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                <TableRow key={index} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                  <TableCell
                    sx={{
                      color: "#4D4D4D",
                      fontWeight: "500",
                      minWidth: "200px",
                      boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      position: "sticky",
                      left: 0,
                      bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                      textAlign: "left",
                    }}
                  >
                    {item.po_number + "_" + item.city}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "200px",
                      textAlign: "center",
                    }}
                  >
                    {item.item_name}
                  </TableCell>

                  <TableCell sx={{ width: "200px" }}>{item.po_state}</TableCell>
                  <TableCell
                    sx={{
                      minWidth: "200px",
                      textAlign: "center",
                    }}
                  >
                    {dayjs(item.order_date).format("DD-MMM-YYYY")}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "200px",
                      textAlign: "center",
                    }}
                  >
                    {item?.last_inward_date ? dayjs(item?.last_inward_date).format("DD-MMM-YYYY") : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: "200px",
                      textAlign: "center",
                    }}
                  >
                    {dayjs(item.expiry_date).format("DD-MMM-YYYY")}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: "3", // Left shadow for this cell,
                      position: "sticky",
                      bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                      right: 0,
                    }}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default InstamartUpcomingPO;
