export const THIRDPARTY_AVAILABILITY_REQUEST =
  "THIRDPARTY_AVAILABILITY_REQUEST";
export const THIRDPARTY_AVAILABILITY_SUCCESS =
  "THIRDPARTY_AVAILABILITY_SUCCESS";
export const THIRDPARTY_AVAILABILITY_FAILURE =
  "THIRDPARTY_AVAILABILITY_FAILURE";

export const THIRDPARTY_VISIBILITY_REQUEST = "THIRDPARTY_VISIBILITY_REQUEST";
export const THIRDPARTY_VISIBILITY_SUCCESS = "THIRDPARTY_VISIBILITY_SUCCESS";
export const THIRDPARTY_VISIBILITY_FAILURE = "THIRDPARTY_VISIBILITY_FAILURE";

export const FETCH_AVAILABILITY_WEEKLY_REQUEST = "FETCH_BLINKIT_WEEKLY_REQUEST";
export const FETCH_AVAILABILITY_WEEKLY_SUCCESS = "FETCH_BLINKIT_WEEKLY_SUCCESS";
export const FETCH_AVAILABILITY_WEEKLY_FAILURE = "FETCH_BLINKIT_WEEKLY_FAILURE";
