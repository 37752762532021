import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlinkitData,
  fetchInstamartData,
  fetchZeptoData,
  fetchBlinkitWeeklyData,
  fetchInstamartWeeklyData,
  fetchZeptoWeeklyData,
} from "../../services/inventoryServices/localityAvailabilityServices";
import {
  getThirdPartyAvailabilityRequest,
  getThirdPartyAvailabilitySuccess,
  getThirdPartyAvailabilityFailure,
  fetchAvailabilityWeeklyRequest,
  fetchAvailabilityWeeklySuccess,
  fetchAvailabilityWeeklyFailure,
} from "../../redux/actions/thirdPartyActions";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import MapViewSectionsContainer from "../../components/inventory/LocalityAvailabilityComponents/MapViewSectionsContainer";
import SkuCityLevelAvailabilitySectionsContainer from "../../components/inventory/LocalityAvailabilityComponents/SkuCityLevelAvailabilitySectionsContainer";
import WeeklyLevelOverviewSectionsContainer from "../../components/inventory/LocalityAvailabilityComponents/WeeklyLevelOverviewSectionsContainer";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

// Options for the component switcher (map view, SKU city-level availability, weekly overview)
const options = ["Map View", "Sku City Level Availability", "Weekly Level Overview"];

const LocalityAvailabilityScreen = () => {
  const { thirdPartyAvailabilityWeekly } = useSelector((state) => state.thirdParty);
  console.log("🚀 ~ LocalityAvailabilityScreen ~ thirdPartyAvailabilityWeekly:", thirdPartyAvailabilityWeekly);

  // Local state to track selected platform and option (view type)
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);

  // Hook to access the selected date from the date picker (single day)
  const { selectedDate,setSelectedDate } = useDate();
  // Redux dispatch function to trigger actions
  const dispatch = useDispatch();

  // Reset the selected option to default (0) whenever the platform changes
  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchAvailability: fetchBlinkitData,
      fetchWeeklyAvailability: fetchBlinkitWeeklyData,
      availabilityActions: {
        request: getThirdPartyAvailabilityRequest,
        success: getThirdPartyAvailabilitySuccess,
        failure: getThirdPartyAvailabilityFailure,
      },
      weeklyActions: {
        request: fetchAvailabilityWeeklyRequest,
        success: fetchAvailabilityWeeklySuccess,
        failure: fetchAvailabilityWeeklyFailure,
      },
    },

    Zepto: {
      fetchAvailability: fetchZeptoData,
      fetchWeeklyAvailability: fetchZeptoWeeklyData,
      availabilityActions: {
        request: getThirdPartyAvailabilityRequest,
        success: getThirdPartyAvailabilitySuccess,
        failure: getThirdPartyAvailabilityFailure,
      },
      weeklyActions: {
        request: fetchAvailabilityWeeklyRequest,
        success: fetchAvailabilityWeeklySuccess,
        failure: fetchAvailabilityWeeklyFailure,
      },
    },

    Instamart: {
      fetchAvailability: fetchInstamartData,
      fetchWeeklyAvailability: fetchInstamartWeeklyData,
      availabilityActions: {
        request: getThirdPartyAvailabilityRequest,
        success: getThirdPartyAvailabilitySuccess,
        failure: getThirdPartyAvailabilityFailure,
      },
      weeklyActions: {
        request: fetchAvailabilityWeeklyRequest,
        success: fetchAvailabilityWeeklySuccess,
        failure: fetchAvailabilityWeeklyFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];

    if (platform) {
      dispatch(platform.availabilityActions.request());
      // fetch selected date data
      fetchWithRetry(
        platform.fetchAvailability,
        platform.availabilityActions.success,
        platform.availabilityActions.failure,
        selectedDate,
        dispatch,
        1,
      );

      // Fetch weekly data
      dispatch(platform.weeklyActions.request());
      fetchWithRetry(
        platform.fetchWeeklyAvailability,
        platform.weeklyActions.success,
        platform.weeklyActions.failure,
        selectedDate,
        dispatch,
        1,
      );
    }
  }, [platformName, selectedDate]);

  return (
    <>
      {/* Heading bar component with platform selection and date picker */}
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />

      {/* Toolbar for switching between different views */}
      <Toolbar>
        {/* Component switch to toggle between map view, SKU city level, and weekly overview */}
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />

        {/* Render the corresponding container based on the selected option */}
        {selectedOption === 0 && <MapViewSectionsContainer />}
        {selectedOption === 1 && <SkuCityLevelAvailabilitySectionsContainer platformName={platformName} />}
        {selectedOption === 2 && <WeeklyLevelOverviewSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default LocalityAvailabilityScreen;
