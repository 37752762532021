import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const colors = [
  "#ff1493", // Deep Pink
  "#000080", // Navy
  "#ffa500", // Orange
  "#dc143c", // Crimson
  "#00fa9a", // Medium Spring Green
  "#ffb6c1", // Light Pink
  "#000000", // Black
  "#696969", // Dim Gray
  "#006400", // Dark Green
  "#808000", // Olive
  "#483d8b", // Dark Slate Blue
  "#3cb371", // Medium Sea Green
  "#008b8b", // Dark Cyan
  "#32cd32", // Lime Green
  "#800080", // Purple
  "#b03060", // Maroon 3
  "#00ff00", // Lime
  "#8a2be2", // Blue Violet
  "#0000ff", // Blue
  "#adff2f", // Green Yellow
  "#ffff54", // Laser Lemon
  "#add8e6", // Light Blue
  "#8b4513", // Saddle Brown
  "#7b68ee", // Medium Slate Blue
  "#fafad2", // Light Goldenrod
];

const CompetitorsPpu = ({ data }) => {
  // Collect all unique dates from all brands
  const allDatesSet = new Set();
  Object.values(data).forEach((brandData) => {
    Object.keys(brandData).forEach((date) => {
      allDatesSet.add(date);
    });
  });
  const allDates = Array.from(allDatesSet).sort();

  // Preparing the datasets for each brand with missing dates filled as null
  const brands = Object.keys(data);
  const datasets = brands.map((brand, index) => {
    return {
      label: brand,
      data: allDates.map((date) => (data[brand][date] ? data[brand][date].totalPpu : 0)),
      fill: false,
      borderColor: colors[index],
      tension: 0.4,
      pointBackgroundColor: "#fff",
    };
  });

  const chartData = {
    labels: allDates,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Competitor's Price Per Unit Over the Week",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + tooltipItem.raw.toFixed(2);
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Discount Percentage (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
    },
  };
  return (
    <div className="w-full h-96 border p-4 rounded-md bg-white mb-24">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default CompetitorsPpu;
