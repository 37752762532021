import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useDate } from "../../../hooks/SingleDatePicker/SingleDatePickerProvider";

export default function SingleDatePicker() {
  const { selectedDate, setSelectedDate } = useDate();
  const location = useLocation(); // Get current location

  // Update the date when the pathname changes
  useEffect(() => {
    setSelectedDate(dayjs()); // Set to current date
  }, [location.pathname]); // Runs every time pathname changes

  const handleDateChange = (date) => {
    const isoDateWithoutTime = date.utc().startOf("day").toISOString();
    setSelectedDate(dayjs.utc(isoDateWithoutTime));
  };

  return (
    <div className="flex gap-2 items-center">
      <div>Select Date : </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            width: 160,
            "& .MuiSvgIcon-root": {
              color: "#387FF5" /* Change the icon color */,
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: 1,
              padding: "0 4px",
              height: 36,
            },
            "& .MuiOutlinedInput-input": {
              height: "100%",
              fontSize: ".95rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)",
              border: "none",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "0.75rem",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              top: "-6px",
              left: "0",
              right: "0",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#387FF5",
              top: "-6px",
              fontSize: "0.95rem",
            },
            "& .MuiInputAdornment-root": {
              marginRight: "12px",
              color: "#387FF5",
            },
          }}
        >
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            maxDate={dayjs()} // Prevent future date selection
            // format={"DD-MM-YYYY"}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 1,
                padding: "0 4px",
                height: "36px",
                backgroundColor: "#F3F8FF",
                color: "#387FF5",
              },
              "& .MuiInputLabel-root": {
                color: "#387FF5",
                top: "-6px",
                fontSize: "0.95rem",
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    </div>
  );
}
