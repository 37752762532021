import { useMemo } from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";

import { palette } from "./palette";
import typography from "./typography";

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography: typography,
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              // padding: "10px",
              minHeight: "60px",
              fontSize: "1rem",
              color: "#727278",
              fontFamily: "DM Sans Variable, sans-serif",
              // Set desired global padding
            },
          },
        },
      },
    }),
    []
  );

  const theme = createTheme(memoizedValue);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
