import { GET_ALL_RBCA } from "../constants/rbacConstants";

const initialState = {
  accessControl: {},
};

export const rbacReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_RBCA:
      return {
        ...state,
        accessControl: action.payload,
      };

    default:
      return state;
  }
};
