import {
  MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_FAILURE,
  MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_REQUEST,
  MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_SUCCESS,
  MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE,
  MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST,
  MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS,
  MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_REQUEST,
  MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_SUCCESS,
  MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_FAILURE,
  MARKETING_REPORT_BLINKIT_BANNER_LISTING_REQUEST,
  MARKETING_REPORT_BLINKIT_BANNER_LISTING_SUCCESS,
  MARKETING_REPORT_BLINKIT_BANNER_LISTING_FAILURE,
  MARKETING_REPORT_INSTAMART_REQUEST,
  MARKETING_REPORT_INSTAMART_SUCCESS,
  MARKETING_REPORT_INSTAMART_FAILURE,
  SKU_PERFORMANCE_ZEPTO_REQUEST,
  SKU_PERFORMANCE_ZEPTO_SUCCESS,
  SKU_PERFORMANCE_ZEPTO_FAILURE,
  SKU_ANALYTICS_ZEPTO_REQUEST,
  SKU_ANALYTICS_ZEPTO_SUCCESS,
  SKU_ANALYTICS_ZEPTO_FAILURE,
  CAMPAIGN_PERFORMANCE_SKU_REQUEST,
  CAMPAIGN_PERFORMANCE_SKU_SUCCESS,
  CAMPAIGN_PERFORMANCE_SKU_FAILURE,
  AWARENESS_PERFORMANCE_DATA_SUCCESS,
  AWARENESS_PERFORMANCE_DATA_REQUEST,
  AWARENESS_PERFORMANCE_DATA_FAILURE,
} from "../constants/marketingConstants";

const initialState = {
  productListing: {
    loading: false,
    data: null,
    error: null,
  },
  productRecommendation: {
    loading: false,
    data: null,
    error: null,
  },
  searchSuggestion: {
    loading: false,
    data: null,
    error: null,
  },
  bannerListing: {
    loading: false,
    data: null,
    error: null,
  },

  instamartReport: {
    loading: false,
    data: null,
    error: null,
  },
  skuPerformanceZepto: {
    loading: false,
    data: null,
    error: null,
  },
  skuAnalyticsZepto: {
    loading: false,
    data: null,
    error: null,
  },
  campaignPerformanceSku: {
    loading: false,
    data: null,
    error: null,
  },
  awarenessPerformanceData: {
    loading: false,
    data: null,
    error: null,
  },
};

export const marketingReducer = (state = initialState, action) => {
  switch (action.type) {
    // Product Listing cases
    case MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_REQUEST:
      return {
        ...state,
        productListing: {
          ...state.productListing,
          loading: true,
          error: null,
        },
      };
    case MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_SUCCESS:
      return {
        ...state,
        productListing: {
          ...state.productListing,
          loading: false,
          data: action.payload,
        },
      };
    case MARKETING_REPORT_BLINKIT_PRODUCT_LISTING_FAILURE:
      return {
        ...state,
        productListing: {
          ...state.productListing,
          loading: false,
          error: action.payload,
        },
      };

    // Product Recommendation cases
    case MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_REQUEST:
      return {
        ...state,
        productRecommendation: {
          ...state.productRecommendation,
          loading: true,
          error: null,
        },
      };
    case MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        productRecommendation: {
          ...state.productRecommendation,
          loading: false,
          data: action.payload,
        },
      };
    case MARKETING_REPORT_BLINKIT_PRODUCT_RECOMMENDATION_FAILURE:
      return {
        ...state,
        productRecommendation: {
          ...state.productRecommendation,
          loading: false,
          error: action.payload,
        },
      };

    // Search Suggestion cases
    case MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_REQUEST:
      return {
        ...state,
        searchSuggestion: {
          ...state.searchSuggestion,
          loading: true,
          error: null,
        },
      };
    case MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_SUCCESS:
      return {
        ...state,
        searchSuggestion: {
          ...state.searchSuggestion,
          loading: false,
          data: action.payload,
        },
      };
    case MARKETING_REPORT_BLINKIT_SEARCH_SUGGESTION_FAILURE:
      return {
        ...state,
        searchSuggestion: {
          ...state.searchSuggestion,
          loading: false,
          error: action.payload,
        },
      };

    // Banner Listing cases
    case MARKETING_REPORT_BLINKIT_BANNER_LISTING_REQUEST:
      return {
        ...state,
        bannerListing: {
          ...state.bannerListing,
          loading: true,
          error: null,
        },
      };
    case MARKETING_REPORT_BLINKIT_BANNER_LISTING_SUCCESS:
      return {
        ...state,
        bannerListing: {
          ...state.bannerListing,
          loading: false,
          data: action.payload,
        },
      };
    case MARKETING_REPORT_BLINKIT_BANNER_LISTING_FAILURE:
      return {
        ...state,
        bannerListing: {
          ...state.bannerListing,
          loading: false,
          error: action.payload,
        },
      };
    // Instamart cases
    case MARKETING_REPORT_INSTAMART_REQUEST:
      return {
        ...state,
        instamartReport: {
          ...state.instamartReport,
          loading: true,
          error: null,
        },
      };
    case MARKETING_REPORT_INSTAMART_SUCCESS:
      return {
        ...state,
        instamartReport: {
          ...state.instamartReport,
          loading: false,
          data: action.payload,
        },
      };
    case MARKETING_REPORT_INSTAMART_FAILURE:
      return {
        ...state,
        instamartReport: {
          ...state.instamartReport,
          loading: false,
          error: action.payload,
        },
      };

    // SKU Performance cases
    case SKU_PERFORMANCE_ZEPTO_REQUEST:
      return {
        ...state,
        skuPerformanceZepto: {
          ...state.skuPerformanceZepto,
          loading: true,
          error: null,
        },
      };
    case SKU_PERFORMANCE_ZEPTO_SUCCESS:
      return {
        ...state,
        skuPerformanceZepto: {
          ...state.skuPerformanceZepto,
          loading: false,
          data: action.payload,
        },
      };
    case SKU_PERFORMANCE_ZEPTO_FAILURE:
      return {
        ...state,
        skuPerformanceZepto: {
          ...state.skuPerformanceZepto,
          loading: false,
          error: action.payload,
        },
      };

    // SKU Analytics cases
    case SKU_ANALYTICS_ZEPTO_REQUEST:
      return {
        ...state,
        skuAnalyticsZepto: {
          ...state.skuAnalyticsZepto,
          loading: true,
          error: null,
        },
      };
    case SKU_ANALYTICS_ZEPTO_SUCCESS:
      return {
        ...state,
        skuAnalyticsZepto: {
          ...state.skuAnalyticsZepto,
          loading: false,
          data: action.payload,
        },
      };
    case SKU_ANALYTICS_ZEPTO_FAILURE:
      return {
        ...state,
        skuAnalyticsZepto: {
          ...state.skuAnalyticsZepto,
          loading: false,
          error: action.payload,
        },
      };

    // Campaign Performance SKU cases
    case CAMPAIGN_PERFORMANCE_SKU_REQUEST:
      return {
        ...state,
        campaignPerformanceSku: {
          ...state.campaignPerformanceSku,
          loading: true,
          error: null,
        },
      };
    case CAMPAIGN_PERFORMANCE_SKU_SUCCESS:
      return {
        ...state,
        campaignPerformanceSku: {
          ...state.campaignPerformanceSku,
          loading: false,
          data: action.payload,
        },
      };
    case CAMPAIGN_PERFORMANCE_SKU_FAILURE:
      return {
        ...state,
        campaignPerformanceSku: {
          ...state.campaignPerformanceSku,
          loading: false,
          error: action.payload,
        },
      };

    case AWARENESS_PERFORMANCE_DATA_REQUEST:
      return {
        ...state,
        awarenessPerformanceData: {
          ...state.awarenessPerformanceData,
          loading: true,
          error: null,
        },
      };

    case AWARENESS_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        awarenessPerformanceData: {
          ...state.awarenessPerformanceData,
          loading: false,
          data: action.payload,
        },
      };
    case AWARENESS_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        awarenessPerformanceData: {
          ...state.awarenessPerformanceData,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
