import React from "react";

const GainerDrainerCard = ({ title, current, previous, diff, percentageChange, isGainer }) => {
  return (
    <div className="bg-white border shadow-sm rounded-lg p-4 mb-2 text-[#727278] font-medium h-[6.25rem]">
      {/* upper */}
      <div className="text-sm">{title}</div>
      {/* lower */}
      <div className="mt-2 text-xs flex justify-between">
        <div>Current MRP: {current}</div>
        <div>Previous MRP: {previous}</div>
      </div>
      <div className={`${isGainer ? "text-[#22C55E]" : "text-red-500"} flex items-center text-xs`}>
        {isGainer == true ? "Gain : ▲" : "Drain : ▼"}
        {diff}({percentageChange})%
      </div>
    </div>
  );
};

export default GainerDrainerCard;
