import React from "react";
import { dummyPricingData } from "../../../utils/dummyPricingData";
import CompetitorsDiscounting from "./CompetitorsDiscounting";
import CompetitorsPpu from "./CompetitorsPpu";

const dateAndSkuWiseGroupedData = (data) => {
  return data.reduce((acc, item) => {
    const { date, sku, brand, discounted_price, price, unitWeight } = item;
    const discountPercent = ((parseFloat(price) - parseFloat(discounted_price)) / price) * 100;
    const pricePer1 = parseFloat(price) / parseFloat(unitWeight); // Calculate price for 1ml/1gm

    // Initialize brand if not present
    if (!acc[brand]) {
      acc[brand] = {};
    }

    // Initialize date if not present for the brand
    if (!acc[brand][date]) {
      acc[brand][date] = {};
    }

    // Initialize sku if not present for the date
    if (!acc[brand][date][sku]) {
      acc[brand][date][sku] = {
        ppu: 0,
        discount: 0,
        count: 0,
      };
    }

    // Calculate discount (price - discounted_price) and count occurrences
    acc[brand][date][sku].ppu += pricePer1;
    acc[brand][date][sku].discount += discountPercent;
    acc[brand][date][sku].count += 1;

    return acc;
  }, {});
};

const groupedByBrandAndDate = (data) => {
  const groupedData = {};

  for (const brand in data) {
    groupedData[brand] = {}; // Initialize for each brand

    for (const date in data[brand]) {
      let totalPpu = 0;
      let totalDiscountPercent = 0;
      let totalCount = 0;

      for (const sku in data[brand][date]) {
        const { ppu, discount, count } = data[brand][date][sku];
        totalPpu += ppu / count;
        totalDiscountPercent += discount / count; // Sum of discounts
        totalCount += 1; // Sum of counts (sku count)
      }

      // Calculate the average discount percentage if totalCount is not zero
      const averagePpu = totalCount > 0 ? totalPpu / totalCount : 0;
      const averageDiscountPercent = totalCount > 0 ? totalDiscountPercent / totalCount : 0;

      // Group by brand and date
      groupedData[brand][date] = {
        totalPpu: averagePpu,
        totalDiscountPercent: averageDiscountPercent,
      };
    }
  }

  return groupedData;
};

const CompetitorTrends = ({ sku }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const ourBrand = user.organizationDetail.name;
  // Step 1: Sort the data by date in ascending order
  const sortedData = dummyPricingData.sort((a, b) => new Date(a.date) - new Date(b.date));
  const competitorData = sortedData.filter((item) => item.brand != ourBrand);
  let filteredData;
  if (sku == "All SKU's") {
    filteredData = competitorData;
  } else {
    // filteredData = competitorData.filter((item) => item.sku_mapping == sku);
    filteredData = competitorData.filter((item) => item.product == sku);
  }

  const groupedData = dateAndSkuWiseGroupedData(filteredData);

  const brandsGrouped = groupedByBrandAndDate(groupedData);

  return (
    <div className="flex flex-col gap-4 overflow-y-auto max-h-[calc(100vh-50px)]">
      <CompetitorsDiscounting data={brandsGrouped} />
      <CompetitorsPpu data={brandsGrouped} />
    </div>
  );
};

export default CompetitorTrends;
