import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import LoginScreen from "./screens/login/LoginScreen";
import InventoryReportScreen from "./screens/inventory/InventoryReportScreen";
import StockingInsightsScreen from "./screens/inventory/StockingInsightsScreen";
import PerformanceMarketingAdsScreen from "./screens/ads/PerformanceMarketingAdsScreen";
import DisplayAdScreen from "./screens/ads/DisplayAdScreen";
import PrivateRoutesWrapper from "./utils/PrivateRoutesWrapper";
import PublicRoutesWrapper from "./utils/PublicRoutesWrapper";
import KeywordRankingScreen from "./screens/ads/KeywordRankingScreen";
import PricingDetailScreen from "./screens/ads/PricingDetailsScreen";
import TargetTrackerScreen from "./screens/revenue/TargetTrackerScreen";
import SetTargetScreen from "./screens/setTargets/SetTargetScreen";
import SalesReportScreen from "./screens/revenue/SalesReportScreen";
import LeastPerformingScreen from "./screens/ads/LeastPerformingScreen";
import OverallProfitabilityScreen from "./screens/profitability/OverallProfitabilityScreen";
import PotentialSalesLossScreen from "./screens/fulfillment/PotentialSalesLossScreen";
import LocalityAvailabilityScreen from "./screens/inventory/LocalityAvailabilityScreen";
import FulfillmentReportScreen from "./screens/fulfillment/FulfillmentReportScreen";
import Layout from "./components/common/Layout/Layout";
import CompetitorPricingScreen from "./screens/ads/CompetitorPricingScreen";
import CompetitorScreen from "./screens/competitor/CompetitorScreen";
import VisibilityScreen from "./screens/visibility/VisibilityScreen";
import InsightsScreen from "./screens/insights/InsightsScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Initialize Google Analytics with your Tracking ID
const TRACKING_ID = "G-T2JC84EWX0";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App">
      <Router>
        {/* ToastContainer added here */}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <Routes>
          <Route path="/login" element={<PublicRoutesWrapper element={LoginScreen} />} />
          <Route path="/login" element={<PublicRoutesWrapper element={LoginScreen} />} />
        </Routes>
        <Layout collapsed={collapsed} setCollapsed={setCollapsed}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/inventory/inventory-report"
              element={<PrivateRoutesWrapper element={InventoryReportScreen} />}
            />
            <Route
              path="/inventory/stocking-insights"
              element={<PrivateRoutesWrapper element={StockingInsightsScreen} />}
            />
            <Route
              path="/ads/performance-marketing-ads"
              element={<PrivateRoutesWrapper element={PerformanceMarketingAdsScreen} />}
            />
            <Route path="/ads/display-ads" element={<PrivateRoutesWrapper element={DisplayAdScreen} />} />
            <Route
              path="/ads/competitor-pricing"
              element={<PrivateRoutesWrapper element={CompetitorPricingScreen} />}
            />
            <Route
              path="/inventory/locality-availability"
              element={<PrivateRoutesWrapper element={LocalityAvailabilityScreen} />}
            />
            <Route path="/ads/keyword-ranking" element={<PrivateRoutesWrapper element={KeywordRankingScreen} />} />
            <Route path="/ads/pricing-details" element={<PrivateRoutesWrapper element={PricingDetailScreen} />} />
            <Route path="/ads/least-performing" element={<PrivateRoutesWrapper element={LeastPerformingScreen} />} />
            <Route path="/ads/visibility" element={<PrivateRoutesWrapper element={VisibilityScreen} />} />
            <Route path="/ads/insights" element={<PrivateRoutesWrapper element={InsightsScreen} />} />
            <Route path="/revenue/target-tracker" element={<PrivateRoutesWrapper element={TargetTrackerScreen} />} />
            <Route path="/set-targets" element={<PrivateRoutesWrapper element={SetTargetScreen} />} />
            <Route path="/revenue/sales-report" element={<PrivateRoutesWrapper element={SalesReportScreen} />} />
            <Route
              path="/fulfillment/fulfillment-report"
              element={<PrivateRoutesWrapper element={FulfillmentReportScreen} />}
            />
            <Route
              path="/profitability/overall-profitability"
              element={<PrivateRoutesWrapper element={OverallProfitabilityScreen} />}
            />
            <Route
              path="/fulfillment/potential-sales-loss"
              element={<PrivateRoutesWrapper element={PotentialSalesLossScreen} />}
            />
            <Route path="/ads/category-rca" element={<PrivateRoutesWrapper element={CompetitorScreen} />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
