import React from "react";
import { useSelector } from "react-redux";

import HeatMap from "./HeatMap";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const MapViewSectionsContainer = () => {
  // Access thirdPartyAvailability from the Redux store
  const { thirdPartyAvailability } = useSelector((state) => state.thirdParty);

  // Extract unique brands from the thirdPartyAvailability data (if available)
  const brands = Array.isArray(thirdPartyAvailability?.data)
    ? Array.from(new Set(thirdPartyAvailability.data.map((item) => item["third_party_availability.brand"])))
    : [];

  return (
    <div className="w-full flex flex-col mb-4 rounded-lg">
      {/* Container with specific styling for the map view */}
      <div className="h-[120vh] mt-4 rounded-lg w-full mb-4 pb-8 p-4">
        {thirdPartyAvailability?.loading ? (
          // Display a skeleton loader while data is being fetched
          <ChartSkeleton />
        ) : !Array.isArray(thirdPartyAvailability?.data) || thirdPartyAvailability.data.length === 0 ? (
          // Display a message when no data is available or data format is invalid
          <NoDataFound />
        ) : (
          // Display the heatmap component when data is available
          <HeatMap apiData={thirdPartyAvailability?.data || []} brands={brands} />
        )}
      </div>
    </div>
  );
};

export default MapViewSectionsContainer;

// Use Case

// The MapViewSectionsContainer component displays a heatmap of third-party brand availability.
// It fetches availability data from the Redux store (thirdPartyAvailability).
// Based on the loading state, it either shows a loading skeleton (ChartSkeleton), a "No Data Found" message, or the HeatMap component with the fetched data.
// The component checks if the fetched data contains valid brand information and extracts unique brands.
// The valid data and brands are passed to the HeatMap component for visualization.
// It handles various states (loading, empty data) to ensure a smooth user experience with third-party availability insights.
