import React from "react";
import { TbAlarmAverage } from "react-icons/tb";

const AvgAppointmnetTurnAroundTimeCard = ({ data, title }) => {
  return (
    <div className=" flex  p-4 border bg-white rounded-lg w-auto items-start space-x-4 ">
      <div className="flex items-center text-5xl gap-2 text-blue-400">
        <img src="/icons/fulfillmentInsight.svg" />
      </div>
      <div className="flex flex-col ">
        <h2 className="text-base font-body font-semibold text-[#4F4E69]">{title}</h2>

        {/* Large Data with Pulse Animation */}
        <p className="text-base font-body leading-5 font-normal text-[#717171] mt-2 ">
          {data?.toFixed(2)} <span>Days</span>
        </p>
      </div>
    </div>
  );
};

export default AvgAppointmnetTurnAroundTimeCard;
