import {
  blinkitCategoryProfitablityQuery,
  blinkitCityProfitabilityQuery,
  blinkitProfitabilityQuery,
  instamartCategoryProfitabilityQuery,
  instamartCityProfitabilityQuery,
  instamartProfitabilityQuery,
  zeptoCategoryProfitabilityQuery,
  zeptoCityProfitabilityQuery,
  zeptoProfitablityQuery,
} from "../../utils/queries/profitabilityQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";
import dayjs from "dayjs";

//monthly
export const fetchMonthlyProfitabilityDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const dataArray = [];
  // Fetch data for the current month
  const currentMonthName = dayjs(selectedDate).format("MMMM");
  const startDate1 = dayjs(selectedDate).startOf("month").format("YYYY-MM-DDTHH:mm:ss");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  const result1 = await cubejsApi.load(blinkitProfitabilityQuery(startDate1, endDate1));
  if (
    result1.tablePivot()[0]["combined_sales_report_blinkit.total_gmv"] ||
    result1.tablePivot()[0]["combined_sales_report_blinkit.total_taxes_paid"] ||
    result1.tablePivot()[0]["combined_sales_report_blinkit.total_cogs"]
  ) {
    dataArray.push({
      month: currentMonthName,
      data: result1.tablePivot(),
    });
  }

  // Fetch data for the previous 5 months
  for (let i = 1; i < 6; i++) {
    const startDate = dayjs(selectedDate).subtract(i, "month").startOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const endDate = dayjs(selectedDate).subtract(i, "month").endOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const monthName = dayjs(startDate).format("MMMM");
    const monthlyData = await cubejsApi.load(blinkitProfitabilityQuery(startDate, endDate));
    if (
      monthlyData.tablePivot()[0]["combined_sales_report_blinkit.total_gmv"] ||
      monthlyData.tablePivot()[0]["combined_sales_report_blinkit.total_taxes_paid"] ||
      monthlyData.tablePivot()[0]["combined_sales_report_blinkit.total_cogs"]
    ) {
      dataArray.push({
        month: monthName,
        data: monthlyData.tablePivot(),
      });
    }
  }
  return dataArray;
};
export const fetchMonthlyProfitabilityDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const dataArray = [];
  // Fetch data for the current month
  const currentMonthName = dayjs(selectedDate).format("MMMM");
  const startDate1 = dayjs(selectedDate).startOf("month").format("YYYY-MM-DDTHH:mm:ss");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  const result1 = await cubejsApi.load(zeptoProfitablityQuery(startDate1, endDate1));
  if (
    result1.tablePivot()[0]["combined_sales_report_zepto.total_gross_merchandise_value"] ||
    result1.tablePivot()[0]["combined_sales_report_zepto.total_taxes_paid"] ||
    result1.tablePivot()[0]["combined_sales_report_zepto.total_cogs"]
  ) {
    dataArray.push({
      month: currentMonthName,
      data: result1.tablePivot(),
    });
  }

  // Fetch data for the previous 5 months
  for (let i = 1; i < 6; i++) {
    const startDate = dayjs(selectedDate).subtract(i, "month").startOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const endDate = dayjs(selectedDate).subtract(i, "month").endOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const monthName = dayjs(startDate).format("MMMM");
    const monthlyData = await cubejsApi.load(zeptoProfitablityQuery(startDate, endDate));
    if (
      monthlyData.tablePivot()[0]["combined_sales_report_zepto.total_gross_merchandise_value"] ||
      monthlyData.tablePivot()[0]["combined_sales_report_zepto.total_taxes_paid"] ||
      monthlyData.tablePivot()[0]["combined_sales_report_zepto.total_cogs"]
    ) {
      dataArray.push({
        month: monthName,
        data: monthlyData.tablePivot(),
      });
    }
  }
  return dataArray;
};
export const fetchMonthlyProfitabilityDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const dataArray = [];
  // Fetch data for the current month
  const currentMonthName = dayjs(selectedDate).format("MMMM");
  const startDate1 = dayjs(selectedDate).startOf("month").format("YYYY-MM-DDTHH:mm:ss");
  const endDate1 = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss");
  const result1 = await cubejsApi.load(instamartProfitabilityQuery(startDate1, endDate1));
  if (
    result1.tablePivot()[0]["combined_sales_report_instamart.total_gmv"] ||
    result1.tablePivot()[0]["combined_sales_report_instamart.total_taxes_paid"] ||
    result1.tablePivot()[0]["combined_sales_report_instamart.total_cogs"]
  ) {
    dataArray.push({
      month: currentMonthName,
      data: result1.tablePivot(),
    });
  }

  // Fetch data for the previous 5 months
  for (let i = 1; i < 6; i++) {
    const startDate = dayjs(selectedDate).subtract(i, "month").startOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const endDate = dayjs(selectedDate).subtract(i, "month").endOf("month").format("YYYY-MM-DDTHH:mm:ss");
    const monthName = dayjs(startDate).format("MMMM");
    const monthlyData = await cubejsApi.load(instamartProfitabilityQuery(startDate, endDate));
    if (
      monthlyData.tablePivot()[0]["combined_sales_report_instamart.total_gmv"] ||
      monthlyData.tablePivot()[0]["combined_sales_report_instamart.total_taxes_paid"] ||
      monthlyData.tablePivot()[0]["combined_sales_report_instamart.total_cogs"]
    ) {
      dataArray.push({
        month: monthName,
        data: monthlyData.tablePivot(),
      });
    }
  }
  return dataArray;
};

//city
export const fetchCityProfitabilityDataBlinkit = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitCityProfitabilityQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchCityProfitabilityDataZepto = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoCityProfitabilityQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchCityProfitabilityDataInstamart = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartCityProfitabilityQuery(startDate, endDate));
  return result.tablePivot();
};

//category
export const fetchCategoryProfitabilityDataBlinkit = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitCategoryProfitablityQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchCategoryProfitabilityDataZepto = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoCategoryProfitabilityQuery(startDate, endDate));
  return result.tablePivot();
};
export const fetchCategoryProfitabilityDataInstamart = async (selectedDate) => {
  const startDate = dayjs(selectedDate).subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS");
  const endDate = dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartCategoryProfitabilityQuery(startDate, endDate));
  return result.tablePivot();
};
