import React, { useState } from "react";
import { MdTableChart } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  setSelectedSovKeyword,
  getFilteredSovAreaName,
  setSelectedSovAreaName,
} from "../../../redux/actions/sovThirdPartyActions";
import ShareOfVoiceDrawer from "./ShareOfVoiceDrawer";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import ShareOfVoiceStatsTable from "./ShareOfVoiceStatsTable";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

const ShareOfVoiceKeywordsWise = ({ setSelectedKeyword, averagedData, percentageChangeData }) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [state, setState] = useState(false);

  const dispatch = useDispatch();
  const handleCardChange = (index, keyword) => {
    setSelectedCard(index + 1);
    dispatch(setSelectedSovKeyword(keyword));
  };

  const toggleDrawer = (open, titleName) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(open);
    dispatch(setSelectedSovKeyword(titleName));
  };
  // Convert values from strings to numbers for the total calculations
  const totalAd = averagedData.reduce((total, item) => total + item.avgSovAdPercentage, 0) || 0;
  const totalOrganic = averagedData.reduce((total, item) => total + item.avgSovOrgaincPercentage, 0) || 0;
  const countOfAd = averagedData.filter((item) => item.avgSovAdPercentage > 0).length || 0;
  const countOfOrganic = averagedData.filter((item) => item.avgSovOrgaincPercentage > 0).length || 0;
  const ad = totalAd / countOfAd || 0;
  const organic = totalOrganic / countOfOrganic || 0;
  const overall = ad + organic || 0;

  // Calculate the overall percentage change for "All Keywords" (total ad, organic, and overall)
  const overallOld = percentageChangeData.reduce(
    (acc, item) => ({
      adChange: acc.adChange + (parseFloat(item.adChange) || 0),
      organicChange: acc.organicChange + (parseFloat(item.organicChange) || 0),
    }),
    { adChange: 0, organicChange: 0 }
  );

  const overallChange = overallOld.adChange + overallOld.organicChange; // Sum of ad and organic changes for overall change

  const getChangeColor = (value) => {
    if (value === "No previous data") return "#6B7280"; // Tailwind's gray-500
    return value >= 0 ? "#16A34A" : "#F53E3E"; // Tailwind's green-600 and red-600
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-lg">
        <div className="flex py-5 px-5 border-b">
          <h1 className="flex gap-4 items-center text-lg font-medium ">
            <MdTableChart />

            <span className="font-medium text-lg text-[#4f4e69]">SKU Level Breakdown</span>
            <CustomTooltip
              title={"Shows total sales data for each of the last 30 days, helping you track daily sales trends."}
            >
              <PiInfoLight />
            </CustomTooltip>
          </h1>
        </div>

        <div className="p-4">
          <div className="p-2 border rounded-xl">
            <div className="w-full bg-white rounded-[0.25rem] overflow-hidden">
              <Table>
                <TableHead sx={{ marginBottom: "1rem" }}>
                  <TableRow>
                    <TableCell sx={{ fontSize: "0.875rem", textAlign: "left", color: "#4d4d4d", bgcolor: "#EAEDF3" }}>
                      Keyword
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.875rem", textAlign: "center", color: "#4d4d4d", bgcolor: "#EAEDF3" }}>
                      Overall Sov
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.875rem", textAlign: "center", color: "#4d4d4d", bgcolor: "#EAEDF3" }}>
                      Organic Sov
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.875rem", textAlign: "center", color: "#4d4d4d", bgcolor: "#EAEDF3" }}>
                      Ad Sov
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "0.875rem", textAlign: "center", color: "#4d4d4d", bgcolor: "#EAEDF3" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ bgcolor: "#F0F6FF" }}>
                    <TableCell sx={{ fontWeight: "500", color: "#4d4d4d", fontWeight: "500" }}>All Keywords</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{overall}%</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <div className="flex items-center justify-center gap-2">
                        {organic}%
                        <Typography sx={{ color: getChangeColor(overallOld.organicChange) }}>
                          {overallOld.organicChange >= 0 ? "▲" : "▼"} {overallOld.organicChange.toFixed(2)}%
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <div className="flex items-center justify-center gap-2">
                        {ad}%
                        <Typography sx={{ color: getChangeColor(overallOld.adChange) }}>
                          <span>{overallOld.adChange >= 0 ? "▲" : "▼"}</span> {overallOld.adChange}%
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        onClick={toggleDrawer(true, "")}
                        className="border border-[#387FF5] text-[#387FF5] rounded-[0.25rem] text-center text-xs p-2 text-medium w-fit cursor-pointer"
                      >
                        {/* <img src="/logos/dots.svg" alt="dots" /> */}
                        View Insights
                      </div>
                    </TableCell>
                  </TableRow>
                  {averagedData.map((item, index) => {
                    const percentageChange = percentageChangeData.find(
                      (changeItem) => changeItem.keyword === item.keyword
                    );
                    return (
                      <ShareOfVoiceStatsTable
                        key={index}
                        selectedCard={selectedCard}
                        handleCardChange={() => handleCardChange(index, item.keyword)}
                        code={index + 1}
                        titleName={item.keyword}
                        keywordType={item.keywordType}
                        ad={item.avgSovAdPercentage}
                        organic={item.avgSovOrgaincPercentage}
                        overall={item.avgSovAdPercentage + item.avgSovOrgaincPercentage}
                        adChange={percentageChange?.adChange || "No previous data"}
                        organicChange={percentageChange?.organicChange || "No previous data"}
                        setSelectedKeyword={setSelectedKeyword}
                        updateKeyword={true}
                        index={index}
                        toggleDrawer={toggleDrawer}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <ShareOfVoiceDrawer toggleDrawer={toggleDrawer} state={state} keyword={"All Keywords"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareOfVoiceKeywordsWise;
