import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { MdOutlineRateReview } from "react-icons/md";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const tooltipTitle =
  "This table shows the fill rates for different products across various locations. Fill rate indicates how much of the demand has been met, helping assess stock levels and fulfillment efficiency.";

const getGroupedData = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const city = item["fulfillment_report_instamart.city"];
    const skuName = item["fulfillment_report_instamart.skuname"];
    const totalGrnQty = parseFloat(item["fulfillment_report_instamart.total_grn_qty"]);
    const poQty = parseFloat(item["fulfillment_report_instamart.poqty"]);

    // Initialize the city object if it doesn't exist
    if (!groupedData[city]) {
      groupedData[city] = {};
    }

    // Initialize the skuName object if it doesn't exist under the city
    if (!groupedData[city][skuName]) {
      groupedData[city][skuName] = {
        rate: 0,
        count: 0,
      };
    }

    // Calculate the ratio and store it
    groupedData[city][skuName].rate += totalGrnQty / poQty;
    groupedData[city][skuName].count++;
  });
  return groupedData;
};

const heading = ["City -> SKU", "Average Fill Rate"];

const FillRateInstamart = () => {
  const { instamartFulfillment } = useSelector((state) => state.fulfillment);
  const [expandedCategories, setExpandedCategories] = useState({});

  const filteredData = getGroupedData(instamartFulfillment.data);

  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <Paper
      className="w-full rounded-lg shadow overflow-scroll"
      sx={{
        width: "100%",
        overflowX: "auto",
        mb: "2rem",
      }}
    >
      <div className="flex gap-4 items-center p-4 font-medium text-lg border-b">
        <MdOutlineRateReview />
        Fill Rate
        <CustomTooltip title={tooltipTitle}>
          <PiInfoLight />
        </CustomTooltip>
      </div>
      <div className="p-2">
        <TableContainer sx={{ borderRadius: "0.25rem" }}>
          <Table className="table-auto border-collapse min-w-full" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ display: "flex" }}>
                {heading.map((head, index) => (
                  <TableCell
                    key={`heading-${index}`}
                    className="text-left text-[#39393D] px-6 py-4 font-body gap-[2.75rem] tracking-wider"
                    sx={{
                      fontWeight: "500",
                      color: "#4D4D4D",
                      fontSize: "0.875rem",
                      textAlign: index == 0 ? "left" : "center",
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                      zIndex: index === 0 && "3",
                      minWidth: index === 0 ? "400px" : "auto",
                      maxWidth: index === 0 ? "400px" : "auto",
                      flexGrow: index == 1 && 1,
                      position: index === 0 && "sticky",
                      left: index === 0 && 0,
                      bgcolor: "#F9F9F9",
                    }}
                  >
                    {head}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                    zIndex: "3",
                    position: "sticky",
                    right: 0,
                    bgcolor: "#F9F9F9",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="bg-white">
              {Object.keys(filteredData).map((city, index) => {
                const calculateSumRatesAndCounts = (data) => {
                  let num = 0;

                  // Iterate over each SKU in the city
                  Object.keys(data[city]).forEach((sku) => {
                    const { rate, count } = data[city][sku];
                    num += rate / count;
                  });
                  return num / Object.keys(data[city]).length;
                };
                return (
                  <React.Fragment key={city}>
                    <TableRow
                      className={`cursor-pointer ${expandedCategories[city] ? "bg-[#E5E5E5]" : ""}`}
                      sx={{ display: "flex", bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}
                      onClick={() => handleCategoryClick(city)}
                    >
                      <TableCell
                        className="flex items-center text-left px-3 gap-2 py-4 whitespace-nowrap font-body"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "500",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          minWidth: "400px",
                          maxWidth: "400px",
                          position: "sticky",
                          left: 0,
                          display: "flex",
                          bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          textAlign: "left",
                        }}
                      >
                        {expandedCategories[city] ? <FaCaretUp /> : <FaCaretDown />}
                        {city}
                      </TableCell>
                      <TableCell
                        className="px-6 py-4 whitespace-nowrap text-left font-body"
                        sx={{
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        {calculateSumRatesAndCounts(filteredData).toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                        }}
                      ></TableCell>
                    </TableRow>

                    {/* Sub-rows for each SKU under the city */}
                    {expandedCategories[city] &&
                      Object.keys(filteredData[city]).map((skuName) => (
                        <TableRow sx={{ display: "flex" }} key={skuName} className="bg-[#F0F0F0]">
                          <TableCell
                            className="pl-6 text-left font-body"
                            sx={{
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              minWidth: "400px",
                              maxWidth: "400px",
                              position: "sticky",
                              left: 0,
                              paddingLeft: "4rem",
                              textAlign: "left",
                            }}
                          >
                            {skuName}
                          </TableCell>
                          <TableCell
                            className="px-6 py-4 whitespace-nowrap text-left font-body"
                            sx={{
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            {(filteredData[city][skuName].rate / filteredData[city][skuName].count).toFixed(2)}
                          </TableCell>
                          <TableCell
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3",
                              position: "sticky",
                              right: 0,
                              bgcolor: "white",
                            }}
                          ></TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

export default FillRateInstamart;
