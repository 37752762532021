export const groupedDataByCampaignsBannerListing = (arr) => {
  const groupedData = arr?.reduce((acc, item) => {
    const title =
      item["marketting_report_blinkit_banner_listing.campaign_name"];

    const impressions =
      parseInt(
        item["marketting_report_blinkit_banner_listing.impressions"],
        10
      ) || 0;

    const estimateBudget =
      parseFloat(
        item["marketting_report_blinkit_banner_listing.estimated_budget"],
        10
      ) || 0;

    const cpm =
      parseFloat(item["marketting_report_blinkit_banner_listing.cpm"], 10) || 0;

    const reach =
      parseInt(item["marketting_report_blinkit_banner_listing.reach"], 10) || 0;

    const ctr =
      parseFloat(item["marketting_report_blinkit_banner_listing.ctr"], 10) || 0;

    if (!acc[title]) {
      acc[title] = {
        title: title,
        impressions: 0,
        estimateBudget: 0,
        cpm: 0,
        reach: 0,
        ctr: 0,
        count: 0,
      };
    }

    acc[title].impressions += impressions;
    acc[title].reach += reach;
    acc[title].estimateBudget += estimateBudget;
    acc[title].cpm += cpm;
    acc[title].count += 1;
    acc[title].ctr += ctr;

    return acc;
  }, {});
  return groupedData;
};

export const groupedDataByKeywordsBannerListing = (arr) => {
  const groupedData = arr?.reduce((acc, item) => {
    const type =
      item["marketting_report_blinkit_banner_listing.targeting_type"];

    if (type === "Keyword") {
      const title =
        item["marketting_report_blinkit_banner_listing.targeting_value"];
      const impressions =
        parseInt(
          item["marketting_report_blinkit_banner_listing.impressions"],
          10
        ) || 0;

      const estimateBudget =
        parseFloat(
          item["marketting_report_blinkit_banner_listing.estimated_budget"],
          10
        ) || 0;

      const cpm =
        parseFloat(item["marketting_report_blinkit_banner_listing.cpm"], 10) ||
        0;

      const reach =
        parseInt(item["marketting_report_blinkit_banner_listing.reach"], 10) ||
        0;

      const ctr =
        parseFloat(item["marketting_report_blinkit_banner_listing.ctr"], 10) ||
        0;

      if (!acc[title]) {
        acc[title] = {
          title: title,
          impressions: 0,
          estimateBudget: 0,
          cpm: 0,
          reach: 0,
          ctr: 0,
          count: 0,
        };
      }

      acc[title].impressions += impressions;
      acc[title].reach += reach;
      acc[title].estimateBudget += estimateBudget;
      acc[title].cpm += cpm;
      acc[title].count += 1;
      acc[title].ctr += ctr;
    }

    return acc;
  }, {});
  return groupedData;
};

export const groupedDataByDateBannerListing = (arr) => {
  const obj = arr?.reduce((acc, curr) => {
    const date = curr["marketting_report_blinkit_banner_listing.date"];
    const targetingType =
      curr["marketting_report_blinkit_banner_listing.targeting_type"];
    const keyword =
      curr["marketting_report_blinkit_banner_listing.targeting_value"]; // Assuming targeting_value is the keyword

    if (targetingType === "Keyword") {
      if (!acc[date]) {
        acc[date] = {
          impressions: 0,
          estimateBudget: 0,
          reach: 0,
          cpm: 0,
          count: 0,
        };
      }

      acc[date].impressions += parseInt(
        curr["marketting_report_blinkit_banner_listing.impressions"],
        10
      );

      acc[date].cpm += parseFloat(
        curr["marketting_report_blinkit_banner_listing.cpm"],
        10
      );

      acc[date].estimateBudget += parseFloat(
        curr["marketting_report_blinkit_banner_listing.estimated_budget"],
        10
      );

      acc[date].reach += parseInt(
        curr["marketting_report_blinkit_banner_listing.reach"],
        10
      );
      acc[date].count += 1;
    }

    return acc;
  }, {});
  return obj;
};

export const groupedDataByCampaignNameBannerListing = (data) => {
  return data?.reduce((acc, item) => {
    const campaignName =
      item["marketting_report_blinkit_banner_listing.campaign_name"];

    if (!acc[campaignName]) {
      acc[campaignName] = [];
    }

    acc[campaignName].push({
      date: item["marketting_report_blinkit_banner_listing.date"],
      cpm: item["marketting_report_blinkit_banner_listing.cpm"],
      reach: item["marketting_report_blinkit_banner_listing.reach"],
      matchType: item["marketting_report_blinkit_banner_listing.match_type"],
      impressions: item["marketting_report_blinkit_banner_listing.impressions"],
      uniqueClicks:
        item["marketting_report_blinkit_banner_listing.unique_clicks"],
      targetingType:
        item["marketting_report_blinkit_banner_listing.targeting_type"],
      targetingValue:
        item["marketting_report_blinkit_banner_listing.targeting_value"],
      estimatedBudget:
        item["marketting_report_blinkit_banner_listing.estimated_budget"],
    });

    return acc;
  }, {});
};
