import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import SingleDatePicker from "../../DatePickers/SingleDatePicker/SingleDatePicker";
import CustomSigleDateRangePicker from "../../DatePickers/SingleDatePicker/CustomSigleDateRangePicker";
import CustomDateRangePicker from "../../DatePickers/CustomDateRangePicker/CustomDateRangePicker";
import PagePath from "../PagePath/PagePath";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null); // Ref for the menu box
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("cubeToken");
    localStorage.removeItem("token");
    navigate("/login");
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const renderDatePicker = () => {
    const { pathname } = location;

    const singleDatePickerPaths = [
      "/inventory/locality-availability",
      "/inventory/inventory-report",
      "/inventory/stocking-insights",
      "/fulfillment/potential-sales-loss",
      "/revenue/target-tracker",
      "/ads/performance-marketing-ads",
      "/ads/display-ads",
      "/fulfillment/fulfillment-report",
      "/ads/least-performing",
      "/ads/pricing-details",
      "/ads/keyword-ranking",
      "/ads/competitor-pricing",
      "/profitability/overall-profitability",
      "/ads/visibility",
      "/ads/insights",
      "/ads/category-rca",
      "/ads/visibility",
    ];

    const customDateRangePickerPaths = ["/revenue/sales-report"];

    if (singleDatePickerPaths.some((path) => pathname.includes(path))) {
      return <SingleDatePicker />;
    }

    if (customDateRangePickerPaths.some((path) => pathname.includes(path))) {
      return <CustomDateRangePicker />;
    }

    return null; // Return null if no date picker is needed for the current page
  };

  return (
    <div>
      <nav className="bg-backgroundWhite py-1 shadow w-[100%]">
        <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-14 items-center justify-between">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <PagePath />
            </div>

            <div className="flex flex-col">
              <div>{renderDatePicker()}</div>
            </div>

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="relative ml-3" ref={menuRef}>
                <div>
                  <button
                    type="button"
                    className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                    id="user-menu-button"
                    aria-expanded={isMenuOpen}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                  >
                    <span className="absolute -inset-1.5"></span>
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src="/logos/brandLogoBlue.svg" alt="" />
                  </button>
                </div>
                {isMenuOpen && (
                  <div
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex="-1"
                  >
                    <Link
                      to={"/set-targets"}
                      className="block px-4 py-2  hover:bg-gray-100 text-sm text-gray-700 cursor-pointer"
                      role="menuitem"
                      tabIndex="-1"
                      id="user-menu-item-2"
                      onClick={() => setIsMenuOpen(false)} // Close the menu on link click
                    >
                      Set Targets
                    </Link>
                    <div className="w-ful ">
                      <button
                        onClick={() => {
                          handleLogout();
                          setIsMenuOpen(false); // Close the menu on logout
                        }}
                        className="block px-4 py-2 text-start w-full text-sm bg-white hover:bg-gray-100 text-gray-700 cursor-pointer"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
