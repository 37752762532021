import { Skeleton } from "@mui/material";
import React from "react";

const RowOfFunnel = () => (
  <div className="flex justify-evenly items-center flex-wrap">
    <Skeleton variant="rounded" sx={{ width: "30%", height: "400px", mb: "40px" }} />
    <Skeleton variant="rounded" sx={{ width: "30%", height: "400px", mb: "40px" }} />
    <Skeleton variant="rounded" sx={{ width: "30%", height: "400px", mb: "40px" }} />
    <Skeleton variant="rounded" sx={{ width: "30%", height: "400px", mb: "40px" }} />
    <Skeleton variant="rounded" sx={{ width: "30%", height: "400px", mb: "40px" }} />
    <Skeleton variant="rounded" sx={{ width: "30%", height: "400px", mb: "40px" }} />
  </div>
);

const FunnelSkeleton = () => {
  return <RowOfFunnel />;
};

export default FunnelSkeleton;
