import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import GainerDrainerCard from "./GainerDrainerCard";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
function mergeArraysByProductName(arr1, arr2) {
  const mergedArray = [];

  // Create a map for faster lookup
  const arr2Map = new Map(arr2.map((item) => [item.product_name, item]));

  // Iterate through the first array
  arr1.forEach((item1) => {
    const item2 = arr2Map.get(item1.product_name);

    if (item2) {
      // If the product name is the same, merge the objects
      let diff;
      let diffPercentage;
      if (item2.mrp > item1.mrp) {
        diff = item2.mrp - item1.mrp;
        diffPercentage = (diff / item1.mrp) * 100;
      } else {
        diff = item1.mrp - item2.mrp;
        diffPercentage = (diff / item2.mrp) * 100;
      }

      mergedArray.push({
        product_name: item1.product_name,
        previous: item1.mrp,
        current: item2.mrp,
        diff: diff,
        diffPercentage: diffPercentage,
      });
    }
  });

  return mergedArray;
}

const TopDrainers = ({ previous, current }) => {
  const mergedArray = mergeArraysByProductName(previous, current);
  const gainersData = mergedArray.filter((item) => item.current < item.previous);
  const finalTableData = gainersData.sort((a, b) => a.diffPercentage - b.diffPercentage).slice(0, 5);
  return (
    <div className="relative">
      <div className="font-semibold">Top Drainers</div>
      <div className="border p-3 rounded-xl">
        {finalTableData.length > 0 ? (
          finalTableData.map((item, index) => (
            <GainerDrainerCard
              key={index}
              title={item.product_name}
              current={item.current}
              previous={item.previous}
              diff={item.diff}
              percentageChange={item.diffPercentage.toFixed(2)}
              isGainer={false}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-full">No Drainers</div>
        )}
      </div>
    </div>
  );
};

export default TopDrainers;
