export const INSIGHTS_REQUEST = "INSIGHTS_REQUEST";
export const INSIGHTS_SUCCESS = "INSIGHTS_SUCCESS";
export const INSIGHTS_FAILURE = "INSIGHTS_FAILURE";

export const INSIGHTS_CITY_REQUEST = "INSIGHTS_CITY_REQUEST";
export const INSIGHTS_CITY_SUCCESS = "INSIGHTS_CITY_SUCCESS";
export const INSIGHTS_CITY_FAILURE = "INSIGHTS_CITY_FAILURE";

export const INSIGHTS_PRODUCT_REQUEST = "INSIGHTS_PRODUCT_REQUEST";
export const INSIGHTS_PRODUCT_SUCCESS = "INSIGHTS_PRODUCT_SUCCESS";
export const INSIGHTS_PRODUCT_FAILURE = "INSIGHTS_PRODUCT_FAILURE";

export const INSIGHTS_PRODUCT_PREVIOUS_REQUEST = "INSIGHTS_PRODUCT_PREVIOUS_REQUEST";
export const INSIGHTS_PRODUCT_PREVIOUS_SUCCESS = "INSIGHTS_PRODUCT_PREVIOUS_SUCCESS";
export const INSIGHTS_PRODUCT_PREVIOUS_FAILURE = "INSIGHTS_PRODUCT_PREVIOUS_FAILURE";
