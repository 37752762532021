export const groupedDataByCampaignNameProductRcomm = (data) => {
  //   {
  //     "marketting_report_blinkit_product_recommendation.campaign_name": "SP-Product Recommendations-A2 Ghee-23-02-2024-11:41AM",
  //     "marketting_report_blinkit_product_recommendation.date": "01-07-2024",
  //     "marketting_report_blinkit_product_recommendation.direct_roas": "0",
  //     "marketting_report_blinkit_product_recommendation.cpm": "392",
  //     "marketting_report_blinkit_product_recommendation.direct_atc": "0",
  //     "marketting_report_blinkit_product_recommendation.indirect_atc": "1",
  //     "marketting_report_blinkit_product_recommendation.direct_sales": "0",
  //     "marketting_report_blinkit_product_recommendation.impressions": "597",
  //     "marketting_report_blinkit_product_recommendation.d_q_sold": "0",
  //     "marketting_report_blinkit_product_recommendation.in_q_sold": "0",
  //     "marketting_report_blinkit_product_recommendation.i_sales": "0",
  //     "marketting_report_blinkit_product_recommendation.n_u_a": "0",
  //     "marketting_report_blinkit_product_recommendation.e_budg": "234.024"
  // }

  return data?.reduce((acc, item) => {
    const title = item["marketting_report_blinkit_product_recommendation.campaign_name"];

    if (!acc[title]) {
      acc[title] = {
        title,
        cpm: 0,
        totaRoas: 0,
        directAddToCart: 0,
        indirectAddToCart: 0,
        addToCarts: 0,
        impressions: 0,
        indirectSales: 0,
        directQuantitiesSold: 0,
        indirectQuantitiesSold: 0,
        totalQuantitiesSold: 0,
        directSales: 0,
        estimateBudget: 0,
        newUsersAcquired: 0,
        count: 0,
      };
    }

    acc[title].cpm += parseFloat(item["marketting_report_blinkit_product_recommendation.cpm"] || 0);
    acc[title].totaRoas += parseFloat(item["marketting_report_blinkit_product_recommendation.direct_roas"] || 0);
    acc[title].directAddToCart += parseInt(
      item["marketting_report_blinkit_product_recommendation.direct_atc"] || 0,
      10
    );
    acc[title].indirectAddToCart += parseInt(
      item["marketting_report_blinkit_product_recommendation.indirect_atc"] || 0,
      10
    );
    acc[title].addToCarts = acc[title].directAddToCart + acc[title].indirectAddToCart;
    acc[title].impressions += parseInt(item["marketting_report_blinkit_product_recommendation.impressions"] || 0, 10);
    acc[title].indirectSales += parseInt(item["marketting_report_blinkit_product_recommendation.i_sales"] || 0, 10);
    acc[title].directQuantitiesSold += parseInt(
      item["marketting_report_blinkit_product_recommendation.d_q_sold"] || 0,
      10
    );
    acc[title].indirectQuantitiesSold += parseInt(
      item["marketting_report_blinkit_product_recommendation.in_q_sold"] || 0,
      10
    );
    acc[title].totalQuantitiesSold = acc[title].directQuantitiesSold + acc[title].indirectQuantitiesSold;
    acc[title].directSales += parseFloat(item["marketting_report_blinkit_product_recommendation.direct_sales"] || 0);
    acc[title].estimateBudget += parseFloat(item["marketting_report_blinkit_product_recommendation.e_budg"] || 0);
    acc[title].newUsersAcquired += parseInt(item["marketting_report_blinkit_product_recommendation.n_u_a"] || 0, 10);

    acc[title].count += 1;

    return acc;
  }, {});
};

export const groupedDataByCampaignRecommendation = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const campaignName = item["marketting_report_blinkit_product_recommendation.campaign_name"];

    if (!acc[campaignName]) {
      acc[campaignName] = [];
    }

    acc[campaignName].push({
      date: item["marketting_report_blinkit_product_recommendation.date"],
      directRoas: item["marketting_report_blinkit_product_recommendation.direct_roas"],
      cpm: item["marketting_report_blinkit_product_recommendation.cpm"],
      directAtc: item["marketting_report_blinkit_product_recommendation.direct_atc"],
      indirectAtc: item["marketting_report_blinkit_product_recommendation.indirect_atc"],
      directSales: item["marketting_report_blinkit_product_recommendation.direct_sales"],
      impressions: item["marketting_report_blinkit_product_recommendation.impressions"],
      directQuantitiesSold: item["marketting_report_blinkit_product_recommendation.d_q_sold"],
      indirectQuantitiesSold: item["marketting_report_blinkit_product_recommendation.in_q_sold"],
      indirectSales: item["marketting_report_blinkit_product_recommendation.i_sales"],
      newUsersAcquired: item["marketting_report_blinkit_product_recommendation.n_u_a"],
      estimateBudget: item["marketting_report_blinkit_product_recommendation.e_budg"],
    });

    return acc;
  }, {});
  
  // Sort the items within each campaign by date
  Object.keys(groupedData).forEach((campaignName) => {
    groupedData[campaignName].sort((a, b) => new Date(a.date) - new Date(b.date));
  });
  return groupedData;
};

export const groupedDataByDateRecommendation = (arr) => {
  const obj = arr?.reduce((acc, curr) => {
    const date = curr["marketting_report_blinkit_product_recommendation.date"];

    if (!acc[date]) {
      acc[date] = {
        impressions: 0,
        totalQuantitiesSold: 0,
        addToCart: 0,
        estimateBudget: 0,
        cpm: 0,
        count: 0,
      };
    }

    acc[date].impressions += parseInt(curr["marketting_report_blinkit_product_recommendation.impressions"], 10);
    acc[date].addToCart +=
      parseInt(curr["marketting_report_blinkit_product_recommendation.direct_atc"], 10) +
      parseInt(curr["marketting_report_blinkit_product_recommendation.indirect_atc"], 10);
    acc[date].totalQuantitiesSold +=
      parseInt(curr["marketting_report_blinkit_product_recommendation.d_q_sold"], 10) +
      parseInt(curr["marketting_report_blinkit_product_recommendation.in_q_sold"], 10);

    acc[date].cpm += parseInt(curr["marketting_report_blinkit_product_recommendation.cpm"], 10);

    acc[date].estimateBudget += parseFloat(curr["marketting_report_blinkit_product_recommendation.e_budg"], 10);
    acc[date].count += 1;

    return acc;
  }, {});
  return obj;
};
