import React, { useEffect, useState, useMemo } from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import { Button, Select, MenuItem } from "@mui/material";
import MapModal from "./MapModal";
import { BASE_URL } from "../../../utils/assets";
import Dropdown from "../../common/Dropdown/Dropdown";

// Function to get latitude and longitude from a pincode using your Express API
const getLatLngFromPincode = async (pincode) => {
  console.log("pincode", pincode);
  console.log("Type of pincode:", typeof pincode); // Logs the type of pincode

  try {
    const response = await fetch(`${BASE_URL}/get-lat-lng`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pincode }),
    });

    const data = await response.json();
    console.log("data====", data);

    if (response.ok) {
      return {
        lat: data.lat,
        lng: data.lng,
      };
    } else {
      console.error("Error fetching lat/lng:", data.message);
      return { lat: 0, lng: 0 };
    }
  } catch (error) {
    console.error("Fetch error:", error);
    return { lat: 0, lng: 0 };
  }
};

// Styles for the map container
const mapContainerStyle = {
  height: "98vh",
  width: "100%",
  borderRadius: "0.625rem",
};

// Default map center coordinates (India)
const center = {
  lat: 20.5937, // Center latitude
  lng: 78.9629, // Center longitude
};

const googlelibraries = ["visualization"]; // Required for heatmap

// Main HeatMap component
const HeatMap = ({ apiData, brands }) => {
  const userDetail = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userDetail);

  const [map, setMap] = useState(null); // Store reference to Google Map instance
  const [heatMapData, setHeatMapData] = useState([]); // Data for heatmap rendering
  const [markers, setMarkers] = useState([]); // Store marker data for cities
  const [showModal, setShowModal] = useState(false); // Manage modal visibility
  const [modalContent, setModalContent] = useState({}); // Content to show in modal
  const [selectedSku, setSelectedSku] = useState(""); // Track selected SKU for filtering
  const [selectedMarker, setSelectedMarker] = useState(null); // Track selected marker on the map
  const [selectedBrand, setSelectedBrand] = useState(parsedUserData?.organizationDetail?.name); // Track selected brand

  // Load Google Maps API with necessary libraries
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyChKg5h4JbQHNI6ltvMVKVhRr5ApBi5ICE", // Replace with your actual API key
    libraries: googlelibraries, // Load visualization library for heatmap
  });

  // Filter data for the selected brand
  const data = apiData?.filter((item) => item["third_party_availability.brand"] == selectedBrand);

  // Extract unique SKUs from the filtered data
  const uniqueSkus = useMemo(() => {
    const skus = data?.map((item) => item["third_party_availability.product_name"]);
    return [...new Set(skus)]; // Return unique SKUs
  }, [apiData]);

  // Group data by city and SKU
  const groupedByCity = useMemo(() => {
    return data?.reduce((acc, item) => {
      const city = item["third_party_availability.city_lm"];
      if (!acc[city]) {
        acc[city] = {}; // Initialize city object
      }

      // Initialize SKU array for the city
      const sku = item["third_party_availability.product_name"];
      if (!acc[city][sku]) {
        acc[city][sku] = [];
      }

      acc[city][sku].push(item); // Add item to the city's SKU group
      return acc;
    }, {});
  }, [apiData]);

  // Helper function to round values up to the nearest quarter
  const roundUpToNearestQuarter = (value) => {
    return Math.ceil(value * 4) / 4;
  };

  // Calculate average availability percentage for each city and SKU
  const calculateAverageAvailabilityPercentage = (groupedByCityAndSKU) => {
    const updatedData = {};

    if (!groupedByCityAndSKU) {
      return updatedData; // Return an empty object if the input is undefined or null
    }

    // Iterate over each city
    Object.keys(groupedByCityAndSKU).forEach((city) => {
      updatedData[city] = {
        averageAvailabilityPercentage: 0,
        cityPincode: null, // Initialize the cityPincode key
        skus: {}, // Store SKU data here
      };

      let totalAvailabilityPercentage = 0;
      let numberOfSkus = 0;

      // Iterate over each SKU in the city
      Object.keys(groupedByCityAndSKU[city]).forEach((sku) => {
        const skuItems = groupedByCityAndSKU[city][sku];
        const totalItems = skuItems.length;
        // Update availableItems check to handle both string '1' and number 1
        const availableItems = skuItems.filter(
          (item) =>
            item["third_party_availability.stock_binary"] === "1" || item["third_party_availability.stock_binary"] === 1
        ).length;
        // Calculate availability percentage for each SKU
        const availabilityPercentage = (availableItems / totalItems) * 100;

        // Store the availability percentage and items for each SKU
        updatedData[city].skus[sku] = {
          items: skuItems,
          availabilityPercentage,
        };

        // Set cityPincode from the 0th index SKU item
        if (updatedData[city].cityPincode === null && skuItems.length > 0) {
          updatedData[city].cityPincode = skuItems[0]["third_party_availability.pincode_lm"];
        }

        // Accumulate the availability percentages and count SKUs
        totalAvailabilityPercentage += availabilityPercentage;
        numberOfSkus += 1;
      });

      const totalNumberOfSku = numberOfSkus * 100;
      updatedData[city].averageAvailabilityPercentage = numberOfSkus
        ? roundUpToNearestQuarter((totalAvailabilityPercentage / totalNumberOfSku) * 100)
        : 0;
    });

    return updatedData;
  };

  // Memoize the city-wise availability percentage
  const cityWiseAvailPercentage = useMemo(() => calculateAverageAvailabilityPercentage(groupedByCity), [groupedByCity]);

  // Filter city-wise data based on the selected SKU
  const filteredCityWiseAvailPercentage = useMemo(() => {
    if (selectedSku === "") return cityWiseAvailPercentage;

    const filteredData = {};
    Object.keys(cityWiseAvailPercentage).forEach((city) => {
      filteredData[city] = {
        ...cityWiseAvailPercentage[city],
        skus: cityWiseAvailPercentage[city].skus[selectedSku]
          ? {
              [selectedSku]: cityWiseAvailPercentage[city].skus[selectedSku],
            }
          : {},
      };
    });
    return filteredData;
  }, [selectedSku, cityWiseAvailPercentage]);

  console.log("filteredCityWiseAvailPercentage====", filteredCityWiseAvailPercentage);

  // Effect to fetch city data and populate heatmap markers
  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded) {
        const processedData = [];
        const pinsData = [];
        const newMarkers = [];

        // Loop through each city and retrieve lat/lng from pincode
        for (const city in filteredCityWiseAvailPercentage) {
          let count = 0;
          const cityData = filteredCityWiseAvailPercentage[city];
          const cityPincode = cityData.cityPincode;
          if (cityPincode) {
            count++;
            const { lat, lng } = await getLatLngFromPincode(cityPincode);
            if (lat && lng) {
              const latLng = new window.google.maps.LatLng(lat, lng);
              processedData.push(latLng);

              // Add data for pins/markers
              pinsData.push({
                latLng: [lat, lng],
                availability: cityData.averageAvailabilityPercentage,
                pincode: cityPincode,
                city, // Include city in pinsData
              });
              newMarkers.push({
                latLng: [lat, lng],
                availability: cityData.averageAvailabilityPercentage,
                pincode: cityPincode,
                city, // Include city in newMarkers
                cityData: cityData,
              });
            }
          }
        }

        // Set Heatmap Layer
        const heatmap = new window.google.maps.visualization.HeatmapLayer({
          data: processedData,
          map: map,
          radius: 20,
          opacity: 0.6,
          gradient: [
            "rgba(0, 255, 255, 0)",
            "rgba(0, 255, 255, 1)",
            "rgba(0, 191, 255, 1)",
            "rgba(0, 127, 255, 1)",
            "rgba(0, 0, 255, 1)",
          ],
        });

        // Set heatmap data and markers
        setMarkers(newMarkers);
        setHeatMapData(pinsData);

        // Cleanup the heatmap layer when the component is unmounted or map changes
        return () => {
          heatmap.setMap(null);
        };
      }
    };

    fetchData();
  }, [isLoaded, filteredCityWiseAvailPercentage, map]);

  // Handle marker click to show more details in the modal
  const handleDrilldown = () => {
    if (selectedMarker) {
      setModalContent({
        city: selectedMarker.city,
        pincode: selectedMarker.pincode,
        cityData: selectedMarker.cityData,
      });
      setShowModal(true);
    }
  };

  const handleMarkerClick = (pin) => {
    setSelectedMarker(pin);
  };

  const handleSkuChange = (event) => {
    setSelectedSku(event.target.value);
  };

  const options = {
    disableDefaultUI: true, // Disable all default UI
    zoomControl: false, // Disable zoom control (optional)
    mapTypeControl: false, // Disable map type control (optional)
    streetViewControl: false, // Disable street view control (optional)
    fullscreenControl: false, // Disable fullscreen control (optional)
  };

  return (
    <div style={mapContainerStyle} className="mb-4 rounded-lg">
      <div className="flex justify-end gap-3">
        <div>
          <Dropdown
            options={[...brands]}
            value={selectedBrand}
            onChange={setSelectedBrand}
            initalTitle={"Select Brand"}
          />
        </div>

        <div>
          <Dropdown
            options={[...uniqueSkus]}
            value={selectedSku}
            onChange={setSelectedSku}
            initalTitle={"Select SKU"}
          />
        </div>
      </div>

      {/* Render Google Map */}
      {isLoaded ? (
        <div className="bg-white shadow-lg mt-4 rounded-xl">
          <div className="py-4 px-6 border-b text-[1.125rem] flex gap-4 font-medium">
            <img src="/logos/map-view.svg" alt="" /> Map View
          </div>
          <div className="p-6">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={5}
              onLoad={(map) => setMap(map)} // Set map instance when loaded
              options={options} // Apply the options here
            >
              {/* Render markers on the map */}
              {markers?.map((pin, index) => (
                <Marker
                  key={index}
                  position={{ lat: pin.latLng[0], lng: pin.latLng[1] }}
                  onClick={() => handleMarkerClick(pin)} // Handle marker click
                >
                  {/* Show info window on marker click */}
                  {selectedMarker && selectedMarker === pin && (
                    <InfoWindow
                      position={{
                        lat: pin.latLng[0],
                        lng: pin.latLng[1],
                      }}
                    >
                      <div className="w-[20vw] h-[10vw] mx-2 bg-white flex flex-col">
                        <div className="flex items-center gap-2">
                          <span className="text-lg font-semibold text-gray-400">City :</span>{" "}
                          <h3 className="text-lg text-navBarColour font-semibold font-body">{pin.city}</h3>
                        </div>

                        <div className="flex items-center gap-2">
                          <span className="text-lg font-semibold text-gray-400">Availability:</span>
                          <span
                            className={`text-lg font-semibold font-body ${
                              pin.availability < 70 ? "text-yellow-500" : "text-green-500"
                            }`}
                          >
                            {pin.availability}%
                          </span>
                        </div>

                        <Button
                          onClick={handleDrilldown}
                          sx={{
                            fontSize: "1rem",
                            marginTop: "1rem",
                            textTransform: "capitalize",
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}

              {/* Render modal when showModal is true */}
              <MapModal
                city={modalContent.city}
                pincode={modalContent.pincode}
                cityData={modalContent.cityData}
                onClose={() => setShowModal(false)}
                open={showModal}
                handleClose={() => setShowModal(false)}
              />
            </GoogleMap>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default HeatMap;
