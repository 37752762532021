import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Title,
  Legend,
} from "chart.js";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Register required components from Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Title, Legend);

const InsightsCM1TrendLineChart = ({ salesData }) => {
  // Prepare data for the chart
  let newSalesData = [];
  for (let i = salesData.length - 1; i >= 0; i--) {
    newSalesData.push(salesData[i]);
  }
  const months = newSalesData.map((entry) => entry.month);
  const netSalesValues = newSalesData.map(
    (entry) => parseFloat(entry.mrp) - parseFloat(entry.gst) - parseFloat(entry.cogs)
  );

  const shadowPlugin = {
    id: "shadowLine",
    beforeDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save();

      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);

        // Apply shadow only to line elements
        if (meta.type === "line") {
          ctx.shadowColor = "#387FF529"; // Shadow color
          ctx.shadowBlur = 6; // Blur radius for the shadow
          ctx.shadowOffsetX = 0; // No horizontal shadow offset
          ctx.shadowOffsetY = 5; // Vertical shadow only (bottom side)
        }
      });
    },
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.restore(); // Restore the context to avoid affecting other elements
    },
  };

  const chartData = {
    labels: months, // Month names on X-axis
    datasets: [
      {
        label: "CM1",
        data: netSalesValues, // CM1 data for each month
        borderColor: "#387FF5", // Solid line color
        backgroundColor: "#3881f508",
        borderWidth: 2, // Line thickness
        tension: 0.4, // Smooth curve line
        pointRadius: 3, // Point size
        pointBackgroundColor: "#fff",
        pointBorderColor: "#2B62BC",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        fill: true,
        pointHoverCursor: "pointer",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false, //do not Display the legend
        labels: {
          font: {
            size: 12, // Font size
            weight: "bold", // Set the font to bold
          },
          color: "black", // Set the legend label color (optional)
          pointStyle: "circle", // This makes the labels round
          usePointStyle: true, // Ensures that the point style (circle) is used for the legend
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#333", // Dark theme for the tooltip
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },

        ticks: {
          beginAtZero: true,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },

        title: {
          display: true,
          text: "Months",
          color: "#87878F",
          font: {
            size: 12,
            family: "DM Sans Variable",
            weight: 600,
          },
          padding: {
            top: 10, // Add some margin at the top
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (val) => `${(val / 1000).toFixed(0)}k`,
          beginAtZero: true,
          stepSize: 1500000,
          color: "#848396",
          font: {
            family: "DM Sans Variable",
            size: 12, // Optional: set the font size
            weight: 400,
          },
        },
        title: {
          display: true,
          text: "CM1",
          color: "#87878F",
          font: {
            size: 12,
            family: "DM Sans Variable",
            weight: 600,
          },
          padding: {
            // Adjust margin to the right
            bottom: 12, // Add some margin below the title
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Adjust the chart size
    datalabels: {
      display: false, // Disable data labels for this line chart
    },
  };

  return (
    <div>
      <div className="flex ml-1 justify-between items-center">
        <h2 className="text-md leading-5 text-[#4F4E69] font-body font-medium text-start mb-2">
          Trends of CM1 over the last 6 Months
        </h2>
      </div>

      <div className="px-4 border h-[20.125rem] w-full rounded-[0.65rem]">
        {salesData.length > 0 ? (
          <div className="h-[16rem] mt-12">
            <Line data={chartData} options={chartOptions} plugins={[shadowPlugin]} />
          </div>
        ) : (
          <div className="h-full flex justify-center items-center">No Data in CM1 Trends</div>
        )}
      </div>
    </div>
  );
};

export default InsightsCM1TrendLineChart;
