import React, { useState } from "react";
import { setSelectedSovKeyword } from "../../../redux/actions/sovThirdPartyActions";
import { useDispatch } from "react-redux";
import { TableRow, TableCell, Button, Typography, Box } from "@mui/material";
import ShareOfVoiceCityWise from "./ShareOfVoiceCityWise";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const ShareOfVoiceStatsTable = ({ titleName, ad, organic, overall, adChange, organicChange, index, toggleDrawer }) => {
  const [open, setOpen] = useState({});
  const [state, setState] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (product) => {
    setOpen((prev) => ({ ...prev, [product]: !prev[product] }));
  };

  const getChangeColor = (value) => {
    if (value === "No previous data") return "#6B7280"; // Tailwind's gray-500
    return value >= 0 ? "#16A34A" : "#F53E3E"; // Tailwind's green-600 and red-600
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          cursor: "pointer",
          bgcolor: index % 2 != 0 ? "#F0F6FF" : "#fff",
        }}
      >
        <TableCell
          sx={{
            textTransform: "capitalize",
            textAlign: "left",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            color: "#4d4d4d",
            fontWeight: "500",
          }}
          onClick={() => handleClick(titleName)}
        >
          {open[titleName] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          {titleName}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{overall}%</TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <div className="flex items-center justify-center gap-2">
            {organic}%
            <Typography sx={{ color: getChangeColor(organicChange) }}>
              {organicChange >= 0 ? "▲" : "▼"} {organicChange}%
            </Typography>
          </div>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <div className="flex items-center justify-center gap-2">
            {ad}%
            <Typography sx={{ color: getChangeColor(adChange) }}>
              <span>{adChange >= 0 ? "▲" : "▼"}</span> {adChange}%
            </Typography>
          </div>
        </TableCell>

        <TableCell>
          <div
            onClick={toggleDrawer(true, titleName)}
            className="border border-[#387FF5] text-[#387FF5] rounded-[0.25rem] text-center text-xs p-2 text-medium w-fit cursor-pointer"
          >
            {/* <img src="/logos/dots.svg" alt="dots" /> */}
            View Insights
          </div>
        </TableCell>
      </TableRow>
      {open[titleName] && <ShareOfVoiceCityWise keyword={titleName} />}
    </React.Fragment>
  );
};

export default ShareOfVoiceStatsTable;
