import React, { useState, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import SkuVsKeywords from "./SkuVsKeywords";
import SelectBoxSkeleton from "../../common/Skeletons/SelectBoxSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Error from "../../common/Error/Error";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Dropdown from "../../common/Dropdown/Dropdown";

const SkuVsKeywordsSectionsContainer = () => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);
  const { loading, data, error } = thirdPartyVisibility;
  const [selectedAreaName, setSelectedAreaName] = useState(
    thirdPartyVisibility?.data?.length > 0
      ? thirdPartyVisibility.data[0]["postgres_third_party_visibility.area_name_lm"] // Corrected key for AreaName
      : null
  );

  useEffect(() => {
    setSelectedAreaName(
      thirdPartyVisibility?.data?.length > 0
        ? thirdPartyVisibility.data[0]["postgres_third_party_visibility.area_name_lm"]
        : null
    );
  }, [thirdPartyVisibility.data]);

  // Extract unique areanames from the fetched data to populate the Select dropdown
  const areaNames = Array.from(new Set(data?.map((item) => item["postgres_third_party_visibility.area_name_lm"])));

  // Display skeleton loaders while data is being fetched
  if (loading) {
    return (
      <>
        <SelectBoxSkeleton /> {/* Show skeleton for Select box */}
        <TableSkeleton /> {/* Show skeleton for table */}
      </>
    );
  }

  // Display error component if an error occurs during data fetching
  if (error) {
    return <NoDataFound />;
  }
  // If there is no data or the data array is empty, show NoDataFound component
  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <>
      {/* areaname selection dropdown with label */}
      <div className="gap-3 mt-2 w-full flex  justify-end items-center p-4">
        <Dropdown
          options={[...areaNames]}
          value={selectedAreaName}
          onChange={setSelectedAreaName}
          initalTitle={"Select Area Name"}
        />
      </div>

      {/* Container for the SkuVsKeywords component */}
      <div className="mt-4 mb-4 rounded-lg w-full flex flex-col items-center p-1">
        {/* Pass the selected areaname as props to the SkuVsKeywords component */}
        <SkuVsKeywords selectedAreaName={selectedAreaName} />
      </div>
    </>
  );
};

export default SkuVsKeywordsSectionsContainer;
