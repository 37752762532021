import dayjs from "dayjs";

const formatDateWithoutTimezone = (date) => {
  const formattedDate = dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  return formattedDate;
};

const getLast7DaysRange = (selectedDate) => {
  // Ensure selectedDate is valid
  if (!dayjs(selectedDate).isValid()) {
    console.error("Invalid selectedDate:", selectedDate);
    return ["", ""];
  }

  // Calculate endDate and startDate
  const endDate = dayjs(selectedDate);
  const startDate = dayjs(selectedDate).subtract(6, "day");

  // Format dates using formatDateWithoutTimezone
  return [formatDateWithoutTimezone(startDate), formatDateWithoutTimezone(endDate)];
};

export const RcaCategoryBlinkitQuery = (selectedDate) => {
  const [startDate, endDate] = getLast7DaysRange(selectedDate);

  return {
    dimensions: ["category_rca_blinkit.category"],
    measures: ["category_rca_blinkit.total_gmv", "category_rca_blinkit.total_qty_sold"],
    order: {
      "category_rca_blinkit.total_gmv": "desc",
    },

    timeDimensions: [
      {
        dimension: "category_rca_blinkit.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
  };
};

export const RcaCityBlinkitQuery = (selectedDate, selectedSku) => {
  const [startDate, endDate] = getLast7DaysRange(selectedDate);

  let query = {
    dimensions: ["category_rca_blinkit.brands_choice"],
    measures: ["category_rca_blinkit.total_gmv", "category_rca_blinkit.total_qty_sold"],
    order: [["category_rca_blinkit.category", "asc"]],
    timeDimensions: [
      {
        dimension: "category_rca_blinkit.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
  };

  // Conditionally add the category filter if selectedCategory is not empty
  if (selectedSku) {
    query.filters = [
      {
        member: "category_rca_blinkit.brand_internal_sku_code",
        operator: "equals",
        values: [selectedSku],
      },
    ];
  }

  return query;
};

// SKU based query
export const RcaSkuLevelBlinkitQuery = (selectedDate) => {
  const [startDate, endDate] = getLast7DaysRange(selectedDate);

  let query = {
    dimensions: ["category_rca_blinkit.brand_internal_sku_code"],
    measures: ["category_rca_blinkit.total_gmv", "category_rca_blinkit.total_qty_sold"],
    order: [["category_rca_blinkit.category", "asc"]],
    timeDimensions: [
      {
        dimension: "category_rca_blinkit.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
  };

  // // Conditionally add the category filter if selectedCategory is not empty
  // if (selectedCity) {
  //   query.filters = [
  //     {
  //       member: "category_rca_blinkit.brands_choice",
  //       operator: "equals",
  //       values: [selectedCity],
  //     },
  //   ];
  // }

  return query;
};

// export const instamartQuery = (selectedDateRange) => {
//   return {
//     dimensions: [
//       "combined_sales_report_instamart.item_code",
//       "combined_sales_report_instamart.date",
//       "combined_sales_report_instamart.product_name",
//       "combined_sales_report_instamart.qty",
//       "combined_sales_report_instamart.mrp",
//       "combined_sales_report_instamart.city",
//       "combined_sales_report_instamart.gmv",
//       "combined_sales_report_instamart.brand_internal_sku_code",
//       "combined_sales_report_instamart.category",
//       "combined_sales_report_instamart.sub_category",
//       "combined_sales_report_instamart.brands_choice",
//     ],
//     order: [["combined_sales_report_instamart.date", "asc"]],

//     timeDimensions: [
//       {
//         dimension: "combined_sales_report_instamart.normalized_date",
//         dateRange: [formatDateWithoutTimezone(selectedDateRange[0]), formatDateWithoutTimezone(selectedDateRange[1])],
//       },
//     ],
//   };
// };

// export const zeptoQuery = (selectedDateRange) => {
//   return {
//     dimensions: [
//       "combined_sales_report_zepto.date",
//       "combined_sales_report_zepto.sku_number",
//       "combined_sales_report_zepto.brand_internal_sku_code",
//       "combined_sales_report_zepto.sku_name",
//       "combined_sales_report_zepto.category",
//       "combined_sales_report_zepto.sub_category",
//       "combined_sales_report_zepto.city",
//       "combined_sales_report_zepto.brands_choice",
//       "combined_sales_report_zepto.mrp",
//       "combined_sales_report_zepto.qty",
//       "combined_sales_report_zepto.gmv",
//     ],
//     order: [["combined_sales_report_zepto.date", "asc"]],
//     timeDimensions: [
//       {
//         dimension: "combined_sales_report_zepto.normalized_date",
//         dateRange: [formatDateWithoutTimezone(selectedDateRange[0]), formatDateWithoutTimezone(selectedDateRange[1])],
//       },
//     ],
//   };
// };
