import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import CategoryRcaSkuCard from "./CategoryRcaSkuCard";
import { useDispatch, useSelector } from "react-redux";
import CategoryRcaFlowChart from "./CategoryRcaFlowChart";
import { getFilteredRcaCity, setSelectedRcaSku } from "../../../redux/actions/categoryRcaActions";

// Utility function to format numbers with K, L (lakh), and Cr (crore)
const formatPrice = (value) => {
  if (value >= 10000000) {
    // If value is greater than or equal to 1 crore
    return `₹${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    // If value is greater than or equal to 1 lakh
    return `₹${(value / 100000).toFixed(2)} Lac`;
  } else if (value >= 1000) {
    // If value is greater than or equal to 1 thousand
    return `₹${(value / 1000).toFixed(2)} K`;
  } else {
    // For values below 1 thousand
    return `₹${value}`;
  }
};

const CategoryRcaSkuWise = () => {
  const { rcaSkusData, rcaCitiesData } = useSelector((state) => state.rcaCategory);
  const [selectedSKU, setSelectedSKU] = useState(0);
  const dispatch = useDispatch();

  const handleSkuChange = (index, skuCode) => {
    setSelectedSKU(index);
    dispatch(setSelectedRcaSku(skuCode));
  };

  const handleOverallSkuClick = (index) => {
    setSelectedSKU(index);
    dispatch(getFilteredRcaCity(rcaCitiesData.data));
    dispatch(setSelectedRcaSku(null));
  };

  // Calculate overall GMV and Quantity
  const overallCategoryGMV = rcaSkusData.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_gmv"] || 0),
    0
  );

  const overallCategoryQty = rcaSkusData.data.reduce(
    (total, category) => total + parseFloat(category["category_rca_blinkit.total_qty_sold"] || 0),
    0
  );

  // Use formatted GMV
  const offtakesAtMrp = {
    title: "Offtake at",
    value: { heading: "All", totalGMV: formatPrice(overallCategoryGMV), totalQty: overallCategoryQty }, // Format the GMV value here
  };

  // Format rcaSkusData with total GMV and Qty
  const formattedSkus = {
    totalGMV: overallCategoryGMV,
    totalQty: overallCategoryQty,
    data: rcaSkusData.data,
  };
  return (
    <div className="bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center p-4">
        <div className="font-semibold text-xl">SKU Level Breakdown</div>

        <div className="flex items-center gap-1 border rounded-lg py-1 px-2">
          <input type="text" name="" id="" className="border-none outline-none" />
          <FiSearch className="text-xl" />
        </div>
      </div>
      <hr />
      <div className="p-4">
        <div className="flex overflow-x-scroll gap-2">
          <CategoryRcaSkuCard
            info={offtakesAtMrp}
            selectedSKU={selectedSKU}
            handleSkuChange={() => handleOverallSkuClick(0)}
            code={0}
          />
          {rcaSkusData.data.map((item, index) => (
            <CategoryRcaSkuCard
              key={item}
              info={{
                value: {
                  heading: item["category_rca_blinkit.brand_internal_sku_code"],
                  totalGMV: formatPrice(item["category_rca_blinkit.total_gmv"]),
                  totalQty: item["category_rca_blinkit.total_qty_sold"],
                },
              }}
              selectedSKU={selectedSKU}
              handleSkuChange={() => handleSkuChange(index + 1, item["category_rca_blinkit.brand_internal_sku_code"])}
              code={index + 1}
            />
          ))}
        </div>
      </div>

  
    </div>
  );
};

export default CategoryRcaSkuWise;
