import { combineReducers } from "redux";
import { salesReducer } from "./salesReducer";
import { platformReducer } from "./platformMenuReducer";
import { marketingReducer } from "./marketingReducer";
import { availTableReducer } from "./competitorReducer";
import { qcommDataReducer } from "./competitorReducer";
import { discountReducer } from "./discountReducer";
import { chartReducer } from "./chartReducer";
import { sidebarReducer } from "./sidebarReducer";
import { targetTrackerReducer } from "./targetTrackerReducer";
import { thirdPartyReducer } from "./thirdPartyReducer";
import { fulfillmentReducer } from "./fulfillmentReducer";
import { potentialSalesLossReducer } from "./potentialSalesLossReducer";
import { profitabilityReducer } from "./profitabilityReducer";
import { stockingInsightsReducer } from "./stockingInsightsReducer";
import { pslFulfillmentReducer } from "./pslFulfillmentReducer";
import { datePickerReducer } from "./datePickersReducer";
import { leastPerformingReducer } from "./LeastPerformingReducer";
import { competitorPricingReducer } from "./competitorPricingReducer";
import { sovReducer } from "./sovThirdPartyReducer";
import { insightsReducer } from "./insightsReducer";
import { rcaCategoryReducer } from "./categoryRcaReducer";
import { rbacReducer } from "./rbacReducer";

export const rootReducer = combineReducers({
  sales: salesReducer,
  platform: platformReducer,
  marketing: marketingReducer,
  leastPerforming: leastPerformingReducer,
  avail: availTableReducer,
  qcomm: qcommDataReducer,
  discount: discountReducer,
  stocking: stockingInsightsReducer,
  chart: chartReducer,
  sidebar: sidebarReducer,
  target: targetTrackerReducer,
  thirdParty: thirdPartyReducer,
  fulfillment: fulfillmentReducer,
  potentailSalesLoss: potentialSalesLossReducer,
  profitability: profitabilityReducer,
  pslFulfillment: pslFulfillmentReducer,
  datePicker: datePickerReducer,
  competitor: competitorPricingReducer,
  sovThirdParty: sovReducer,
  insights: insightsReducer,
  rcaCategory: rcaCategoryReducer,
  rbac: rbacReducer,
});
