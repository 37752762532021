import {
  RcaCategoryBlinkitQuery,
  RcaCityBlinkitQuery,
  RcaSkuLevelBlinkitQuery,
} from "../../utils/queries/competitorsQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

// Fetch RCA category blinkit
export const fetchRcaCategoryBlinkitData = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(RcaCategoryBlinkitQuery(selectedDate));
  return result.tablePivot();
};

// Fetch RCA city blinkit
export const fetchRcaCityBlinkitData = async (selectedDate, selectedSku) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(RcaCityBlinkitQuery(selectedDate, selectedSku));
  return result.tablePivot();
};

// Fetch RCA city blinkit
export const fetchRcaSkuLevelBlinkitData = async (selectedDate, selectedCity) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(RcaSkuLevelBlinkitQuery(selectedDate, selectedCity));
  return result.tablePivot();
};
