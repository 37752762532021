const sidebarItems = [
  {
    name: "Revenue",
    children: [
      {
        icon: "sales-report",
        name: "Sales Report",
        path: "/revenue/sales-report",
      },

      {
        icon: "target-tracker",
        name: "Target Tracker",
        path: "/revenue/target-tracker",
      },
    ],
  },

  {
    name: "Inventory",
    children: [
      {
        icon: "locality-availability",
        name: "Locality Availability",
        path: "/inventory/locality-availability",
      },
      {
        icon: "inventory-report",
        name: "Inventory Report",
        path: "/inventory/inventory-report",
      },
      {
        icon: "stocking-insights",
        name: "Stocking Insights",
        path: "/inventory/stocking-insights",
      },
    ],
  },

  {
    name: "Ads",
    children: [
      {
        icon: "keyword-ranking",
        name: "Keyword Ranking",
        path: "/ads/keyword-ranking",
      },
      {
        icon: "pricing-detail",
        name: "Pricing Detail",
        path: "/ads/pricing-details",
      },
      {
        icon: "pma-ads",
        name: "Performance Marketing Ads",
        path: "/ads/performance-marketing-ads",
      },
      {
        icon: "display-ads",
        name: "Display Ads",
        path: "/ads/display-ads",
      },
      {
        icon: "least-performing",
        name: "Least Performing",
        path: "/ads/least-performing",
      },

      {
        icon: "competitor-pricing",
        name: "Competitor Pricing",
        path: "/ads/competitor-pricing",
      },
      {
        icon: "insights",
        name: "Insights",
        path: "/ads/insights",
      },
      {
        icon: "visibility",
        name: "Visibility",
        path: "/ads/visibility",
      },
      {
        icon: "category-rca",
        name: "Category RCA",
        path: "/ads/category-rca",
      },
    ],
  },
  {
    name: "Profitability",
    children: [
      {
        icon: "overall-profitability",
        name: "Overall Profitability",
        path: "/profitability/overall-profitability",
      },
    ],
  },

  {
    name: "Fulfillment",
    children: [
      {
        icon: "fulfillment-report",
        name: "Fulfillment Report",
        path: "/fulfillment/fulfillment-report",
      },
      {
        icon: "psl",
        name: "Potential Sales Loss",
        path: "/fulfillment/potential-sales-loss",
      },
    ],
  },
];

export default sidebarItems;
