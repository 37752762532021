import {
  ADD_AVAIL,
  GET_ALL_QCOMM_REQUEST,
  GET_ALL_QCOMM_SUCCESS,
  GET_ALL_QCOMM_FAILURE,
} from "../constants/competitorConstants";

const initialState = {
  availValue: "Dilldown",
  qcommData: {
    loading: false,
    data: null,
    error: null,
  },
};

export const availTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AVAIL:
      return {
        ...state,
        availValue: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const qcommDataReducer = (state = initialState.qcommData, action) => {
  switch (action.type) {
    case GET_ALL_QCOMM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_QCOMM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ALL_QCOMM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
