import React from "react";
import { useSelector } from "react-redux";
import InsightsCategoriesSalesPieChart from "./InsightsCategoriesSalesPieChart";
import OverallProfitabilityInsightsPieChart from "./OverallProfitabilityInsightsPieChart";
import InsightsCM1TrendLineChart from "./InsightsCM1TrendLineChart";
import {
  monthlyProfitabilityGroupingBlinkit,
  monthlyProfitabilityGroupingInstamart,
  monthlyProfitabilityGroupingZepto,
} from "../../../utils/dataGrouping/OverallProfitability/MonthlyProfitabilityGrouping";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import ProductInsightsPieChart from "./ProductInsightsPieChart";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const ProfitabilityInsights = ({ platformName }) => {
  const { monthlyProfitability, categoryProfitability } = useSelector((state) => state.profitability);
  const { productInsights } = useSelector((state) => state.insights);
  let groupedData;
  if (platformName == "Blinkit") {
    groupedData = monthlyProfitabilityGroupingBlinkit(monthlyProfitability.data);
  } else if (platformName == "Instamart") {
    groupedData = monthlyProfitabilityGroupingInstamart(monthlyProfitability.data);
  } else {
    groupedData = monthlyProfitabilityGroupingZepto(monthlyProfitability.data);
  }

  if (!monthlyProfitability.data || monthlyProfitability.data.length == 0) {
    return (
      <div className="bg-white rounded-lg my-2">
        <div className="flex py-5 border-b border-[#EAEDF3]">
          <span className="flex  gap-4 items-center leading-6 text-[#4F4E69] text-lg  font-body font-medium ml-5">
            <FaMoneyBillTrendUp className="text-xl" />
            Profitability Insights
            <CustomTooltip title={"Analyze Self-Brand Dscounting This Week"}>
              <PiInfoLight />
            </CustomTooltip>
          </span>
        </div>
        <div className="h-96 flex justify-center items-center">
          <NoDataFound height={200} width={200} maximum={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-[0.625rem]">
      <div className="flex py-5 border-b border-[#EAEDF3]">
        <span className="flex  gap-4 items-center leading-6 text-[#4F4E69] text-lg  font-body font-medium ml-5">
          <FaMoneyBillTrendUp className="text-xl" />
          Profitability Insights
          <CustomTooltip title={"Analyze Self-Brand Dscounting This Week"}>
            <PiInfoLight />
          </CustomTooltip>
        </span>
      </div>

      <div className="p-6 ">
        {/* cm trends over 6 months line chart */}
        <Link to={"/profitability/overall-profitability"} className="cursor-pointer">
          <InsightsCM1TrendLineChart salesData={groupedData} />
        </Link>

        {/* donuts chart */}
        <div className="grid grid-cols-2 grid-rows-2 gap-4 mt-4">
          {/* First Column */}
          <div className="row-start-1 col-start-1 p-2 h-[18.563rem]  rounded-[0.625rem] border">
            {groupedData.length > 0 ? (
              <Link to={"/profitability/overall-profitability"} className="cursor-pointer">
                <OverallProfitabilityInsightsPieChart groupedData={groupedData} />
              </Link>
            ) : (
              <div className="h-full">
                <div className="text-sm pl-12 text-[#4F4E69] font-medium font-body">
                  Overall Profitability over 1 Month
                </div>
                <div className="flex justify-center items-center h-full">No Data for Overall Profitability</div>
              </div>
            )}
          </div>
          <div className="row-start-2 col-start-1 p-2 h-[18.563rem] rounded-[0.625rem] border">
            <Link to={"/profitability/overall-profitability"} className="cursor-pointer">
              <InsightsCategoriesSalesPieChart salesData={categoryProfitability.data} platformName={platformName} />
            </Link>
          </div>

          {/* Second Column */}
          <div className="row-span-2 col-start-2 border rounded-[0.625rem] p-2">
            <Link to={"/profitability/overall-profitability"} className="cursor-pointer">
              <ProductInsightsPieChart salesData={productInsights.data} platformName={platformName} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitabilityInsights;
