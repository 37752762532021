import {
  POTENTIAL_SALES_LOSS_BLINKIT_REQUEST,
  POTENTIAL_SALES_LOSS_BLINKIT_SUCCESS,
  POTENTIAL_SALES_LOSS_BLINKIT_FAILURE,
  POTENTIAL_SALES_LOSS_ZEPTO_REQUEST,
  POTENTIAL_SALES_LOSS_ZEPTO_SUCCESS,
  POTENTIAL_SALES_LOSS_ZEPTO_FAILURE,
  POTENTIAL_SALES_LOSS_INSTAMART_REQUEST,
  POTENTIAL_SALES_LOSS_INSTAMART_SUCCESS,
  POTENTIAL_SALES_LOSS_INSTAMART_FAILURE,
} from "../constants/potentialSalesLossConstants.js";

// Blinkit
export const getPotentialSalesLossBlinkitRequest = () => {
  return {
    type: POTENTIAL_SALES_LOSS_BLINKIT_REQUEST,
  };
};

export const getPotentialSalesLossBlinkitSuccess = (data) => {
  return {
    type: POTENTIAL_SALES_LOSS_BLINKIT_SUCCESS,
    payload: data,
  };
};

export const getPotentialSalesLossBlinkitFailure = (error) => {
  return {
    type: POTENTIAL_SALES_LOSS_BLINKIT_FAILURE,
    payload: error,
  };
};

// Instamart
export const getPotentialSalesLossInstamartRequest = () => {
  return {
    type: POTENTIAL_SALES_LOSS_INSTAMART_REQUEST,
  };
};

export const getPotentialSalesLossInstamartSuccess = (data) => {
  return {
    type: POTENTIAL_SALES_LOSS_INSTAMART_SUCCESS,
    payload: data,
  };
};

export const getPotentialSalesLossInstamartFailure = (error) => {
  return {
    type: POTENTIAL_SALES_LOSS_INSTAMART_FAILURE,
    payload: error,
  };
};

// Zepto

export const getPotentialSalesLossZeptoRequest = () => {
  return {
    type: POTENTIAL_SALES_LOSS_ZEPTO_REQUEST,
  };
};

export const getPotentialSalesLossZeptoSuccess = (data) => {
  return {
    type: POTENTIAL_SALES_LOSS_ZEPTO_SUCCESS,
    payload: data,
  };
};

export const getPotentialSalesLossZeptoFailure = (error) => {
  return {
    type: POTENTIAL_SALES_LOSS_ZEPTO_FAILURE,
    payload: error,
  };
};
