import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaChartLine } from "react-icons/fa";
import { FiTable } from "react-icons/fi";
import PricingDetailBarChart from "./PricingDetailBarChart";
import PricingDetailsTable from "./PricingDetailsTable";
import SelectBoxSkeleton from "../../common/Skeletons/SelectBoxSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import Error from "../../common/Error/Error";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Dropdown from "../../common/Dropdown/Dropdown";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

// Function to group data by SKU and keyword while calculating parentBrand and competitor price details
const groupDataByKeywords = (data, parsedUserData) => {
  let result = {};
  data.forEach((item) => {
    const SKU = item["postgres_third_party_visibility.sku_name"];
    const keyword = item["postgres_third_party_visibility.searchkeyword"];
    const isParentBrand = item["postgres_third_party_visibility.brand"] === parsedUserData?.organizationDetail?.name;
    const price = parseFloat(item["postgres_third_party_visibility.price"]);
    const discountPrice = parseFloat(item["postgres_third_party_visibility.discountprice"]);
    const discountPercent = ((price - discountPrice) / price) * 100;

    // Initializing SKU and keyword in the result object if not already present
    if (!result[SKU]) {
      result[SKU] = {};
    }

    if (!result[SKU][keyword]) {
      result[SKU][keyword] = {
        parentBrand: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
        other: { count: 0, price: 0, discountPrice: 0, discountPercent: 0 },
      };
    }

    // Accumulating parentBrand brand details or competitor details based on the item data
    if (isParentBrand) {
      result[SKU][keyword].parentBrand.count += 1;
      result[SKU][keyword].parentBrand.price += price;
      result[SKU][keyword].parentBrand.discountPrice += discountPrice;
      result[SKU][keyword].parentBrand.discountPercent += discountPercent;
    } else {
      result[SKU][keyword].other.count += 1;
      result[SKU][keyword].other.price += price;
      result[SKU][keyword].other.discountPrice += discountPrice;
      result[SKU][keyword].other.discountPercent += discountPercent;
    }
  });
  return result;
};

const PricingDetailSectionsContainer = ({ platformName }) => {
  const { thirdPartyVisibility } = useSelector((state) => state.thirdParty);
  const [selectedAreaName, setSelectedAreaName] = useState(
    thirdPartyVisibility?.data?.length > 0
      ? thirdPartyVisibility.data[0]["postgres_third_party_visibility.area_name_lm"] // Corrected key for areaname
      : null
  );
  const { loading, data, error } = thirdPartyVisibility;
  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);

  //  csvExportedData is the data that is state lifted up from its child component PricingDetailsTable.
  const [csvExportedData, setCsvExportedData] = useState({});
  console.log("csvExportedData====", csvExportedData);

  // Filtering data based on the selected areaname
  const filteredDataByPincode = thirdPartyVisibility.data?.filter(
    (item) => item["postgres_third_party_visibility.area_name_lm"] == selectedAreaName
  );

  // Grouping data by SKU and keywords
  const dataGrouped = groupDataByKeywords(filteredDataByPincode, parsedUserData);
  const allSKUs = Object.keys(dataGrouped);
  const [selectedSKUs, setSelectedSKUs] = useState([]);
  const [all, setAll] = useState(true);
  const [open, setOpen] = useState(false);

  // Effect to update selected SKUs when data changes
  useEffect(() => {
    const func = async () => {
      const newData = groupDataByKeywords(filteredDataByPincode);
      const allSKUs = Object.keys(newData);
      setSelectedSKUs(allSKUs);
    };
    func();
  }, [thirdPartyVisibility.data, allSKUs.length]);

  useEffect(() => {
    if (all) {
      setSelectedSKUs(allSKUs);
    } else {
      setSelectedSKUs([]);
    }
  }, [all]);

  const handleCheckboxChange = (event, sku) => {
    if (sku === "all") {
      if (event.target.checked) {
        setSelectedSKUs(allSKUs);
      } else {
        setSelectedSKUs([]);
      }
    } else {
      if (selectedSKUs.includes(sku)) {
        const updated = selectedSKUs.filter((innerItem) => innerItem !== sku);
        setSelectedSKUs(updated);
      } else {
        setSelectedSKUs([...selectedSKUs, sku]);
      }
    }
  };

  useEffect(() => {
    setSelectedAreaName(
      thirdPartyVisibility?.data?.length > 0
        ? thirdPartyVisibility.data[0]["postgres_third_party_visibility.area_name_lm"]
        : null
    );
  }, [thirdPartyVisibility.data]);

  const handleExportCSV = (groupedData, platformName) => {
    if (!groupedData || Object.keys(groupedData).length === 0) {
      console.warn("No data available for CSV export.");
      return;
    }

    // Flatte+++++++++++++++++++++++n the grouped data into an array of objects for CSV
    const csvRows = [];
    csvRows.push(["SKU", "Keyword", "Category", "Count", "Price", "Discount Price", "Discount Percent"].join(",")); // Header row

    // Loop through grouped data to create rows
    Object.entries(groupedData).forEach(([sku, keywords]) => {
      Object.entries(keywords).forEach(([keyword, details]) => {
        ["parentBrand", "other"].forEach((category) => {
          const { count, price, discountPrice, discountPercent } = details[category];
          csvRows.push(
            [
              sku,
              keyword,
              category,
              count,
              price.toFixed(2),
              discountPrice.toFixed(2),
              discountPercent.toFixed(2),
            ].join(",")
          );
        });
      });
    });

    // Create a Blob from the CSV rows and trigger download
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `pricing_detail_${platformName.toLowerCase()}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Extract unique  AreaName from the fetched data
  const areaNames = Array.from(new Set(data?.map((item) => item["postgres_third_party_visibility.area_name_lm"])));

  // Display skeleton loaders while data is being fetched
  if (loading) {
    return (
      <>
        <SelectBoxSkeleton /> {/* Show skeleton for the select box while loading */}
        <ChartSkeleton /> {/* Show skeleton for the chart while loading */}
        <TableSkeleton /> {/* Show skeleton for the table while loading */}
      </>
    );
  }

  // Display error component if an error occurs during data fetching
  if (error) {
    return <NoDataFound />;
  }
  
  // If there is no data or the data array is empty, show the NoDataFound component
  if (!data || data.length === 0) {
    return <NoDataFound />; // Show "No Data Found" component if the data is empty
  }

  return (
    <>
      {/* Dropdown for selecting a AreaName */}
      <div className="gap-3  mt-10 w-full flex  justify-end items-center ">
        <Dropdown
          options={[...areaNames]}
          value={selectedAreaName}
          onChange={setSelectedAreaName}
          initalTitle={"Select Area Name"}
        />

        {/* Filter Dropdown */}
        <div className="relative inline-block w-auto min-w-64 h-[42px]">
          <button
            className="w-full p-3 shadow rounded bg-white text-[#666666] flex gap-3 justify-between items-center"
            onClick={() => setOpen(!open)}
          >
            <span className="font-body font-medium text-sm">
              {selectedSKUs.length === allSKUs.length ? "All Selected" : "Select SKU's"}
            </span>
            <img
              width={10}
              height={10}
              src="/icons/dropdownArrowIcon.svg"
              className={`mr-1 transition-transform duration-300 ${open ? "rotate-180" : "rotate-0"}`}
            />
          </button>
          {open && (
            <ul className="absolute z-20 w-full mt-1 bg-white border rounded shadow-lg max-h-56 overflow-y-auto">
              <div className="p-2 flex gap-x-2 items-center">
                <label htmlFor="all">
                  <input
                    type="checkbox"
                    id="all"
                    value={"all"}
                    checked={selectedSKUs.length == allSKUs.length}
                    onChange={() => setAll(!all)}
                  />
                  All SKU's
                </label>
              </div>
              {allSKUs.map((sku) => (
                <div key={sku} className="w-fit p-2 flex gap-x-2 items-center">
                  <label htmlFor={sku}>
                    <input
                      type="checkbox"
                      id={sku}
                      value={sku}
                      checked={selectedSKUs.includes(sku)}
                      onChange={(event) => handleCheckboxChange(event, sku)}
                    />
                    {sku}
                  </label>
                </div>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Section for displaying the pricing chart */}
      {selectedAreaName ? (
        <>
          {/* ================================== */}
          <div className="bg-white flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max shadow-md rounded-xl">
            <div className="flex py-5 border-b">
              <h1 className="flex  gap-4 items-center text-lg font-bold ml-5">
                {/* Orders Per Day section with icon */}
                <FaChartLine />
                Price Chart
                <CustomTooltip
                  title={
                    " Compare our brand's product pricing against competitors' average prices. Stay informed to make better pricing decisions and enhance market positioning."
                  }
                >
                  <PiInfoLight />
                </CustomTooltip>
              </h1>
            </div>

            <PricingDetailBarChart selectedAreaName={selectedAreaName} selectedSKUs={selectedSKUs} />
          </div>
        </>
      ) : (
        <ChartSkeleton /> // Show skeleton if selectedAreaName is not available yet
      )}

      {/* Dropdown for selecting a AreaName */}
      <div className="gap-3  mt-10 w-full flex  justify-end items-center ">
        <div className="w-auto min-w-32 h-[42px]  flex justify-end">
          <span
            onClick={() => handleExportCSV(csvExportedData, platformName)}
            className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
          >
            <span className="font-body font-normal text-sm">Export to CSV</span>
            <img width={16} height={16} src="/icons/download-circular-icon.svg" />
          </span>
        </div>
      </div>

      {/* Section for displaying detailed table view */}
      {selectedAreaName ? (
        <div className="bg-white flex flex-col mt-[1rem] w-full mb-10 shadow-md rounded-xl">
          <h1 className="flex gap-3 items-center text-lg font-medium pb-4 border-b p-4">
            <img src="/logos/sku-vs-keyword.svg" alt="pricing-detail" />
            Detailed View
          </h1>
          {/* PricingDetailsTable component that shows a detailed table view for the selected areaname and SKU */}
          <PricingDetailsTable selectedAreaName={selectedAreaName} setCsvExportedData={setCsvExportedData} />
        </div>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default PricingDetailSectionsContainer;
