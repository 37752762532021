export const STOCKING_INSIGHTS_BLINKIT_REQUEST =
  "STOCKING_INSIGHTS_BLINKIT_REQUEST";
export const STOCKING_INSIGHTS_BLINKIT_SUCCESS =
  "STOCKING_INSIGHTS_BLINKIT_SUCCESS";
export const STOCKING_INSIGHTS_BLINKIT_FAILURE =
  "STOCKING_INSIGHTS_BLINKIT_FAILURE";

export const STOCKING_INSIGHTS_INSTAMART_REQUEST =
  "STOCKING_INSIGHTS_INSTAMART_REQUEST";
export const STOCKING_INSIGHTS_INSTAMART_SUCCESS =
  "STOCKING_INSIGHTS_INSTAMART_SUCCESS";
export const STOCKING_INSIGHTS_INSTAMART_FAILURE =
  "STOCKING_INSIGHTS_INSTAMART_FAILURE";

export const STOCKING_INSIGHTS_ZEPTO_REQUEST = "STOCKING_INSIGHTS_ZEPTO_REQUEST";
export const STOCKING_INSIGHTS_ZEPTO_FAILURE = "STOCKING_INSIGHTS_ZEPTO_FAILURE";
export const STOCKING_INSIGHTS_ZEPTO_SUCCESS = "STOCKING_INSIGHTS_ZEPTO_SUCCESS";
