import axios from "axios";
import { BASE_URL } from "../../utils/assets";

export const authService = {
  registerUser: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/register`, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  signinUser: async (data) => {
    try {
      const response = await axios.post(`${BASE_URL}/login`, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
};
