import React, { useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LeastPerformDatesWise = ({ groupedData, option }) => {
  // State to manage pagination for each date group
  const [paginationStates, setPaginationStates] = useState(
    Object.keys(groupedData).reduce((acc, date) => {
      acc[date] = { page: 0, rowsPerPage: 5 }; // Default pagination state for each date
      return acc;
    }, {})
  );

  const handleChangePage = (date, event, newPage) => {
    setPaginationStates((prev) => ({
      ...prev,
      [date]: { ...prev[date], page: newPage },
    }));
  };

  const handleChangeRowsPerPage = (date, event) => {
    setPaginationStates((prev) => ({
      ...prev,
      [date]: { page: 0, rowsPerPage: parseInt(event.target.value, 10) },
    }));
  };

  const { tableHeaders, tableRows } = useMemo(() => {
    let headers = [];
    let rows = (data) => [];

    switch (option) {
      case "ProductListing":
        headers = ["Campaign Name", "Keyword Name", "Total Spend", "Total Revenue", "ROI", "Impressions", "CPM", "MVP"];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_blinkit_product_listing.campaign_name"],
            item["marketting_report_blinkit_product_listing.targeting_value"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
            item.cpm,
            item.mvp,
          ]);
        break;

      case "ProductRecommandation":
        headers = ["Campaign Name", "Total Spend", "Total Revenue", "ROI", "Impressions", "CPM"];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_blinkit_product_recommendation.campaign_name"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
            item.cpm,
          ]);
        break;

      case "CampaignPerformance":
        headers = ["Campaign Name", "Total Spend", "Total Revenue", "ROI", "Impressions"];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_zepto_campaign_performance.campaign_name"],
            item.totalSpend.toFixed(2),
            item.totalRevenue,
            item.ROI.toFixed(2),
            item.impression,
          ]);
        break;

      case "MarketingReport":
        headers = ["Campaign Name", "Keyword Name", "Total Spend", "Total Revenue", "ROI", "Impressions"];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_instamart.campaign_name"],
            item["marketting_report_instamart.keyword"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
          ]);
        break;

      case "SkuPerformance":
        headers = ["Campaign Name", "Total Spend", "Total Revenue", "ROI", "Impressions"];
        rows = (data) =>
          data.map((item) => [
            item["marketting_report_zepto_sku_performance.product_name"],
            item.totalSpend.toFixed(2),
            item.totalRevenue.toFixed(2),
            item.ROI.toFixed(2),
            item.impression,
          ]);
        break;

      default:
        console.warn(`Unknown option: ${option}`);
    }

    return { tableHeaders: headers, tableRows: rows };
  }, [option]);

  return (
    <div>
      {Object.entries(groupedData).map(([date, { data }], index) => {
        const { page, rowsPerPage } = paginationStates[date] || {
          page: 0,
          rowsPerPage: 5,
        };

        // Paginate the table rows
        const paginatedRows = tableRows(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{date}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer sx={{ borderRadius: "0.25rem" }}>
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: "500",
                            color: "#4D4D4D",
                            fontSize: "0.875rem",
                            textAlign: "left",
                            boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: index === 0 && "3",
                            position: "sticky",
                            bgcolor: "#F9F9F9",
                            left: index === 0 ? 0 : "auto",

                            maxWidth: index === 0 ? "200px" : "auto",
                          }}
                          style={{
                            minWidth: index === 0 ? 150 : "auto",
                            textAlign: index !== 0 ? "center" : "",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedRows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <TableCell
                            key={cellIndex}
                            sx={{
                              color: cellIndex == 0 && "#4D4D4D",
                              cursor: "pointer",
                              fontWeight: cellIndex === 0 && "500",
                              boxShadow: cellIndex === 0 ? "5px 0px 5px -5px rgba(16, 24, 40, 0.06)" : "none",
                              position: cellIndex === 0 ? "sticky" : "static",
                              bgcolor: rowIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                              left: cellIndex === 0 ? 0 : "auto",
                              maxWidth: cellIndex === 0 ? "200px" : "auto",
                              textAlign: cellIndex === 0 ? "left" : "center",
                            }}
                            style={{
                              minWidth: cellIndex === 0 ? 150 : "auto",
                            }}
                          >
                            {cell}
                          </TableCell>
                        ))}
                        <TableCell
                          align="right"
                          sx={{
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3",
                            position: "sticky",
                            bgcolor: rowIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                            right: 0,
                          }}
                        ></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Add pagination controls */}
              <TablePagination
                component="div"
                count={tableRows(data).length}
                page={page}
                onPageChange={(event, newPage) => handleChangePage(date, event, newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(date, event)}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default LeastPerformDatesWise;
