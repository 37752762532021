import React from "react";
import { useSelector } from "react-redux";
import {
  groupByCampaignAwarenessTable,
  groupByCCDDate,
  groupDatabyCampaignName,
} from "../../../utils/dataGrouping/awarenessPerformanceGrouping";
import AdsFunnel from "../PerformanceMarketingAdsComponents/AdsFunnel";
import { AiFillFunnelPlot } from "react-icons/ai";
import AdCampaigns from "../PerformanceMarketingAdsComponents/AdCampaigns";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";

const AwarenessPerformanceZeptoSectionsContainer = () => {
  const { awarenessPerformanceData } = useSelector((state) => state.marketing);
  const { loading, error, data } = awarenessPerformanceData;

  if (loading) {
    return (
      <div className="w-full">
        <div className="mb-2 bg-white rounded p-2">
          <FunnelSkeleton />
        </div>
        <div className="bg-white rounded-xl">
          <ChartSkeleton />
        </div>
        <div className="bg-white mt-2 rounded-xl">
          <TableSkeleton />
        </div>
      </div>
    );
  }

  if (error) {
    return <NoDataFound />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  const groupedDatabyCampaignName = groupDatabyCampaignName(awarenessPerformanceData.data);

  // Extract the values from the object and convert them into an array
  const valuesArray = Object.values(groupedDatabyCampaignName);

  // Sort the array in descending order based on the budget
  const sortedArray = valuesArray.sort((a, b) => b.spend - a.spend);

  // Get the top 10 objects with the highest budget
  const top10 = sortedArray.slice(0, 10);

  const groupedDateData = groupByCCDDate(awarenessPerformanceData.data);
  const tableData = groupByCampaignAwarenessTable(awarenessPerformanceData.data);
  return (
    <div className="w-full">
      {/* top 10 by campaigns by spend */}
      <div className="bg-white shadow-md rounded-xl">
        <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2 mb-2">
          <div className="p-2 rounded-full bg-gray-200">
            <AiFillFunnelPlot />
          </div>
          Top 10 Campaigns By Spend
          <CustomTooltip
            title={
              "Explore the top 10 keywords ranked by budget allocation, allowing you to see which keywords are driving the most investment and potentially the best returns."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
        <div className="flex justify-evenly flex-wrap gap-4 p-2">
          {top10.map((item, index) => (
            <AdsFunnel obj={item} key={index} />
          ))}
        </div>
      </div>
      {/* date wise overview */}
      {/* <div className="bg-white shadow-md rounded-xl mt-4">
        <AdsChart groupedData={groupedDateData} />
      </div> */}
      <div className="mt-4">
        <AdCampaigns groupedDataAd={tableData} />
      </div>
    </div>
  );
};

export default AwarenessPerformanceZeptoSectionsContainer;
