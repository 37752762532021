import {
  BLINKIT_FULFILLMENT_ERROR,
  BLINKIT_FULFILLMENT_REQUEST,
  BLINKIT_FULFILLMENT_SUCCESS,
  INSTAMART_FULFILLMENT_ERROR,
  INSTAMART_FULFILLMENT_REQUEST,
  INSTAMART_FULFILLMENT_SUCCESS,
  ZEPTO_FULFILLMENT_ERROR,
  ZEPTO_FULFILLMENT_REQUEST,
  ZEPTO_FULFILLMENT_SUCCESS,
} from "../constants/fulfillmentConstants";

export const getBlinkitFulfillmentRequest = () => {
  return {
    type: BLINKIT_FULFILLMENT_REQUEST,
  };
};

export const getBlinkitFulfillmentSuccess = (report) => {
  return {
    type: BLINKIT_FULFILLMENT_SUCCESS,
    payload: report,
  };
};

export const getBlinkitFulfillmentFailure = (error) => {
  return {
    type: BLINKIT_FULFILLMENT_ERROR,
    payload: error,
  };
};

export const getZeptoFulfillmentRequest = () => {
  return {
    type: ZEPTO_FULFILLMENT_REQUEST,
  };
};

export const getZeptoFulfillmentSuccess = (report) => {
  return {
    type: ZEPTO_FULFILLMENT_SUCCESS,
    payload: report,
  };
};

export const getZeptoFulfillmentFailure = (error) => {
  return {
    type: ZEPTO_FULFILLMENT_ERROR,
    payload: error,
  };
};

export const getInstamartFulfillmentRequest = () => {
  return {
    type: INSTAMART_FULFILLMENT_REQUEST,
  };
};

export const getInstamartFulfillmentSuccess = (report) => {
  return {
    type: INSTAMART_FULFILLMENT_SUCCESS,
    payload: report,
  };
};

export const getInstamartFulfillmentFailure = (error) => {
  return {
    type: INSTAMART_FULFILLMENT_ERROR,
    payload: error,
  };
};
