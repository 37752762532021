import {
  INSIGHTS_CITY_FAILURE,
  INSIGHTS_CITY_REQUEST,
  INSIGHTS_CITY_SUCCESS,
  INSIGHTS_FAILURE,
  INSIGHTS_PRODUCT_FAILURE,
  INSIGHTS_PRODUCT_PREVIOUS_FAILURE,
  INSIGHTS_PRODUCT_PREVIOUS_REQUEST,
  INSIGHTS_PRODUCT_PREVIOUS_SUCCESS,
  INSIGHTS_PRODUCT_REQUEST,
  INSIGHTS_PRODUCT_SUCCESS,
  INSIGHTS_REQUEST,
  INSIGHTS_SUCCESS,
} from "../constants/insightsConstants";

const initialState = {
  // Monthly Profitability

  monthlyInsights: {
    loading: false,
    data: [],
    error: null,
  },
  cityInsights: {
    loading: false,
    data: [],
    error: null,
  },
  productInsights: {
    loading: false,
    data: [],
    error: null,
  },
  productInsightsPrevious: {
    loading: false,
    data: [],
    error: null,
  },
};

export const insightsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Monthly Profitability

    case INSIGHTS_REQUEST:
      return {
        ...state,
        monthlyInsights: {
          ...state.monthlyInsights,
          loading: true,
          error: null,
          data: [],
        },
      };
    case INSIGHTS_SUCCESS:
      return {
        ...state,
        monthlyInsights: {
          ...state.monthlyInsights,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case INSIGHTS_FAILURE:
      return {
        ...state,
        monthlyInsights: {
          ...state.monthlyInsights,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case INSIGHTS_CITY_REQUEST:
      return {
        ...state,
        cityInsights: {
          ...state.cityInsights,
          loading: true,
          error: null,
          data: [],
        },
      };
    case INSIGHTS_CITY_SUCCESS:
      return {
        ...state,
        cityInsights: {
          ...state.cityInsights,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case INSIGHTS_CITY_FAILURE:
      return {
        ...state,
        cityInsights: {
          ...state.cityInsights,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case INSIGHTS_PRODUCT_REQUEST:
      return {
        ...state,
        productInsights: {
          ...state.productInsights,
          loading: true,
          error: null,
          data: [],
        },
      };
    case INSIGHTS_PRODUCT_SUCCESS:
      return {
        ...state,
        productInsights: {
          ...state.productInsights,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case INSIGHTS_PRODUCT_FAILURE:
      return {
        ...state,
        productInsights: {
          ...state.productInsights,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    case INSIGHTS_PRODUCT_PREVIOUS_REQUEST:
      return {
        ...state,
        productInsightsPrevious: {
          ...state.productInsightsPrevious,
          loading: true,
          error: null,
          data: [],
        },
      };
    case INSIGHTS_PRODUCT_PREVIOUS_SUCCESS:
      return {
        ...state,
        productInsightsPrevious: {
          ...state.productInsightsPrevious,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case INSIGHTS_PRODUCT_PREVIOUS_FAILURE:
      return {
        ...state,
        productInsightsPrevious: {
          ...state.productInsightsPrevious,
          loading: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
