import {
  GET_SOV_BY_KEYWORD_REQUEST,
  GET_SOV_BY_KEYWORD_SUCCESS,
  GET_SOV_BY_KEYWORD_FAILURE,
  GET_SOV_BY_AREANAME_REQUEST,
  GET_SOV_BY_AREANAME_SUCCESS,
  GET_SOV_BY_AREANAME_FAILURE,
  GET_SOV_BY_KEYWORD_WEEKLY_REQUEST,
  GET_SOV_BY_KEYWORD_WEEKLY_SUCCESS,
  GET_SOV_BY_KEYWORD_WEEKLY_FAILURE,
  GET_SOV_BY_AREANAME_WEEKLY_REQUEST,
  GET_SOV_BY_AREANAME_WEEKLY_SUCCESS,
  GET_SOV_BY_AREANAME_WEEKLY_FAILURE,
  SELECTED_SOA_KEYWORD,
  SELECTED_SOA_AREANAME,
  FILTERED_SOA_AREANAME,
  SELECTED_SOA_WEEKLY_KEYWORD,
  SELECTED_SOA_WEEKLY_AREANAME,
  FILTERED_SOA_WEEKLY_AREANAME,
} from "../constants/sovThirdPartyConstants";

const initialState = {
  sovKeywordData: {
    loading: false,
    data: [],
    error: null,
  },
  sovAreaNameData: {
    loading: false,
    data: [],
    error: null,
  },
  filteredAreaNames: {
    loading: false,
    data: [],
    error: null,
  },
  selectedSovKeyword: "",
  selectedSovAreaName: null,
  // weekly states
  sovKeywordWeeklyData: {
    loading: false,
    data: [],
    error: null,
  },
  sovAreaNameWeeklyData: {
    loading: false,
    data: [],
    error: null,
  },
  filteredWeeklyAreaNames: {
    loading: false,
    data: [],
    error: null,
  },
  selectedSovWeeklyKeyword: null,
  selectedSovWeeklyAreaName: null,
};

export const sovReducer = (state = initialState, action) => {
  switch (action.type) {
    // SOV by Keyword cases
    case GET_SOV_BY_KEYWORD_REQUEST:
      return {
        ...state,
        sovKeywordData: {
          ...state.sovKeywordData,
          loading: true,
          error: null,
        },
      };
    case GET_SOV_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        sovKeywordData: {
          ...state.sovKeywordData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SOV_BY_KEYWORD_FAILURE:
      return {
        ...state,
        sovKeywordData: {
          ...state.sovKeywordData,
          loading: false,
          error: action.payload,
        },
      };

    // SOV by Area Name cases
    case GET_SOV_BY_AREANAME_REQUEST:
      return {
        ...state,
        sovAreaNameData: {
          ...state.sovAreaNameData,
          loading: true,
          error: null,
        },
      };
    case GET_SOV_BY_AREANAME_SUCCESS:
      return {
        ...state,
        sovAreaNameData: {
          ...state.sovAreaNameData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SOV_BY_AREANAME_FAILURE:
      return {
        ...state,
        sovAreaNameData: {
          ...state.sovAreaNameData,
          loading: false,
          error: action.payload,
        },
      };

    // SOV by Keyword Weekly cases
    case GET_SOV_BY_KEYWORD_WEEKLY_REQUEST:
      return {
        ...state,
        sovKeywordWeeklyData: {
          ...state.sovKeywordWeeklyData,
          loading: true,
          error: null,
        },
      };
    case GET_SOV_BY_KEYWORD_WEEKLY_SUCCESS:
      return {
        ...state,
        sovKeywordWeeklyData: {
          ...state.sovKeywordWeeklyData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SOV_BY_KEYWORD_WEEKLY_FAILURE:
      return {
        ...state,
        sovKeywordWeeklyData: {
          ...state.sovKeywordWeeklyData,
          loading: false,
          error: action.payload,
        },
      };

    // SOV by Area Name Weekly cases
    case GET_SOV_BY_AREANAME_WEEKLY_REQUEST:
      return {
        ...state,
        sovAreaNameWeeklyData: {
          ...state.sovAreaNameWeeklyData,
          loading: true,
          error: null,
        },
      };
    case GET_SOV_BY_AREANAME_WEEKLY_SUCCESS:
      return {
        ...state,
        sovAreaNameWeeklyData: {
          ...state.sovAreaNameWeeklyData,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SOV_BY_AREANAME_WEEKLY_FAILURE:
      return {
        ...state,
        sovAreaNameWeeklyData: {
          ...state.sovAreaNameWeeklyData,
          loading: false,
          error: action.payload,
        },
      };

    // Selected SOV Keyword
    case SELECTED_SOA_KEYWORD:
      return {
        ...state,
        selectedSovKeyword: action.payload,
      };

    // Selected SOV Area Name
    case SELECTED_SOA_AREANAME:
      return {
        ...state,
        selectedSovAreaName: action.payload,
      };

    // Selected SOV Weekly Keyword
    case SELECTED_SOA_WEEKLY_KEYWORD:
      return {
        ...state,
        selectedSovWeeklyKeyword: action.payload,
      };

    // Selected SOV Weekly Area Name
    case SELECTED_SOA_WEEKLY_AREANAME:
      return {
        ...state,
        selectedSovWeeklyAreaName: action.payload,
      };

    // Filtered SOV by Area Name
    case FILTERED_SOA_AREANAME:
      return {
        ...state,
        filteredAreaNames: {
          ...state.filteredAreaNames,
          loading: false,
          data: action.payload,
        },
      };

    // Filtered SOV by Weekly Area Name
    case FILTERED_SOA_WEEKLY_AREANAME:
      return {
        ...state,
        filteredWeeklyAreaNames: {
          ...state.filteredWeeklyAreaNames,
          loading: false,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};
