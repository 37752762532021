import React from "react";
import { useNavigate } from "react-router-dom";

const TopAndLowPerformingCatAndCity = ({ path, data, heading }) => {
  console.log("data inventory====", data);
  const navigate = useNavigate();

  const handleViewMore = () => {
    navigate(path); // This will navigate to the /ads page
  };
  return (
    <div onClick={handleViewMore} className="hover:cursor-pointer bg-white h-full ">
      <div className="flex justify-between items-center">
        <h2 className="text-md leading-5 text-[#030229] font-body font-medium text-start mb-2">{heading}</h2>
      </div>
      <div className="p-4 border  rounded-lg">
        <ul className="space-y-2  ">
          {Object.keys(data).length > 0 ? (
            Object.entries(data)?.map(([categoryName, categoryDetails]) => (
              <li key={categoryName} className={` h-[100px] w-[320px] px-3 py-4 border rounded-lg shadow-sm`}>
                <span className="text-md font-medium font-body text-[#727278]">{categoryName}</span>
                <div className="gap-1 mt-2">
                  <div className="flex justify-between ">
                    <div className="flex gap-3">
                      <span className="text-sm text-[#727278] text-medium font-body">Daily RR :</span>
                      <span className="text-[#8E8E8E] text-sm text-medium font-body">
                        {categoryDetails.totals.dailyRunRate?.toFixed(2)}{" "}
                      </span>
                    </div>
                    <div className="flex gap-3">
                      <span className="text-sm text-[#727278] text-medium font-body">Inventory Days :</span>
                      <span className="text-[#8E8E8E] text-sm text-medium font-body">
                        {" "}
                        {categoryDetails.totals.inventoryDays?.toFixed(2)}
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="flex gap-3">
                      <span className="text-sm text-[#727278] text-medium font-body">Total Inventory :</span>
                      <span className="text-[#387FF5] text-sm text-medium font-body">
                        {categoryDetails.totals.totalInventory}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div className="flex flex-col items-start">
                <span className="text-[0.65rem] text-gray-500">SKU List</span>
                <strong className="text-sm w-40 font-medium text-blue-700">{categoryName}</strong>
              </div>

              <div className="flex  gap-6">
                <div className="flex flex-col gap-1 items-start">
                  <span className="text-[0.65rem] text-gray-500">Daily RR</span>
                  <span className="text-xs font-semibold ">
                    {categoryDetails.totals.dailyRunRate?.toFixed(2)} 
                  </span>
                </div>

                <div className="flex flex-col gap-1 items-start ">
                  <span className="text-[0.65rem] text-gray-500">Total Inventory</span>
                  <span className="text-xs font-semibold text-green-600">
                    {categoryDetails.totals.totalInventory} 
                  </span>
                </div>

                <div className="flex flex-col gap-1 items-start">
                  <span className="text-[0.65rem] text-gray-500">Inventory Days</span>
                  <span className="text-xs font-semibold ">
                    {categoryDetails.totals.inventoryDays?.toFixed(2)}
                  </span>
                </div>
              </div> */}
              </li>
            ))
          ) : (
            <div className="flex justify-center items-center h-40">No {heading}</div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopAndLowPerformingCatAndCity;
