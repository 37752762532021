import React from "react";
import { useSelector } from "react-redux";

import SkuCityLevelAvailabilityTable from "./SkuCityLevelAvailabilityTable";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";

// Component to display SKU city-level availability sections
const SkuCityLevelAvailabilitySectionsContainer = ({ platformName }) => {
  // Retrieve third-party availability data from Redux store
  const { thirdPartyAvailability } = useSelector((state) => state.thirdParty);

  // Extract unique brands from third-party availability data, if available
  const brands = Array.isArray(thirdPartyAvailability?.data)
    ? Array.from(new Set(thirdPartyAvailability.data.map((item) => item["third_party_availability.brand"])))
    : [];

  return (
    <div>
      {/* Main container for SKU availability data */}
      <div className="mt-4 mb-4 rounded-lg w-full flex flex-col items-center p-1">
        {/* Show a loading skeleton if data is still loading */}
        {thirdPartyAvailability?.loading ? (
          <div className="w-full">
            <TableSkeleton />
          </div>
        ) : !thirdPartyAvailability.data || thirdPartyAvailability.data.length === 0 ? (
          // Show "No Data Found" message if data is empty or unavailable
          <NoDataFound />
        ) : (
          // Render the Dark Store Level Availability Table if data is available
          <SkuCityLevelAvailabilityTable
            apiData={thirdPartyAvailability?.data} // Pass API data
            brands={brands} // Pass extracted unique brands
            platformName={platformName} // Pass the platform name
          />
        )}
      </div>
    </div>
  );
};

export default SkuCityLevelAvailabilitySectionsContainer;
