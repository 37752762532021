import React, { useState } from "react";
import { useSelector } from "react-redux";
import { dummyPricingData } from "../../../utils/dummyPricingData";
import DiscountOverWeek from "./DiscountOverWeek";
import PricePerUnitOverWeek from "./PricePerUnitOverWeek";
import SkuWiseData from "./SkuWiseData";
import CityWiseData from "./CityWiseData";
import CompetitorPricingKeyInsights from "./CompetitorPricingKeyInsights";

const CompetitorPricingContainer = () => {
  const { competitorPricing } = useSelector((state) => state.competitor);
  const [selectedSKU, setSelectedSKU] = useState("All SKU's");
  const user = JSON.parse(localStorage.getItem("user"));
  const ourBrand = user.organizationDetail.name;
  const brandData = dummyPricingData.filter((item) => item.brand == ourBrand);

  return (
    <div className="py-4 flex flex-col gap-y-4 w-full">
      <div className="flex mt-10 gap-4 w-full justify-between">
        <DiscountOverWeek chartData={brandData} />
        <PricePerUnitOverWeek chartData={brandData} />
      </div>
      <div className="shadow-xl rounded-xl bg-white">
        <div className="p-4 border-b text-lg font-medium flex items-center gap-4">
          <img src="/logos/overview.svg" alt="overview" />
          Overview
        </div>
        <SkuWiseData chartData={brandData} />
      </div>

      <CompetitorPricingKeyInsights />
    </div>
  );
};

export default CompetitorPricingContainer;
