import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import SkuCityLevelAvailabilityModal from "./SkuCityLevelAvailabilityModal";
import Dropdown from "../../common/Dropdown/Dropdown";
import Papa from "papaparse"; // Import PapaParse
import dayjs from "dayjs"; // Import Day.js

// Utility function to calculate the percentage of stock availability for a given city's details
const calculateStockAvailabilityPercentage = (cityDetails) => {
  const totalPincodes = cityDetails.length;
  const availableStock = cityDetails.filter((detail) => detail.stockBinary === "1").length;
  return (availableStock / totalPincodes) * 100;
};

// Function to calculate the average availability for a city across all products
const calculateAverageAvailability = (groupedData, cityName) => {
  const totalSKUs = groupedData.length;
  const totalAvailability = groupedData.reduce((acc, item) => {
    const city = item.cities.find((c) => c.city === cityName);
    return acc + (city ? city.availabilityPercentage : 0);
  }, 0);
  return totalAvailability / totalSKUs;
};

// Function to transform the raw API data into a more usable format
const transformData = (data) => {
  const groupedData = {};

  data.forEach((item) => {
    const {
      "third_party_availability.product_name": sku,
      "third_party_availability.city_lm": city,
      "third_party_availability.stock_binary": stockBinary,
      "third_party_availability.sku_listing_status": stockListing,
      "third_party_availability.pincode_lm": pincode,
      "third_party_availability.area_name_lm": areaName,
      "third_party_availability.date": date,
    } = item;

    const parsedDate = new Date(date);

    if (!groupedData[sku]) {
      groupedData[sku] = {
        sku,
        cities: {},
        dates: {},
      };
    }

    if (!groupedData[sku].cities[city]) {
      groupedData[sku].cities[city] = [];
    }

    if (!groupedData[sku].dates[parsedDate]) {
      groupedData[sku].dates[parsedDate] = [];
    }

    groupedData[sku].cities[city].push({ stockBinary, stockListing, pincode, areaName });
    groupedData[sku].dates[parsedDate].push({
      stockBinary,
      stockListing,
      pincode,
    });
  });

  const result = [];

  for (const sku in groupedData) {
    const cities = [];
    const dates = [];

    for (const city in groupedData[sku].cities) {
      const details = groupedData[sku].cities[city];
      const availabilityPercentage = calculateStockAvailabilityPercentage(details);
      cities.push({
        city,
        details,
        availabilityPercentage,
      });
    }

    for (const date in groupedData[sku].dates) {
      const details = groupedData[sku].dates[date];
      const availabilityPercentage = calculateStockAvailabilityPercentage(details);
      dates.push({
        date: new Date(date),
        availabilityPercentage,
      });
    }

    result.push({
      sku: groupedData[sku].sku,
      cities,
      dates,
    });
  }

  return result;
};

const SkuCityLevelAvailabilityTable = ({ apiData, brands, platformName }) => {
  const userDetail = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userDetail);
  const [selectedBrand, setSelectedBrand] = useState(parsedUserData?.organizationDetail?.name);
  const data = apiData?.filter((item) => item["third_party_availability.brand"] == selectedBrand);
  const groupedData = transformData(data);
  console.log("groupedData====", groupedData);

  const [popupData, setPopupData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectSku, setSelectSku] = useState("");

  const openPopup = (city, sku) => {
    setPopupData(city); // Update popup data for the selected city
    setSelectSku(sku); // Update the selected SKU
  };

  // Effect to open the modal when both popupData and selectSku are set
  useEffect(() => {
    if (popupData && selectSku) {
      setIsPopupOpen(true);
    }
  }, [popupData, selectSku]);

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupData(null);
    setSelectSku(""); // Reset selectSku when closing the modal
  };

  const getBackgroundColor = (percentage) => {
    if (percentage < 30) return "bg-[#F53E3E1A] text-[#F53E3E]";
    if (percentage >= 30 && percentage <= 70) return "bg-[#FEBE401A] text-[#FEBE40]";
    return "bg-[#22C55E1A] text-[#22C55E]";
  };

  const handleExportCSV = (groupedData, platformName) => {
    const csvData = [];

    // Flatten the data and collect required fields
    groupedData.forEach((item) => {
      item.cities.forEach((city) => {
        city.details.forEach((detail) => {
          item.dates.forEach((date) => {
            csvData.push({
              Date: dayjs(date.date).format("DD/MM/YYYY"), // Use Day.js for formatting
              Sku: item.sku,
              Platform: platformName, // Use the platformName passed to the function
              City: city.city,
              Pincode: detail.pincode,
              "Area Name": detail.areaName,
              "Stock Binary":
                detail?.stockListing !== 1 ? "Not Listed" : detail?.stockBinary === "1" ? "In Stock" : "Out of Stock",
            });
          });
        });
      });
    });

    // Convert data to CSV format
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `sku_city_availability_${platformName.toLowerCase()}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="w-full">
      {/* Render the brand selection dropdown */}
      <div className="gap-3 mt-2 w-full flex font-semibold justify-end items-center">
        <Dropdown
          options={[...brands]}
          value={selectedBrand}
          onChange={setSelectedBrand}
          initalTitle={"Select Brand"}
        />
        <div className="w-auto min-w-32 h-[42px]  flex justify-end">
          <span
            onClick={() => handleExportCSV(groupedData, platformName)}
            className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
          >
            <span className="font-body font-normal text-sm">Export to CSV</span>
            <img width={16} height={16} src="/icons/download-circular-icon.svg" />
          </span>
        </div>
      </div>
      {/* Render the data table */}
      <div className="bg-white rounded-xl mt-4">
        <div className="text-lg border-b flex items-center gap-4 p-4 font-medium">
          <img src="/logos/city-level-availability.svg" alt="sku-level" />
          SKU Level Availabiity
        </div>
        <div className="bg-white p-4 rounded-xl shadow-xl mt-4">
          <div className="p-2 overflow-x-scroll border rounded-xl">
            <TableContainer sx={{ borderRadius: "0.25rem" }} className="rounded-lg">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        fontSize: "0.875rem",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: "#F9F9F9",
                        left: 0,
                        maxWidth: "200px",
                      }}
                      style={{ minWidth: 150 }}
                    >
                      SKU
                    </TableCell>
                    {groupedData[0]?.cities.map((cityData) => (
                      <TableCell
                        key={cityData.city}
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        {cityData.city}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedData.map((item, index) => (
                    <TableRow key={item.sku} sx={{ bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff" }}>
                      <TableCell
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "500",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          position: "sticky",
                          bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          left: 0,
                          maxWidth: "300px",
                        }}
                        style={{ minWidth: 150 }}
                      >
                        {item.sku}
                      </TableCell>
                      {groupedData[0]?.cities.map((cityData) => {
                        const city = item.cities.find((c) => c.city === cityData.city);
                        const percentage = city ? city.availabilityPercentage : 0;
                        const backgroundColor = getBackgroundColor(percentage);
                        return (
                          <TableCell
                            key={item.sku + cityData.city}
                            onClick={() => openPopup(city, item.sku)}
                            sx={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontWeight: "400",
                            }}
                          >
                            <div className={`inline-block ${backgroundColor} px-4 py-[0.375rem] rounded-full`}>
                              {city ? `${percentage.toFixed(2)}%` : "N/A"}
                            </div>
                          </TableCell>
                        );
                      })}
                      <TableCell
                        align="right"
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                          right: 0,
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
                  {/* Render row for average availability */}
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        color: "#4D4D4D",
                        textAlign: "left",
                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: "#D0E3FF",
                        left: 0,
                        maxWidth: "200px",
                      }}
                      style={{ minWidth: 150 }}
                    >
                      Average Availability
                    </TableCell>
                    {groupedData[0]?.cities.map((cityData) => {
                      const backgroundColor = getBackgroundColor(
                        calculateAverageAvailability(groupedData, cityData.city)
                      );
                      return (
                        <TableCell
                          key={`average-${cityData.city}`}
                          sx={{
                            textAlign: "center",
                            bgcolor: "#D0E3FF",
                            fontWeight: "400",
                          }}
                        >
                          <div
                            className={`inline-block ${backgroundColor} px-4 py-[0.375rem] rounded-full`}
                          >{`${calculateAverageAvailability(groupedData, cityData.city).toFixed(2)}%`}</div>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      align="right"
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        bgcolor: "#D0E3FF",
                        right: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        {/* Modal for city details */}
        {isPopupOpen && (
          <SkuCityLevelAvailabilityModal
            open={isPopupOpen}
            handleClose={closePopup}
            data={popupData}
            selectedSku={selectSku}
          />
        )}
      </div>
    </div>
  );
};

export default SkuCityLevelAvailabilityTable;
