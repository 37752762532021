import React, { useEffect, useState } from "react";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import CategoryRcaSectionsContainer from "../../components/competitor/CategoryRcaComponents/CategoryRcaSectionsContainer";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import {
  fetchRcaCategoryBlinkitData,
  fetchRcaCityBlinkitData,
  fetchRcaSkuLevelBlinkitData,
} from "../../services/competitors/competitorsServices";

import {
  fetchSoaThirdPartyVisibilityKeywordData,
  fetchSoaThirdPartyVisibilityAreaNameData,
  fetchSovThirdPartyVisibilityWeeklyKeywordData,
  fetchSoaThirdPartyVisibilityWeeklyAreaNameData,
} from "../../services/visibility/sovThirdPartyVisibilityServices";

import {
  getRCACityRequest,
  getRCACitySuccess,
  getRCACityFailure,
  getRCASKURequest,
  getRCASKUSuccess,
  getRCASKUFailure,
  getFilteredRcaCity,
} from "../../redux/actions/categoryRcaActions";

import { useDispatch, useSelector } from "react-redux";

import {
  getFilteredSovAreaName,
  getFilteredSovWeeklyAreaName,
  getSovByAreaNameFailure,
  getSovByAreaNameRequest,
  getSovByAreaNameSuccess,
  getSovByAreaNameWeeklyFailure,
  getSovByAreaNameWeeklyRequest,
  getSovByAreaNameWeeklySuccess,
  getSovByKeywordFailure,
  getSovByKeywordRequest,
  getSovByKeywordSuccess,
  getSovByKeywordWeeklyFailure,
  getSovByKeywordWeeklyRequest,
  getSovByKeywordWeeklySuccess,
} from "../../redux/actions/sovThirdPartyActions";

const options = ["Category Rca"];

const CompetitorScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { rcaCitiesData, selectedRcaSku } = useSelector(
    (state) => state.rcaCategory
  );
  console.log("selectedRcaSku:", selectedRcaSku);

  const { selectedDate } = useDate();
  const dispatch = useDispatch(); // Use Redux dispatch

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  // Fetch categories and cities initially
  const handleBlinkitQueries = async () => {
    // Fetch Categories
    // try {
    //   dispatch(getRCACategoryRequest()); // Dispatch category RCA request action
    //   const categoriesData = await fetchRcaCategoryBlinkitData(selectedDate);
    //   dispatch(getRCACategorySuccess(categoriesData)); // Dispatch success action
    // } catch (error) {
    //   console.error("Error fetching category RCA data:", error);
    //   dispatch(getRCACategoryFailure(error)); // Dispatch failure action
    // }

    // Fetch SKUs
    try {
      dispatch(getRCASKURequest()); // Dispatch SKU RCA request action
      const skuData = await fetchRcaSkuLevelBlinkitData(selectedDate);
      dispatch(getRCASKUSuccess(skuData)); // Dispatch success action
    } catch (error) {
      console.error("Error fetching SKU RCA data:", error);
      dispatch(getRCASKUFailure(error)); // Dispatch failure action
    }

    // Fetch Cities
    try {
      dispatch(getRCACityRequest()); // Dispatch city RCA request action
      const citiesData = await fetchRcaCityBlinkitData(selectedDate, selectedRcaSku);
      dispatch(getRCACitySuccess(citiesData)); // Dispatch success action
      dispatch(getFilteredRcaCity(citiesData));
    } catch (error) {
      console.error("Error fetching city RCA data:", error);
      dispatch(getRCACityFailure(error)); // Dispatch failure action
    }
  };

  const fetchCitiesForSelectedCategory = async (selectedDate, selectedRcaSku) => {
    try {
      dispatch(getRCACityRequest()); // Dispatch city RCA request action
      const citiesData = await fetchRcaCityBlinkitData(selectedDate, selectedRcaSku);
      dispatch(getFilteredRcaCity(citiesData)); // Dispatch success action
    } catch (error) {
      console.error("Error fetching city RCA data:", error);
      dispatch(getRCACityFailure(error)); // Dispatch failure action
    }
  };

  const handleShareOfVoiceQueries = async () => {
    // to get inital keywords data
    try {
      dispatch(getSovByKeywordRequest());
      const keywordSoaData = await fetchSoaThirdPartyVisibilityKeywordData(selectedDate, platformName);
      dispatch(getSovByKeywordSuccess(keywordSoaData));
    } catch (error) {
      console.log("error", error);
      dispatch(getSovByKeywordFailure(error));
    }

    // to get initail areanames data
    try {
      dispatch(getSovByAreaNameRequest());
      const areaNameData = await fetchSoaThirdPartyVisibilityAreaNameData(selectedDate, platformName);
      dispatch(getSovByAreaNameSuccess(areaNameData));
      dispatch(getFilteredSovAreaName(areaNameData));
    } catch (error) {
      console.log("error", error);
      dispatch(getSovByAreaNameFailure(error));
    }

    // to get inital keywords weekly data
    try {
      dispatch(getSovByKeywordWeeklyRequest());
      const weeklyKeywordSoaData = await fetchSovThirdPartyVisibilityWeeklyKeywordData(selectedDate, platformName);
      dispatch(getSovByKeywordWeeklySuccess(weeklyKeywordSoaData));
    } catch (error) {
      dispatch(getSovByKeywordWeeklyFailure(error));
    }

    // to get inital area names weekly data

    try {
      dispatch(getSovByAreaNameWeeklyRequest());
      const areaNameData = await fetchSoaThirdPartyVisibilityWeeklyAreaNameData(selectedDate, platformName);
      dispatch(getSovByAreaNameWeeklySuccess(areaNameData));
      dispatch(getFilteredSovWeeklyAreaName(areaNameData));
    } catch (error) {
      dispatch(getSovByAreaNameWeeklyFailure(error));
    }
  };

  useEffect(() => {
    handleBlinkitQueries();
    handleShareOfVoiceQueries();
  }, [selectedDate]);

  // when category select cities fetched for that selected category
  useEffect(() => {
    if (selectedRcaSku) {
      fetchCitiesForSelectedCategory(selectedDate, selectedRcaSku);
    } else {
      dispatch(getFilteredRcaCity(rcaCitiesData.data));
    }
  }, [selectedRcaSku, selectedDate]);

  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption == 0 && <CategoryRcaSectionsContainer />}
      </Toolbar>
    </>
  );
};

export default CompetitorScreen;
