import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const OverallProfitabilityInsightsDonutChart = ({ groupedData }) => {
  // Extract month and remove it from the grouped data
  const { month, mrp, ...chartData } = groupedData[0];

  const data = {
    labels: ["Gst", "Cogs", "Net Sales", "CM1"],
    datasets: [
      {
        label: "Profitability Data",
        data: Object.values(chartData),
        backgroundColor: ["#FEBE40", "#22CFCF", "#4597DB", "#FF6384", "#8142FF"], // Colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        borderWidth: 0.7, // Make borders thicker
        borderRadius: 4,
        hoverBorderColor: "#fff", // Change the border color on hover to white
        cutout: "50%", // Make it a donut and increase the width of the cutout (default is 50%)
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right", // Move legend to the right
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          color: "#606060", // Customize legend text color
          font: {
            size: 12, // Font size
            weight: "normal", // Set the font to bold
          },
        },
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw.toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
            })}`;
          },
        },
      },
      datalabels: {
        color: "#fff", // Set the text color for the labels inside slices
        formatter: (value) => value.toFixed(2), // Format the labels to 2 decimal places
        font: {
          weight: "bold",
          size: 10,
        },
        anchor: "center", // Positioning of the labels
        align: "center",
      },
    },
  };

  return (
    <>
      <span className="text-sm pl-12 text-center text-[#4F4E69] font-medium font-body">
        Overall Profitability over 1 month
      </span>
      <div className="flex  mt-4 flex-col justify-start" style={{ height: "220px", width: "80%" }}>
        {/* Fixing height of the chart */}
        <Doughnut data={data} options={options} />
      </div>
    </>
  );
};

export default OverallProfitabilityInsightsDonutChart;
