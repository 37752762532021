import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross1 } from "react-icons/rx";
import { RiMenu2Line } from "react-icons/ri";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "16px",
  p: 0,
};

const KeywordBasedOwnBrandRankingModal = ({ open, handleClose, data }) => {
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);

  // Provide a default empty object if data.detailsGroupedByKeyword is undefined or null
  const detailsGroupedByKeyword = data?.detailsGroupedByKeyword || {};

  useEffect(() => {
    const keys = Object.keys(detailsGroupedByKeyword);

    setSelectedKeyword(keys[0]);
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjusted opacity here
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* heading container */}
          <div className="flex justify-between items-center mb-4 bg-white text-gray-600 p-4 rounded-t-2xl border-b">
            <h2 className="text-lg font-medium flex items-center gap-4">
              <img src="/logos/sku-vs-keyword.svg" alt="inventory" />
              Keywords Based Ranking for SKU- {data?.skuName}
            </h2>
            <RxCross1 className="text-xl cursor-pointer" onClick={handleClose} />
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg w-full  max-h-[70vh] overflow-y-auto flex">
            {/* Left side: Keywords  */}
            <div className="w-3/12 p-2 border rounded-xl border-gray-300 overflow-y-auto max-h-[70vh]">
              <h2 className="flex flex-col rounded-xl font-medium text-sm mb-4 bg-[#EBF1FF] p-2 font-inter">
                <span>Keyword List For :- </span>
                <span className="text-[#387FF5]">{data.skuName}</span>
              </h2>
              {Object.keys(detailsGroupedByKeyword).map((keyword, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedKeyword(keyword)}
                  className={`p-2 mb-2 cursor-pointer capitalize rounded-md font-inter  ${
                    selectedKeyword === keyword
                      ? "bg-[#387FF5] text-white hover:bg-[#2a4f8b]"
                      : "hover:bg-gray-100 text-[#4F4E69]"
                  }`}
                >
                  {keyword}
                </div>
              ))}
            </div>

            {/* Right side: Keyword details table*/}
            <div className="flex flex-col px-4 w-9/12 max-h-[70vh]">
              <div className="w-full pl-4 overflow-y-auto max-h-[70vh]">
                {selectedKeyword && (
                  <>
                    <h3 className="font-medium mb-2 ">Details For Keyword - {selectedKeyword}</h3>
                    <div className="border p-2 rounded-xl">
                      <div>
                        <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-auto">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow className="bg-gray-200">
                                <TableCell
                                  sx={{
                                    fontWeight: "500",
                                    color: "#4D4D4D",
                                    fontSize: "0.875rem",
                                    textAlign: "left",
                                    boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    zIndex: "3",
                                    position: "sticky",
                                    bgcolor: "white",
                                    left: 0,
                                    maxWidth: "200px",
                                    bgcolor: "#F9F9F9",
                                  }}
                                >
                                  <div>Brand Name</div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "500",
                                    color: "#4D4D4D",
                                    fontSize: "0.875rem",
                                    textAlign: "center",
                                    bgcolor: "#F9F9F9",
                                  }}
                                >
                                  <div> Product Name</div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "500",
                                    color: "#4D4D4D",
                                    fontSize: "0.875rem",
                                    textAlign: "center",
                                    bgcolor: "#F9F9F9",
                                  }}
                                >
                                  <div>Overall Ranking</div>
                                </TableCell>

                                <TableCell
                                  sx={{
                                    boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                    zIndex: "3",
                                    position: "sticky",
                                    bgcolor: "#F9F9F9",
                                    right: 0,
                                  }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.detailsGroupedByKeyword[selectedKeyword]
                                .filter((detail) => detail.brand === parsedUserData?.organizationDetail?.name) // Filter by brand
                                .sort((a, b) => a.slot - b.slot)
                                .map((detail, detailIndex) => (
                                  <TableRow
                                    key={detailIndex}
                                    sx={{ bgcolor: detailIndex % 2 !== 0 ? "#F0F6FF" : "#fff" }}
                                  >
                                    <TableCell
                                      sx={{
                                        fontWeight: "500",
                                        color: "#4D4D4D",
                                        boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                        position: "sticky",
                                        bgcolor: "white",
                                        left: 0,
                                        maxWidth: "300px",
                                        bgcolor: detailIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                                      }}
                                    >
                                      {detail.brand}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{detail.productName}</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>{detail.slot}</TableCell>

                                    <TableCell
                                      align="right"
                                      sx={{
                                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                        zIndex: "3",
                                        position: "sticky",
                                        bgcolor: detailIndex % 2 !== 0 ? "#F0F6FF" : "#fff",
                                        right: 0,
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </>
                )}
                {!selectedKeyword && <p className="text-gray-500">Select a keyword to see details.</p>}
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default KeywordBasedOwnBrandRankingModal;
