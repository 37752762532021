import { blinkitQuery, instamartQuery, zeptoQuery } from "../../utils/queries/keywordBasedRankingQueries";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

export const fetchKeywordRankingDataBlinkit = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(blinkitQuery(selectedDate));
  return result.tablePivot();
};

export const fetchKeywordRankingDataInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(instamartQuery(selectedDate));
  return result.tablePivot();
};

export const fetchKeywordRankingDataZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(zeptoQuery(selectedDate));
  return result.tablePivot();
};
