import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ThemeProvider from "./theme";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { DateRangeProvider } from "./hooks/DateRange/DateRangeProvider";
import { DateProvider } from "./hooks/SingleDatePicker/SingleDatePickerProvider";
import { CustomSingleDateProvider } from "./hooks/SingleDatePicker/CustomSigleDatePickerProvider";
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400
import "@fontsource/ibm-plex-sans/400.css"; // Specify weight
import "@fontsource/ibm-plex-sans/400-italic.css"; // Specify weight and style
import { DateRangePickerProvider } from "./hooks/CustomDateRange/CustomDateRangePickerProvider";
import { AlertProvider } from "./components/common/Alert/AlertProvider ";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <AlertProvider>
          <DateRangeProvider>
            <DateRangePickerProvider>
              <DateProvider>
                <CustomSingleDateProvider>
                  <App />
                </CustomSingleDateProvider>
              </DateProvider>
            </DateRangePickerProvider>
          </DateRangeProvider>
        </AlertProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
