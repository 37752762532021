import React from "react";
import Funnel from "./FunnelChart";
import { AiFillFunnelPlot } from "react-icons/ai";
import FunnelSkeleton from "../../common/Skeletons/FunnelSkeleton";
import { PiInfoLight } from "react-icons/pi";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";

const TopTenCompaignBySpend = ({ groupedData, isLoading }) => {
  // Check if data is still loading or if groupedData is empty

  // If groupedData is empty, show a message
  if (!groupedData || Object.keys(groupedData).length === 0) {
    return <FunnelSkeleton />;
  }

  // Extract the values from the object and convert them into an array
  const valuesArray = Object.values(groupedData);

  // Sort the array in descending order based on the budget
  const sortedArray = valuesArray.sort((a, b) => b.estimateBudget - a.estimateBudget);

  // Get the top 10 objects with the highest budget
  const top10 = sortedArray.slice(0, 10);

  return (
    <div className="bg-white shadow-md rounded-xl ">
      <div>
        <h1 className="flex gap-3 items-center text-lg font-medium border-b p-4">
          <AiFillFunnelPlot />
          Top 10 Campaign By Budget
          <CustomTooltip
            title={
              "View the top 10 campaigns ranked by budget allocation, helping you identify the most impactful campaigns based on spending."
            }
          >
            <PiInfoLight />
          </CustomTooltip>
        </h1>
        <div className="flex p-4 flex-wrap justify-evenly gap-8">
          {top10?.map((item, index) => (
            <Funnel obj={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopTenCompaignBySpend;
