import React from "react";
import { TbBuildingWarehouse } from "react-icons/tb";
import { useSelector } from "react-redux";
import TopAndLowPerformingProductBasedOnDailyRunRate from "./TopAndLowPerformingProductBasedOnDailyRunRate";
import TopAndLowPerformingCatAndCity from "./TopAndLowPerformingCatAndCity";
import { Skeleton } from "@mui/material";
import ShowSelectedDate from "../../common/ShowSelectedDate/ShowSelectedDate";
import CustomTooltip from "../../common/CustomTooltip/CustomTooltip";
import { PiInfoLight } from "react-icons/pi";
import { FaChartArea } from "react-icons/fa"; // Importing chart icon
import NoDataFound from "../../common/NoDataFound/NoDataFound";

const InventoryInsights = ({ platformName, selectedDate }) => {
  const { inventoryReportBlinkit, inventoryReportZepto, inventoryReportInstamart } = useSelector(
    (state) => state.sales
  );

  function consolidateData(data, platform) {
    const consolidated = {};

    data.forEach((entry) => {
      const city = entry[`combined_inventory_data_${platform}.brands_choice`];
      const skuCode = entry[`combined_inventory_data_${platform}.brand_internal_sku_code`];
      const dailyRunRate = parseFloat(entry[`combined_sales_report_${platform}.daily_run_rate`]);
      const totalInv =
        platform === "zepto"
          ? parseInt(entry[`combined_inventory_data_${platform}.total_quantity`], 10)
          : parseInt(entry[`combined_inventory_data_${platform}.frontend_inv_qty`], 10) +
            parseInt(entry[`combined_inventory_data_${platform}.backend_inv_qty`], 10);

      // Create a unique key based on city and SKU code
      const key = `${city}-${skuCode}`;

      // Initialize consolidated entry if it doesn't exist
      if (!consolidated[key]) {
        consolidated[key] = {
          ...entry,
          [`combined_sales_report_${platform}.daily_run_rate`]: 0,
          totalInv: 0,
          count: 0,
        };
      }

      // Accumulate dailyRunRate and inventory quantities
      consolidated[key][`combined_sales_report_${platform}.daily_run_rate`] += dailyRunRate;
      consolidated[key].totalInv += totalInv;
      consolidated[key].count += 1;
    });

    // Map the consolidated data to calculate averages and totals
    return Object.values(consolidated)
      .map((entry) => ({
        ...entry,
        [`combined_sales_report_${platform}.daily_run_rate`]:
          entry[`combined_sales_report_${platform}.daily_run_rate`] / entry.count,
        totalInv: entry.totalInv,
      }))
      .sort((a, b) =>
        a[`combined_inventory_data_${platform}.brand_internal_sku_code`].localeCompare(
          b[`combined_inventory_data_${platform}.brand_internal_sku_code`]
        )
      );
  }

  function aggregateBySkuCode(consolidatedData, platform) {
    const aggregatedData = {};

    consolidatedData.forEach((entry) => {
      const skuCode = entry[`combined_inventory_data_${platform}.brand_internal_sku_code`];

      if (!aggregatedData[skuCode]) {
        aggregatedData[skuCode] = {
          ...entry,
          totalInv: 0,
          totalDailyRunRate: 0,
          count: 0,
        };
      }

      // Sum up inventory and daily run rate
      aggregatedData[skuCode].totalInv += entry.totalInv;
      aggregatedData[skuCode].totalDailyRunRate += entry[`combined_sales_report_${platform}.daily_run_rate`];
      aggregatedData[skuCode].count += 1;
    });

    // Calculate averages for dailyRunRate and inventoryDays
    return Object.values(aggregatedData).map((entry) => {
      const averageDailyRunRate = entry.totalDailyRunRate / entry.count;
      const inventoryDays = entry.totalInv / averageDailyRunRate;

      return {
        ...entry,
        [`combined_sales_report_${platform}.daily_run_rate`]: averageDailyRunRate,
        inventoryDays: inventoryDays || 0,
      };
    });
  }

  // Example usage:
  const blinkitConsolidated = consolidateData(inventoryReportBlinkit.data, "blinkit");
  const blinkitAggregated = aggregateBySkuCode(blinkitConsolidated, "blinkit");
  console.log("blinkitAggregated:", blinkitAggregated);

  const zeptoConsolidated = consolidateData(inventoryReportZepto.data, "zepto");
  const zeptoAggregated = aggregateBySkuCode(zeptoConsolidated, "zepto");

  const instamartConsolidated = consolidateData(inventoryReportInstamart.data, "instamart");
  const instamartAggregated = aggregateBySkuCode(instamartConsolidated, "instamart");

  // Group data by category, sub-category, and product name
  const groupedByCategoryBlinkit = blinkitAggregated.reduce((acc, item) => {
    const category = item["combined_inventory_data_blinkit.category"];
    const subCategory = item["combined_inventory_data_blinkit.sub_category"];
    const productName = item["combined_inventory_data_blinkit.item_name"];

    // Initialize category if not present
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          backendInvQty: 0,
          frontendInvQty: 0,
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if not present
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          backendInvQty: 0,
          frontendInvQty: 0,
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize product name if not present
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        backendInvQty: 0,
        frontendInvQty: 0,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update product totals
    acc[category].subCategories[subCategory].productNames[productName].backendInvQty += parseInt(
      item["combined_inventory_data_blinkit.backend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].productNames[productName].frontendInvQty += parseInt(
      item["combined_inventory_data_blinkit.frontend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_blinkit.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.backendInvQty += parseInt(
      item["combined_inventory_data_blinkit.backend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].totals.frontendInvQty += parseInt(
      item["combined_inventory_data_blinkit.frontend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_blinkit.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.backendInvQty += parseInt(item["combined_inventory_data_blinkit.backend_inv_qty"], 10);
    acc[category].totals.frontendInvQty += parseInt(item["combined_inventory_data_blinkit.frontend_inv_qty"], 10);
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_blinkit.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategoryBlinkit).forEach((category) => {
    const categoryData = groupedByCategoryBlinkit[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate = categoryData.totals.dailyRunRateSum / categoryData.totals.productCount;
    categoryData.totals.inventoryDays = categoryData.totals.inventoryDaysSum / categoryData.totals.productCount;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount;
      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount;
    });
  });

  // Group data by category, subcategory, and product name
  const groupedByCategoryZepto = zeptoAggregated.reduce((acc, item) => {
    const category = item["combined_inventory_data_zepto.category"];
    const subCategory = item["combined_inventory_data_zepto.sub_category"];
    const productName = item["combined_inventory_data_zepto.sku_name"];

    // Initialize category if it doesn't exist
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if it doesn't exist
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize product name if it doesn't exist
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update the product, sub-category, and category totals
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_zepto.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_zepto.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_zepto.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategoryZepto).forEach((category) => {
    const categoryData = groupedByCategoryZepto[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate = categoryData.totals.dailyRunRateSum / categoryData.totals.productCount;
    categoryData.totals.inventoryDays = categoryData.totals.inventoryDaysSum / categoryData.totals.productCount;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount;
      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount;
    });
  });

  // Group data by category and subcategory, and calculate totals
  const groupedByCategoryInstamart = instamartAggregated.reduce((acc, item) => {
    const category = item["combined_inventory_data_instamart.category"];
    const subCategory = item["combined_inventory_data_instamart.sub_category"];
    const productName = item["combined_inventory_data_instamart.product_name"];

    // Initialize category if not present
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if not present
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize productName if not present
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update product totals
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_instamart.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_instamart.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_instamart.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategoryInstamart).forEach((category) => {
    const categoryData = groupedByCategoryInstamart[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate =
      categoryData.totals.productCount > 0 ? categoryData.totals.dailyRunRateSum / categoryData.totals.productCount : 0;

    categoryData.totals.inventoryDays =
      categoryData.totals.productCount > 0
        ? categoryData.totals.inventoryDaysSum / categoryData.totals.productCount
        : 0;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.productCount > 0
          ? subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount
          : 0;

      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.productCount > 0
          ? subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount
          : 0;
    });
  });

  console.log("groupedByCategoryBlinkit====", groupedByCategoryBlinkit);

  const topPerformProducts = blinkitAggregated
    .sort(
      (a, b) => b["combined_sales_report_blinkit.daily_run_rate"] - a["combined_sales_report_blinkit.daily_run_rate"]
    )
    .slice(0, 5); // Top 5 products

  const lowPerformProducts = blinkitAggregated
    .sort(
      (a, b) => a["combined_sales_report_blinkit.daily_run_rate"] - b["combined_sales_report_blinkit.daily_run_rate"]
    )
    .slice(0, 5); // Bottom 5 products

  let platformPrefix;
  if (platformName === "Blinkit") {
    platformPrefix = "blinkit";
  } else if (platformName === "Zepto") {
    platformPrefix = "zepto";
  } else {
    platformPrefix = "instamart";
  }

  let categoryWiseData;
  if (platformName === "Blinkit") {
    categoryWiseData = groupedByCategoryBlinkit;
  } else if (platformName === "Zepto") {
    categoryWiseData = groupedByCategoryZepto;
  } else {
    categoryWiseData = groupedByCategoryInstamart;
  }

  if (inventoryReportBlinkit.loading || inventoryReportZepto.loading || inventoryReportInstamart.loading) {
    return (
      <div className="bg-white h-[78vh] shadow-md rounded-xl">
        <h1 className="flex gap-3 items-center text-xl font-bold pb-4 border-b p-2">
          <div className="p-2 rounded-full bg-gray-200">
            <TbBuildingWarehouse />
          </div>
          Inventory Insights
        </h1>
        <div className="flex gap-4 px-4 py-6">
          <Skeleton variant="rectangular" width="100%" height="100%" />
          <Skeleton variant="rectangular" width="100%" height="100%" />
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </div>
      </div>
    );
  }

  if (!inventoryReportBlinkit.data || inventoryReportBlinkit.data.length == 0) {
    return (
      <div className="bg-white rounded-lg my-2">
        <div className="flex px-5 justify-between py-5 border-b">
          <h1 className="flex  gap-4 items-center text-lg font-medium ">
            {/* Icon for Date Wise Overview */}
            <FaChartArea />
            Inventory Insights
            <CustomTooltip
              title={"Shows total sales data for each of the last 30 days, helping you track daily sales trends."}
            >
              <PiInfoLight />
            </CustomTooltip>
          </h1>
          <div>
            <ShowSelectedDate date={selectedDate} />
          </div>
        </div>
        <div className="h-96 flex justify-center items-center">
          <NoDataFound height={200} width={200} maximum={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-[0.625rem]">
      <div>
        <div className="flex px-5 justify-between py-5 border-b">
          <h1 className="flex  gap-4 items-center text-lg font-medium ">
            {/* Icon for Date Wise Overview */}
            <FaChartArea />
            Inventory Insights
            <CustomTooltip
              title={"Shows total sales data for each of the last 30 days, helping you track daily sales trends."}
            >
              <PiInfoLight />
            </CustomTooltip>
          </h1>
          <div>
            <ShowSelectedDate date={selectedDate} />
          </div>
        </div>

        <div className="flex  gap-4 px-4 py-6 ">
          <div className="flex flex-col gap-5 flex-1">
            {/* Top Performing products */}
            <TopAndLowPerformingProductBasedOnDailyRunRate
              path={"/inventory/inventory-report"}
              data={topPerformProducts}
              heading={"Top Performing Products"}
              platformPrefix={platformPrefix}
              type={"topPerform"}
              title={"Best Performer Product"}
            />

            {/* Low Performing products */}
            <TopAndLowPerformingProductBasedOnDailyRunRate
              path={"/inventory/inventory-report"}
              data={lowPerformProducts}
              heading={"Low Performing Products"}
              platformPrefix={platformPrefix}
              type={"lowPerform"}
              title={"Low Performer Product"}
            />
          </div>

          <div className="flex-1  flex-col gap-4">
            <TopAndLowPerformingCatAndCity
              path={"/inventory/inventory-report"}
              data={categoryWiseData}
              heading={"Low Inventory Category"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryInsights;
