import React, { useEffect, useState } from "react";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import Toolbar from "../../components/Toolbar/Toolbar";
import InsightsSectionsContainer from "../../components/Insights/InsightsComponents/InsightsSectionsContainer";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import { useDispatch } from "react-redux";
import {
  getCategoryProfitabilityError,
  getCategoryProfitabilityRequest,
  getCategoryProfitabilitySuccess,
  getCityProfitabilityError,
  getCityProfitabilityRequest,
  getCityProfitabilitySuccess,
  getMonthyProfitabilityError,
  getMonthyProfitabilityRequest,
  getMonthyProfitabilitySuccess,
} from "../../redux/actions/profitabilityActions";
import {
  fetchCategoryProfitabilityDataBlinkit,
  fetchCategoryProfitabilityDataInstamart,
  fetchCategoryProfitabilityDataZepto,
  fetchCityProfitabilityDataBlinkit,
  fetchCityProfitabilityDataInstamart,
  fetchCityProfitabilityDataZepto,
  fetchMonthlyProfitabilityDataBlinkit,
  fetchMonthlyProfitabilityDataInstamart,
  fetchMonthlyProfitabilityDataZepto,
} from "../../services/profitability/overallProfitabilityServices";
import {
  getAllSalesFailure,
  getAllSalesRequest,
  getAllSalesSuccess,
  inventoryReportBlinkitFailure,
  inventoryReportBlinkitRequest,
  inventoryReportBlinkitSuccess,
  inventoryReportInstamartFailure,
  inventoryReportInstamartRequest,
  inventoryReportInstamartSuccess,
  inventoryReportZeptoFailure,
  inventoryReportZeptoRequest,
  inventoryReportZeptoSuccess,
} from "../../redux/actions/salesActions";
import {
  fetchInventoryReportBlinkitData,
  fetchInventoryReportInstamartData,
  fetchInventoryReportZeptoData,
} from "../../services/inventoryServices/inventoryReportServices";
import { useDateRange } from "../../hooks/CustomDateRange/CustomDateRangePickerProvider";
import {
  fetchBlinkitData,
  fetchInstamartData,
  fetchZeptoData,
} from "../../services/revenueServices/salesReportServices";
import {
  fetchCityInsightsDataBlinkit,
  fetchCityInsightsDataInstamart,
  fetchCityInsightsDataZepto,
  fetchMonthlyInsightsDataBlinkit,
  fetchMonthlyInsightsDataInstamart,
  fetchMonthlyInsightsDataZepto,
  fetchProductInsightsDataBlinkit,
  fetchProductInsightsDataPreviousBlinkit,
  fetchProductInsightsDataPreviousZepto,
  fetchProductInsightsDataZepto,
  fetchProductsInsightsDataInstamart,
  fetchProductsInsightsDataPreviousInstamart,
} from "../../services/insights/InsightsServices";

import {
  getInsightsCityError,
  getInsightsCityRequest,
  getInsightsCitySuccess,
  getInsightsError,
  getInsightsProductError,
  getInsightsProductPreviousError,
  getInsightsProductPreviousRequest,
  getInsightsProductPreviousSuccess,
  getInsightsProductRequest,
  getInsightsProductSuccess,
  getInsightsRequest,
  getInsightsSuccess,
} from "../../redux/actions/insightsActions";

import {
  getAllMarketingReportBlinkitBannerListingFailure,
  getAllMarketingReportBlinkitBannerListingRequest,
  getAllMarketingReportBlinkitBannerListingSuccess,
  getAllMarketingReportBlinkitProductListingFailure,
  getAllMarketingReportBlinkitProductListingRequest,
  getAllMarketingReportBlinkitProductListingSuccess,
  getAllMarketingReportBlinkitProductRecommandationFailure,
  getAllMarketingReportBlinkitProductRecommandationRequest,
  getAllMarketingReportBlinkitProductRecommandationSuccess,
  getAllMarketingReportBlinkitSearchSuggestionFailure,
  getAllMarketingReportBlinkitSearchSuggestionRequest,
  getAllMarketingReportBlinkitSearchSuggestionSuccess,
  getAllMarketingReportInstamartFailure,
  getAllMarketingReportInstamartRequest,
  getAllMarketingReportInstamartSuccess,
  getAllMarketingReportZeptoSkuAnalyticsFailure,
  getAllMarketingReportZeptoSkuAnalyticsRequest,
  getAllMarketingReportZeptoSkuAnalyticsSuccess,
  getAllMarketingReportZeptoSkuPerformanceFailure,
  getAllMarketingReportZeptoSkuPerformanceRequest,
  getAllMarketingReportZeptoSkuPerformanceSuccess,
} from "../../redux/actions/marketingActions";
import {
  fetchPerformanceMarketingAdsCampaignPerformanceDataZepto,
  fetchPerformanceMarketingAdsMarketingReportDataInstamart,
  fetchPerformanceMarketingAdsProductListingDataBlinkit,
  fetchPerformanceMarketingAdsProductRecommendationDataBlinkit,
  fetchPerformanceMarketingAdsSkuPerformanceDataZepto,
} from "../../services/adsServices/performanceMarketingAdsServices";
import {
  fetchDisplayAdsBannerListingDataBlinkit,
  fetchDisplayAdsSearchSuggestionDataBlinkit,
} from "../../services/adsServices/displayAdsServices";
import {
  getBlinkitFulfillmentFailure,
  getBlinkitFulfillmentRequest,
  getBlinkitFulfillmentSuccess,
  getInstamartFulfillmentFailure,
  getInstamartFulfillmentRequest,
  getInstamartFulfillmentSuccess,
  getZeptoFulfillmentFailure,
  getZeptoFulfillmentRequest,
  getZeptoFulfillmentSuccess,
} from "../../redux/actions/fulfillmentActions";
import {
  fetchFulfillmentReportBlinkitData,
  fetchFulfillmentReportInstamartData,
  fetchFulfillmentReportZeptoData,
} from "../../services/fulfillmentServices/fulfillmentReportServices";
const options = ["Insights"];

const InsightsScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const { selectedDate } = useDate();
  const { selectedDateRange } = useDateRange();
  const dispatch = useDispatch();

  // Object mapping platform names to their respective data fetch functions
  const salesDataFetchFunctions = {
    Blinkit: fetchBlinkitData,
    Instamart: fetchInstamartData,
    Zepto: fetchZeptoData,
  };

  const handleBlinkitQueries = async () => {
    try {
      dispatch(inventoryReportBlinkitRequest());
      const result = await fetchInventoryReportBlinkitData(selectedDate);
      dispatch(inventoryReportBlinkitSuccess(result));
    } catch (error) {
      dispatch(inventoryReportBlinkitFailure(error));
    }

    // Product Listing
    try {
      dispatch(getAllMarketingReportBlinkitProductListingRequest());
      const productListingResult = await fetchPerformanceMarketingAdsProductListingDataBlinkit(selectedDate);
      dispatch(getAllMarketingReportBlinkitProductListingSuccess(productListingResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitProductListingFailure(error));
    }

    // Product Recommendation
    try {
      dispatch(getAllMarketingReportBlinkitProductRecommandationRequest());
      const productRecommendationResult = await fetchPerformanceMarketingAdsProductRecommendationDataBlinkit(
        selectedDate
      );
      dispatch(getAllMarketingReportBlinkitProductRecommandationSuccess(productRecommendationResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitProductRecommandationFailure(error));
    }

    // Blinkit Search Suggestion
    try {
      dispatch(getAllMarketingReportBlinkitSearchSuggestionRequest());
      const searchSuggestionResult = await fetchDisplayAdsSearchSuggestionDataBlinkit(selectedDate);
      dispatch(getAllMarketingReportBlinkitSearchSuggestionSuccess(searchSuggestionResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitSearchSuggestionFailure(error));
    }

    // Blinkit Banner Listing
    try {
      dispatch(getAllMarketingReportBlinkitBannerListingRequest());
      const bannerListingResult = await fetchDisplayAdsBannerListingDataBlinkit(selectedDate);
      dispatch(getAllMarketingReportBlinkitBannerListingSuccess(bannerListingResult));
    } catch (error) {
      dispatch(getAllMarketingReportBlinkitBannerListingFailure(error));
    }

    // Blinkit Fulfillment Report
    try {
      dispatch(getBlinkitFulfillmentRequest());
      const result = await fetchFulfillmentReportBlinkitData(selectedDate);
      dispatch(getBlinkitFulfillmentSuccess(result));
    } catch (error) {
      dispatch(getBlinkitFulfillmentFailure(error));
    }

    // Inventory Report Blinkit
    try {
      dispatch(inventoryReportBlinkitRequest());
      const result = await fetchInventoryReportBlinkitData(selectedDate);
      dispatch(inventoryReportBlinkitSuccess(result));
    } catch (error) {
      dispatch(inventoryReportBlinkitFailure(error));
    }
  };

  const handleZeptoQueries = async () => {
    try {
      dispatch(inventoryReportZeptoRequest());
      const result = await fetchInventoryReportZeptoData(selectedDate);
      dispatch(inventoryReportZeptoSuccess(result));
    } catch (error) {
      dispatch(inventoryReportZeptoFailure(error));
    }

    // SKU Performance
    try {
      dispatch(getAllMarketingReportZeptoSkuPerformanceRequest());
      const skuPerformanceResult = await fetchPerformanceMarketingAdsSkuPerformanceDataZepto(selectedDate);
      dispatch(getAllMarketingReportZeptoSkuPerformanceSuccess(skuPerformanceResult));
    } catch (error) {
      dispatch(getAllMarketingReportZeptoSkuPerformanceFailure(error));
    }

    // Campaign Performance
    try {
      dispatch(getAllMarketingReportZeptoSkuAnalyticsRequest());
      const campaignPerformanceResult = await fetchPerformanceMarketingAdsCampaignPerformanceDataZepto(selectedDate);
      dispatch(getAllMarketingReportZeptoSkuAnalyticsSuccess(campaignPerformanceResult));
    } catch (error) {
      dispatch(getAllMarketingReportZeptoSkuAnalyticsFailure(error));
    }

    // Zepto Fulfillment Report
    try {
      dispatch(getZeptoFulfillmentRequest());
      const result = await fetchFulfillmentReportZeptoData(selectedDate);
      dispatch(getZeptoFulfillmentSuccess(result));
    } catch (error) {
      dispatch(getZeptoFulfillmentFailure(error));
    }

    //  Zepto Inventory Report
    try {
      dispatch(inventoryReportZeptoRequest());
      const result = await fetchInventoryReportZeptoData(selectedDate);
      dispatch(inventoryReportZeptoSuccess(result));
    } catch (error) {
      dispatch(inventoryReportZeptoFailure(error));
    }
  };

  const handleInstamartQueries = async () => {
    try {
      dispatch(inventoryReportInstamartRequest());
      const result = await fetchInventoryReportInstamartData(selectedDate);
      dispatch(inventoryReportInstamartSuccess(result));
    } catch (error) {
      dispatch(inventoryReportInstamartFailure(error));
    }

    // marketing report
    try {
      dispatch(getAllMarketingReportInstamartRequest());
      console.log("i am called===");
      const result = await fetchPerformanceMarketingAdsMarketingReportDataInstamart(selectedDate);
      dispatch(getAllMarketingReportInstamartSuccess(result));
    } catch (error) {
      dispatch(getAllMarketingReportInstamartFailure(error));
    }

    // Instamart Fulfillment Report
    try {
      dispatch(getInstamartFulfillmentRequest());
      const result = await fetchFulfillmentReportInstamartData(selectedDate);
      dispatch(getInstamartFulfillmentSuccess(result));
    } catch (error) {
      dispatch(getInstamartFulfillmentFailure(error));
    }

    // Instamart Inventory Report
    try {
      dispatch(inventoryReportInstamartRequest());
      const result = await fetchInventoryReportInstamartData(selectedDate);
      dispatch(inventoryReportInstamartSuccess(result));
    } catch (error) {
      dispatch(inventoryReportInstamartFailure(error));
    }
  };

  useEffect(() => {
    // Dispatch the request action based on the platform name
    if (selectedOption == 0) {
      dispatch(getInsightsRequest());
      dispatch(getInsightsCityRequest());
      dispatch(getInsightsProductRequest());
      dispatch(getInsightsProductPreviousRequest());
      dispatch(getMonthyProfitabilityRequest());
      dispatch(getCityProfitabilityRequest());
      dispatch(getCategoryProfitabilityRequest());
    }
    // Function to fetch monthly profitability data based on platform
    const fetchInsights = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchMonthlyInsightsDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchMonthlyInsightsDataZepto(selectedDate);
        } else {
          result = await fetchMonthlyInsightsDataInstamart(selectedDate);
        }

        dispatch(getInsightsSuccess(result));
      } catch (error) {
        dispatch(getInsightsError(error));
      }

      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchCityInsightsDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchCityInsightsDataZepto(selectedDate);
        } else {
          result = await fetchCityInsightsDataInstamart(selectedDate);
        }

        dispatch(getInsightsCitySuccess(result));
      } catch (error) {
        dispatch(getInsightsCityError(error));
      }

      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchProductInsightsDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchProductInsightsDataZepto(selectedDate);
        } else {
          result = await fetchProductsInsightsDataInstamart(selectedDate);
        }

        dispatch(getInsightsProductSuccess(result));
      } catch (error) {
        dispatch(getInsightsProductError(error));
      }

      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchProductInsightsDataPreviousBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchProductInsightsDataPreviousZepto(selectedDate);
        } else {
          result = await fetchProductsInsightsDataPreviousInstamart(selectedDate);
        }

        dispatch(getInsightsProductPreviousSuccess(result));
      } catch (error) {
        dispatch(getInsightsProductPreviousError(error));
      }
    };
    fetchInsights();

    // Function to fetch monthly profitability data based on platform
    const fetchDataMonthly = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchMonthlyProfitabilityDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchMonthlyProfitabilityDataZepto(selectedDate);
        } else {
          result = await fetchMonthlyProfitabilityDataInstamart(selectedDate);
        }

        dispatch(getMonthyProfitabilitySuccess(result));
      } catch (error) {
        dispatch(getMonthyProfitabilityError(error));
      }
    };

    // Function to fetch city profitability data based on platform
    const fetchCityData = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchCityProfitabilityDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchCityProfitabilityDataZepto(selectedDate);
        } else {
          result = await fetchCityProfitabilityDataInstamart(selectedDate);
        }

        dispatch(getCityProfitabilitySuccess(result));
      } catch (error) {
        dispatch(getCityProfitabilityError(error));
      }
    };

    // Function to fetch category profitability data based on platform
    const fetchCategoryData = async () => {
      try {
        let result;
        if (platformName == "Blinkit") {
          result = await fetchCategoryProfitabilityDataBlinkit(selectedDate);
        } else if (platformName == "Zepto") {
          result = await fetchCategoryProfitabilityDataZepto(selectedDate);
        } else {
          result = await fetchCategoryProfitabilityDataInstamart(selectedDate);
        }

        console.log("Result 230====", result);

        dispatch(getCategoryProfitabilitySuccess(result));
      } catch (error) {
        dispatch(getCategoryProfitabilityError(error));
      }
    };

    const fetchSalesData = async () => {
      try {
        dispatch(getAllSalesRequest());
        const result = await salesDataFetchFunctions[platformName](selectedDateRange);
        dispatch(getAllSalesSuccess(result));
      } catch (error) {
        dispatch(getAllSalesFailure(error));
      }
    };

    fetchSalesData();
    fetchDataMonthly();
    fetchCityData();
    fetchCategoryData();

    if (platformName === "Blinkit") {
      handleBlinkitQueries();
    } else if (platformName === "Instamart") {
      handleInstamartQueries();
    } else {
      handleZeptoQueries();
    }
  }, [platformName, selectedOption, selectedDate]);

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);
  return (
    <>
      <HeadingBar setPlatformName={setPlatformName} selectedDate={selectedDate} platformName={platformName} />
      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption == 0 && <InsightsSectionsContainer platformName={platformName} selectedDate={selectedDate} />}
      </Toolbar>
    </>
  );
};

export default InsightsScreen;
