import {
  pslBlinkitFullfillmentReportQuery,
  pslZeptoFulfillmentQuery,
  pslInstamartFulfillmentQuery,
} from "../../utils/queries/pslFulfillmentReportQueries.js";
import {
  potentialSalesLossBlinkitQuery,
  potentialSalesLossZeptoQuery,
  potentialSalesLossInstamartQuery,
} from "../../utils/queries/potentialSalesLossQuery.js";
import { getCubeJsApiInstance } from "../../utils/apiConfig";

// Function to fetch potential sales loss data for Blinkit
export const fetchPotentialSalesLossBlinkit = async (selectedDate) => {
  console.log("selectedDate potential  query=====", selectedDate)
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(potentialSalesLossBlinkitQuery(selectedDate));
  return result.tablePivot(); // Return the pivoted data from Cube.js
};

// Function to fetch potential sales loss data for Zepto
export const fetchPotentialSalesLossZepto = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(potentialSalesLossZeptoQuery(selectedDate));
  return result.tablePivot(); // Return the pivoted data from Cube.js
};

// Function to fetch potential sales loss data for Instamart
export const fetchPotentialSalesLossInstamart = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(potentialSalesLossInstamartQuery(selectedDate));
  return result.tablePivot(); // Return the pivoted data from Cube.js
};

// Function to fetch fulfillment data for Blinkit
export const fetchPslBlinkitFulfillment = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(pslBlinkitFullfillmentReportQuery(selectedDate));
  return result.tablePivot(); // Return the pivoted data from Cube.js
};

// Function to fetch fulfillment data for Zepto
export const fetchPslZeptoFulfillment = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(pslZeptoFulfillmentQuery(selectedDate));
  return result.tablePivot(); // Return the pivoted data from Cube.js
};

// Function to fetch fulfillment data for Instamart
export const fetchPslInstamartFulfillment = async (selectedDate) => {
  const cubejsApi = getCubeJsApiInstance();
  const result = await cubejsApi.load(pslInstamartFulfillmentQuery(selectedDate));
  return result.tablePivot(); // Return the pivoted data from Cube.js
};
