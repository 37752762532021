export const groupDatabyCampaignName = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const key =
      item["marketting_report_zepto_awareness_performance.campaign_name"];

    if (!acc[key]) {
      acc[key] = {
        title:
          item["marketting_report_zepto_awareness_performance.campaign_name"],
        ccDate: item["marketting_report_zepto_awareness_performance.ccd_date"],
        revenue: 0,
        page_type: [],
        roi: 0,
        status: new Set(),
        spend: 0,
        c_daily_budget: 0,
        impressions: 0,
        clicks: 0,
        isCampaign: true,
      };
    }

    // Aggregate the fields
    acc[key].revenue +=
      parseFloat(
        item["marketting_report_zepto_awareness_performance.revenue"]
      ) || 0;
    acc[key].page_type.push(
      item["marketting_report_zepto_awareness_performance.page_type"]
    );
    acc[key].roi +=
      parseFloat(item["marketting_report_zepto_awareness_performance.roi"]) ||
      0;
    acc[key].status.add(
      item["marketting_report_zepto_awareness_performance.status"]
    );
    acc[key].spend +=
      parseFloat(item["marketting_report_zepto_awareness_performance.spend"]) ||
      0;
    acc[key].c_daily_budget +=
      parseFloat(
        item["marketting_report_zepto_awareness_performance.c_daily_budget"]
      ) || 0;
    acc[key].impressions +=
      parseInt(
        item["marketting_report_zepto_awareness_performance.impressions"],
        10
      ) || 0;
    acc[key].clicks +=
      parseInt(
        item["marketting_report_zepto_awareness_performance.clicks"],
        10
      ) || 0;

    return acc;
  }, {});
  return groupedData;
};

//grouping by date awareness performance

export const groupByCCDDate = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const reportDate =
      item["marketting_report_zepto_awareness_performance.date_of_report"];

    if (!acc[reportDate]) {
      acc[reportDate] = {
        ccDate: item["marketting_report_zepto_awareness_performance.ccd_date"],
        title: new Set(),
        revenue: 0,
        tenant_id:
          item["marketting_report_zepto_awareness_performance.tenant_id"],
        page_type: [],
        camp_end_date: new Set(),
        roi: 0,
        status: new Set(),
        spend: 0,
        c_daily_budget: 0,
        impressions: 0,
        clicks: 0,
        current_date: new Set(),
      };
    }

    // Aggregate the fields
    acc[reportDate].title.add(
      item["marketting_report_zepto_awareness_performance.campaign_name"]
    );
    acc[reportDate].revenue +=
      parseFloat(
        item["marketting_report_zepto_awareness_performance.revenue"]
      ) || 0;
    acc[reportDate].page_type.push(
      item["marketting_report_zepto_awareness_performance.page_type"]
    );
    acc[reportDate].camp_end_date.add(
      item["marketting_report_zepto_awareness_performance.camp_end_date"]
    );
    acc[reportDate].roi +=
      parseFloat(item["marketting_report_zepto_awareness_performance.roi"]) ||
      0;
    acc[reportDate].status.add(
      item["marketting_report_zepto_awareness_performance.status"]
    );
    acc[reportDate].spend +=
      parseFloat(item["marketting_report_zepto_awareness_performance.spend"]) ||
      0;
    acc[reportDate].c_daily_budget += parseFloat(
      item["marketting_report_zepto_awareness_performance.c_daily_budget"]
    );
    acc[reportDate].impressions +=
      parseInt(
        item["marketting_report_zepto_awareness_performance.impressions"],
        10
      ) || 0;
    acc[reportDate].clicks +=
      parseInt(
        item["marketting_report_zepto_awareness_performance.clicks"],
        10
      ) || 0;
    acc[reportDate].current_date.add(
      item["marketting_report_zepto_awareness_performance.current_date"]
    );

    return acc;
  }, {});
  return groupedData;
};

export function groupByCampaignAwarenessTable(data) {
  const grouped = {};

  data.forEach((item) => {
    const campaignName =
      item["marketting_report_zepto_awareness_performance.campaign_name"];

    // Initialize the campaign array if it doesn't exist
    if (!grouped[campaignName]) {
      grouped[campaignName] = [];
    }

    // Create a key-value pair from the item
    const keyValue = {
      CampaignCreationDate:
        item["marketting_report_zepto_awareness_performance.ccd_date"],
      campaignEndDate:
        item["marketting_report_zepto_awareness_performance.camp_end_date"],
      revenue: item["marketting_report_zepto_awareness_performance.revenue"],
      pageType: item["marketting_report_zepto_awareness_performance.page_type"],
      roi: item["marketting_report_zepto_awareness_performance.roi"],
      status: item["marketting_report_zepto_awareness_performance.status"],
      spend: item["marketting_report_zepto_awareness_performance.spend"],
      currentDailyBudget:
        item["marketting_report_zepto_awareness_performance.c_daily_budget"],
      impressions:
        item["marketting_report_zepto_awareness_performance.impressions"],
      clicks: item["marketting_report_zepto_awareness_performance.clicks"],
    };

    // Push the key-value pair into the corresponding campaign array
    grouped[campaignName].push(keyValue);
  });

  return grouped;
}
