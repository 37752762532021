import dayjs from "dayjs";

// Format selectedDate without the timezone
const formatDateWithoutTimezone = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
};

const getSingleDayDateRange = (date) => {
  const startOfDay = dayjs(date).startOf("day");
  const endOfDay = dayjs(date).endOf("day");
  const formattedStartOfDay = formatDateWithoutTimezone(startOfDay);
  const formattedEndOfDay = formatDateWithoutTimezone(endOfDay);
  return { start: formattedStartOfDay, end: formattedEndOfDay };
};

export const blinkitFullfillmentReportQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "fulfillment_report_blinkit.landing_rate",
      "fulfillment_report_blinkit.cgst_value",
      "fulfillment_report_blinkit.expiry_date",
      "fulfillment_report_blinkit.mrp",
      "fulfillment_report_blinkit.cess_value",
      "fulfillment_report_blinkit.cost_price",
      "fulfillment_report_blinkit.item_id",
      "fulfillment_report_blinkit.facility_name",
      "fulfillment_report_blinkit.sgst_value",
      "fulfillment_report_blinkit.name",
      "fulfillment_report_blinkit.tax_value",
      "fulfillment_report_blinkit.upc",
      "fulfillment_report_blinkit.igst_value",
      "fulfillment_report_blinkit.margin_percentage",
      "fulfillment_report_blinkit.tenant_id",
      "fulfillment_report_blinkit.bucket_type",
      "fulfillment_report_blinkit.total_amount",
      "fulfillment_report_blinkit.order_date",
      "fulfillment_report_blinkit.appointment_date",
      "fulfillment_report_blinkit.po_number",
      "fulfillment_report_blinkit.po_state",
      "fulfillment_report_blinkit.units_ordered",
    ],
    order: {
      "fulfillment_report_blinkit.order_date": "desc",
    },
    timeDimensions: [
      {
        dimension: "fulfillment_report_blinkit.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const zeptoFulfillmentQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "fulfillment_report_zepto.manufacturer_id",
      "fulfillment_report_zepto.sku_level_fill_rate",
      "fulfillment_report_zepto.category_name",
      "fulfillment_report_zepto.grn_date",
      "fulfillment_report_zepto.sku_name",
      "fulfillment_report_zepto.packsize",
      "fulfillment_report_zepto.city",
      "fulfillment_report_zepto.unit_of_measure",
      "fulfillment_report_zepto.po_quantity",
      "fulfillment_report_zepto.brand_name",
      "fulfillment_report_zepto.sub_category_name",
      "fulfillment_report_zepto.manufacturer_name",
      "fulfillment_report_zepto.po_date",
      "fulfillment_report_zepto.vendor_name",
      "fulfillment_report_zepto.vendor_id",
      "fulfillment_report_zepto.grn_quantity",
      "fulfillment_report_zepto.sku_id",
      "fulfillment_report_zepto.po_code",
      "fulfillment_report_zepto.warehouse_name",
    ],
    order: {
      "fulfillment_report_zepto.city": "asc",
    },
    timeDimensions: [
      {
        dimension: "fulfillment_report_zepto.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};

export const instamartFulfillmentQuery = (selectedDate) => {
  const { start, end } = getSingleDayDateRange(selectedDate);
  return {
    dimensions: [
      "fulfillment_report_instamart.brand",
      "fulfillment_report_instamart.skuname",
      "fulfillment_report_instamart.total_grn_qty",
      "fulfillment_report_instamart.supplier_name",
      "fulfillment_report_instamart.qty_delivered_after_leadtime",
      "fulfillment_report_instamart.business_unit",
      "fulfillment_report_instamart.expirydate",
      "fulfillment_report_instamart.whname",
      "fulfillment_report_instamart.city",
      "fulfillment_report_instamart.max_last_grn_date",
      "fulfillment_report_instamart.last_inward_date",
      "fulfillment_report_instamart.otif",
      "fulfillment_report_instamart.flag",
      "fulfillment_report_instamart.externpocode",
      "fulfillment_report_instamart.l1",
      "fulfillment_report_instamart.postatus",
      "fulfillment_report_instamart.catpoc",
      "fulfillment_report_instamart.qty_delivered_in_leadtime",
      "fulfillment_report_instamart.poqty",
      "fulfillment_report_instamart.buying_type_str",
      "fulfillment_report_instamart.lead_time",
      "fulfillment_report_instamart.sku",
      "fulfillment_report_instamart.pocode",
      "fulfillment_report_instamart.udf1",
      "fulfillment_report_instamart.podate",
      "fulfillment_report_instamart.l2",
      "fulfillment_report_instamart.vendorcode",
      "fulfillment_report_instamart.lfr",
      "fulfillment_report_instamart.company",
    ],
    order: {
      "fulfillment_report_instamart.podate": "asc",
    },
    timeDimensions: [
      {
        dimension: "fulfillment_report_instamart.normalized_date",
        dateRange: [start, end],
      },
    ],
  };
};