import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Papa from "papaparse";

const OutOfStockTableBlinkit = ({ platformName }) => {
  const { stockingInsightBlinkit } = useSelector((state) => state.stocking);
  // Assuming stockingInsightBlinkit has a structure with loading, error, and data properties
  const { loading, data } = stockingInsightBlinkit;

  const dataWithTotalInventory = data.map((item) => ({
    ...item,
    totalInventory:
      parseInt(item["combined_inventory_data_blinkit.frontend_inv_qty"], 10) +
      parseInt(item["combined_inventory_data_blinkit.backend_inv_qty"], 10),
  }));

  const groupedData = {};
  dataWithTotalInventory.forEach((item) => {
    const key = `${item["combined_inventory_data_blinkit.backend_facility_name"]}_${item["combined_inventory_data_blinkit.brand_internal_sku_code"]}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(item);
  });

  const newModifiedData = [];
  Object.keys(groupedData).forEach((key) => {
    const group = groupedData[key];

    group.sort(
      (a, b) =>
        new Date(a["combined_inventory_data_blinkit.created_at"]) -
        new Date(b["combined_inventory_data_blinkit.created_at"])
    );

    const latestEntry = group[group.length - 1];

    let dailyRunRate = parseFloat(latestEntry["combined_sales_report_blinkit.daily_run_rate"]) || 0;

    let inventoryDays = 0;
    if (dailyRunRate > 0) {
      inventoryDays = latestEntry.totalInventory / dailyRunRate;
    }

    newModifiedData.push({
      ...latestEntry,
      dailyRunRate,
      inventoryDays: isNaN(inventoryDays) ? 0 : inventoryDays,
    });
  });

  const filteredData = newModifiedData.filter((item) => item.inventoryDays === 0);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("item_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({
    item_id: "",
    city: "",
    item_name: "",
  });

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const filteredAndSortedData = filteredData
    .filter((item) =>
      (item["combined_inventory_data_blinkit.item_id"] || "").toLowerCase().includes(filter.item_id.toLowerCase())
    )
    .filter((item) =>
      (item["combined_inventory_data_blinkit.brands_choice"] || "").toLowerCase().includes(filter.city.toLowerCase())
    )
    .filter((item) =>
      (item["combined_inventory_data_blinkit.item_name"] || "").toLowerCase().includes(filter.item_name.toLowerCase())
    )
    .sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  console.log("filteredAndSortedData===", filteredAndSortedData);
  const paginatedData = filteredAndSortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  //   {
  //     "combined_inventory_data_blinkit.created_at": "2024-09-17",
  //     "combined_inventory_data_blinkit.primary_key": "1515",
  //     "combined_inventory_data_blinkit.item_id": "10129832",
  //     "combined_inventory_data_blinkit.backend_facility_name": "Super Store Dasna 2 - Warehouse",
  //     "combined_inventory_data_blinkit.backend_facility_id": "92",
  //     "combined_inventory_data_blinkit.frontend_inv_qty": "0",
  //     "combined_inventory_data_blinkit.backend_inv_qty": "0",
  //     "combined_inventory_data_blinkit.item_name": "Anveshan Wood Cold Pressed Coconut Oil(Plastic Bottle) 500 ml - Rs 360",
  //     "combined_inventory_data_blinkit.brands_choice": "NCR",
  //     "combined_inventory_data_blinkit.category": "Oils",
  //     "combined_inventory_data_blinkit.sub_category": "Coconut Oil",
  //     "combined_inventory_data_blinkit.brand_internal_sku_code": "FPOP-OILL-COCO-PETT-500M",
  //     "combined_sales_report_blinkit.daily_run_rate": "27.7333333333333333",
  //     "totalInventory": 0,
  //     "dailyRunRate": 27.733333333333334,
  //     "inventoryDays": 0
  // }

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = filteredAndSortedData.map((entry) => ({
      "Blinkit SKU Name": entry["combined_inventory_data_blinkit.item_name"],
      City: entry["combined_inventory_data_blinkit.brands_choice"],
      "Facility Name": entry["combined_inventory_data_blinkit.backend_facility_name"],
      "Item Id": entry["combined_inventory_data_blinkit.item_id"],
      "Total Inventory": entry.totalInventory,
      "Daily Run Rate": entry.dailyRunRate?.toFixed(2),
      "Inventory Days": entry.inventoryDays?.toFixed(0),
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `currently_out_of_stock_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Conditional Rendering Based on State
  if (loading) {
    return <TableSkeleton />;
  }

  if (data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="w-full">
      <div className="mb-4 h-[42px] min-w-32 flex justify-end">
        <span
          onClick={handleExportCSV}
          className=" p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
        >
          <span className="font-body font-normal text-sm">Export to CSV</span>
          <img width={16} height={16} src="/icons/download-circular-icon.svg" />
        </span>
      </div>

      <Paper
        sx={{
          width: "100%",
          mb: "2rem",
        }}
      >
        <>
          <div className="border-b p-4 text-lg font-medium flex gap-4 items-center">
            <img src="/logos/inventory-page-icon.svg" alt="stocking" />
            Currently OOS
          </div>
          <div className="p-4">
            <div className="border rounded p-2">
              <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
                <Table
                  stickyHeader
                  className="table-auto border min-w-full"
                  aria-label="sticky table"
                  sx={{ overflowX: "auto" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          color: "#4D4D4D",
                          textAlign: "left",
                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          minWidth: "200px",
                          position: "sticky",
                          left: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "item_name"}
                          direction={orderBy === "item_name" ? order : "asc"}
                          onClick={() => handleRequestSort("item_name")}
                        >
                          Blinkit SKU Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "city"}
                          direction={orderBy === "city" ? order : "asc"}
                          onClick={() => handleRequestSort("city")}
                        >
                          City
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "city"}
                          direction={orderBy === "city" ? order : "asc"}
                          onClick={() => handleRequestSort("city")}
                        >
                          Facility Name
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "item_id"}
                          direction={orderBy === "item_id" ? order : "asc"}
                          onClick={() => handleRequestSort("item_id")}
                        >
                          Item ID
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "totalInventory"}
                          direction={orderBy === "totalInventory" ? order : "asc"}
                          onClick={() => handleRequestSort("totalInventory")}
                        >
                          Total Inventory
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "dailyRunRate"}
                          direction={orderBy === "dailyRunRate" ? order : "asc"}
                          onClick={() => handleRequestSort("dailyRunRate")}
                        >
                          Daily Run Rate
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          minWidth: "200px",
                          bgcolor: "#F9F9F9",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "inventoryDays"}
                          direction={orderBy === "inventoryDays" ? order : "asc"}
                          onClick={() => handleRequestSort("inventoryDays")}
                        >
                          Inventory Days
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: "3",
                          position: "sticky",
                          right: 0,
                          bgcolor: "#F9F9F9",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((row, index) => (
                        <TableRow key={index} className={` ${index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-[#fff]"}`}>
                          <TableCell
                            sx={{
                              color: "#4d4d4d",
                              boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              minWidth: "300px",
                              position: "sticky",
                              left: 0,
                              bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                          >
                            <div className="text-left">{row["combined_inventory_data_blinkit.item_name"]}</div>
                          </TableCell>
                          <TableCell>
                            <div className="text-center">{row["combined_inventory_data_blinkit.brands_choice"]}</div>
                          </TableCell>

                          <TableCell>
                            <div className="text-center">
                              {row["combined_inventory_data_blinkit.backend_facility_name"]}
                            </div>
                          </TableCell>

                          <TableCell>
                            <div className="text-center">{row["combined_inventory_data_blinkit.item_id"]}</div>
                          </TableCell>
                          <TableCell>
                            <div className="text-center">{row.totalInventory.toFixed(2)}</div>
                          </TableCell>
                          <TableCell>
                            <div className="text-center">{row.dailyRunRate.toFixed(2)}</div>
                          </TableCell>
                          <TableCell>
                            <div className="text-center">{row.inventoryDays.toFixed(2)}</div>
                          </TableCell>
                          <TableCell
                            sx={{
                              boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                              zIndex: "3",
                              position: "sticky",
                              right: 0,
                              bgcolor: index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            }}
                          ></TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center text-[0.75rem] text-[#727278]">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25]}
            component="div"
            count={filteredAndSortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    </div>
  );
};

export default OutOfStockTableBlinkit;
