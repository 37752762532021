import React from "react";
import { FaListUl } from "react-icons/fa";
import { GoInfo } from "react-icons/go";

const CategoryRcaHeading = () => {
  return (
    <div class="w-40">
      <div className="bg-gray-100 flex items-center justify-center p-3 rounded-lg mb-2">
        <FaListUl />
      </div>
      {/* <!-- Offtake Card --> */}
      <div class="flex items-center justify-between bg-gray-100  p-3 py-8 rounded-lg shadow relative mb-2">
        <div class="font-semibold flex items-center justify-center w-full">Offtake</div>
        <GoInfo className="absolute top-1 right-1" />
      </div>

      {/* <!-- Estimated Category Share Card --> */}
      <div class="flex items-center justify-between px-4 bg-gray-100 rounded-lg shadow relative mb-4">
        <span class="flex items-center justify-center space-x-2 px-6">
          <span class="font-semibold text-center">Est. Category Share</span>
        </span>
        <GoInfo className="absolute top-1 right-1" />
      </div>

      {/* <!-- Components Label --> */}
      <div className="relative bg-gray-100 rounded-lg pt-1">
        <div class="text-xs font-semibold text-purple-500 bg-purple-50 absolute border -top-2 left-[20%] flex items-center gap-1 rounded-lg p-[2px]">
          Components
          <GoInfo />
        </div>

        {/* <!-- Indexed Impressions Card --> */}
        <div class="p-2 flex justify-center items-center">
          <span class="font-semibold text-center">Indexed Impressions</span>
        </div>

        {/* <!-- Indexed Conversion Card --> */}
        <div class="p-2 flex justify-center items-center">
          <span class="font-semibold text-center">Indexed Conversion</span>
        </div>
        {/* 
<!-- ASP Card --> */}
        <div class="p-2 flex justify-center items-center">
          <span class="font-semibold text-center">ASP</span>
        </div>
      </div>
    </div>
  );
};

export default CategoryRcaHeading;