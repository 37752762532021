import React, { useState } from "react";

const Dropdown = ({ options, value, onChange, initalTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false); // close dropdown after selecting
  };

  return (
    <div className="relative inline-block w-auto min-w-32 h-[42px]">
      {/* Trigger Button */}
      <button
        className="w-full p-3  shadow rounded bg-white text-[#666666] flex gap-3 justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-body font-medium text-sm ">{value || initalTitle}</span>
        <img
          width={10}
          height={10}
          src="/icons/dropdownArrowIcon.svg"
          className={`mr-1 transition-transform duration-300 ${isOpen ? "rotate-180" : "rotate-0"}`}
        />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul className="absolute z-20 w-full mt-1 bg-white border rounded shadow-lg max-h-56 overflow-y-auto">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className={`text-sm font-body px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                value === option ? "text-[#387FF5]" : "text-gray-700"
              }`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
