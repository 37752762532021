import React from "react";
import { MdChevronRight } from "react-icons/md";
import { TbBulbFilled } from "react-icons/tb";
import { TbBrandStackshare } from "react-icons/tb";

const CategoryRcaStatsCard = ({ info = {}, selectedSKU, handleSkuChange, code }) => {
  const { value = { heading: "", totalGMV: 0, totalQty: 0 } } = info;
  return (
    <div className={`rounded-lg min-w-60 max-w-60`} onClick={handleSkuChange}>
      {/* Top section with "All" and metrics */}
      <div
        className={`p-1 bg-gray-100 rounded-lg border ${
          selectedSKU == code && "border-navBarColour border-2 bg-[#dae0ea]"
        }`}
      >
        <div
          className={`text-black text-sm font-bold rounded-lg p-2 mb-1 ${
            selectedSKU == code && "bg-navBarColour text-white"
          }`}
        >
          {value.heading}
        </div>

        <div>
          {/* Metric 1 */}
          <div className="border pb-2 bg-white rounded-lg p-2">
            <h3 className="text-lg font-semibold">{value?.totalGMV || "0"}</h3>
            <p className="text-green-500 text-sm border-b">11.8% (₹16.9 lac)</p>
            <div className="flex justify-between items-center py-1">
              <p className="text-sm">#Units: {value?.totalQty || "0"}</p>
              <p className="text-green-500 text-sm">▲ 11.0%</p>
            </div>
          </div>

          {/* Metric 2 */}
          <div className="border pb-2 bg-white rounded-lg p-2 mt-1">
            <h3 className="text-lg font-semibold">2.8%</h3>
            <p className="text-red-500 text-sm">▼ -1.5% (0.0%)</p>
          </div>
        </div>
      </div>

      {/* Lower Metrics ..........section 2 */}
      <div
        className={`p-1 bg-gray-100 mt-2 rounded-lg border ${
          selectedSKU == code && "border-navBarColour border-2 bg-[#dae0ea]"
        } ${code == 0 && "sticky left-0"}`}
      >
        <div className="space-y-2 bg-white rounded-lg p-2 border">
          <div>
            <h3 className="text-lg font-semibold">5.4 lac</h3>
            <p className="text-green-500 text-sm">3.1% (16.2 K)</p>
          </div>

          <div>
            <h3 className="text-lg font-semibold">4.7%</h3>
            <p className="text-green-500 text-sm">▲ 7.7% (0.3%)</p>
          </div>

          <div>
            <h3 className="text-lg font-semibold">₹511.8</h3>
            <p className="text-green-500 text-sm">▲ 0.9% (₹4.8)</p>
          </div>
        </div>

        {/* Buttons Section */}
        <div className="flex justify-between my-2">
          <button
            className={`${
              selectedSKU == code ? "text-[#3965ab]" : "text-gray-700"
            } rounded-lg px-3 py-1 text-xs flex items-center gap-1 bg-white font-semibold`}
          >
            <TbBulbFilled /> Insights
          </button>
          <button
            className={`${
              selectedSKU == code ? "text-[#3965ab]" : "text-gray-700"
            } rounded-lg px-3 py-1 text-xs flex items-center gap-1 bg-white font-semibold`}
          >
            <TbBrandStackshare /> RCA
          </button>
        </div>
        <hr />
        {/* Bottom links */}
        <div className="flex mt-2 gap-2 flex-wrap">
          <button
            className={`${
              selectedSKU == code ? "text-[#3965ab]" : "text-gray-700"
            } bg-white rounded-lg px-2 py-1 text-xs flex items-center w-fit border font-semibold`}
          >
            View Trends <MdChevronRight />
          </button>
          <button
            className={`${
              selectedSKU == code ? "text-[#3965ab]" : "text-gray-700"
            } bg-white rounded-lg px-2 py-1 text-xs flex items-center w-fit border font-semibold`}
          >
            Competition <MdChevronRight />
          </button>
          <button
            className={`${
              selectedSKU == code ? "text-[#3965ab]" : "text-gray-700"
            } bg-white rounded-lg px-2 py-1 text-xs flex items-center w-fit border font-semibold`}
          >
            Cross Platform <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryRcaStatsCard;