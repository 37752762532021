export const blinkitProfitabilityQuery = (startDate, endDate) => {
  return {
    measures: [
      "combined_sales_report_blinkit.total_gmv",
      "combined_sales_report_blinkit.total_taxes_paid",
      "combined_sales_report_blinkit.total_cogs",
    ],
    timeDimensions: [
      {
        dimension: "combined_sales_report_blinkit.normalized_date",
        dateRange: [startDate, endDate],
      },
    ],
    order: {
      "combined_sales_report_blinkit.normalized_date": "asc",
    },
  };
};

export const zeptoProfitablityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_zepto.total_cogs",
    "combined_sales_report_zepto.total_taxes_paid",
    "combined_sales_report_zepto.total_gross_merchandise_value",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_zepto.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_zepto.normalized_date": "asc",
  },
});

export const instamartProfitabilityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_instamart.total_cogs",
    "combined_sales_report_instamart.total_gmv",
    "combined_sales_report_instamart.total_taxes_paid",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_instamart.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_instamart.normalized_date": "asc",
  },
});

export const blinkitCategoryProfitablityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_blinkit.total_gmv",
    "combined_sales_report_blinkit.total_taxes_paid",
    "combined_sales_report_blinkit.total_cogs",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_blinkit.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  dimensions: ["combined_sales_report_blinkit.category"],
  order: {
    "combined_sales_report_blinkit.total_gmv": "desc",
  },
});

export const zeptoCategoryProfitabilityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_zepto.total_cogs",
    "combined_sales_report_zepto.total_taxes_paid",
    "combined_sales_report_zepto.total_gross_merchandise_value",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_zepto.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_zepto.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_zepto.category"],
});

export const instamartCategoryProfitabilityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_instamart.total_cogs",
    "combined_sales_report_instamart.total_gmv",
    "combined_sales_report_instamart.total_taxes_paid",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_instamart.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_instamart.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_instamart.category"],
});

export const blinkitCityProfitabilityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_blinkit.total_gmv",
    "combined_sales_report_blinkit.total_taxes_paid",
    "combined_sales_report_blinkit.total_cogs",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_blinkit.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_blinkit.total_gmv": "desc",
  },
  dimensions: ["combined_sales_report_blinkit.brands_choice"],
});

export const zeptoCityProfitabilityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_zepto.total_cogs",
    "combined_sales_report_zepto.total_taxes_paid",
    "combined_sales_report_zepto.total_gross_merchandise_value",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_zepto.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_zepto.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_zepto.brands_choice"],
});

export const instamartCityProfitabilityQuery = (startDate, endDate) => ({
  measures: [
    "combined_sales_report_instamart.total_cogs",
    "combined_sales_report_instamart.total_gmv",
    "combined_sales_report_instamart.total_taxes_paid",
  ],
  timeDimensions: [
    {
      dimension: "combined_sales_report_instamart.normalized_date",
      dateRange: [startDate, endDate],
    },
  ],
  order: {
    "combined_sales_report_instamart.total_cogs": "desc",
  },
  dimensions: ["combined_sales_report_instamart.brands_choice"],
});
