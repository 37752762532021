import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ComponentSwitch from "../../common/ComponentSwitch/ComponentSwitch";
import CompetitorDetailedView from "./CompetitorDetailedView";
import CompetitorTrends from "./CompetitorTrends";

const options = ["Competitor Detailed View", "Competitor Trends"];

export default function CompetitorPricingDrawer({ toggleDrawer, state, sku }) {
  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <div>
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: "60vw", // Set the width to 80% of the viewport width
            borderTopLeftRadius: "20px", // Rounded top-left corner
            borderBottomLeftRadius: "20px", // Rounded bottom-left corner
            overflow: "visible", // Ensure content is not clipped
            backgroundColor: "#F5F7FA",
          },
        }}
      >
        <Box
          role="presentation"
          onKeyDown={toggleDrawer(false)}
          sx={{
            height: "100%", // Ensure full height of the drawer
            overflow: "hidden", // Prevent content overflow
          }}
        >
          <div className="py-4">
            <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
            <div className="p-4">
              {selectedOption == 0 && <CompetitorDetailedView sku={sku} />}
              {selectedOption == 1 && <CompetitorTrends sku={sku} />}
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}
