import React from "react";
import { Doughnut } from "react-chartjs-2"; // Import Doughnut instead of Pie
import { useNavigate } from "react-router-dom";

const TopAndLowPerformingProductBasedOnDailyRunRate = ({ path, data, heading, platformPrefix, type, title }) => {
  const navigate = useNavigate();

  const handleViewMore = () => {
    navigate(path); // Navigate to the specified path
  };

  const chartData = {
    labels: data.map((product) => product[`combined_inventory_data_${platformPrefix}.item_name`]),
    datasets: [
      {
        data: data.map((product) => product[`combined_sales_report_${platformPrefix}.daily_run_rate`]),
        backgroundColor: ["#FF6384", "#22CFCF", "#4597DB", "#FEBE40", "#8142FF"], // Colors
        borderWidth: 0.7, // Make borders thicker
        borderRadius: 4,
      },
    ],
  };

  const bestPerformerProduct = data[0];
  const bestRunRate = bestPerformerProduct
    ? bestPerformerProduct[`combined_sales_report_${platformPrefix}.daily_run_rate`]?.toFixed(2)
    : null;

  return (
    <div onClick={handleViewMore} className=" hover:cursor-pointer bg-white  overflow-hidden">
      <div className="flex justify-between items-center">
        <h2 className="text-md leading-5 text-[#4F4E69] font-body font-medium text-start mb-2">{heading}</h2>
      </div>

      {data.length > 0 ? (
        <div className="flex min-w-[44.7vw] min-h-[260px] p-7 border rounded-lg">
          <div className=" flex flex-col justify-center w-1/3">
            <span className="text-xs font-medium text-gray-500">{title}</span>
            <div className="flex flex-col gap-7 items-start">
              <span className="text-base font-body font-semibold text-[#2C2C2C]">
                {bestPerformerProduct
                  ? bestPerformerProduct[`combined_inventory_data_${platformPrefix}.item_name`]
                  : "No product available"}
              </span>
              <div className="flex flex-col ">
                <p
                  className={`text-2xl font-body font-bold ${
                    type === "topPerform" ? "text-mainBrandColour" : "text-red-500"
                  } leading-none mt-2`}
                >
                  {bestRunRate ? `${bestRunRate}` : "N/A"}
                </p>
                <span className="text-xs text-[#4D4D4D] font-semibold">Daily RR</span>
              </div>
            </div>
          </div>

          <div className="flex-1 w-2/3 items-center justify-center">
            <Doughnut
              className=""
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false, // Allows height/width customization
                plugins: {
                  legend: {
                    position: "right", // Legend position
                    labels: {
                      usePointStyle: true,
                      pointStyle: "circle",
                      color: "#000", // Customize legend text color
                      font: {
                        size: 8, // Font size
                        weight: "normal", // Set the font to bold
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const index = tooltipItem.dataIndex;
                        return `DRR: ${data[index][`combined_sales_report_${platformPrefix}.daily_run_rate`].toFixed(
                          2
                        )}`;
                      },
                    },
                  },
                },

                cutout: "50%", // Thicker doughnut sections
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-20 border rounded-lg">No {heading}</div>
      )}
    </div>
  );
};

export default TopAndLowPerformingProductBasedOnDailyRunRate;
