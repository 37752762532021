import React, { useEffect, useState, useRef } from "react";
import { Line } from "react-chartjs-2"; // Import the Line chart from react-chartjs-2
import {
  Chart as ChartJS, // Import necessary modules from Chart.js
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Import date-fns adapter for time formatting in Chart.js
import { MenuItem, Select } from "@mui/material"; // Import MUI components for dropdown selection

// Register the necessary Chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const colors = [
  "#ff1493", // Deep Pink
  "#000080", // Navy
  "#ffa500", // Orange
  "#dc143c", // Crimson
  "#00fa9a", // Medium Spring Green
  "#ffb6c1", // Light Pink
  "#000000", // Black
  "#696969", // Dim Gray
  "#006400", // Dark Green
  "#808000", // Olive
  "#483d8b", // Dark Slate Blue
  "#3cb371", // Medium Sea Green
  "#008b8b", // Dark Cyan
  "#32cd32", // Lime Green
  "#800080", // Purple
  "#b03060", // Maroon 3
  "#00ff00", // Lime
  "#8a2be2", // Blue Violet
  "#0000ff", // Blue
  "#adff2f", // Green Yellow
  "#ffff54", // Laser Lemon
  "#add8e6", // Light Blue
  "#8b4513", // Saddle Brown
  "#7b68ee", // Medium Slate Blue
  "#fafad2", // Light Goldenrod
];

// Function to transform the API data into a format that the chart can use
const transformDataForChart = (data) => {
  const dateAvailabilityMap = {}; // Object to store stock availability data by date

  data?.forEach((item) => {
    const {
      "third_party_availability.brand": brand, // Destructure the brand name field
      "third_party_availability.date": date, // Destructure the date field
      "third_party_availability.stock_binary": stockBinary, // Destructure the stock binary field
    } = item;

    const parsedDate = new Date(date); // Parse the date into a Date object

    // Initialize the brand object if it doesn't exist
    if (!dateAvailabilityMap[brand]) {
      dateAvailabilityMap[brand] = {};
    }

    // Initialize the array for the parsed date under the brand if it doesn't exist
    if (!dateAvailabilityMap[brand][parsedDate]) {
      dateAvailabilityMap[brand][parsedDate] = [];
    }

    // Add the stockBinary value to the corresponding brand and date
    dateAvailabilityMap[brand][parsedDate].push(stockBinary);
  });

  // Create an array of data points with the availability percentage per date
  const brandAvailabilityData = Object.keys(dateAvailabilityMap).map((brand) => {
    // Get availability data for each brand
    const dataPoints = Object.keys(dateAvailabilityMap[brand]).map((date) => {
      const stockBinaries = dateAvailabilityMap[brand][new Date(date)]; // Get the stock binaries for the date
      const totalPincodes = stockBinaries.length; // Calculate the total number of pincodes

      // Check for both '1' (string) and 1 (number) when calculating availability
      const availableStock = stockBinaries.filter(
        (binary) => binary === "1" || binary === 1 // Check for both string and number types
      ).length;

      const availabilityPercentage = (availableStock / totalPincodes) * 100; // Calculate the availability percentage

      return {
        date: new Date(date), // Store the date
        availability: availabilityPercentage, // Store the calculated availability percentage
      };
    });

    const chartDataPoints = dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Return the data for each brand along with the calculated availability
    return {
      brand, // Store the brand name
      chartDataPoints, // Store the availability data points for this brand
    };
  });

  return brandAvailabilityData;
  // Return the data formatted for the Line chart
};

// Component for rendering the line chart
const CompetitorWeeklyOverview = ({ apiData, brands }) => {
  const userDetail = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userDetail);
  const [selectedBrand, setSelectedBrand] = useState(parsedUserData?.organizationDetail?.name); // Default brand selected

  // Filter the API data based on the selected brand
  //   const brandsData = apiData?.filter((item) => item["third_party_availability.brand"] == selectedBrand);
  const brandsData = apiData?.filter((item) => item);
  // useEffect hook to update the chart data when the selected brand's data changes

  const data = transformDataForChart(brandsData); // Update chart data when the actual content of `data` changes

  console.log("transformDataForChart====", data);

  /// Extract dates and availability data for each brand
  const labels = [
    ...new Set(
      data.flatMap((brand) => brand.chartDataPoints.map((point) => new Date(point.date).toLocaleDateString()))
    ),
  ];

  const chartData = {
    labels: labels,
    datasets: data.map((brand, index) => ({
      label: brand.brand,
      data: labels.map((label) => {
        const point = brand.chartDataPoints.find((p) => new Date(p.date).toLocaleDateString() === label);
        return point ? point.availability : null;
      }),
      borderColor: colors[index], // Colorful lines for each brand
      fill: false,
      tension: 0.4, // Smooth curves
      borderWidth: 2,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 2,
      pointRadius: 3,
      pointHoverRadius: 3,
    })),
  };

  const options = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: false, // Disable the aspect ratio maintenance
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 12, // Adjust the font size to make the circles smaller
          },
        },
      },
    },
    scales: {
      x: {
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Date",
          font: {
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Average Availibility Percentage",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <div className="w-full h-96 p-4">
      <Line data={chartData} options={options} /> {/* Render the chart */}
    </div>
  );
};

export default CompetitorWeeklyOverview;
