import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import {
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
} from "@mui/material";
import TableSkeleton from "../../common/Skeletons/TableSkeleton";
import NoDataFound from "../../common/NoDataFound/NoDataFound";
import Papa from "papaparse";

// Define the column headers for the table
const heading = [
  { label: "Category", key: "category" },
  { label: "Backend Inventory Quantity", key: "backendInvQty" },
  { label: "Frontend Inventory Quantity", key: "frontendInvQty" },
  { label: "Total Inventory", key: "totalInventory" },
  { label: "Daily Run Rate", key: "dailyRunRate" },
  { label: "Inventory Days", key: "inventoryDays" },
];

// Function to consolidate the inventory data based on city and SKU code
function consolidateData(data) {
  const consolidated = {};

  data.forEach((entry) => {
    const city = entry["combined_inventory_data_blinkit.brands_choice"];
    const skuCode = entry["combined_inventory_data_blinkit.brand_internal_sku_code"];
    const dailyRunRate = parseFloat(entry["combined_sales_report_blinkit.daily_run_rate"]);
    const frontendInv = parseInt(entry["combined_inventory_data_blinkit.frontend_inv_qty"], 10);
    const backendInv = parseInt(entry["combined_inventory_data_blinkit.backend_inv_qty"], 10);

    // Create a unique key based on city and SKU code
    const key = `${city}-${skuCode}`;

    // Initialize consolidated entry if it doesn't exist
    if (!consolidated[key]) {
      consolidated[key] = {
        ...entry, // Spread all other fields from the first entry for the city and SKU
        "combined_sales_report_blinkit.daily_run_rate": 0, // Initialize dailyRunRate
        totalFrontendInv: 0,
        totalBackendInv: 0,
        count: 0,
      };
    }

    // Accumulate dailyRunRate and inventory quantities
    consolidated[key]["combined_sales_report_blinkit.daily_run_rate"] += dailyRunRate;
    consolidated[key].totalFrontendInv += frontendInv;
    consolidated[key].totalBackendInv += backendInv;
    consolidated[key].count += 1;
  });

  // Map the consolidated data to calculate averages and totals
  return (
    Object.values(consolidated)
      .map((entry) => ({
        ...entry, // Spread all other fields
        "combined_sales_report_blinkit.daily_run_rate":
          entry["combined_sales_report_blinkit.daily_run_rate"] / entry.count, // Average dailyRunRate
        "combined_inventory_data_blinkit.frontend_inv_qty": entry.totalFrontendInv,
        "combined_inventory_data_blinkit.backend_inv_qty": entry.totalBackendInv,
        totalInv: entry.totalFrontendInv + entry.totalBackendInv, // Calculate total inventory
      }))
      // Sort by SKU code
      .sort((a, b) =>
        a["combined_inventory_data_blinkit.brand_internal_sku_code"].localeCompare(
          b["combined_inventory_data_blinkit.brand_internal_sku_code"]
        )
      )
  );
}

// Function to aggregate data by SKU code
function aggregateBySkuCode(consolidatedData) {
  const aggregatedData = {};

  consolidatedData.forEach((entry) => {
    const skuCode = entry["combined_inventory_data_blinkit.brand_internal_sku_code"];

    // Initialize entry for SKU code if not present
    if (!aggregatedData[skuCode]) {
      aggregatedData[skuCode] = {
        ...entry, // Initialize with the first entry's data
        totalFrontendInv: 0,
        totalBackendInv: 0,
        totalInv: 0,
        totalDailyRunRate: 0,
        count: 0,
      };
    }

    // Accumulate frontend, backend inventory, total inventory, and daily run rate aggregatedData[skuCode].totalFrontendInv += entry.totalFrontendInv;
    aggregatedData[skuCode].totalFrontendInv += entry.totalFrontendInv;
    aggregatedData[skuCode].totalBackendInv += entry.totalBackendInv;
    aggregatedData[skuCode].totalInv += entry.totalInv;
    aggregatedData[skuCode].totalDailyRunRate += entry["combined_sales_report_blinkit.daily_run_rate"];
    aggregatedData[skuCode].count += 1;
  });

  // Calculate averages for dailyRunRate and inventoryDays
  return Object.values(aggregatedData).map((entry) => {
    const averageDailyRunRate = entry.totalDailyRunRate / entry.count;
    const totalInv = entry.totalFrontendInv + entry.totalBackendInv;
    const inventoryDays = totalInv / averageDailyRunRate;

    return {
      ...entry,
      "combined_sales_report_blinkit.daily_run_rate": averageDailyRunRate, // Average dailyRunRate
      "combined_inventory_data_blinkit.frontend_inv_qty": entry.totalFrontendInv,
      "combined_inventory_data_blinkit.backend_inv_qty": entry.totalBackendInv,
      totalInv, // Recalculate totalInv to ensure it's correct
      inventoryDays: inventoryDays || 0, // Prevent division by zero, set inventoryDays to 0 if averageDailyRunRate is 0
    };
  });
}

const InventoryDaysBlinkitTable = ({ platformName }) => {
  // Get inventory report data from Redux store
  const { inventoryReportBlinkit } = useSelector((state) => state.sales);
  console.log("inventoryReportBlinkit===", inventoryReportBlinkit);

  // State to manage expanded categories and sub-categories
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const { loading, data } = inventoryReportBlinkit;
  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // You can adjust this based on how many rows you want to display per page
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Consolidate and aggregate the data
  const consolidatedData = consolidateData(data); // Consolidate data based on city and SKU
  console.log("consolidatedData===", consolidatedData);
  const aggregatedData = aggregateBySkuCode(consolidatedData); // Aggregate the consolidated data by SKU
  console.log("aggregatedData===", aggregatedData);

  // Handle category click to toggle expansion state
  const handleCategoryClick = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category], // Toggle expansion state for the clicked category
    }));
  };

  // Handle sub-category click to toggle expansion state
  const handleSubCategoryClick = (subCategory) => {
    setExpandedSubCategories((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory], // Toggle expansion state for the clicked sub-category
    }));
  };

  // Group data by category, sub-category, and product name
  const groupedByCategory = aggregatedData.reduce((acc, item) => {
    const category = item["combined_inventory_data_blinkit.category"];
    const subCategory = item["combined_inventory_data_blinkit.sub_category"];
    const productName = item["combined_inventory_data_blinkit.item_name"];

    // Initialize category if not present
    if (!acc[category]) {
      acc[category] = {
        subCategories: {},
        totals: {
          backendInvQty: 0,
          frontendInvQty: 0,
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize sub-category if not present
    if (!acc[category].subCategories[subCategory]) {
      acc[category].subCategories[subCategory] = {
        productNames: {},
        totals: {
          backendInvQty: 0,
          frontendInvQty: 0,
          totalInventory: 0,
          dailyRunRateSum: 0, // Sum of dailyRunRate for averaging later
          inventoryDaysSum: 0, // Sum of inventoryDays for averaging later
          productCount: 0, // To calculate averages
        },
      };
    }

    // Initialize product name if not present
    if (!acc[category].subCategories[subCategory].productNames[productName]) {
      acc[category].subCategories[subCategory].productNames[productName] = {
        ...item,
        backendInvQty: 0,
        frontendInvQty: 0,
        totalInventory: 0,
        dailyRunRate: 0,
        inventoryDays: 0,
      };
    }

    // Update product totals
    acc[category].subCategories[subCategory].productNames[productName].backendInvQty += parseInt(
      item["combined_inventory_data_blinkit.backend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].productNames[productName].frontendInvQty += parseInt(
      item["combined_inventory_data_blinkit.frontend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].productNames[productName].totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].productNames[productName].dailyRunRate +=
      item["combined_sales_report_blinkit.daily_run_rate"];
    acc[category].subCategories[subCategory].productNames[productName].inventoryDays += item.inventoryDays;

    // Update sub-category totals
    acc[category].subCategories[subCategory].totals.backendInvQty += parseInt(
      item["combined_inventory_data_blinkit.backend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].totals.frontendInvQty += parseInt(
      item["combined_inventory_data_blinkit.frontend_inv_qty"],
      10
    );
    acc[category].subCategories[subCategory].totals.totalInventory += item.totalInv;
    acc[category].subCategories[subCategory].totals.dailyRunRateSum +=
      item["combined_sales_report_blinkit.daily_run_rate"]; // Sum dailyRunRate
    acc[category].subCategories[subCategory].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].subCategories[subCategory].totals.productCount += 1; // Increment product count for averaging

    // Update category totals
    acc[category].totals.backendInvQty += parseInt(item["combined_inventory_data_blinkit.backend_inv_qty"], 10);
    acc[category].totals.frontendInvQty += parseInt(item["combined_inventory_data_blinkit.frontend_inv_qty"], 10);
    acc[category].totals.totalInventory += item.totalInv;
    acc[category].totals.dailyRunRateSum += item["combined_sales_report_blinkit.daily_run_rate"]; // Sum dailyRunRate
    acc[category].totals.inventoryDaysSum += item.inventoryDays; // Sum inventoryDays
    acc[category].totals.productCount += 1; // Increment product count for averaging

    return acc;
  }, {});

  // After grouping, calculate the average for inventoryDays and dailyRunRate for both sub-categories and categories
  Object.keys(groupedByCategory).forEach((category) => {
    const categoryData = groupedByCategory[category];

    // Calculate the average dailyRunRate and inventoryDays for the category
    categoryData.totals.dailyRunRate = categoryData.totals.dailyRunRateSum / categoryData.totals.productCount;
    categoryData.totals.inventoryDays = categoryData.totals.inventoryDaysSum / categoryData.totals.productCount;

    Object.keys(categoryData.subCategories).forEach((subCategory) => {
      const subCategoryData = categoryData.subCategories[subCategory];

      // Calculate the average dailyRunRate and inventoryDays for the sub-category
      subCategoryData.totals.dailyRunRate =
        subCategoryData.totals.dailyRunRateSum / subCategoryData.totals.productCount;
      subCategoryData.totals.inventoryDays =
        subCategoryData.totals.inventoryDaysSum / subCategoryData.totals.productCount;
    });
  });

  // Slicing data to show based on pagination
  const slicedCategories = Object.keys(groupedByCategory).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortedCategories = useMemo(() => {
    const sortableItems = [...slicedCategories];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let aValue, bValue;

        if (sortConfig.key === "category") {
          aValue = a;
          bValue = b;
        } else {
          aValue = groupedByCategory[a].totals[sortConfig.key];
          bValue = groupedByCategory[b].totals[sortConfig.key];
        }

        if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
    }

    return sortableItems;
  }, [slicedCategories, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  console.log("aggregatedData====", aggregatedData);

  // CSV Export Function
  const handleExportCSV = () => {
    // Transform the aggregated data with user-friendly key names
    const userFriendlyData = aggregatedData.map((entry) => ({
      "Product Name": entry["combined_inventory_data_blinkit.item_name"],
      "Backend Inventory Quantity": entry["combined_inventory_data_blinkit.backend_inv_qty"],
      "Frontend Inventory Quantity": entry["combined_inventory_data_blinkit.frontend_inv_qty"],
      "Total Inventory": entry.totalInv,
      "Daily Run Rate": entry["combined_sales_report_blinkit.daily_run_rate"],
      "Inventory Days": entry.inventoryDays?.toFixed(0),
    }));

    const csvData = Papa.unparse(userFriendlyData); // Convert aggregated data to CSV format
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `inventory_data_${platformName.toLowerCase()}.csv`); // Set CSV file name
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <TableSkeleton />;
  }

  if (!data || data.length === 0) {
    return <NoDataFound />;
  }

  return (
    <div className="">
      <div className="mb-4 h-[42px] min-w-32 flex justify-end">
        <span
          onClick={handleExportCSV}
          className=" p-3  shadow rounded bg-white text-[#666666] flex gap-4 justify-center items-center"
        >
          <span className="font-body font-normal text-sm">Export to CSV</span>
          <img width={16} height={16} src="/icons/download-circular-icon.svg" />
        </span>
      </div>

      <Paper
        className="border rounded-md"
        sx={{
          width: "100%",
          mb: "2rem",
        }}
      >
        <div className="p-3 text-lg font-medium border-b mb-2 flex gap-4">
          <img src="/logos/inventory-page-icon.svg" alt="inventory" /> Inventory Days
        </div>
        <div className="p-4 w-full mb-2">
          <div className="border rounded p-4">
            <TableContainer sx={{ borderRadius: "0.25rem" }} className="overflow-x-scroll">
              <MuiTable
                className="table-auto border min-w-full"
                stickyHeader
                aria-label="sticky table"
                sx={{ overflowX: "auto" }}
              >
                <TableHead className="bg-white sticky top-0 z-10">
                  <TableRow sx={{ bgcolor: "#F9F9F9" }}>
                    {heading.map((head, index) => (
                      <TableCell
                        key={index}
                        onClick={() => handleSort(head.key)} // Sort by key on click
                        className="cursor-pointer px-6 py-4 gap-[2.75rem] tracking-wider"
                        sx={{
                          fontWeight: "500",
                          color: "#4D4D4D",
                          fontSize: "0.875rem",
                          textAlign: index == 0 ? "left" : "center",
                          boxShadow: index === 0 && "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                          zIndex: index === 0 && "3",
                          minWidth: "300px",
                          maxWidth: "300px",
                          position: index === 0 && "sticky",
                          left: index === 0 && 0,
                          bgcolor: "#F9F9F9",
                          cursor: "pointer",
                        }}
                      >
                        {head.label}
                        {sortConfig.key === head.key && <span>{sortConfig.direction === "ascending" ? "↑" : "↓"}</span>}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                        zIndex: "3",
                        position: "sticky",
                        right: 0,
                        bgcolor: "#F9F9F9",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className="bg-white">
                  {/* category mapping */}
                  {sortedCategories.map((category, index) => (
                    <React.Fragment key={category}>
                      <TableRow
                        className={`cursor-pointer ${
                          expandedCategories[category] ? "bg-[#F3F8FF]" : index % 2 !== 0 ? "bg-[#F0F6FF]" : "bg-white"
                        } `}
                        onClick={() => handleCategoryClick(category)}
                      >
                        <TableCell
                          className="flex justify-between items-center text-left px-3 gap-4 py-4 text-[0.75rem] font-medium font-body"
                          sx={{
                            color: "#4D4D4D",
                            boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            minWidth: "300px",
                            maxWidth: "300px",
                            position: "sticky",
                            left: 0,
                            bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            display: "flex",
                            fontWeight: "500",
                            textAlign: "left",
                          }}
                        >
                          {category}
                          {expandedCategories[category] ? (
                            <FaCaretUp className="text-base" />
                          ) : (
                            <FaCaretDown className="text-base" />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">{groupedByCategory[category].totals.backendInvQty}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">{groupedByCategory[category].totals.frontendInvQty}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">{groupedByCategory[category].totals.totalInventory} </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">
                            {groupedByCategory[category].totals.dailyRunRate.toFixed(2)}{" "}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className="text-center">
                            {groupedByCategory[category].totals.inventoryDays.toFixed(2)}
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                            zIndex: "3", // Left shadow for this cell,
                            position: "sticky",
                            bgcolor: expandedCategories[category] ? "#F3F8FF" : index % 2 !== 0 ? "#F0F6FF" : "#fff",
                            right: 0,
                          }}
                        ></TableCell>
                      </TableRow>
                      {/* subcatrgory mapping */}
                      {expandedCategories[category] &&
                        Object.keys(groupedByCategory[category].subCategories).map((subCategory) => (
                          <React.Fragment key={subCategory}>
                            <TableRow
                              className="cursor-pointer bg-[#F0F0F0] text-[#727278]"
                              onClick={() => handleSubCategoryClick(subCategory)}
                            >
                              <TableCell
                                className="flex pl-6 justify-between items-center px-3 gap-4 py-4 font-body"
                                sx={{
                                  boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                  minWidth: "300px",
                                  maxWidth: "300px",
                                  position: "sticky",
                                  left: 0,
                                  bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                  display: "flex",
                                  paddingLeft: "left",
                                }}
                              >
                                {subCategory}

                                {expandedSubCategories[subCategory] ? (
                                  <FaCaretUp className="text-base" />
                                ) : (
                                  <FaCaretDown className="text-base" />
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <div className="text-center">
                                  {groupedByCategory[category].subCategories[subCategory].totals.backendInvQty}
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                <div className="text-center">
                                  {groupedByCategory[category].subCategories[subCategory].totals.frontendInvQty}
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                <div className="text-center">
                                  {groupedByCategory[category].subCategories[subCategory].totals.totalInventory}{" "}
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                <div className="text-center">
                                  {groupedByCategory[category].subCategories[subCategory].totals.dailyRunRate.toFixed(
                                    2
                                  )}{" "}
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                <div className="text-center">
                                  {groupedByCategory[category].subCategories[subCategory].totals.inventoryDays.toFixed(
                                    2
                                  )}{" "}
                                </div>
                              </TableCell>
                              <TableCell
                                sx={{
                                  boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                  zIndex: "3",
                                  position: "sticky",
                                  right: 0,
                                  bgcolor: expandedCategories[category] ? "#F0F0F0" : "white",
                                }}
                              ></TableCell>
                            </TableRow>
                            {/* brand choices mapping based on expanded category and
                      subcategory */}
                            {expandedSubCategories[subCategory] &&
                              Object.keys(groupedByCategory[category].subCategories[subCategory].productNames).map(
                                (productName, index) => {
                                  const item =
                                    groupedByCategory[category].subCategories[subCategory].productNames[productName];
                                  return (
                                    <TableRow key={index} className="bg-white cursor-pointer">
                                      <TableCell
                                        className="px-6 py-4 pl-8 text-[0.75rem] font-medium font-body"
                                        sx={{
                                          boxShadow: "5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                          minWidth: "300px",
                                          maxWidth: "300px",
                                          position: "sticky",
                                          left: 0,
                                          bgcolor: "white",
                                          display: "flex",
                                          paddingLeft: "6rem",
                                          textAlign: "left",
                                        }}
                                      >
                                        <div className="text-left">{productName}</div>
                                      </TableCell>
                                      <TableCell align="right">
                                        <div className="text-center">{item.backendInvQty} </div>
                                      </TableCell>
                                      <TableCell align="right">
                                        <div className="text-center">{item.frontendInvQty} </div>
                                      </TableCell>
                                      <TableCell align="right">
                                        <div className="text-center">{item.totalInventory}</div>
                                      </TableCell>
                                      <TableCell align="right">
                                        <div className="text-center">{item.dailyRunRate.toFixed(2)}</div>
                                      </TableCell>
                                      <TableCell align="right">
                                        <div className="text-center">{item.inventoryDays.toFixed(2)} </div>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          boxShadow: "-5px 0px 5px -5px rgba(16, 24, 40, 0.06)",
                                          zIndex: "3",
                                          position: "sticky",
                                          right: 0,
                                          bgcolor: "white",
                                        }}
                                      ></TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </MuiTable>
            </TableContainer>
          </div>
          {/* Add Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Object.keys(groupedByCategory).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
};

export default InventoryDaysBlinkitTable;
