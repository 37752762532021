import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./css/LineChart.css";
import { useSelector } from "react-redux";
import ChartSkeleton from "../../common/Skeletons/ChartSkeleton";

// Register ChartJS components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

// Normalize sales data based on the platform
const normalizeSalesData = (data) => {
  if (data["combined_sales_report_zepto.brand_internal_sku_code"]) {
    return {
      platform: "zepto",
      date: data["combined_sales_report_zepto.date"],
      mrp: parseFloat(data["combined_sales_report_zepto.gmv"]),
    };
  } else if (data["combined_sales_report_blinkit.blinkit_sku_code"]) {
    return {
      platform: "blinkit",
      date: data["combined_sales_report_blinkit.date"].split("-").reverse().join("-"),
      mrp: parseFloat(data["combined_sales_report_blinkit.gmv"]),
    };
  } else if (data["combined_sales_report_instamart.brand_internal_sku_code"]) {
    return {
      platform: "instamart",
      date: data["combined_sales_report_instamart.date"],
      mrp: parseFloat(data["combined_sales_report_instamart.gmv"]),
    };
  }
  return null;
};

// Aggregate sales data by date
const aggregateSalesByDate = (data) => {
  const aggregatedData = {};
  data.forEach((sale) => {
    const date = sale.date;
    if (aggregatedData[date]) {
      aggregatedData[date] += sale.mrp;
    } else {
      aggregatedData[date] = sale.mrp;
    }
  });

  return Object.keys(aggregatedData).map((date) => ({
    date,
    sales: aggregatedData[date],
  }));
};

const DateWiseSalesLineChart = () => {
  // Get sales data from Redux store
  const { totalSales } = useSelector((state) => state.sales);

  // Normalize and aggregate sales data
  const normalizedSales = (Array.isArray(totalSales.data) ? totalSales.data : []).map(normalizeSalesData);
  const aggregatedSales = aggregateSalesByDate(normalizedSales);

  // Sort aggregated data by date
  const sortedData = aggregatedSales.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Extract sorted dates and sales data for the chart
  const sortedDates = sortedData.map((entry) => entry.date);
  const sortedSalesData = sortedData.map((entry) => entry.sales);

  const shadowPlugin = {
    id: "shadowLine",
    beforeDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save();

      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);

        // Apply shadow only to line elements
        if (meta.type === "line") {
          ctx.shadowColor = "rgba(96, 91, 255, 0.25)"; // Shadow color
          ctx.shadowBlur = 10; // Blur radius for the shadow
          ctx.shadowOffsetX = 0; // No horizontal shadow offset
          ctx.shadowOffsetY = 10; // Vertical shadow only (bottom side)
        }
      });
    },
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.restore(); // Restore the context to avoid affecting other elements
    },
  };

  const data = {
    labels: sortedDates,
    datasets: [
      {
        label: "Sales",
        data: sortedSalesData,
        borderColor: "#387FF5",
        tension: 0.4,
        borderWidth: 2,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#387FF5",
        pointBorderWidth: 2,
        pointRadius: 3,
        pointHoverRadius: 4,
        backgroundColor: "#F0F6FF",
        fill: true,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            return "Sales: " + context.parsed.y.toFixed(2);
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return (value / 1000).toFixed(1) + "k";
            }
            return value.toFixed(0);
          },
        },
        title: {
          display: true,
          text: "Total Sales", // Y-axis title
          font: {
            size: 14,
          },
          padding: {
            // Adjust margin to the right
            bottom: 12, // Add some margin below the title
          },
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12, // Adjust font size of the x-axis labels
          },
          maxRotation: 45, // Rotate the labels up to 45 degrees
          minRotation: 0, // Set the minimum rotation (can be 0 for horizontal labels)
          autoSkip: true, // Auto skip labels if they overlap
          maxTicksLimit: 10, // Limit the number of labels shown
        },
        title: {
          display: true,
          text: "Date", // X-axis title
          font: {
            size: 14,
          },
        },
      },
    },
  };

  // Display loading state if no data
  if (!sortedSalesData.length) {
    return <ChartSkeleton />;
  }

  return (
    <div className="chart-container-sales ">
      <div className="border  w-full h-full px-2 py-4">
        {sortedSalesData.length > 0 ? (
          <Line data={data} options={options} plugins={[shadowPlugin]} height={102} />
        ) : (
          <div className="h-60 w-full flex justify-center items-center">No data available</div>
        )}
      </div>
    </div>
  );
};

export default DateWiseSalesLineChart;

// Use Case

// Component: DateWiseSalesLineChart

// Purpose: Displays a line chart showing total daily sales over time.
// Data Source: Uses totalSales from Redux store.

// Normalization: Converts sales data based on platformName-specific formats.
// Data Processing: Aggregates sales data by date and sorts it.

// Rendering: Utilizes Chart.js to render the line chart with customized options.

// Fallback: Displays a skeleton loader or "No data available" message if no sales data is present.
