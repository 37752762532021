import React from "react";

const CategoryRcaSkuCard = ({ info = {}, selectedSKU, handleSkuChange, code }) => {
  const { value = { heading: "", totalGMV: 0, totalQty: 0 } } = info;

  return (
    <div
      class={`min-w-80 bg-white border rounded-lg overflow-hidden cursor-pointer ${
        selectedSKU == code && "border-navBarColour border-2 bg-[#dae0ea]"
      } ${code == 0 && "sticky left-0"}`}
      onClick={handleSkuChange}
    >
      <div class="p-4">
        <h2 class="font-bold">{value.heading}</h2>
        <div class="flex items-center mt-2">
          <span class="text-xl font-semibold">{value?.totalGMV || "0"}</span>
          <span class="ml-2 text-green-500 flex items-center">▲ 18.4%</span>
        </div>
        <div class="mt-4">
          <div class="flex justify-between bg-gray-100 p-1 rounded">
            <span>Offtake Share:</span>
            <span class="text-green-500 flex items-center">58.9% ▲</span>
          </div>
          <div class="flex justify-between mt-2">
            <span>Indexed Impressions:</span>
            <span class="text-green-500 flex items-center">1.8 lac ▲</span>
          </div>
          <div class="flex justify-between mt-2">
            <span>Indexed CVR:</span>
            <span class="text-red-500 flex items-center">2.3% ▼</span>
          </div>
          <div class="flex justify-between mt-2">
            <span>ASP:</span>
            <span class="text-red-500 flex items-center">₹903.9 ▼</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryRcaSkuCard;