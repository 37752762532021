export const GET_SOV_BY_KEYWORD_REQUEST = "GET_SOV_BY_KEYWORD_REQUEST";
export const GET_SOV_BY_KEYWORD_SUCCESS = "GET_SOV_BY_KEYWORD_SUCCESS";
export const GET_SOV_BY_KEYWORD_FAILURE = "GET_SOV_BY_KEYWORD_FAILURE";
export const GET_SOV_BY_AREANAME_REQUEST = "GET_SOV_BY_AREANAME_REQUEST";
export const GET_SOV_BY_AREANAME_SUCCESS = "GET_SOV_BY_AREANAME_SUCCESS";
export const GET_SOV_BY_AREANAME_FAILURE = "GET_SOV_BY_AREANAME_FAILURE";

export const GET_SOV_BY_KEYWORD_WEEKLY_REQUEST = "GET_SOV_BY_KEYWORD_WEEKLY_REQUEST";
export const GET_SOV_BY_KEYWORD_WEEKLY_SUCCESS = "GET_SOV_BY_KEYWORD_WEEKLY_SUCCESS";
export const GET_SOV_BY_KEYWORD_WEEKLY_FAILURE = "GET_SOV_BY_KEYWORD_WEEKLY_FAILURE";

export const GET_SOV_BY_AREANAME_WEEKLY_REQUEST = "GET_SOV_BY_AREANAME_WEEKLY_REQUEST";
export const GET_SOV_BY_AREANAME_WEEKLY_SUCCESS = "GET_SOV_BY_AREANAME_WEEKLY_SUCCESS";
export const GET_SOV_BY_AREANAME_WEEKLY_FAILURE = "GET_SOV_BY_AREANAME_WEEKLY_FAILURE";

export const SELECTED_SOA_KEYWORD = "SELECTED_SOA_KEYWORD";
export const SELECTED_SOA_AREANAME = "SELECTED_SOA_AREANAME";
export const FILTERED_SOA_AREANAME = "FILTERED_SOA_AREANAME";

export const SELECTED_SOA_WEEKLY_KEYWORD = "SELECTED_SOA_WEEKLY_KEYWORD";
export const SELECTED_SOA_WEEKLY_AREANAME = "SELECTED_SOA_WEEKLY_AREANAME";
export const FILTERED_SOA_WEEKLY_AREANAME = "FILTERED_SOA_WEEKLY_AREANAME";
