import { ResponsiveFunnel } from "@nivo/funnel";

const AdsFunnel = ({ obj }) => {
  if (!obj) {
    return <div>No data...</div>;
  }

  // Prepare data for the Nivo Funnel chart based on the presence of `ccDate`
  const data = obj.ccDate
    ? [
        { id: "Impressions", value: obj.impressions, label: "Impressions" },
        { id: "Clicks", value: obj.clicks, label: "Clicks" },
      ]
    : [
        { id: "Impressions", value: obj.impressions, label: "Impressions" },
        { id: "Details Page Views", value: obj.details, label: "Details Page Views" },
        { id: "Clicks", value: obj.clicks, label: "Clicks" },
      ];

  // Handle CPC and ROI calculations safely
  let cpc = obj.clicks ? obj.spend / obj.clicks : 0;
  let roi = obj.spend ? obj.revenue / obj.spend : 0;

  const customColors = ["#D4DFFA", "#A6BFF5", "#7A9EF0"]; // Example colors: pink, blue, yellow

  return (
    <div className="w-[30%] rounded-lg p-2 border bg-white">
      <h1 className="font-bold text-sm text-center text-[#387FF5] p-1 px-2 bg-[#EBF2FE] rounded-lg mt-1 capitalize">
        {obj.title}
      </h1>
      {obj.ccDate && <h2 className="text-center font-bold text-sm">Creation Date: {obj.ccDate}</h2>}
      <h2 className="font-bold text-sm text-center">Total Spent: {obj.spend.toFixed(0)}</h2>
      <div className="text-center font-bold text-sm">CPC: {cpc.toFixed(2)}</div>
      <h2 className="font-bold text-sm text-center">ROI: {roi.toFixed(2)}</h2>
      <div style={{ height: "300px" }}>
        <ResponsiveFunnel
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          direction="horizontal"
          valueFormat=" >-.4s"
          colors={customColors}
          borderWidth={0}
          borderColor={{ from: "color", modifiers: [] }}
          labelColor={{ from: "color", modifiers: [["darker", 3]] }}
          enableBeforeSeparators={false}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          enableAfterSeparators={false}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
        />
      </div>
    </div>
  );
};

export default AdsFunnel;
