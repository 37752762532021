import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllMarketingReportBlinkitSearchSuggestionSuccess,
  getAllMarketingReportBlinkitSearchSuggestionRequest,
  getAllMarketingReportBlinkitSearchSuggestionFailure,
  getAllMarketingReportBlinkitBannerListingSuccess,
  getAllMarketingReportBlinkitBannerListingRequest,
  getAllMarketingReportBlinkitBannerListingFailure,
  getAllCampaignPerformanceSkuSuccess,
  getAllCampaignPerformanceSkuRequest,
  getAllCampaignPerformanceSkuFailure,
  getAllAwarenessPerformanceDataSuccess,
  getAllAwarenessPerformanceDataRequest,
  getAllAwarenessPerformanceDataFailure,
} from "../../redux/actions/marketingActions";
import {
  fetchDisplayAdsSearchSuggestionDataBlinkit,
  fetchDisplayAdsBannerListingDataBlinkit,
  fetchDisplayAdsCampaignPerformanceDataZepto,
  fetchAwarenessPerformanceDataZepto,
} from "../../services/adsServices/displayAdsServices";
import SearchSuggesionSectionsContainer from "../../components/ads/DisplayAdsComponents/SearchSuggesionSectionsContainer";
import BannerListingSectionsContainer from "../../components/ads/DisplayAdsComponents/BannerListingSectionsContainer";
import AwarenessPerformanceZeptoSectionsContainer from "../../components/ads/DisplayAdsComponents/AwarenessPerformanceZeptoSectionsContainer";
import { useDate } from "../../hooks/SingleDatePicker/SingleDatePickerProvider";
import dayjs from "dayjs";
import ComponentSwitch from "../../components/common/ComponentSwitch/ComponentSwitch";
import Toolbar from "../../components/Toolbar/Toolbar";
import HeadingBar from "../../components/common/HeadingBar/HeadingBar";
import { fetchWithRetry } from "../../utils/queries/fetchWithDateFallback";

const optionsBlinkit = ["Search Suggestion", "Banner Listing"];
const optionsZepto = ["Awareness Performance"];

const DisplayAdScreen = () => {
  const [platformName, setPlatformName] = useState("Blinkit");
  const [selectedOption, setSelectedOption] = useState(0);
  const dispatch = useDispatch();
  const { selectedDate } = useDate();
  const options = platformName === "Blinkit" ? optionsBlinkit : optionsZepto;

  useEffect(() => {
    setSelectedOption(0);
  }, [platformName]);

  const platformDetails = {
    Blinkit: {
      fetchDataOption1: fetchDisplayAdsSearchSuggestionDataBlinkit,
      fetchDataOption2: fetchDisplayAdsBannerListingDataBlinkit,
      displayActions1: {
        request: getAllMarketingReportBlinkitSearchSuggestionRequest,
        success: getAllMarketingReportBlinkitSearchSuggestionSuccess,
        failure: getAllMarketingReportBlinkitSearchSuggestionFailure,
      },
      displayActions2: {
        request: getAllMarketingReportBlinkitBannerListingRequest,
        success: getAllMarketingReportBlinkitBannerListingSuccess,
        failure: getAllMarketingReportBlinkitBannerListingFailure,
      },
    },

    Zepto: {
      fetchDataOption1: fetchAwarenessPerformanceDataZepto,
      displayActions1: {
        request: getAllAwarenessPerformanceDataRequest,
        success: getAllAwarenessPerformanceDataSuccess,
        failure: getAllAwarenessPerformanceDataFailure,
      },
    },
  };

  useEffect(() => {
    const platform = platformDetails[platformName];
    if (platform) {
      dispatch(platform.displayActions1.request());

      // Fetch data for option 1
      fetchWithRetry(
        platform.fetchDataOption1,
        platform.displayActions1.success,
        platform.displayActions1.failure,
        selectedDate,
        dispatch,
        1
      );

      if (platform.displayActions2 && platform.displayActions2) {
        dispatch(platform.displayActions2.request());
        // Fetch data for option 2
        fetchWithRetry(
          platform.fetchDataOption2,
          platform.displayActions2.success,
          platform.displayActions2.failure,
          selectedDate,
          dispatch,
          1
        );
      }
    }
  }, [platformName, selectedDate, selectedOption]);

  const formatDateWithoutTimezone = (date) => {
    return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  // Calculate startDate as 30 days before selectedDate
  const calculateDateRange = (selectedDate) => {
    const endDate = formatDateWithoutTimezone(selectedDate);
    const startDate = formatDateWithoutTimezone(dayjs(selectedDate).subtract(30, "day"));
    return [startDate, endDate];
  };

  return (
    <>
      <HeadingBar
        setPlatformName={setPlatformName}
        selectedDate={calculateDateRange(selectedDate)}
        platformName={platformName}
      />

      <Toolbar>
        <ComponentSwitch options={options} targetBy={selectedOption} setTargetBy={setSelectedOption} />
        {selectedOption === 0 && (
          <div className="flex flex-col mt-[1rem] w-full overflow-hidden first-letter h-max">
            {platformName === "Blinkit" && <SearchSuggesionSectionsContainer />}
            {platformName === "Zepto" && <AwarenessPerformanceZeptoSectionsContainer />}
          </div>
        )}
        {selectedOption === 1 && (
          <div className="rounded-lg w-full flex flex-col items-center mt-[1rem]">
            {platformName === "Blinkit" && <BannerListingSectionsContainer />}
          </div>
        )}
      </Toolbar>
    </>
  );
};

export default DisplayAdScreen;
