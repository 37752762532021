import {
  INSIGHTS_CITY_FAILURE,
  INSIGHTS_CITY_REQUEST,
  INSIGHTS_CITY_SUCCESS,
  INSIGHTS_FAILURE,
  INSIGHTS_PRODUCT_FAILURE,
  INSIGHTS_PRODUCT_PREVIOUS_FAILURE,
  INSIGHTS_PRODUCT_PREVIOUS_REQUEST,
  INSIGHTS_PRODUCT_PREVIOUS_SUCCESS,
  INSIGHTS_PRODUCT_REQUEST,
  INSIGHTS_PRODUCT_SUCCESS,
  INSIGHTS_REQUEST,
  INSIGHTS_SUCCESS,
} from "../constants/insightsConstants";

export const getInsightsRequest = () => {
  return {
    type: INSIGHTS_REQUEST,
  };
};
export const getInsightsSuccess = (payload) => {
  return {
    type: INSIGHTS_SUCCESS,
    payload: payload,
  };
};

export const getInsightsError = (error) => {
  return {
    type: INSIGHTS_FAILURE,
    error: error,
  };
};

export const getInsightsCityRequest = () => {
  return {
    type: INSIGHTS_CITY_REQUEST,
  };
};
export const getInsightsCitySuccess = (payload) => {
  return {
    type: INSIGHTS_CITY_SUCCESS,
    payload: payload,
  };
};

export const getInsightsCityError = (error) => {
  return {
    type: INSIGHTS_CITY_FAILURE,
    error: error,
  };
};

export const getInsightsProductRequest = () => {
  return {
    type: INSIGHTS_PRODUCT_REQUEST,
  };
};
export const getInsightsProductSuccess = (payload) => {
  return {
    type: INSIGHTS_PRODUCT_SUCCESS,
    payload: payload,
  };
};

export const getInsightsProductError = (error) => {
  return {
    type: INSIGHTS_PRODUCT_FAILURE,
    error: error,
  };
};

//

export const getInsightsProductPreviousRequest = () => {
  return {
    type: INSIGHTS_PRODUCT_PREVIOUS_REQUEST,
  };
};
export const getInsightsProductPreviousSuccess = (payload) => {
  return {
    type: INSIGHTS_PRODUCT_PREVIOUS_SUCCESS,
    payload: payload,
  };
};

export const getInsightsProductPreviousError = (error) => {
  return {
    type: INSIGHTS_PRODUCT_PREVIOUS_FAILURE,
    error: error,
  };
};
