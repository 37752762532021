export const groupedDataByKeywords = (arr) => {
  const groupedData = arr?.reduce((acc, item) => {
    const type = item["marketting_report_blinkit_product_listing.targeting_type"];

    // Only process items with targeting_type 'Keyword'
    if (type === "Keyword") {
      const title = item["marketting_report_blinkit_product_listing.targeting_value"];
      const impressions = parseInt(item["marketting_report_blinkit_product_listing.impressions"], 10) || 0;
      const directAtc = parseInt(item["marketting_report_blinkit_product_listing.direct_atc"], 10) || 0;
      const indirectAtc = parseInt(item["marketting_report_blinkit_product_listing.indirect_atc"], 10) || 0;
      const dQuantitiesSold = parseInt(item["marketting_report_blinkit_product_listing.d_quantities_sold"], 10) || 0;
      const inQuantitiesSold = parseInt(item["marketting_report_blinkit_product_listing.in_quantities_sold"], 10) || 0;

      const estimateBudget = parseInt(item["marketting_report_blinkit_product_listing.estimate_budget"], 10) || 0;

      const cpm = parseInt(item["marketting_report_blinkit_product_listing.cpm"], 10) || 0;

      if (!acc[title]) {
        acc[title] = {
          cpm: 0,
          impressions: 0,
          addToCarts: 0,
          totalQuantitiesSold: 0,
          estimateBudget: 0,
          title: title,
          count: 0,
        };
      }

      acc[title].impressions += impressions;
      acc[title].addToCarts += directAtc + indirectAtc;
      acc[title].totalQuantitiesSold += dQuantitiesSold + inQuantitiesSold;
      acc[title].estimateBudget += estimateBudget;
      acc[title].cpm += cpm;
      acc[title].count += 1;
    }

    return acc;
  }, {});
  return groupedData;
};

export const groupedDataByDate = (arr) => {
  // const arr = arrNew.sort(
  //   (a, b) =>
  //     a["marketting_report_blinkit_product_listing.date"] -
  //     b["marketting_report_blinkit_product_listing.date"]
  // );
  const obj = arr?.reduce((acc, curr) => {
    const date = curr["marketting_report_blinkit_product_listing.date"];
    const targetingType = curr["marketting_report_blinkit_product_listing.targeting_type"];
    const keyword = curr["marketting_report_blinkit_product_listing.targeting_value"]; // Assuming `targeting_value` is the keyword

    if (targetingType === "Keyword") {
      if (!acc[date]) {
        acc[date] = {
          impressions: 0,
          totalQuantitiesSold: 0,
          addToCart: 0,
          estimateBudget: 0,
          cpm: 0,
          count: 0,
        };
      }

      acc[date].impressions += parseInt(curr["marketting_report_blinkit_product_listing.impressions"], 10);
      acc[date].addToCart +=
        parseInt(curr["marketting_report_blinkit_product_listing.direct_atc"], 10) +
        parseInt(curr["marketting_report_blinkit_product_listing.indirect_atc"], 10);
      acc[date].totalQuantitiesSold +=
        parseInt(curr["marketting_report_blinkit_product_listing.d_quantities_sold"], 10) +
        parseInt(curr["marketting_report_blinkit_product_listing.in_quantities_sold"], 10);

      acc[date].cpm += parseInt(curr["marketting_report_blinkit_product_listing.cpm"], 10);

      acc[date].estimateBudget += parseInt(curr["marketting_report_blinkit_product_listing.estimate_budget"], 10);
      acc[date].count += 1;
    }

    return acc;
  }, {});
  return obj;
};

export const groupedDataByCampaignName = (data) => {
  return data?.reduce((acc, item) => {
    const title = item["marketting_report_blinkit_product_listing.campaign_name"];

    if (!acc[title]) {
      acc[title] = {
        title,
        cpm: 0,
        totaRoas: 0,
        directAddToCart: 0,
        indirectAddToCart: 0,
        addToCarts: 0,
        impressions: 0,
        indirectSales: 0,
        directQuantitiesSold: 0,
        indirectQuantitiesSold: 0,
        totalQuantitiesSold: 0,
        directSales: 0,
        estimateBudget: 0,
        newUsersAcquired: 0,
        // as MVP
        mostViewedPosition: 0,
        count: 0,
      };
    }

    acc[title].cpm += parseFloat(item["marketting_report_blinkit_product_listing.cpm"] || 0);
    acc[title].totaRoas += parseFloat(item["marketting_report_blinkit_product_listing.total_roas"] || 0);
    acc[title].directAddToCart += parseInt(item["marketting_report_blinkit_product_listing.direct_atc"] || 0, 10);
    acc[title].indirectAddToCart += parseInt(item["marketting_report_blinkit_product_listing.indirect_atc"] || 0, 10);
    acc[title].addToCarts = acc[title].directAddToCart + acc[title].indirectAddToCart;
    acc[title].impressions += parseInt(item["marketting_report_blinkit_product_listing.impressions"] || 0, 10);
    acc[title].indirectSales += parseInt(item["marketting_report_blinkit_product_listing.indirect_sales"] || 0, 10);
    acc[title].directQuantitiesSold += parseInt(
      item["marketting_report_blinkit_product_listing.d_quantities_sold"] || 0,
      10
    );
    acc[title].indirectQuantitiesSold += parseInt(
      item["marketting_report_blinkit_product_listing.in_quantities_sold"] || 0,
      10
    );
    acc[title].totalQuantitiesSold = acc[title].directQuantitiesSold + acc[title].indirectQuantitiesSold;
    acc[title].directSales += parseFloat(item["marketting_report_blinkit_product_listing.direct_sales"] || 0);
    acc[title].estimateBudget += parseFloat(item["marketting_report_blinkit_product_listing.estimate_budget"] || 0);
    acc[title].newUsersAcquired += parseInt(
      item["marketting_report_blinkit_product_listing.new_users_acquired"] || 0,
      10
    );
    acc[title].mostViewedPosition += parseInt(
      item["marketting_report_blinkit_product_listing.most_viewed_position"] || 0,
      10
    );
    acc[title].count += 1;

    return acc;
  }, {});
};

export const groupedDataByCampaign = (data) => {
  const groupedData = data?.reduce((acc, item) => {
    const campaignName = item["marketting_report_blinkit_product_listing.campaign_name"];

    if (!acc[campaignName]) {
      acc[campaignName] = [];
    }

    acc[campaignName].push({
      date: item["marketting_report_blinkit_product_listing.date"],
      cpm: item["marketting_report_blinkit_product_listing.cpm"],
      totalRoas: item["marketting_report_blinkit_product_listing.total_roas"],
      directAtc: item["marketting_report_blinkit_product_listing.direct_atc"],
      indirectAtc: item["marketting_report_blinkit_product_listing.indirect_atc"],
      impressions: item["marketting_report_blinkit_product_listing.impressions"],
      indirectSales: item["marketting_report_blinkit_product_listing.indirect_sales"],
      directQuantitiesSold: item["marketting_report_blinkit_product_listing.d_quantities_sold"],
      indirectQuantitiesSold: item["marketting_report_blinkit_product_listing.in_quantities_sold"],
      directSales: item["marketting_report_blinkit_product_listing.direct_sales"],
      estimateBudget: item["marketting_report_blinkit_product_listing.estimate_budget"],
      targetingType: item["marketting_report_blinkit_product_listing.targeting_type"],
      targetingValue: item["marketting_report_blinkit_product_listing.targeting_value"],
      newUsersAcquired: item["marketting_report_blinkit_product_listing.new_users_acquired"],
      mostViewedPosition: item["marketting_report_blinkit_product_listing.most_viewed_position"],
    });

    return acc;
  }, {});

  Object.keys(groupedData).forEach((campaignName) => {
    groupedData[campaignName].sort((a, b) => new Date(a.date) - new Date(b.date));
  });
  return groupedData;
};
