import React, { createContext, useState, useContext } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const showAlert = (severity, message) => {
    setAlert(
      <Alert severity={severity} className="fixed top-4 right-4 z-50">
        <AlertTitle>
          {severity.charAt(0).toUpperCase() + severity.slice(1)}
        </AlertTitle>
        {message}
      </Alert>
    );

    setTimeout(() => {
      setAlert(null);
    }, 3000); // Hide the alert after 3 seconds
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert }}>
      {children}
      {alert}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
