import React from "react";
import { useNavigate } from "react-router-dom";

const CampaignsRevenueCard = ({ path, data, type, heading }) => {
  const navigate = useNavigate();

  const handleViewMore = () => {
    navigate(path); // This will navigate to the /ads page
  };

  return (
    <div onClick={handleViewMore} className="hover:cursor-pointer bg-white h-full">
      <div className="flex justify-between items-center">
        <h2 className="text-md leading-5 text-[#030229] font-body font-medium text-start mb-2">{heading}</h2>
      </div>
      <div className="px-3 py-4 border  rounded-lg">
        {data.length > 0 ? (
          <ul className="space-y-2  ">
            {data?.map((campaign, index) => (
              <li
                key={index}
                className={`flex flex-col justify-between h-[100px] w-[320px] px-3 py-4 border-[0.4px] rounded-lg shadow-sm`}
              >
                <div className="flex items-start gap-3">
                  {/* Icon */}
                  <img src="/icons/campaignIcon.svg" />

                  <strong className="text-md font-medium font-body text-[#727278] leading-6">{campaign.title}</strong>
                </div>
                <div className="flex justify-between gap-2">
                  <span className={`text-xs font-semibold ${type === "gainers" ? "text-[#22C55E]" : "text-[#F53E3E]"}`}>
                    ₹ {campaign.directSales?.toFixed(2) || campaign.revenue?.toFixed(2)}
                  </span>
                  <div className="flex gap-1 items-center">
                    <span className="text-[0.65rem] text-[#727278]">{`${
                      campaign.totalQuantitiesSold
                        ? "Qty Sold:"
                        : campaign.roi
                        ? "ROI:"
                        : campaign.totaRoas
                        ? "Total ROAS:"
                        : ""
                    }`}</span>
                    <span className="text-xs font-semibold text-[#387FF5]">
                      {campaign.totalQuantitiesSold
                        ? campaign.totalQuantitiesSold?.toFixed(2)
                        : campaign.roi
                        ? campaign.roi?.toFixed(2)
                        : campaign.totaRoas
                        ? campaign.totaRoas?.toFixed(2)
                        : ""}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex justify-center items-center h-full">No Top Revenue Campaigns</div>
        )}
      </div>
    </div>
  );
};

export default CampaignsRevenueCard;
