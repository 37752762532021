import React from "react";
import { IoReload } from "react-icons/io5";

const Error = () => {
  return (
    <div className="text-gray-600 h-96 w-full flex justify-center items-center flex-col gap-2">
      <div className="font-bold">Data is not available for the past 15 days. Choose another date.</div>
      {/* <IoReload className="cursor-pointer text-4xl" onClick={() => window.location.reload()} /> */}
    </div>
  );
};

export default Error;
