import React from "react";

const NoDataFound = ({ height = 400, width = 400, maximum = true }) => {
  return (
    <div className={`w-full ${maximum && "h-[520px]"} flex justify-center items-center rounded-xl`}>
      <img
        height={height}
        width={width}
        src="/icons/no_data_found_img.svg"
        alt="no-data-found"
        className="rounded-xl"
      />
    </div>
  );
};

export default NoDataFound;
