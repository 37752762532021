import React, { useEffect, useState } from "react";
import ShareOfVoiceSelectBox from "./ShareOfVoiceSelectBox";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import ShareOfVoiceKeyInsightsTable from "./ShareOfVoiceKeyInsightsTable";
import { useSelector } from "react-redux";

const ShareOfVoiceKeyInsights = () => {
  const { sovKeywordData, sovAreaNameData, filteredAreaNames, selectedSovKeyword, selectedSovAreaName } = useSelector(
    (state) => state.sovThirdParty
  );

  console.log("filteredAreaNames====", filteredAreaNames);

  const user = JSON.parse(localStorage.getItem("user"));
  const ourBrand = user.organizationDetail.name;
  const [selectedCity, setSelectedCity] = useState("");
  const allCities = [
    "All Areas",
    ...Array.from(new Set(filteredAreaNames.data?.map((item) => item["third_party_keyword.area_name_lm"]))),
  ];
  const [isCompetitorBrand, setIsCompetitorBrand] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setSelectedCity(() => allCities[0]);
  }, [filteredAreaNames.data]);

  const fetchTableData = () => {
    if (isCompetitorBrand) {
      if (selectedCity == "All Areas") {
        setTableData(
          filteredAreaNames.data?.filter(
            (item) =>
              item["third_party_keyword.brand"] != ourBrand &&
              item["third_party_keyword.keyword"].includes(selectedSovKeyword) &&
              item
          )
        );
      } else {
        setTableData(
          filteredAreaNames.data?.filter(
            (item) =>
              item["third_party_keyword.area_name_lm"] == selectedCity &&
              item["third_party_keyword.keyword"].includes(selectedSovKeyword) &&
              item["third_party_keyword.brand"] != ourBrand &&
              item
          )
        );
      }
    } else {
      if (selectedCity == "All Areas") {
        setTableData(
          filteredAreaNames.data?.filter(
            (item) =>
              item["third_party_keyword.brand"] == ourBrand &&
              item["third_party_keyword.keyword"].includes(selectedSovKeyword) &&
              item
          )
        );
      } else {
        setTableData(
          filteredAreaNames.data?.filter(
            (item) =>
              item["third_party_keyword.area_name_lm"] == selectedCity &&
              item["third_party_keyword.keyword"].includes(selectedSovKeyword) &&
              item["third_party_keyword.brand"] == ourBrand &&
              item
          )
        );
      }
    }
  };

  console.log("latest table data=====", tableData);

  // Helper function to sum percentages
  const sumPercentage = (value1, value2) => (parseFloat(value1) + parseFloat(value2)).toFixed(2);

  const addingAdandOrganicOfSameData = tableData.reduce((acc, curr) => {
    const keyword = curr["third_party_keyword.keyword"];
    const area = curr["third_party_keyword.area_name_lm"];
    const product = curr["third_party_keyword.product_name"];
    const keyword_type = curr["third_party_keyword.keyword_type"];
    const brand = curr["third_party_keyword.brand"];
    // Create a unique key based on the grouping criteria
    const key = `${keyword}_${area}_${product}`;

    if (!acc[key]) {
      acc[key] = {
        "third_party_keyword.keyword": keyword,
        "third_party_keyword.keyword_type": keyword_type,
        "third_party_keyword.area_name_lm": area,
        "third_party_keyword.product_name": product,
        "third_party_keyword.brand": brand,
        "third_party_keyword.totalSovAdPercentage": parseFloat(curr["third_party_keyword.sov_ad_percentage"]) || 0,
        "third_party_keyword.totalSovOrgaincPercentage":
          parseFloat(curr["third_party_keyword.sov_organic_percentage"]) || 0,
      };
    } else {
      // Add percentages for matching entries
      acc[key]["third_party_keyword.totalSovAdPercentage"] = sumPercentage(
        acc[key]["third_party_keyword.totalSovAdPercentage"],
        curr["third_party_keyword.sov_ad_percentage"]
      );
      acc[key]["third_party_keyword.totalSovOrgaincPercentage"] = sumPercentage(
        acc[key]["third_party_keyword.totalSovOrgaincPercentage"],
        curr["third_party_keyword.sov_organic_percentage"]
      );
    }

    return acc;
  }, {});

  // Convert the grouped object back to an array
  const clubedDataBySameEntries = Object.values(addingAdandOrganicOfSameData);
  console.log("clubedDataBySameEntries====", clubedDataBySameEntries);

  useEffect(() => {
    fetchTableData();
  }, [selectedCity, isCompetitorBrand]);

  return (
    <div>
      <div>
        Showing Key Insights for Keyword :{" "}
        <span className="bg-[#dee7f5] text-[#387FF5] py-1 px-2 rounded">
          {selectedSovKeyword == "" ? "All Keywords" : selectedSovKeyword}
        </span>
      </div>
      <ShareOfVoiceSelectBox
        text={"Chose City"}
        items={allCities}
        selectedItem={selectedCity}
        setSelectedItem={setSelectedCity}
      />
      <FormGroup>
        <FormControlLabel
          control={<Switch />}
          label="Show Competitor's Data"
          onClick={(e) => {
            setIsCompetitorBrand(e.target.checked);
          }}
        />
      </FormGroup>
      <div className="max-h-[70vh] overflow-y-scroll p-4 mb-20">
        <ShareOfVoiceKeyInsightsTable tableData={clubedDataBySameEntries} />
      </div>
    </div>
  );
};

export default ShareOfVoiceKeyInsights;
